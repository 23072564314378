import { Empty, Flex, message, PaginationProps, Skeleton } from 'antd'
import { v1AgentListList } from 'api/Api'
import { AgentTypeAgentType } from 'api/data-contracts'
import CustomPagination from 'components/custom-pagination'
import MainLayout from 'layouts/portal/main-layout'
import React, { useCallback, useEffect, useState } from 'react'
import { Agent as AgentType, type AgentList } from 'stores/models/agent'
import { getMessageFromError } from 'utils/common'
import AgentCard, { ActionType } from './agent-card'
import AgentModal, { AgentModalProps } from './agent-modal'
import styles from './index.scss'

const DEFAULT_AGENT_DATA: AgentModalProps['data'] = {
  name: '',
  description: '',
  agentType: AgentTypeAgentType.Chatbot,
  iconId: 0,
}

const Agent: React.FC = () => {
  const [agent, setAgent] =
    useState<AgentModalProps['data']>(DEFAULT_AGENT_DATA)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [agents, setAgents] = useState<AgentType[]>([])
  const [loading, setLoading] = useState(false)
  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false)

  const fetchAgents = useCallback(async (page: number, size: number) => {
    try {
      const response = await v1AgentListList({ page, size })

      // TODO: update AgentList type to ResponseAgentResponse from src/api/data-contracts.ts
      const agentList: AgentList = {
        totalCount: response.data.totalCount,
        agents: response.data.agents.map((agent) => ({
          ...agent,
          id: agent.id,
          uuid: agent.uuid,
          agentName: agent.agentName,
          agentType: agent.agentType as AgentTypeAgentType,
          description: agent.description,
          agentIconUUID: agent.agentIconUUID,
          agentIconID: agent.agentIconID,
          organizationId: agent.organizationId,
          updatedAt: agent.updatedAt,
          createdAt: agent.createdAt,
        })),
      }

      return agentList
    } catch (error) {
      throw error
    }
  }, [])

  useEffect(() => {
    let isMounted = true

    const loadData = async () => {
      if (!isMounted) return

      setLoading(true)

      try {
        const data = await fetchAgents(page, size)
        if (isMounted) {
          setAgents(data.agents)
          setTotal(data.totalCount)
        }
      } catch (error) {
        const errorMessage = getMessageFromError(error)
        message.error(errorMessage)
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    loadData()

    return () => {
      isMounted = false
    }
  }, [page, size, fetchAgents])

  const onChange: PaginationProps['onChange'] = (page, size) => {
    setPage(page)
    setSize(size)
  }

  const handleFinish = async (agentUUID?: string) => {
    setIsAgentModalOpen(false)
    // Refetch the agent list after adding or updating an agent
    setLoading(true)
    try {
      const data = await fetchAgents(page, size)
      setAgents(data.agents)
      setTotal(data.totalCount)
    } catch (error) {
      const errorMessage = getMessageFromError(error)
      message.error(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const handleCreateAgentClick = () => {
    setAgent(DEFAULT_AGENT_DATA)
    setIsAgentModalOpen(true)
  }

  const handleAgentAction = (type: ActionType, agent: AgentType) => {
    if (type === ActionType.Edit) {
      setAgent({
        ...agent,
        name: agent.agentName,
        iconId: agent.agentIconID,
        iconUuid: agent.agentIconUUID,
      })
      setIsAgentModalOpen(true)
    } else if (type === ActionType.Delete) {
      // Update the local state to reflect the deletion
      setAgents((currentAgents) =>
        currentAgents.filter((a) => a.id !== agent.id)
      )
      setTotal((currentTotal) => currentTotal - 1)
    }
  }

  return (
    <MainLayout
      title="Agent"
      noBack
      noHeaderBottomLine
      actions={[
        {
          text: 'Create Agent',
          isPrimary: true,
          onClick: handleCreateAgentClick,
        },
      ]}
    >
      <div className={styles.agentList}>
        <Skeleton loading={loading} active>
          {agents.length > 0 ? (
            agents.map((agent) => (
              <AgentCard
                key={agent.id}
                data={{
                  ...agent,
                  name: agent.agentName,
                  iconId: agent.agentIconID,
                  iconUuid: agent.agentIconUUID,
                }}
                onAction={handleAgentAction}
              />
            ))
          ) : (
            <Flex justify="start" style={{ width: '100%' }}>
              <Empty />
            </Flex>
          )}
        </Skeleton>
      </div>
      <div className="pagination">
        <CustomPagination
          total={total}
          current={page}
          pageSize={size}
          onChange={onChange}
          align="center"
        />
      </div>
      <AgentModal
        open={isAgentModalOpen}
        okText="Submit"
        data={agent}
        onFinish={handleFinish}
        onCancel={() => setIsAgentModalOpen(false)}
      />
    </MainLayout>
  )
}

export default Agent
