import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Divider,
  Input,
  InputNumber,
  Select,
  Slider,
  Switch,
  message,
} from 'antd'
import { v1LlmModelsList } from 'api/Api'
import { ResponsesLLMResponse } from 'api/data-contracts'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import WorkflowStore from 'stores/workflow'
import InputField from '../../components/input-field'
import NodeSidebar from '../../components/node-sidebar'
import Section from '../../components/section'
import TemplateEditor from '../../components/template-editor'
import { FlowContext } from '../../context'
import {
  CustomNodeProps,
  DataType,
  InputField as InputFieldType,
  InputType,
  IntentBranch,
  IntentSample,
  NodeData,
  OutputField,
} from '../../model'
import IntentEditor from './intent-editor'
import IntentSettingsModal from './setting'

const { TextArea } = Input
const { Option, OptGroup } = Select
const MAX_INTENTS = 50
const CONVERSATION_CONTEXT_NUMBER = 20

const IntentNodeSidebar: React.FC = observer(() => {
  const context = useContext(FlowContext)
  const [modalVisible, setModalVisible] = useState(false)
  const [curIntentIdx, setCurIntentIdx] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)
  const currentIntent = useMemo(() => {
    if (
      !WorkflowStore.selectedNode?.data?.data?.intentBranch ||
      curIntentIdx === null
    )
      return null
    return WorkflowStore.selectedNode?.data?.data?.intentBranch?.[curIntentIdx]
  }, [curIntentIdx])
  const [modelList, setModelList] = useState<ResponsesLLMResponse[]>([])

  const groupedModels = useMemo(
    () =>
      modelList.reduce<Record<string, ResponsesLLMResponse[]>>((pre, cur) => {
        const { developer } = cur
        const curGroup = Reflect.get(pre, developer)
        if (!curGroup) {
          Reflect.set(pre, developer, [cur])
        } else {
          curGroup.push(cur)
        }
        return pre
      }, {}),
    [modelList]
  )

  const fetchModels = async () => {
    setLoading(true)
    const getModelsResp = await v1LlmModelsList()
    setLoading(false)

    setModelList(getModelsResp?.data ?? [])
    WorkflowStore.setModels(getModelsResp?.data ?? [])
  }

  const handleClose = () => {
    WorkflowStore.selectNode(null)
  }

  const handleOpenSettings = (index: number) => {
    setCurIntentIdx(index)
    setModalVisible(true)
  }

  const handleCloseSettings = () => {
    setCurIntentIdx(null)
    setModalVisible(false)
  }

  const handleSaveSettings = (
    intent: IntentBranch,
    samples: IntentSample[],
    outputVariables: OutputField[]
  ) => {
    const currentIntentBranch =
      WorkflowStore.selectedNode?.data?.data?.intentBranch || []
    const currentBranchOutput =
      WorkflowStore.selectedNode?.data?.data?.branchOutput || {}

    const updatedIntentBranch = currentIntentBranch.map((branch) => {
      if (branch === intent) {
        const updatedBranch = {
          ...branch,
          samples: samples,
          output: outputVariables,
        }

        const branchOutputKey = `branch_${branch.priority}`
        currentBranchOutput[branchOutputKey] = {
          ...currentBranchOutput[branchOutputKey],
          output: outputVariables,
        }

        return updatedBranch
      }
      return branch
    })

    handleNodeDataChange('intentBranch', updatedIntentBranch)
    handleNodeDataChange('branchOutput', currentBranchOutput)
    handleCloseSettings()
  }

  const getUniqueIntentPriority = (currentIntents: IntentBranch[]): number => {
    const usedPriorities = currentIntents
      .filter((intent) => !intent.isElse && !intent.isError)
      .map((intent) => intent.priority)
    let newPriority = 1
    while (usedPriorities.includes(newPriority)) {
      newPriority++
    }
    return newPriority
  }
  const handleAddIntent = () => {
    const currentIntentBranch =
      WorkflowStore.selectedNode?.data?.data?.intentBranch || []
    const currentBranchOutput =
      WorkflowStore.selectedNode?.data?.data?.branchOutput || {}
    const regularIntents = currentIntentBranch.filter(
      (branch) => !branch.isElse && !branch.isError
    )

    if (regularIntents.length < MAX_INTENTS) {
      const newPriority = getUniqueIntentPriority(currentIntentBranch)
      const newIntent: IntentBranch = {
        priority: newPriority,
        intent: `Intent ${newPriority}`,
        description: '',
        samples: [],
        output: [],
      }

      const newBranchOutput = {
        [`branch_${newPriority}`]: {
          branchID: newPriority.toString(),
          output: [],
        },
      }

      const updatedIntentBranch = [...currentIntentBranch, newIntent]
      const updatedBranchOutput = {
        ...currentBranchOutput,
        ...newBranchOutput,
      }

      handleNodeDataChange('intentBranch', updatedIntentBranch)
      handleNodeDataChange('branchOutput', updatedBranchOutput)
    } else {
      message.warning(`You can add a maximum of ${MAX_INTENTS} intents.`)
    }
  }

  const handleRemoveIntent = (intentToRemove: string, idx: number) => {
    const currentIntentBranch =
      WorkflowStore.selectedNode?.data?.data?.intentBranch || []
    const currentBranchOutput =
      WorkflowStore.selectedNode?.data?.data?.branchOutput || {}

    const updatedIntentBranch = currentIntentBranch
      .slice(0, idx)
      .concat(currentIntentBranch.slice(idx + 1))

    const removedBranch = Reflect.get(currentIntentBranch, idx)

    if (!removedBranch) return
    const updatedBranchOutput = { ...currentBranchOutput }
    delete updatedBranchOutput[`branch_${removedBranch.priority}`]

    handleNodeDataChange('intentBranch', updatedIntentBranch)
    handleNodeDataChange('branchOutput', updatedBranchOutput)
  }

  const handleIntentChange = (
    intentToChange: string,
    field: keyof IntentBranch,
    value: any,
    idx: number
  ) => {
    if (
      !WorkflowStore.selectedNode?.data?.data ||
      !WorkflowStore.selectedNode.data.onChange
    )
      return

    const currentNodeData = { ...WorkflowStore.selectedNode.data.data }
    const currentIntentBranch = currentNodeData.intentBranch || []
    const currentBranchOutput = currentNodeData.branchOutput || {}

    const curIntentBranch = Reflect.get(currentIntentBranch, idx)
    if (!curIntentBranch) return

    const updatedBranch = { ...curIntentBranch, [field]: value }
    const branchOutputKey = `branch_${curIntentBranch.priority}`
    if (field === 'output') {
      currentBranchOutput[branchOutputKey] = {
        ...currentBranchOutput[branchOutputKey],
        output: value,
      }
    } else if (field === 'intent') {
      currentBranchOutput[branchOutputKey] = {
        ...currentBranchOutput[branchOutputKey],
        branchID: curIntentBranch.priority.toString(),
      }
    }

    Reflect.set(currentIntentBranch, idx, updatedBranch)

    handleNodeDataChange('intentBranch', currentIntentBranch)
    handleNodeDataChange('branchOutput', currentBranchOutput)
  }

  const handleNodeDataChange = (
    field: string,
    value: any,
    dataType: DataType = 'String'
  ) => {
    if (WorkflowStore.selectedNode?.data?.data) {
      const currentNodeData = { ...WorkflowStore.selectedNode.data.data }
      let updatedNodeData = undefined

      if (field.startsWith('input')) {
        const key = field.split('-')[1]
        let updatedInput = [...(currentNodeData.input || [])]
        const inputIndex = updatedInput.findIndex((input) => input.name === key)
        if (inputIndex !== -1) {
          updatedInput[inputIndex] = { ...updatedInput[inputIndex], value }
        } else {
          const newInputField: InputFieldType = {
            name: key,
            type: 'input',
            dataType: dataType,
            value: value,
            reference: '',
          }
          updatedInput.push(newInputField)
        }
        updatedNodeData = {
          ...(WorkflowStore.selectedNode.data as NodeData),
          data: {
            ...currentNodeData,
            input: updatedInput,
          },
        }
      } else {
        updatedNodeData = {
          ...(WorkflowStore.selectedNode.data as NodeData),
          data: {
            ...currentNodeData,
            [field]: value,
          },
        }
      }

      const updatedNode = {
        ...WorkflowStore.selectedNode,
        data: updatedNodeData,
      }

      WorkflowStore.selectNode(updatedNode as CustomNodeProps)

      if (WorkflowStore.selectedNode.data.onChange) {
        WorkflowStore.selectedNode.data.onChange(
          WorkflowStore.selectedNode.id,
          updatedNodeData
        )
      }
    }
  }
  const getInput = (name: string): InputFieldType => {
    const input = WorkflowStore.selectedNode?.data?.data?.input.find(
      (i: InputFieldType) => i.name === name
    )
    if (name === 'chat_context') {
      return (
        input ||
        ({
          name,
          type: 'input',
          dataType: 'Boolean',
          value: false,
          reference: '',
        } as InputFieldType)
      )
    }
    if (name === 'model_id') {
      return (
        input ||
        ({
          name: 'model_id',
          type: 'input',
          dataType: 'Integer',
          value: '',
          reference: '',
        } as InputFieldType)
      )
    }
    if (name === 'temperature' || name === 'top_p') {
      return (
        input ||
        ({
          name,
          type: 'input' as InputType,
          dataType: 'Number' as DataType,
          value: 1,
          reference: '',
        } as InputFieldType)
      )
    }

    return (
      input ||
      ({
        name,
        type: 'input',
        dataType: 'String',
        value: '',
        reference: '',
      } as InputFieldType)
    )
  }

  const handleFormChange = (
    name: string,
    key: keyof InputFieldType,
    value: any
  ) => {
    if (WorkflowStore.selectedNode?.data?.data) {
      const currentNodeData = { ...WorkflowStore.selectedNode.data.data }
      const updatedInput = [...(currentNodeData.input || [])]

      const existingInputIndex = updatedInput.findIndex(
        (input) => input.name === name
      )
      if (existingInputIndex !== -1) {
        updatedInput[existingInputIndex] = {
          ...updatedInput[existingInputIndex],
          [key]: value,
        }
      } else {
        const newInput = getInput(name)
        newInput[key] = value
        updatedInput.push(newInput)
      }

      const updatedNodeData = {
        ...(WorkflowStore.selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          input: updatedInput,
        },
      }

      const updatedNode = {
        ...WorkflowStore.selectedNode,
        data: updatedNodeData,
      }

      WorkflowStore.selectNode(updatedNode as CustomNodeProps)

      if (WorkflowStore.selectedNode.data.onChange) {
        WorkflowStore.selectedNode.data.onChange(
          WorkflowStore.selectedNode.id,
          updatedNodeData
        )
      }
    }
  }

  const getInputValue = (name: string): any => {
    const input = getInput(name)
    return input.value
  }

  useEffect(() => {
    fetchModels()
  }, [])

  if (WorkflowStore.selectedNode?.type !== 'Intent') {
    return null
  }
  return (
    <NodeSidebar
      nodeType={'intent'}
      onClose={handleClose}
      nodeData={WorkflowStore.selectedNode?.data?.data}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={WorkflowStore.selectedNode?.data?.data?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>
      <Section title="Settings">
        <div className="template-editor-item">
          <span className="label">Query</span>
          <div className="element">
            <InputField
              index={0}
              item={getInput('query')}
              currentNodeId={WorkflowStore.selectedNode.id}
              nodes={context?.nodes || []}
              edges={context?.edges || []}
              allowEditFieldName={false}
              supportArray={false}
              supportObject={false}
              displayDataType={false}
              displayFieldName={false}
              handleFormChange={(_, key, value) =>
                handleFormChange('query', key, value)
              }
            />
          </div>
        </div>
        <div className="setting-item">
          <span>Chat Context</span>
          <div>
            <Switch
              checked={getInputValue('chat_context') as boolean}
              onChange={(checked) =>
                handleFormChange('chat_context', 'value', checked)
              }
            />
          </div>
        </div>

        {getInputValue('chat_context') && (
          <div className="setting-item">
            <span>Number of Conversation Record</span>
            <InputNumber
              min={0}
              max={100}
              value={getInputValue('conversation_context_number')}
              onChange={(value) =>
                handleNodeDataChange(
                  'input-conversation_context_number',
                  value,
                  'Number'
                )
              }
            />
          </div>
        )}

        <div className="setting-item">
          <span>Model</span>
          <div className="element">
            <Select
              value={getInputValue('model_id') as number}
              onChange={(value) => handleFormChange('model_id', 'value', value)}
              style={{ width: '205px' }}
              dropdownStyle={{ width: '300px' }}
              loading={loading}
            >
              {Object.entries(groupedModels).map(([developer, models]) => (
                <OptGroup key={developer} label={developer}>
                  {models.map((model) => (
                    <Option key={model.id} value={model.id}>
                      {`${model.name} - Context: ${model.contextWindow} tokens`}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </div>
        </div>

        {getInputValue('model_id') && (
          <div className="setting-item">
            <span className="label">Max Tokens</span>
            <InputNumber
              min={0}
              max={
                modelList.find((m) => m.id === getInputValue('model_id'))
                  ?.contextWindow
              }
              value={getInputValue('max_tokens')}
              onChange={(value) =>
                handleNodeDataChange('input-max_tokens', value, 'Number')
              }
            />
          </div>
        )}

        <div className="setting-item">
          <span>Temperature</span>
          <div className="element">
            <Slider
              min={0}
              max={2}
              step={0.01}
              value={getInputValue('temperature') as number}
              onChange={(value) =>
                handleFormChange('temperature', 'value', value)
              }
            />
          </div>
        </div>
        <div className="setting-item">
          <span>Top P</span>
          <div className="element">
            <Slider
              min={0}
              max={1}
              step={0.01}
              value={getInputValue('top_p') as number}
              onChange={(value) => handleFormChange('top_p', 'value', value)}
            />
          </div>
        </div>
      </Section>
      <Section title="Prompts">
        <div className="template-editor-item">
          <span className="label">System Prompt</span>
          <div className="element">
            <TemplateEditor
              value={getInputValue('system_prompt') || ''}
              onChange={(value) =>
                handleFormChange('system_prompt', 'value', value)
              }
              currentNodeID={WorkflowStore.selectedNode?.id || ''}
              nodes={context?.nodes ?? []}
              edges={context?.edges ?? []}
            />
          </div>
        </div>
      </Section>
      <Section title="Intents">
        {(WorkflowStore.selectedNode?.data?.data?.intentBranch || []).map(
          (intent: IntentBranch, idx) => {
            if (intent.isElse || intent.isError) return null

            const curIntentBranches =
              WorkflowStore.selectedNode?.data?.data?.intentBranch.filter(
                (br) => br.intent === intent.intent
              )

            return (
              <React.Fragment
                key={`${intent.intent}-${intent.description}-${idx}`}
              >
                {idx !== 0 && <Divider />}
                <IntentEditor
                  index={idx}
                  intent={intent}
                  inputStatus={
                    (curIntentBranches?.length ?? 0) > 1 ? 'error' : undefined
                  }
                  onIntentChange={handleIntentChange}
                  onRemoveIntent={handleRemoveIntent}
                  onOpenSettings={handleOpenSettings}
                />
              </React.Fragment>
            )
          }
        )}
        {(WorkflowStore.selectedNode?.data?.data?.intentBranch || []).filter(
          (branch) => !branch.isElse && !branch.isError
        ).length < MAX_INTENTS && (
          <Button
            icon={<PlusOutlined />}
            onClick={handleAddIntent}
            type="dashed"
            style={{ width: '100%' }}
          >
            Add Intent
          </Button>
        )}
      </Section>

      <IntentSettingsModal
        visible={modalVisible}
        intent={currentIntent}
        onSave={handleSaveSettings}
        onCancel={handleCloseSettings}
      />
    </NodeSidebar>
  )
})

export default IntentNodeSidebar
