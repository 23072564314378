import { Handle, HandleProps, MarkerType, NodeProps } from '@xyflow/react'
import { Popover } from 'antd'
import cls from 'classnames'
import { memo, MouseEventHandler, useContext, useState } from 'react'

import { FlowContext } from '../../context'

import { CustomNodeTypes } from '../../nodes/types'
import { getNewEdgeID, getNewNodeID } from '../../utils'
import ToolMenu from '../tool-menu'
import styles from './index.scss'

export type CustomHandleProps = {
  node: NodeProps

  onClick?: (e: MouseEvent) => void | boolean
  customClassName?: string
} & HandleProps

const CustomHandle = (props: CustomHandleProps) => {
  const flowContext = useContext(FlowContext)
  const [toolOpen, setToolOpen] = useState(false)
  if (!flowContext) return null
  const { node, customClassName, onClick, ...restProps } = props
  const { nodes, setNodes, edges, setEdges } = flowContext

  const handleOpenChange = (newOpen: boolean) => {
    setToolOpen(newOpen)
  }

  const handleToolClick = (toolKey: CustomNodeTypes) => {
    if (!node || !toolKey) return

    const randomPosition = {
      x: (Math.random() + 0.5) * 350,
      y: (Math.random() + 0.5) * 200,
    }

    // create node
    const newNode = {
      id: getNewNodeID(nodes, toolKey),
      type: toolKey,
      position: {
        x: node.positionAbsoluteX + randomPosition.x,
        y: node.positionAbsoluteY + randomPosition.y,
      },
      data: {},
    }

    // create edge
    const [sourceEdgeID] = getNewEdgeID(edges, 1)

    const newSourceEdge = createNewEdge(
      sourceEdgeID,
      node.id,
      newNode.id,
      restProps.id ?? `${node.id}-source`,
      `${newNode.id}-target`
    )

    setEdges([...edges, newSourceEdge])
    setNodes([...nodes, newNode])
    setToolOpen(false)
  }

  const createNewEdge = (
    id: string,
    source: string,
    target: string,
    sourceHandle?: string | null | undefined,
    targetHandle?: string | null | undefined
  ) => {
    return {
      id,
      source,
      target,
      sourceHandle,
      targetHandle,
      type: 'custom',
      animated: true,
      style: { strokeWidth: 3 },
      markerEnd: { type: MarkerType.ArrowClosed },
    }
  }

  const handleHandleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    const customClickCallback = onClick?.(e)
    if (customClickCallback) return
    // Custom handle click event
  }

  return (
    <>
      {restProps.type === 'source' ? (
        <Popover
          placement="right"
          trigger={['hover', 'click']}
          mouseEnterDelay={1}
          destroyTooltipOnHide
          onOpenChange={handleOpenChange}
          open={toolOpen}
          content={<ToolMenu onClick={handleToolClick} />}
        >
          <Handle
            {...restProps}
            id={restProps.id ?? `${node.id}-source`}
            className={cls(
              styles.customHandle,
              styles.customHandleSource,
              customClassName
            )}
            onClick={handleHandleClick}
          />
        </Popover>
      ) : (
        <Handle
          {...restProps}
          id={restProps.id ?? `${node.id}-target`}
          className={cls(
            styles.customHandle,
            styles.customHandleTarget,
            customClassName
          )}
          onClick={handleHandleClick}
        />
      )}
    </>
  )
}

export default memo(CustomHandle)
