import { memo, useMemo, useState } from 'react'
import ComponentWrapper from '../lib/component-wrapper'
import { omitBy } from 'lodash-es'
import { CustomCompactInputValueItemType } from 'components/custom-compact-input'
import { Input, InputProps, Select, SelectProps, Space } from 'antd'
import { getCommonDefaultValue } from '../constants'

type ItemReferenceSelectProps = {
  referenceSelectConfig?: (InputProps | SelectProps) & Record<string, any>
} & Record<string, any>

export const getDefaultValue = () => getCommonDefaultValue('referenceSelect')

const ItemReferenceSelect = (props: ItemReferenceSelectProps) => {
  const { referenceSelectConfig } = props
  const [curSelectType, setCurSelectType] = useState('input')

  const filterProps = useMemo(
    () => omitBy(props, (_, k) => k !== 'onChange' && /[A-Z]/.test(k)),
    [props]
  )

  const formatCustomCompactInputValueInput = (
    value?: CustomCompactInputValueItemType[][]
  ): SelectProps['options'] => {
    const options: SelectProps['options'] = []
    if (!value) return options
    return value.map((row) => {
      const [l, v] = row
      return { label: l?.value, value: v?.value ?? '' }
    })
  }

  const customOptions = useMemo(
    () =>
      formatCustomCompactInputValueInput(referenceSelectConfig?.options as any),
    [referenceSelectConfig?.options]
  )

  return (
    <Space.Compact>
      <Select
        onChange={setCurSelectType}
        options={[
          { label: 'Input', value: 'input' },
          { label: 'Reference', value: 'reference' },
        ]}
        defaultValue={'input'}
      />
      {curSelectType === 'input' ? (
        <Input {...filterProps} {...(referenceSelectConfig as InputProps)} />
      ) : (
        <Select
          {...filterProps}
          {...(referenceSelectConfig as SelectProps)}
          options={customOptions}
        />
      )}
    </Space.Compact>
  )
}

export default ComponentWrapper(memo(ItemReferenceSelect))
