import { ComponentType } from 'react'
// Render
import ItemSection from './section'
import ItemInput from './input'
import ItemInputNumber from './input-number'
import ItemSwitch from './switch'
import ItemSlider from './slider'
import ItemCheckbox from './checkbox'
import ItemRadio from './radio'
import ItemSelect from './select'
import ItemTimePicker from './time-picker'
import ItemDatePicker from './date-picker'
import ItemUpload from './upload'
import ItemCode from './code'
import ItemReferenceSelect from './reference-select'
import ItemList from './list'
import ItemTextArea from './text-area'

// Config
import ItemSectionConfig from './section/config'
import ItemInputConfig from './input/config'
import ItemInputNumberConfig from './input-number/config'
import ItemSwitchConfig from './switch/config'
import ItemSliderConfig from './slider/config'
import ItemCheckboxConfig from './checkbox/config'
import ItemRadioConfig from './radio/config'
import ItemSelectConfig from './select/config'
import ItemTimePickerConfig from './time-picker/config'
import ItemDatePickerConfig from './date-picker/config'
import ItemUploadConfig from './upload/config'
import ItemCodeConfig from './code/config'
import ItemReferenceSelectConfig from './reference-select/config'
import ItemListConfig from './list/config'
import ItemTextAreaConfig from './text-area/config'

import { ConfigSectionType, FormItemType, FormSelectorType } from '../types'
import { WithDraggingProps } from './lib/component-wrapper'

export const FormItemRenderMap: Record<
  FormSelectorType,
  ComponentType<WithDraggingProps<FormItemType> & Record<string, any>> | null
> = {
  root: null,
  section: ItemSection,
  input: ItemInput,
  inputNumber: ItemInputNumber,
  switch: ItemSwitch,
  slider: ItemSlider,
  checkbox: ItemCheckbox,
  radio: ItemRadio,
  select: ItemSelect,
  timePicker: ItemTimePicker,
  datePicker: ItemDatePicker,
  upload: ItemUpload,
  code: ItemCode,
  referenceSelect: ItemReferenceSelect,
  list: ItemList,
  textArea: ItemTextArea,
}

export const FormItemConfigMap: Record<FormSelectorType, ConfigSectionType[]> =
  {
    section: ItemSectionConfig,
    input: ItemInputConfig,
    inputNumber: ItemInputNumberConfig,
    switch: ItemSwitchConfig,
    slider: ItemSliderConfig,
    checkbox: ItemCheckboxConfig,
    radio: ItemRadioConfig,
    select: ItemSelectConfig,
    timePicker: ItemTimePickerConfig,
    datePicker: ItemDatePickerConfig,
    upload: ItemUploadConfig,
    code: ItemCodeConfig,
    referenceSelect: ItemReferenceSelectConfig,
    list: ItemListConfig,
    root: [],
    textArea: ItemTextAreaConfig,
  }
