import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button, Flex, Popconfirm, Space, TableProps, Tag } from 'antd'
import EditSvg from 'assets/images/edit.svg'
import DeleteSvg from 'assets/images/delete.svg'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import {
  v1PermissionTreeList,
  v1RoleDeleteDelete,
  v1RoleListList,
} from 'api/Api'
import CustomTable from 'components/custom-table'
import { ResponsesPermissionResponse } from 'api/data-contracts'
import { RolesModalProps } from './modal'
import RolesModal from './modal'
import styles from './index.scss'

export type RolesDataType = {
  key: string
  name: string
  owner: boolean
  permissions: string
}

export type RolesHandles = {
  setModalVisible: (open: boolean) => void
  setModalType: (type: RolesModalProps['type']) => void
}

const Roles = forwardRef((props, ref) => {
  const [data, setData] = useState<RolesDataType[]>([])
  const [totalCount, setTotalCount] = useState<number>()
  const [modalType, setModalType] = useState<RolesModalProps['type']>('edit')
  const [modalVisible, setModalVisible] = useState(false)
  const [curRole, setCurRole] = useState<RolesDataType>()
  const [curPage, setCurPage] = useState(1)
  const [curPageSize, setCurPageSize] = useState(10)
  const [permissionData, setPermissionData] =
    useState<ResponsesPermissionResponse[]>()
  const [loading, setLoading] = useState(false)
  const permissionMap = useRef<Map<string, ResponsesPermissionResponse>>()

  const columns: TableProps<RolesDataType>['columns'] = useMemo(
    () => [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'owner',
        width: 100,
        render: (val) =>
          val ? (
            <Tag className={styles.tagEnabled}>
              <CheckCircleOutlined />
              Yes
            </Tag>
          ) : (
            <Tag className={styles.tagDisabled}>
              <CloseCircleOutlined />
              No
            </Tag>
          ),
      },
      {
        title: 'Permissions',
        dataIndex: 'permissions',
        key: 'permissions',
        render: (val: string = '', record) => {
          if (record.owner) {
            return <Tag className={styles.tagInactive}>All Permissions</Tag>
          }
          const tags = val.split(',')
          return tags.map((tag) => (
            <Tag key={tag} className={styles.tagInactive}>
              {permissionMap.current?.get(tag)?.name}
            </Tag>
          ))
        },
      },
      {
        title: 'Actions',
        width: 100,
        render: (_: any, record: RolesDataType) => {
          const { key } = record
          return (
            <Space>
              <Button
                type="text"
                icon={<EditSvg className={styles.tableIcon} />}
                onClick={() => handleActionEdit(key)}
              />
              <Popconfirm
                title="Are you sure to delete the role?"
                onConfirm={() => handleActionDelete(Number(key))}
              >
                <Button
                  type="text"
                  icon={<DeleteSvg className={styles.tableIcon} />}
                  danger
                />
              </Popconfirm>
            </Space>
          )
        },
      },
    ],
    [data]
  )

  const initPage = async () => {
    const permissionResp = await v1PermissionTreeList({
      // TODO@Tanner: replace the tenant and remove the 'OrganizationType'
      organizationType: 'tenant',
    })
    setPermissionData(permissionResp.data.permissions)

    // permission map
    const newMap = new Map()
    permissionResp.data.permissions.forEach((p) => {
      const { subs, identity } = p
      newMap.set(identity, p)
      if (!subs?.length) return
      subs.forEach((sub) => {
        const { identity: subIdentity } = sub
        newMap.set(subIdentity, sub)
      })
    })
    permissionMap.current = newMap
  }

  const fetchData = async () => {
    setLoading(true)
    const rolesResp = await v1RoleListList().finally(() => setLoading(false))
    if (!rolesResp.data) return

    const { roles, total_count } = rolesResp.data
    const tableData = roles.map<RolesDataType>((r) => {
      return {
        key: `${r.id}`,
        name: r.name,
        owner: r.owner,
        permissions: r.permissions,
      }
    })
    setData(tableData)
    setTotalCount(total_count)
  }

  const handleActionEdit = (id: string) => {
    setModalType('edit')
    const role = data.find((o) => `${o.key}` === id)

    if (!role) return
    setCurRole(role)
    setModalVisible(true)
  }

  const handleActionDelete = async (id: number) => {
    const deleteResp = await v1RoleDeleteDelete({ id })
    if (!deleteResp) return
    await fetchData()
  }

  const handleUpdateData = (page = curPage, pageSize = curPageSize) => {
    fetchData()
    if (page) setCurPage(page)
    if (pageSize) setCurPageSize(pageSize)
  }

  useImperativeHandle(ref, () => ({
    setModalVisible,
    setModalType,
  }))

  useEffect(() => {
    initPage()
    fetchData()
  }, [])

  return (
    <>
      <CustomTable<RolesDataType>
        columns={columns}
        data={data}
        loading={loading}
        paginationOnChange={handleUpdateData}
        paginationProps={{
          total: totalCount,
          align: 'center',
          current: curPage,
          pageSize: curPageSize,
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
          // page margin + title + title margin + tab content + tab margin + pagination content + pagination margin + table title
          y: 'calc(100vh - 2*24px - 38px - 24px - 46px - 16px - 32px - 16px - 55px)',
        }}
      />

      <RolesModal
        data={modalType === 'edit' ? curRole : undefined}
        type={modalType}
        open={modalVisible}
        onOpenChange={setModalVisible}
        updateData={handleUpdateData}
        permissionData={permissionData}
      />
    </>
  )
})

export default memo(Roles)
