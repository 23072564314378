import { PlusOutlined } from '@ant-design/icons'
import { App, Button, Col, Flex, Form, Input, Modal, Row } from 'antd'
import {
  v1EvaluationQuestionsCreate,
  v1EvaluationQuestionsUpdate,
} from 'api/Api'
import React, { ReactNode, useEffect, useState } from 'react'
import { getMessageFromError } from 'utils/common'
import styles from '../modal.scss'

import {
  EvaluationModelsLLMMessage,
  ModelsConversationRoleType,
} from 'api/data-contracts'

const { TextArea } = Input

export interface QuestionModalProps {
  data: {
    id?: number
    agentId: number
    testCase: string
    questionAnswers: EvaluationModelsLLMMessage[]
    expectedAnswer: string
    createdAt?: string
    updatedAt?: string
  }
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (questionId?: number) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export interface QuestionAnswersFormItemProps {
  name?: string
  value?: EvaluationModelsLLMMessage[]
  onChange?: (value: EvaluationModelsLLMMessage[]) => void
}

const QuestionAnswersFormItem: React.FC<QuestionAnswersFormItemProps> = (
  props
) => {
  const [questionAnswers, setQuestionAnswers] = useState<
    EvaluationModelsLLMMessage[]
  >([])

  useEffect(() => {
    setQuestionAnswers(props.value ?? [])
  }, [props.value])

  return (
    <Flex gap={8} vertical>
      {React.Children.toArray(
        questionAnswers.map((questionAnswer, index) => (
          <Row gutter={[16, 16]}>
            <Col span={4} style={{ textTransform: 'capitalize' }}>
              {questionAnswer.role}
            </Col>
            <Col span={20}>
              <TextArea
                value={questionAnswer.content}
                placeholder=""
                onChange={(e) => {
                  const newQuestionAnswers = [...questionAnswers]
                  newQuestionAnswers[index].content = e.target.value
                  setQuestionAnswers(newQuestionAnswers)
                  props.onChange?.(newQuestionAnswers)
                }}
                required
              />
            </Col>
          </Row>
        ))
      )}
      <Button
        className={styles.addButton}
        variant="dashed"
        color="default"
        onClick={() => {
          setQuestionAnswers([
            ...questionAnswers,
            { role: ModelsConversationRoleType.RoleAssistant, content: '' },
            { role: ModelsConversationRoleType.RoleUser, content: '' },
          ])
        }}
      >
        <PlusOutlined />
        Add
      </Button>
    </Flex>
  )
}

const QuestionModal: React.FC<QuestionModalProps> = (props) => {
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    if (props.open) {
      form.setFieldsValue({ ...props.data })
    }
  }, [props.open, form, props.data])

  const handleSave = async (values: QuestionModalProps['data']) => {
    setConfirmLoading(true)
    try {
      if (props.data.id == undefined) {
        const response = await v1EvaluationQuestionsCreate({
          agentID: props.data.agentId,
          testCase: values.testCase,
          questionAnswers: values.questionAnswers,
          expectedAnswer: values.expectedAnswer,
        })
        const data = response.data
        message.success('Question is created successfully!')
        return data.id
      } else {
        await v1EvaluationQuestionsUpdate({
          id: props.data.id,
          agentID: props.data.agentId,
          testCase: values.testCase,
          questionAnswers: values.questionAnswers,
          expectedAnswer: values.expectedAnswer,
        })
        message.success('Question is updated successfully!')
      }
      return props.data.id
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async (values: QuestionModalProps['data']) => {
    const questionId = await handleSave(values)
    if (questionId === undefined) {
      return
    }
    props.onFinish?.(questionId)
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    props.onCancel?.(e)
  }

  return (
    <Modal
      className={styles.modal}
      title={props.title ?? 'Question Edit'}
      open={props.open}
      okText={props.okText ?? 'Save'}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      onOk={() => form.submit()}
      okButtonProps={{ htmlType: 'submit' }}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        style={{ maxWidth: 620 }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          name="testCase"
          label="Test Case"
          rules={[
            { required: true, message: 'Please input a test case name!' },
          ]}
        >
          <Input placeholder="Your test case name" />
        </Form.Item>
        <Form.Item
          name="questionAnswers"
          label="Question Answers"
          rules={[{ required: true, message: 'Please input some settings!' }]}
        >
          <QuestionAnswersFormItem />
        </Form.Item>
        <Form.Item
          name="expectedAnswer"
          label="Expected Answer"
          rules={[
            { required: true, message: 'Please input your expected answer!' },
          ]}
        >
          <TextArea placeholder="Your expected answer..." />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default QuestionModal
