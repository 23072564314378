import React, {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Button, Dropdown, Flex, MenuProps, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import cls from 'classnames'
import styles from './index.scss'

export const TimezoneItems: MenuProps['items'] = Array.from({ length: 27 }).map(
  (_, index) => ({
    label: `UTC${index < 12 ? '' : '+'}${index - 12}:00`,
    key: index - 12,
  })
)

export const PeriodItems: MenuProps['items'] = [
  {
    label: 'Hourly',
    key: 'hourly',
  },
  {
    label: 'Daily',
    key: 'daily',
  },
  {
    label: 'Weekly',
    key: 'weekly',
  },
  {
    label: 'Monthly',
    key: 'monthly',
  },
]

export type PeriodType = 'hourly' | 'daily' | 'weekly' | 'monthly'

export interface SectionCardProps {
  icon?: ReactElement
  name: string
  children: ReactNode
  className?: string
  style?: React.CSSProperties
  period?: PeriodType
  onPeriodChange?: (period: PeriodType) => void
}

export interface SectionCardRef {
  period: () => PeriodType
}

const SectionCard: ForwardRefRenderFunction<
  SectionCardRef,
  SectionCardProps
> = (props, ref) => {
  const [periodItem, setPeriodItem] = useState<(typeof PeriodItems)[0]>()

  useImperativeHandle(
    ref,
    () => ({
      period: () => (periodItem as any).key,
    }),
    [periodItem]
  )

  useEffect(() => {
    const periodItem = PeriodItems.find((item) => item?.key === props.period)
    if (periodItem) {
      setPeriodItem(periodItem)
    }
  }, [props.period])

  return (
    <Flex
      className={cls(styles.sectionWrapper, props.className)}
      style={props.style}
      vertical
    >
      <Flex
        gap={8}
        style={{ justifyContent: 'space-between', alignItems: 'baseline' }}
      >
        <Flex gap={8}>
          {props.icon && (
            <span className={styles.sectionCardIcon}>{props.icon}</span>
          )}
          <span className={styles.sectionCardTitle}>{props.name}</span>
        </Flex>
        {periodItem && (
          <Dropdown
            menu={{
              items: PeriodItems,
              onClick: (e) => {
                const item = PeriodItems.find((item) => item?.key === e.key)
                if (item) {
                  setPeriodItem(item)
                  props.onPeriodChange?.(item.key as PeriodType)
                }
              },
            }}
          >
            <Button>
              <Space>
                {(periodItem as any)?.label}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
      </Flex>
      <Flex className={styles.sectionCardContent} gap={8}>
        {props.children}
      </Flex>
    </Flex>
  )
}

export default forwardRef(SectionCard)
