import { memo, useMemo } from 'react'
import { InputNumber, InputNumberProps } from 'antd'
import ComponentWrapper from '../lib/component-wrapper'
import { omitBy } from 'lodash-es'
import { getCommonDefaultValue } from '../constants'

type ItemInputNumberProps = { inputNumberConfig?: InputNumberProps } & Record<
  string,
  any
>

export const getDefaultValue = () => getCommonDefaultValue('inputNumber')

const ItemInputNumber = (props: ItemInputNumberProps) => {
  const { inputNumberConfig } = props

  const filterProps = useMemo(
    () =>
      omitBy(
        props,
        (_, k) => k !== 'onChange' && (k === 'children' || /[A-Z]/.test(k))
      ),
    [props]
  )

  return <InputNumber {...filterProps} {...inputNumberConfig} />
}

export default ComponentWrapper(memo(ItemInputNumber))
