import { ConfigSectionType } from 'components/new-dynamic-form/types'
import { CommonConfig } from '../constants'

const ItemListConfig: ConfigSectionType[] = [
  ...CommonConfig,
  {
    sectionLabel: 'listConfig',
    items: [
      { itemLabel: 'Disabled', itemName: 'disabled', type: 'switch' },
      { itemLabel: 'DefaultValue', itemName: 'defaultValue', type: 'switch' },
    ],
  },
]

export default ItemListConfig
