import React, { useState, useEffect, MouseEventHandler } from 'react'
import { Skeleton, PaginationProps, App, Space, Flex, Empty } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { Chunk } from 'stores/models/chunk'
import ChunkCard, { ActionType } from './chunk-card'
import { getFileIcon, getMessageFromError } from 'utils/common'
import ChunkEditor, { ChunkEditorProps } from './chunk-editor'
import MainLayout from 'layouts/portal/main-layout'
import { useExNavigate } from 'hooks/use-ex-navigate'
import { v1KnowledgeDocumentChunksList } from 'api/Api'
import {
  ResponsesKnowledgeDocumentChunkResponse,
  ResponsesKnowledgeDocumentResponse,
} from 'api/data-contracts'
import CustomPagination from 'components/custom-pagination'
import { KnowledgeCardProps } from '../knowledge-card'
import styles from './index.scss'

const DEFAULT_CHUNK_DATA: ChunkEditorProps['data'] = {
  _id: '',
  knowledgeID: 0,
  fileID: 0,
  documentID: 0,
  enabled: true,
  content: '',
  metadata: {},
}

const ChunkPage: React.FC = () => {
  const { message, modal } = App.useApp()
  const navigate = useExNavigate()
  const [curPage, setCurPage] = useState(1)
  const [curPageSize, setCurPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<ResponsesKnowledgeDocumentChunkResponse[]>(
    []
  )
  const [loading, setLoading] = useState(false)
  const [isEditorOpen, setIsEditorOpen] = useState(false)
  const [chunk, setChunk] =
    useState<ChunkEditorProps['data']>(DEFAULT_CHUNK_DATA)
  const location = useLocation()
  const document = location.state.document as ResponsesKnowledgeDocumentResponse
  const knowledge = location.state.knowledge as KnowledgeCardProps['data']

  const load = async (page: number, size: number) => {
    setLoading(true)
    try {
      const resp = await v1KnowledgeDocumentChunksList({
        knowledgeID: document.knowledgeID,
        documentID: document.id,
        page,
        size,
      })
      setData(resp.data.chunks)
      setTotal(resp.data.totalCount)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setLoading(false)
  }

  const onChange: PaginationProps['onChange'] = (
    page: number,
    size: number
  ) => {
    if (page) setCurPage(page)
    if (size) setCurPageSize(size)
  }

  const handleAddButtonClick: MouseEventHandler<HTMLElement> = (e) => {
    setChunk({
      ...DEFAULT_CHUNK_DATA,
      knowledgeID: document.knowledgeID,
      fileID: document.fileID,
      documentID: document.id,
    })
    setIsEditorOpen(true)
  }

  const onChunkCardAction = (type: ActionType, chunk: Chunk) => {
    if (type === ActionType.Edit) {
      setChunk(chunk)
      setIsEditorOpen(true)
    }
  }

  useEffect(() => {
    load(curPage, curPageSize)
  }, [curPage, curPageSize])

  return (
    <MainLayout
      title={document.documentName}
      icon={getFileIcon(document.documentName)}
      onClickBack={() =>
        navigate('/portal/knowledge/document', {
          state: { knowledge: { ...knowledge, id: document.knowledgeID } },
        })
      }
      actions={[
        {
          text: 'Add Chunk',
          isPrimary: true,
          onClick: handleAddButtonClick,
        },
      ]}
      noHeaderBottomLine
    >
      <Skeleton
        loading={loading}
        style={{ padding: '16px', height: '100%' }}
        active
      >
        {data.length > 0 ? (
          <Flex className={styles.chunksCardWrapper} gap={16} wrap>
            {data.map((item, i) => (
              <ChunkCard key={i} data={item} onAction={onChunkCardAction} />
            ))}
          </Flex>
        ) : (
          <Flex justify="start" style={{ width: '100%' }}>
            <Empty />
          </Flex>
        )}
      </Skeleton>
      <CustomPagination
        total={total}
        current={curPage}
        pageSize={curPageSize}
        onChange={onChange}
        align="center"
        className={styles.chunksPagination}
      />
      <ChunkEditor
        title={
          <Space>
            <span style={{ fontWeight: 600, fontSize: '20px' }}>
              Chunk Editor
            </span>
          </Space>
        }
        data={chunk}
        open={isEditorOpen}
        onOk={() => {
          setIsEditorOpen(false)
          load(curPage, curPageSize)
        }}
        onCancel={() => setIsEditorOpen(false)}
      />
    </MainLayout>
  )
}

export default ChunkPage
