import { Button, Tabs, TabsProps, Tag } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import styles from './index.scss'

import { v1OrganizationListList, v1RoleListList, v1UserListList } from 'api/Api'
import { useNavigate } from 'react-router-dom'
import { getQuery, setQuery } from 'utils/common'
import Management, { ManagementHandles } from './management'
import Members, { MembersHandles } from './members'
import Roles, { RolesHandles } from './roles'

type OrganizationProps = {}
type OrganizationPageParams = { curTab: TabItemKeys }
type TabItemKeys = 'management' | 'members' | 'roles'

const Organization: React.FC<OrganizationProps> = () => {
  const navigate = useNavigate()
  const { curTab } = getQuery<OrganizationPageParams>(location.search)
  const [tabItems, setTabItems] = useState<TabsProps['items']>([])
  const [curTabItem, setCurTabItem] = useState<TabItemKeys>(
    curTab ?? 'management'
  )
  const [createBtnText, setCreateBtnText] = useState<string>('Create')
  const [totalArr, setTotalArr] = useState<[number, number, number]>([0, 0, 0])

  const managementRef = useRef<ManagementHandles>()
  const membersRef = useRef<MembersHandles>()
  const rolesRef = useRef<RolesHandles>()

  // Get the count of tab
  const initPage = async () => {
    const managementResp = await v1OrganizationListList({ page: 1, size: 1 })
    const roleResp = await v1RoleListList()
    const userResp = await v1UserListList({ page: 1, page_size: 1 })
    setTotalArr([
      managementResp.data?.totalCount,
      userResp.data?.totalCount,
      roleResp.data?.total_count,
    ])

    if (!curTab) {
      handleTabChange('management')
    }
  }

  useEffect(() => {
    setTabItems([
      {
        key: 'management',
        label: (
          <span>
            Management
            <Tag className={styles.organizationTabCount} bordered={false}>
              {totalArr[0]}
            </Tag>
          </span>
        ),
        children: <Management ref={managementRef} />,
      },
      {
        key: 'members',
        label: (
          <span>
            Members
            <Tag className={styles.organizationTabCount} bordered={false}>
              {totalArr[1]}
            </Tag>
          </span>
        ),
        children: <Members ref={membersRef} />,
      },
      {
        key: 'roles',
        label: (
          <span>
            Roles
            <Tag className={styles.organizationTabCount} bordered={false}>
              {totalArr[2]}
            </Tag>
          </span>
        ),
        children: <Roles ref={rolesRef} />,
      },
    ])
  }, [totalArr])

  const handleTabChange = (tab: TabItemKeys) => {
    setCurTabItem(tab)
    const params = setQuery({ curTab: tab })
    navigate({ search: params })
  }

  const handleCreate = (tab: TabItemKeys) => {
    switch (tab) {
      case 'management': {
        managementRef.current?.setModalType('create')
        managementRef.current?.setModalVisible(true)
        break
      }
      case 'members': {
        membersRef.current?.setModalType('create')
        membersRef.current?.setModalVisible(true)
        break
      }
      case 'roles': {
        rolesRef.current?.setModalType('create')
        rolesRef.current?.setModalVisible(true)
        break
      }
    }
  }

  useEffect(() => {
    initPage()
  }, [])

  useEffect(() => {
    let text = 'Create '
    switch (curTab) {
      case 'management':
        text += 'Management'
        break
      case 'members':
        text += 'Member'
        break
      case 'roles':
        text += 'Role'
        break
    }
    setCreateBtnText(text)
  }, [curTab])

  return (
    <div className={styles.organizationWrapper}>
      <div className={styles.organizationTitle}>Organization</div>
      {!!tabItems?.length && (
        <Tabs
          items={tabItems}
          tabBarExtraContent={
            <Button type="primary" onClick={() => handleCreate(curTabItem)}>
              {createBtnText}
            </Button>
          }
          defaultActiveKey={curTab}
          onChange={(key: string) => handleTabChange(key as TabItemKeys)}
          className={styles.organizationTabWrapper}
        />
      )}
    </div>
  )
}

export default Organization
