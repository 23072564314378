import React, { useEffect, useRef, useState } from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
  attachClosestEdge,
  // type Edge,
  // extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import { FormItemType } from 'components/new-dynamic-form/types'
import { FormItemRenderMap } from '../constants-map'

import styles from './index.scss'
import { WithDraggingProps } from '../lib/component-wrapper'
import { Collapse } from 'antd'
import { useFormContext } from 'components/new-dynamic-form/context'
import { deepCopy } from 'utils/common'
import {
  getItemByPath,
  updateItemListByPath,
} from 'components/new-dynamic-form/util'
import { getCommonDefaultValue } from '../constants'

// type SectionState =
//   | { type: 'idle' }
//   | { type: 'is-card-over' }
//   | { type: 'is-section-over'; closestEdge: Edge | null }
// preventing re-renders with stable state objects
// const idle: SectionState = { type: 'idle' }

export const getDefaultValue = () => getCommonDefaultValue('section')

type SectionProps = WithDraggingProps<FormItemType>
const Section = (props: SectionProps) => {
  const { formItemId, children, props: itemProps } = props
  const { formItemConfig } = itemProps ?? {}
  const { root, onItemClick, setRoot } = useFormContext() ?? {}

  const sectionRef = useRef<HTMLDivElement | null>(null)
  const headerRef = useRef<HTMLDivElement | null>(null)
  const scrollableRef = useRef<HTMLDivElement | null>(null)
  // const [state, setState] = useState<SectionState>(idle)
  // const [isDragging, setIsDragging] = useState<boolean>(false)

  // const { instanceId, registerSection } = useBoardContext() ?? {}

  const handleDeleteClick = (item: SectionProps) => {
    if (!root) return
    const { path, formItemId } = item
    const newRoot = deepCopy(root)
    const delFormItemParent = getItemByPath(newRoot, path.slice(0, -1))
    if (!delFormItemParent) return
    const newItem = updateItemListByPath(
      newRoot,
      delFormItemParent.path,
      delFormItemParent.children.filter((i) => i.formItemId !== formItemId)
    )

    setRoot?.(newItem)
  }

  useEffect(() => {
    if (!sectionRef.current || !headerRef.current || !scrollableRef.current)
      return

    return combine(
      draggable({
        element: sectionRef.current,
        dragHandle: headerRef.current,
        getInitialData: () => ({
          sectionId: formItemId,
          ...props,
          type: 'section',
        }),
        // onDragStart: () => setIsDragging(true),
        // onDrop: () => {
        // setState(idle)
        // setIsDragging(false)
        // },
      }),
      dropTargetForElements({
        element: scrollableRef.current,
        getIsSticky: () => true,
        getData: (args) => {
          const { input, element } = args
          const data = { sectionId: formItemId, ...props }

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['top', 'bottom'],
          })
        },
        // onDragEnter: ({ self, source }) => {
        //   setState({
        //     type: 'is-section-over',
        //     closestEdge: extractClosestEdge(self.data),
        //   })
        // },
        // onDrag: ({ self, source }) => {
        //   setState((current) => {
        //     const closestEdge = extractClosestEdge(self.data)
        //     if (
        //       current.type === 'is-section-over' &&
        //       current.closestEdge === closestEdge
        //     ) {
        //       return current
        //     }
        //     return { type: 'is-section-over', closestEdge }
        //   })
        // },
        // onDragLeave: () => setState(idle),
        // onDrop: () => setState(idle),
      })
    )
  }, [props, formItemId])

  return (
    <Collapse
      ref={sectionRef}
      ghost
      collapsible="icon"
      className={styles.sectionWrapper}
      defaultActiveKey={0}
      items={[
        {
          label: (
            <div
              ref={headerRef}
              onClick={() => onItemClick?.(props)}
              className={styles.sectionHeaderWrapper}
              tabIndex={0}
              role="button"
              onKeyDown={() => {}}
            >
              <span className={styles.sectionHeaderTitle}>
                {formItemConfig?.label}
              </span>
              <CloseCircleOutlined
                className={styles.sectionHeaderDeleteIcon}
                onClick={() => handleDeleteClick(props)}
              />
            </div>
          ),
          children: (
            <>
              {!children?.length ? (
                <div className={styles.sectionDropArea} ref={scrollableRef}>
                  Replace me
                </div>
              ) : (
                <div ref={scrollableRef} className={styles.sectionItemWrapper}>
                  {children?.map((item, idx) => {
                    const { formItemId, type } = item
                    const Cmp = Reflect.get(FormItemRenderMap, type)
                    if (!Cmp) return null
                    return (
                      <React.Fragment key={formItemId}>
                        <Cmp {...item} index={idx} />
                      </React.Fragment>
                    )
                  })}
                </div>
              )}
            </>
          ),
        },
      ]}
    />
  )
}

export default Section
