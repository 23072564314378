import { Button, Space } from 'antd'
import React, { memo, useEffect, useState } from 'react'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'

import styles from './index.scss'
import { isNumber } from 'lodash-es'

type baseDataType = {
  render?: React.ReactNode
  label?: string
  compact?: boolean
}

type ItemListProps<T = Record<string, any>> = {
  data: (T & baseDataType)[]
  onAddItem: () => void
  onDeleteItem: (index: number) => void

  maxCount?: number
  render?: (node: any) => React.ReactNode
}

const ItemList = (props: ItemListProps) => {
  const { data, onAddItem, onDeleteItem, maxCount, render } = props
  const [curList, setCurList] = useState(data)

  useEffect(() => {
    setCurList(data)
  }, [data])

  return (
    <div>
      <div className={styles.itemListItemWrapper}>
        {curList.map((item, idx) => {
          const { render: itemRender, label, compact = true } = item

          return (
            <React.Fragment key={idx}>
              {compact ? (
                <Space.Compact
                  rootClassName={styles.itemListItemCompactWrapper}
                >
                  {render ? render(item) : itemRender}
                  <Button
                    onClick={() => onDeleteItem?.(idx)}
                    icon={<MinusCircleOutlined />}
                  />
                </Space.Compact>
              ) : (
                <div className={styles.itemListItemNoCompactWrapper}>
                  <div className={styles.itemListItemNoCompactHeader}>
                    <span>{label}</span>
                    <Button
                      onClick={() => onDeleteItem?.(idx)}
                      icon={<MinusCircleOutlined />}
                    />
                  </div>
                  {render ? render(item) : itemRender}
                </div>
              )}
            </React.Fragment>
          )
        })}
      </div>
      <Button
        type="dashed"
        block
        icon={<PlusOutlined />}
        onClick={onAddItem}
        disabled={isNumber(maxCount) ? data.length >= maxCount : undefined}
      >
        Add
      </Button>
    </div>
  )
}

export default ItemList
