import Icon, {
  ApiOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Form,
  GetProp,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd'
import { v1AgentCreateCreate, v1AgentUpdatePartialUpdate } from 'api/Api'
import {
  AgentTypeAgentType,
  HttpMethodSmartAPIURLMethod,
} from 'api/data-contracts'
import Robot from 'assets/images/robot.svg'
import { ReactNode, useEffect, useState } from 'react'
import fileStore, { FileBelongsType, FileDocument } from 'stores/file'
import { getFileUrl, getMessageFromError, isEmpty } from 'utils/common'
import styles from './agent-modal.scss'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export interface AgentModalProps {
  data: {
    id?: number
    uuid?: string
    name: string
    description: string
    agentType: AgentTypeAgentType
    iconId?: number
    iconUuid?: string
    organizationId?: number
    smartAPI?: {
      resourceName: string
      method: HttpMethodSmartAPIURLMethod
      version: string
      [key: string]: any
    }
  }
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (agentUuid?: string) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const httpMethods = [
  { label: 'GET', value: 'GET' },
  { label: 'POST', value: 'POST' },
  { label: 'DELETE', value: 'DELETE' },
  { label: 'PATCH', value: 'PATCH' },
  { label: 'PUT', value: 'PUT' },
]

const AgentModal: React.FC<AgentModalProps> = (props) => {
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [fileList, setFileList] = useState<UploadFile<any>[]>([])
  const [imageUrl, setImageUrl] = useState<string>()

  useEffect(() => {
    form.setFieldsValue({
      ...props.data,
    })
    setImageUrl(getFileUrl(props.data.iconUuid ?? ''))
  }, [props.data])

  const handleSave = async (values: AgentModalProps['data']) => {
    setConfirmLoading(true)
    try {
      let iconId = props.data.iconId ?? 0
      if (fileList.length > 0) {
        const file = fileList[0].response[0] as FileDocument
        iconId = file.id
      }
      if (props.data.id === undefined) {
        const response = await v1AgentCreateCreate({
          ...values,
          agentIconID: iconId,
        })
        return response.data.uuid
      } else {
        await v1AgentUpdatePartialUpdate({
          id: props.data.id,
          ...values,
          iconID: iconId,
        })
      }
      return props.data.uuid
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async (values: AgentModalProps['data']) => {
    const agentUuid = await handleSave(values)
    if (isEmpty(agentUuid)) {
      return
    }
    if (props.onFinish) {
      props.onFinish(agentUuid)
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const uploadProps: UploadProps = {
    showUploadList: false,
    action: fileStore.getUploadUrl(FileBelongsType.AgentIcon),
    beforeUpload: (file: FileType) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
      }
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        message.error('Image must smaller than 10MB!')
      }
      return isJpgOrPng && isLt10M
    },
    onChange: (info) => {
      setFileList(info.fileList)

      switch (info.file.status) {
        case 'uploading':
          setIsUploading(true)
          break
        case 'done':
          getBase64(info.file.originFileObj as FileType, (url) => {
            setImageUrl(url)
          })
          setIsUploading(false)
          break
        case 'error':
          message.error(`${info.file.name} file upload failed.`)
          setIsUploading(false)
          break
        case 'removed':
          setIsUploading(false)
          break
      }
    },
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {isUploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Upload</div>
    </button>
  )

  return (
    <Modal
      title={props.title ?? (props.data.id ? 'Edit' : 'Create')}
      open={props.open}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      okText={props.okText || 'Save'}
      onOk={() => form.submit()}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={416}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Agent Type"
          name="agentType"
          rules={[{ required: true, message: '' }]}
        >
          <Radio.Group disabled={!!props.data.id}>
            <Radio.Button value={AgentTypeAgentType.Chatbot}>
              <Space size="small">
                <Icon component={() => <Robot fill="transparent" />} />
                Chatbot
              </Space>
            </Radio.Button>
            <Radio.Button value={AgentTypeAgentType.SmartAPI}>
              <Space size="small">
                <ApiOutlined />
                Smart API
              </Space>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Agent icon">
          <Upload
            name="file"
            listType="picture-card"
            className={styles.avatarUploader}
            {...uploadProps}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Agent name"
          name="name"
          rules={[{ required: true, message: '' }]}
        >
          <Input placeholder="Give your agent a name" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          className={styles.paddingBottom}
          rules={[{ required: false, message: '' }]}
        >
          <Input.TextArea
            showCount
            maxLength={150}
            rows={5}
            placeholder="Enter a description"
          />
        </Form.Item>

        <Form.Item
          noStyle
          dependencies={['agentType']}
          className={styles.paddingBottom}
        >
          {(formProps) => {
            const agentType = formProps.getFieldValue('agentType')

            return (
              agentType === AgentTypeAgentType.SmartAPI && (
                <>
                  <Form.Item
                    label="Version"
                    name={['smartAPI', 'version']}
                    required
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name={['smartAPI', 'resourceName']}
                    label="Resource Name"
                    initialValue={props.data.smartAPI?.resourceName}
                    required
                  >
                    <Input
                      addonBefore={`${location.origin}/${props?.data?.smartAPI?.status === 'inactive' ? '/internal' : ''}`}
                      placeholder="Enter Resource Name"
                    />
                  </Form.Item>

                  <Form.Item
                    name={['smartAPI', 'method']}
                    label="HTTP Method"
                    initialValue={props.data.smartAPI?.method ?? 'GET'}
                  >
                    <Select
                      placeholder="Select HTTP Method"
                      options={httpMethods}
                    />
                  </Form.Item>
                </>
              )
            )
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AgentModal
