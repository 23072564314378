import { memo, useMemo } from 'react'
import { Switch, SwitchProps } from 'antd'
import { FormItemType } from 'components/new-dynamic-form/types'
import ComponentWrapper from '../lib/component-wrapper'
import { omitBy } from 'lodash-es'
import { getCommonDefaultValue } from '../constants'

type ItemSwitchProps = { switchConfig?: SwitchProps } & Record<string, any>

export const getDefaultValue = () => getCommonDefaultValue('switch')

const ItemSwitch = (props: ItemSwitchProps) => {
  const { switchConfig } = props

  const filterProps = useMemo(
    () => omitBy(props, (_, k) => k !== 'onChange' && /[A-Z]/.test(k)),
    [props]
  )

  return <Switch {...filterProps} {...switchConfig} />
}

export default ComponentWrapper(memo(ItemSwitch))
