import React, { useEffect, useState } from 'react'
import { Handle, Position } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { MessageOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import { observer } from 'mobx-react'
import {
  NodeData,
  OutputField,
  InputField,
  DataType,
  CustomNodeProps,
} from 'views/portal/agent/studio/workflow/model'
import { getQuery } from 'utils/common'
import { StudioPageQueryType } from 'views/portal/agent/agent-card'
import CustomHandle from '../../components/custom-handle'

// Define default values
const defaultInputs: Record<StudioPageQueryType['agentType'], InputField[]> = {
  chatbot: [
    // {
    //   name: 'answer',
    //   type: 'input' as InputType,
    //   dataType: 'String' as DataType,
    //   value: '{{.llm_1.text}}',
    //   reference: '',
    // },
  ],
  smart_api: [],
}

const defaultOutputs: OutputField[] = [
  {
    name: 'answer',
    type: 'String' as DataType,
    description: 'Answer',
    required: true,
    children: [],
  },
]

const EndNode = (node: CustomNodeProps) => {
  const { agentType } = getQuery<StudioPageQueryType>(location.search)

  const defaultNodeData: NodeData = {
    label: 'End',
    description:
      "Outputs the agent's response, marking the end of a conversation.",
    input: defaultInputs[agentType],
    output: defaultOutputs,
    intentBranch: [],
    conditionBranch: [],
    branchOutput: {},
  }
  const [nodeData, setNodeData] = useState<NodeData>(
    node.data.data ?? defaultNodeData
  )

  useEffect(() => {
    if (node.data?.data) {
      setNodeData(node.data.data)
    } else if (node.data.onChange) {
      node.data.onChange(node.id, { data: defaultNodeData })
    }
  }, [node.data])

  useEffect(() => {
    if (node.selected) {
      WorkflowStore.selectNode(node)
    }
  }, [node.selected])

  return (
    <div
      className={
        node.id === WorkflowStore.selectedNode?.id
          ? 'custom-node end-node active'
          : 'custom-node end-node'
      }
    >
      <CustomHandle
        node={node}
        type="target"
        position={Position.Left}
        isConnectable={node.isConnectable}
      />
      <div className="node-title">
        <div className="icon">
          <MessageOutlined />
        </div>
        <div className="text">{nodeData.label}</div>
      </div>
      <div className="node-desc">{nodeData.description}</div>
    </div>
  )
}

export default observer(EndNode)
