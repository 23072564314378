import { GetProp, UploadProps } from 'antd'
import {
  FilePdfOutlined,
  FileTextOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileWordOutlined,
  FileOutlined,
} from '@ant-design/icons'
import { AxiosResponse } from 'axios'
import config from 'config'
import apiConfig from 'services/api'

export const getMessageFromError = (error: any) => {
  let message = 'Oops! We encountered an issue. Please retry in a few moments.'
  if (error?.response?.data) {
    message = error.response?.data?.message ?? message
  } else if (error?.message) {
    message = error.message
  }
  return message
}

export type UploadFileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export const getBase64 = (
  img: UploadFileType,
  callback: (url: string) => void
) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

export const isEmpty = (data: undefined | null | string | object) => {
  return data === undefined || data === null || data.toString().trim() === ''
}

export const getStaticUrl = (path: string) => {
  return new URL(config.STATIC_URL + path).toString()
}

export const normalizePath = (...paths: string[]) => {
  return paths.join('/').replace(/(?<!:)\/{2,}/g, '/')
}

export const getFileUrl = (uuid: string) => {
  if (isEmpty(uuid)) {
    return ''
  }

  let url = apiConfig.file.url
  if (!url.endsWith('/')) {
    url += '/'
  }

  if (uuid.startsWith('/')) {
    uuid = uuid.substring(1)
  }

  return new URL(normalizePath(config.API_GATEWAY, url + uuid)).toString()
}

export const escapeRegExp = (str: string) => {
  if (str === undefined || str === null) {
    return ''
  }
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const getUserLanguage = () => {
  return navigator.language
}

export const sleep = (delay: number) =>
  new Promise((fn) => setTimeout(fn, delay))

export function safeJsonParse<T = any>(jsonString: string): T | undefined
export function safeJsonParse<T = any>(jsonString: string, fallback: T): T
export function safeJsonParse<T = any>(
  jsonString: string,
  fallback?: any
): any {
  try {
    return JSON.parse(jsonString) as T
  } catch (error) {
    console.error('JSON parse error:', error)
    if (typeof fallback === 'function') return fallback()
    if (fallback === undefined) return fallback
  }
}

export const deepCopy = <T extends unknown>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj))
}

export const getQuery = <T extends NonNullable<Record<string, any>>>(
  search: string
): T => {
  const res = {} as T
  if (!search) return res
  const searchParams = new URLSearchParams(search)

  for (const i of searchParams.entries()) {
    const [k, v] = i
    Reflect.set(res, k, v)
  }
  return res
}

export const setQuery = (params: Record<string, any>): string =>
  Object.entries(params)
    .map(([k, v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v))
    .join('&') ?? ''

export const validateRegex = (regex: string, ignoreEmpty: boolean = true) => {
  let message = ''
  try {
    if (!ignoreEmpty || regex !== '') {
      new RegExp(regex)
    }
  } catch (e) {
    message = (e as Error).message
  }
  return {
    valid: message === '',
    message,
  }
}

export const downloadFile = (
  response: AxiosResponse<File, any>,
  filename: string = 'unknown'
) => {
  const blob = new Blob([response.data], { type: response.data.type })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  if (filename === 'unknown') {
    const contentDisposition = response.headers['content-disposition']
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="(.+)"/)
      if (filenameMatch.length === 2) {
        filename = filenameMatch[1]
      }
    }
  }
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

//////////////////////////////////////////////////////////////////
// Fetch with cache
type AnyFunction = (...args: any[]) => any
type Await<T> = T extends {
  then(onfulfilled?: (value: infer U) => unknown): unknown
}
  ? U
  : T

type CacheData = {
  resp?: any
  concurrent: number
}

const CACHE = new Map<string, CacheData>()

export const fetchWithCache = async <T extends AnyFunction>(
  fn: T,
  ...args: Parameters<T>
): Promise<Await<ReturnType<T>>> => {
  const key = `${fn.name}-${JSON.stringify(args)}`
  let cached = CACHE.get(key)

  if (cached) {
    console.log(`waiting for concurrent cache: ${fn.name}`)
    cached.concurrent++
    if (!cached.resp) {
      while (!cached.resp) {
        await sleep(50)
      }
    }
    cached.concurrent--
  } else {
    console.log(`creating for concurrent cache: ${fn.name}`)
    cached = { concurrent: 1 }
    CACHE.set(key, cached)
    const resp = await fn(...args)
    cached.resp = resp
    cached.concurrent--
  }

  const resp = cached.resp

  if (cached.concurrent === 0) {
    CACHE.delete(key)
    console.log(`removing concurrent cache: ${fn.name}`)
  }

  return resp
}

export const convertJsonBlobResponse: <T>(
  response: AxiosResponse
) => Promise<T> = async (response) => {
  const isJsonBlob = (data: any) =>
    data instanceof Blob && data.type === 'application/json'
  const responseData = isJsonBlob(response?.data)
    ? await response?.data?.text()
    : response?.data || {}
  const responseJson =
    typeof responseData === 'string' ? JSON.parse(responseData) : responseData
  return responseJson
}

export const getFileIcon = (filename: string) => {
  const extension = filename.split('.').pop()
  switch (extension) {
    case 'pdf':
      return <FilePdfOutlined />
    case 'txt':
      return <FileTextOutlined />
    case 'csv':
      return <FileExcelOutlined />
    case 'xlsx':
      return <FileExcelOutlined />
    case 'pptx':
      return <FilePptOutlined />
    case 'docx':
      return <FileWordOutlined />
    default:
      return <FileOutlined />
  }
}
