import {
  message as antdMessage,
  notification as antdNotification,
  Modal as antdModal,
  App,
} from 'antd'

import type { MessageInstance } from 'antd/es/message/interface'
import type { NotificationInstance } from 'antd/es/notification/interface'
import type { ModalStaticFunctions } from 'antd/es/modal/confirm'

// because warn is deprecated, so we need to remove it.
const { warn, ...resetFns } = antdModal

const _: {
  message: MessageInstance
  notification: NotificationInstance
  modal: Omit<ModalStaticFunctions, 'warn'>
} = {
  message: antdMessage,
  notification: antdNotification,
  modal: resetFns,
}

const AntdStaticFunction = () => {
  const staticFunctions = App.useApp()

  _.message = staticFunctions.message
  _.notification = staticFunctions.notification
  _.modal = staticFunctions.modal

  return null
}

const MessageGlobalConfig = {}
const NotificationGlobalConfig = {
  pauseOnHover: true,
  showProgress: true,
}

export const message = _.message
export const notification = _.notification
export const modal = _.modal

export { MessageGlobalConfig, NotificationGlobalConfig }
export default AntdStaticFunction
