import React, { memo, useEffect, useState } from 'react'
import { FormItemRenderMap } from '../components/constants-map'
import { FormItemType } from '../types'

import styles from './index.scss'
import { Avatar, Button, Form, Input } from 'antd'

type FormItemRenderProps = {
  data: FormItemType[]
}

const FormItemRender = (props: FormItemRenderProps) => {
  const { data, ...restProps } = props
  const [mainForm] = Form.useForm()

  const [curFormValues, setCurFormValues] = useState()
  const handleFormValueChange = (changedValues: any, values: any) => {
    setCurFormValues(values)
  }

  return (
    <div className={styles.dynamicFormItemRenderWrapper}>
      <Form
        form={mainForm}
        onFinish={(v) => console.log(v)}
        onValuesChange={handleFormValueChange}
      >
        <div className={styles.dynamicFormItemRenderHeaderWrapper}>
          <div className={styles.dynamicFormItemRenderHeaderTitle}>
            <Avatar size={30} shape="square" />
            <Form.Item name={'name'}>
              <Input placeholder="Node Name" variant="borderless" />
            </Form.Item>
          </div>

          <Form.Item name={'description'}>
            <Input placeholder="Node Description" variant="borderless" />
          </Form.Item>
        </div>

        {data.map((item, idx) => {
          const { formItemId, type } = item
          const Cmp = Reflect.get(FormItemRenderMap, type)

          return (
            <React.Fragment key={`${formItemId}-${curFormValues}`}>
              {Cmp ? <Cmp {...item} {...restProps} index={idx} /> : null}
            </React.Fragment>
          )
        })}

        {/* <Form.Item>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item> */}
      </Form>
    </div>
  )
}

export default memo(FormItemRender)
