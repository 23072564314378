import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Button, Space } from 'antd'

import SearchSvg from 'assets/images/search.svg'
import FilterSvg from 'assets/images/filter.svg'
import SortSvg from 'assets/images/sort.svg'
import CustomPagination from 'components/custom-pagination'
import PluginCard from './card'

import { v1PluginsList } from 'api/Api'
import { ResponsesPluginResponse } from 'api/data-contracts'

import styles from './index.scss'
import { useNavigate } from 'react-router-dom'
import { setQuery, sleep } from 'utils/common'
import Loading from 'layouts/loading'

const Plugin = () => {
  const navigate = useNavigate()
  const [pluginList, setPluginList] = useState<ResponsesPluginResponse[]>([])
  const [loading, setLoading] = useState(false)

  const fetchData = async (param: {
    page?: number
    limit?: number
    sort?: string
  }) => {
    const { page = 1, limit = 10, sort } = param
    setLoading(true)
    const pluginResp = await v1PluginsList({
      page,
      limit,
      sort: '{"updated_at":"desc"}',
    })
    setLoading(false)
    if (!pluginResp?.data) return
    const { data } = pluginResp.data
    setPluginList(data)
  }

  const handleCreatePluginClick = () => {
    const search = setQuery({ opType: 'create' })
    navigate(`/portal/plugin/detail?${search}`)
  }

  useEffect(() => {
    fetchData({})
  }, [])

  return (
    <div className={styles.pluginWrapper}>
      <div className={styles.pluginHeaderWrapper}>
        <span className={styles.pluginHeaderTitle}>Plugin</span>
        <Space className={styles.pluginHeaderActionsWrapper}>
          <Button
            icon={<SearchSvg />}
            className={styles.pluginHeaderActionsIcon}
          />
          <Button
            icon={<FilterSvg />}
            className={styles.pluginHeaderActionsIcon}
          />
          <Button
            icon={<SortSvg />}
            className={styles.pluginHeaderActionsIcon}
          />

          <Button type="primary" onClick={handleCreatePluginClick}>
            Create Plugin
          </Button>
        </Space>
      </div>

      <div className={styles.pluginContentWrapper}>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.pluginCardWrapper}>
            {pluginList.map((item) => (
              <PluginCard key={item.id} data={item} onUpdate={fetchData} />
            ))}
          </div>
        )}
        <CustomPagination align="center" />
      </div>
    </div>
  )
}

export default Plugin
