import { ApartmentOutlined } from '@ant-design/icons'
import { Col, DatePicker, Form, Input, Modal, Row, Select, Switch } from 'antd'
import { v1OrganizationCreateCreate, v1OrganizationUpdateUpdate } from 'api/Api'
import { GLOBAL_DATE_FORMAT } from 'constants/common'
import dayjs from 'dayjs'
import { useIsAdmin } from 'hooks/use-is-admin'
import { memo, useEffect, useState } from 'react'
import { currentTimezone } from 'utils/filter'
import { ManagementDataType } from './index'

export type ManagementModalProps = {
  type: 'edit' | 'create'
  open: boolean
  onOpenChange: (open: boolean) => void
  updateData?: () => void
  data?: ManagementDataType
}

type FormType = Pick<
  ManagementDataType,
  'name' | 'type' | 'languageCode' | 'status' | 'expireDate' | 'required2FA'
>

const ManagementModal = (props: ManagementModalProps) => {
  const { type, open, data, onOpenChange, updateData } = props
  const [form] = Form.useForm<FormType>()
  const [isModalOpen, setIsModalOpen] = useState(open)
  const isAdmin = useIsAdmin()

  const handleOk = async () => {
    const values = await form.validateFields()
    if (type === 'edit' && data) {
      const updateResp = await v1OrganizationUpdateUpdate({
        ...values,
        id: Number(data.key),
        organizationType: values.type,
        timezone: 0,
      })
      if (!updateResp) return
    } else {
      const createResp = await v1OrganizationCreateCreate({
        ...values,
        organizationType: values.type,
        timezone: currentTimezone(),
      })
      if (!createResp) return
    }

    onOpenChange(false)
    updateData?.()
  }
  const handleCancel = () => {
    onOpenChange(false)
  }

  useEffect(() => {
    setIsModalOpen(open)
    form.resetFields()

    if (type === 'edit' && data) {
      form.setFieldsValue({
        ...data,
        expireDate: dayjs(data.expireDate) as any,
      })
    }
  }, [props])

  return (
    <Modal
      title={`${type === 'create' ? 'Create' : 'Edit'} Organization`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={form} autoComplete="off">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Organization Name"
              rules={[{ required: true, message: 'Please input a name!' }]}
            >
              <Input prefix={<ApartmentOutlined />} placeholder="Name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="type"
              label="Type"
              rules={[{ required: true, message: 'Please select one type!' }]}
            >
              <Select<any>
                options={[
                  { value: 'owner', label: 'Owner' },
                  { value: 'tenant', label: 'Tenant' },
                ]}
                style={{ textTransform: 'capitalize' }}
                disabled={type === 'edit' && !isAdmin}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="languageCode"
              label="Language Code"
              rules={[
                { required: true, message: 'Please input a language code!' },
              ]}
            >
              <Input placeholder="Language code" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: 'Please select a status!' }]}
            >
              <Select
                options={[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                ]}
                style={{ textTransform: 'capitalize' }}
                disabled={type === 'edit' && !isAdmin}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="expireDate"
              label="Expire Date"
              rules={[{ required: true, message: 'Please select a date!' }]}
            >
              <DatePicker format={GLOBAL_DATE_FORMAT} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="required2FA" label="Required 2FA">
              <Switch disabled={!isAdmin} />
            </Form.Item>
          </Col>
        </Row>
        {/* <Form.Item>
          <Button type="primary" htmlType="submit" shape="round">
            Ok
          </Button>
        </Form.Item> */}
      </Form>
    </Modal>
  )
}

export default memo(ManagementModal)
