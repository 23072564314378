import {
  ConfigFormItemType,
  ConfigSectionType,
} from 'components/new-dynamic-form/types'
import { CommonConfig } from '../constants'

const ItemTimePickerConfig: ConfigSectionType[] = [
  ...CommonConfig,
  {
    sectionLabel: 'timePickerConfig',
    items: [
      { itemLabel: 'Disabled', itemName: 'disabled', type: 'switch' },
      // { itemLabel: 'DefaultValue', itemName: 'defaultValue', type: 'input' },
      {
        itemLabel: 'HourStep',
        itemName: 'hourStep',
        type: 'inputNumber',
        min: 1,
        max: 24,
        defaultValue: 1,
      },
      {
        itemLabel: 'MinuteStep',
        itemName: 'minuteStep',
        type: 'inputNumber',
        min: 1,
        max: 60,
        defaultValue: 1,
      },
      {
        itemLabel: 'SecondStep',
        itemName: 'secondStep',
        type: 'inputNumber',
        min: 1,
        max: 60,
        defaultValue: 1,
      },
      { itemLabel: 'Format', itemName: 'format', type: 'input' },
      { itemLabel: 'Placeholder', itemName: 'placeholder', type: 'input' },
      { itemLabel: 'Use12Hours', itemName: 'use12Hours', type: 'switch' },
      {
        itemLabel: 'ShowNow',
        itemName: 'showNow',
        type: 'switch',
        defaultValue: true,
      },
    ],
  },
]

export default ItemTimePickerConfig
