import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { createApiRequest, RequestData } from 'services/request'
import { makePersistable } from 'mobx-persist-store'
import { CodeMsgResponse } from './models/common'
import {
  AvatarResponse,
  User,
  UserList,
  LoginUser,
  TwoFactorAuthUser,
  UserStatus,
} from './models/user'
import { OrganizationType } from './models/organization'
import roleStore from './role'
import { PermissionType } from './models/permission'
import { v1UserCurrentList } from 'api/Api'

class UserStore {
  loginUser: LoginUser | null = null
  isLoginUserOwner: boolean = false
  userList: UserList = {
    totalCount: 0,
    users: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  async init() {
    await makePersistable(this, {
      name: 'UserStore',
      properties: ['loginUser'],
      storage: window.localStorage,
    })
  }

  async login(email: string, password: string, otp: string) {
    const params: RequestData = {
      body: { email, password, otp },
    }

    const response = await createApiRequest<any | CodeMsgResponse>(
      apiConfig.userLogin,
      params
    )

    const userData = this.setUser(response.data)

    return userData
  }

  async logout() {
    try {
      await createApiRequest(apiConfig.userLogout)
      this.setUser(null)
    } catch (error) {
      this.setUser(null)
      throw error
    }
  }

  setUser(data: any): LoginUser | null {
    if (!data) {
      this.loginUser = null
      return this.loginUser
    }
    const permissions = data.permissions
      ? (data.permissions as string).split(',')
      : []
    const formattedUser: LoginUser = {
      id: data.id,
      name: data.name,
      email: data.email,
      roleID: data.roleID,
      roleName: data.roleName,
      permissions: permissions as PermissionType[],
      organizationName: data.organizationName,
      organizationID: data.organizationID,
      organizationType: data.organizationType as OrganizationType,
      expireDate: new Date(data.expireDate),
      avatarID: data.avatarID,
      avatarUUID: data.avatarUUID,
      sessionID: data.sessionID,
      action: data.action,
      languageCode: data.languageCode,
      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt),
      status: data.status,
    }
    runInAction(() => {
      this.loginUser = formattedUser
      this.isLoginUserOwner =
        roleStore.getRoleById(formattedUser.roleID)?.owner === true
    })
    return formattedUser
  }

  async getCurrentUser() {
    const response = await v1UserCurrentList()
    if (response.data) {
      const user = this.setUser(response.data)
      return user
    }
  }

  get organizationId() {
    let organizationId: number = 0
    if (this.loginUser?.organizationType !== OrganizationType.Owner) {
      organizationId = this.loginUser ? this.loginUser.organizationID : 0
    }
    return organizationId
  }

  async updateName(name: string) {
    const params: RequestData = {
      body: {
        newName: name,
      },
    }

    const response = await createApiRequest(apiConfig.userUpdateName, params)

    runInAction(() => {
      if (this.loginUser) {
        this.loginUser.name = name
      }
    })

    return response
  }

  async updateStatus(id: number, status: UserStatus) {
    const params: RequestData = {
      body: {
        userID: id,
        newStatus: status,
      },
    }

    const response = await createApiRequest<User | CodeMsgResponse>(
      apiConfig.userUpdateStatus,
      params
    )

    const index = this.userList.users.findIndex((user) => user.id === id)
    if (index !== -1) {
      runInAction(() => {
        this.userList.users[index] = {
          ...this.userList.users[index],
          ...params.body,
        }
      })
    }

    return response
  }

  async updatePassword(newPassword: string, oldPassword: string) {
    const params: RequestData = {
      body: {
        newPassword: newPassword,
        oldPassword: oldPassword,
      },
    }

    const response = await createApiRequest<string | CodeMsgResponse>(
      apiConfig.userUpdatePassword,
      params
    )

    return response
  }

  async updatePasswordSession(
    sessionId: string,
    userId: number,
    password: string
  ) {
    const params: RequestData = {
      body: {
        newPassword: password,
        sessionID: sessionId,
        userID: userId,
      },
    }

    const response = await createApiRequest<string | CodeMsgResponse>(
      apiConfig.userUpdatePasswordSession,
      params
    )

    return response
  }

  async resetPassword(userId: number, password: string) {
    const params: RequestData = {
      body: {
        userID: userId,
        newPassword: password,
      },
    }

    const response = await createApiRequest<string | CodeMsgResponse>(
      apiConfig.userResetPassword,
      params
    )

    return response
  }

  async updateAvatar(fileId: number) {
    const params: RequestData = {
      body: {
        fileID: fileId,
      },
    }

    const response = await createApiRequest<AvatarResponse | CodeMsgResponse>(
      apiConfig.userUpdateAvatar,
      params
    )

    runInAction(() => {
      if (this.loginUser) {
        const data = response.data as AvatarResponse
        if (data.avatarID && data.avatarUUID) {
          this.loginUser.avatarID = data.avatarID
          this.loginUser.avatarUUID = data.avatarUUID
        }
      }
    })

    return response
  }

  async getList(page: number, size: number) {
    const params: RequestData = {
      queryParams: {
        page,
        page_size: size,
      },
    }
    const response = await createApiRequest<UserList | CodeMsgResponse>(
      apiConfig.userList,
      params
    )
    const data = response.data as UserList
    runInAction(() => {
      this.userList.totalCount = data?.totalCount
      this.userList.users = data?.users
    })
    return response
  }

  async createUser(
    name: string,
    email: string,
    roleId: number,
    organizationId: number
  ) {
    const params: RequestData = {
      body: {
        name,
        email,
        roleID: roleId,
        organizationID: organizationId,
      },
    }
    const response = await createApiRequest<User | CodeMsgResponse>(
      apiConfig.userCreate,
      params
    )

    return response
  }

  async deleteUser(id: number) {
    throw new Error('Not implemented')
  }

  async resendCreationEmail(id: number) {
    const params: RequestData = {
      body: {
        userID: id,
      },
    }
    const response = await createApiRequest<string | CodeMsgResponse>(
      apiConfig.userResendCreationEmail,
      params
    )
    return response
  }

  async getTwoFactorAuthQRCode() {
    const response = await createApiRequest<string | CodeMsgResponse>(
      apiConfig.user2faQr
    )
    return response
  }

  async getTwoFactorAuthQRCodeSession(sessionId: string, userId: number) {
    const params: RequestData = {
      queryParams: {
        session_id: sessionId,
        user_id: userId,
      },
    }
    const response = await createApiRequest<string | CodeMsgResponse>(
      apiConfig.user2faQrSession,
      params
    )
    return response
  }

  async twoFactorAuthSetup(passcode: string) {
    const params: RequestData = {
      body: {
        passcode,
      },
    }
    const response = await createApiRequest<string | CodeMsgResponse>(
      apiConfig.user2faSetup,
      params
    )
    return response
  }

  async twoFactorAuthSetupSession(
    sessionId: string,
    userId: number,
    passcode: string
  ) {
    const params: RequestData = {
      body: {
        passcode,
        sessionID: sessionId,
        userID: userId,
      },
    }
    const response = await createApiRequest<
      TwoFactorAuthUser | CodeMsgResponse
    >(apiConfig.user2faSetupSession, params)
    return response
  }

  async twoFactorAuthValidate(
    passcode: string,
    sessionId: string,
    userId: number
  ) {
    const params: RequestData = {
      body: {
        passcode,
        sessionID: sessionId,
        userID: userId,
      },
    }
    const response = await createApiRequest<
      TwoFactorAuthUser | CodeMsgResponse
    >(apiConfig.user2faValidate, params)
    return response
  }

  async twoFactorAuthValidateSetupSession(sessionId: string, userId: number) {
    const params: RequestData = {
      body: {
        sessionID: sessionId,
        userID: userId,
      },
    }
    const response = await createApiRequest<
      TwoFactorAuthUser | CodeMsgResponse
    >(apiConfig.user2faValidateSetupSession, params)
    return response
  }

  hasPermission(permission: PermissionType) {
    if (this.isLoginUserOwner) {
      return true
    }
    return this.loginUser?.permissions.includes(permission)
  }
}

const store = new UserStore()

export const hasPermission = store.hasPermission.bind(store)

export default store
