import { App, Col, Flex, Modal, Progress, Row, Typography } from 'antd'
import {
  EvaluationEvaluationMappingStatus,
  ResponsesEvaluationAPIResponse,
  ResponsesEvaluationResponseAPIResponse,
} from 'api/data-contracts'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'
import React, { ReactNode, useState } from 'react'
import { formatDate } from 'utils/filter'

export interface ReportViewerProps {
  data?: ResponsesEvaluationAPIResponse
  agentId: number
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (evaluationId?: number) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const renderProgressBar = (record: ResponsesEvaluationAPIResponse) => {
  const percent = Number(
    (record.completedQuestions / record.totalQuestions).toFixed(2)
  )
  let status: 'success' | 'exception' | 'active' | undefined = undefined
  switch (record.status) {
    case EvaluationEvaluationMappingStatus.Completed:
      status = 'success'
      break
    case EvaluationEvaluationMappingStatus.Failed:
      status = 'exception'
      break
    default:
      status = 'active'
  }
  return (
    <Flex gap={8}>
      <Typography.Text style={{ whiteSpace: 'nowrap' }}>
        ({record.completedQuestions}/{record.totalQuestions})
      </Typography.Text>
      <Progress percent={percent * 100} size="small" status={status} />
    </Flex>
  )
}

const ReportViewer: React.FC<ReportViewerProps> = (props) => {
  const { message } = App.useApp()
  const [confirmLoading, setConfirmLoading] = useState(false)

  if (!props.data) {
    return <></>
  }

  const renderMetricEvaluation = (
    evaluation: ResponsesEvaluationResponseAPIResponse,
    index?: number
  ) => {
    return (
      <Flex gap={8} vertical style={{ paddingBottom: '24px' }}>
        <Row>
          <Col span={6}>Test Case:</Col>
          <Col span={18}>{evaluation.evaluationQuestion.testCase}</Col>
        </Row>
        <Row>
          <Col span={6}>Metrics (Completed/Total):</Col>
          <Col span={18}>
            {evaluation.completedMetrics}/{evaluation.totalMetrics}
          </Col>
        </Row>
        {evaluation.evaluationMetricScores.map((metric, index) => (
          <Row>
            <Col span={6}></Col>
            <Col span={18}>
              <Row>
                <Col span={6}>Name:</Col>
                <Col span={18}>{metric.metricDetails.name}</Col>
              </Row>
              <Row>
                <Col span={6}>Description:</Col>
                <Col span={18}>{metric.metricDetails.description}</Col>
              </Row>
              <Row>
                <Col span={6}>Weight:</Col>
                <Col span={18}>{metric.weight}</Col>
              </Row>
              <Row>
                <Col span={6}>Score:</Col>
                <Col span={18}>{metric.score}</Col>
              </Row>
              <Row>
                <Col span={6}>Reason:</Col>
                <Col span={18}>{metric.reason}</Col>
              </Row>
            </Col>
          </Row>
        ))}
        <Row>
          <Col span={6}>Q&A Context:</Col>
          <Col span={18}>
            {evaluation.evaluationQuestion.questionAnswers.length}
          </Col>
        </Row>
        {evaluation.evaluationQuestion.questionAnswers.map((content, index) => (
          <Row>
            <Col
              span={6}
              style={{ paddingTop: '16px', textTransform: 'capitalize' }}
            >
              {content.role}:
            </Col>
            <Col span={18}>
              <Typography.Paragraph>
                <pre>{content.content}</pre>
              </Typography.Paragraph>
            </Col>
          </Row>
        ))}
        <Row>
          <Col span={6}>Expected Answer:</Col>
          <Col span={18}>
            <Typography.Paragraph>
              <pre>{evaluation.evaluationQuestion.expectedAnswer}</pre>
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={6}>Agent Answer:</Col>
          <Col span={18}>
            <Typography.Paragraph>
              <pre>{evaluation.agentAnswer}</pre>
            </Typography.Paragraph>
          </Col>
        </Row>
      </Flex>
    )
  }

  return (
    <Modal
      title={<h1>Evaluation Report</h1>}
      open={props.open}
      onOk={props.onCancel}
      onCancel={props.onCancel}
      confirmLoading={confirmLoading}
      okText={props.okText}
      width="80%"
      footer={<></>}
    >
      <Flex gap={8} vertical>
        <div>This is an evaluation report for your detailed reference.</div>
        <h2>General:</h2>
        <Row>
          <Col span={6}>Start Time:</Col>
          <Col span={18}>{formatDate(props.data.startTime)}</Col>
        </Row>
        <Row>
          <Col span={6}>End Time:</Col>
          <Col span={18}>{formatDate(props.data.endTime)}</Col>
        </Row>
        <Row>
          <Col span={6}>Name:</Col>
          <Col span={18}>
            {isEmpty(props.data.name) ? 'NA' : props.data.name}
          </Col>
        </Row>
        <Row>
          <Col span={6}>Workflow Version:</Col>
          <Col span={18}>
            {isEmpty(props.data.workflowVersion)
              ? 'NA'
              : props.data.workflowVersion}
          </Col>
        </Row>
        <Row>
          <Col span={6}>Progress:</Col>
          <Col span={18}>{renderProgressBar(props.data)}</Col>
        </Row>
        <Row>
          <Col span={6}>Scores:</Col>
          <Col span={18}>{(props.data.averageScore * 100).toFixed(2)}%</Col>
        </Row>
        <Row>
          <Col span={6}>Status:</Col>
          <Col span={18} style={{ textTransform: 'capitalize' }}>
            {props.data.status}
          </Col>
        </Row>
        <h2>Evaluation:</h2>
        {props.data.evaluationResponses.map((metric, index) =>
          renderMetricEvaluation(metric, index)
        )}
      </Flex>
    </Modal>
  )
}

export default observer(ReportViewer)
