import {
  ConfigFormItemType,
  ConfigSectionType,
} from 'components/new-dynamic-form/types'
import { CommonConfig } from '../constants'

const ItemCodeConfig: ConfigSectionType[] = [
  ...CommonConfig,
  {
    sectionLabel: 'codeConfig',
    items: [{ itemLabel: 'Language', itemName: 'language', type: 'input' }],
  },
]

export default ItemCodeConfig
