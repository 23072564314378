import { MailOutlined, UserOutlined } from '@ant-design/icons'
import { Form, Input, Modal, Select, Switch } from 'antd'
import { memo, useEffect, useRef, useState } from 'react'
import roleStore from 'stores/role'
import userStore from 'stores/user'

import {
  v1User2FaRemovePartialUpdate,
  v1User2FaResetPartialUpdate,
  v1UserCreateCreate,
  v1UserResetPasswordCreate,
  v1UserUpdateEmailPartialUpdate,
  v1UserUpdateNamePartialUpdate,
  v1UserUpdateRolePartialUpdate,
  v1UserUpdateStatusPartialUpdate,
} from 'api/Api'
import { UserStatusUserStatus } from 'api/data-contracts'
import PasswordFormItem, {
  PasswordFormItemRefProps,
} from 'components/password-form-item'
import { useIsAdmin } from 'hooks/use-is-admin'
import { UserStatusOptions } from 'stores/models/user'
import { currentTimezone } from 'utils/filter'
import { MembersDataType } from './index'

export type MembersModalProps = {
  type: 'edit' | 'create'
  open: boolean
  onOpenChange: (open: boolean) => void
  updateData?: () => void
  data?: MembersDataType
}

type FormType = Pick<
  MembersDataType,
  'name' | 'email' | 'role' | 'status' | 'twoFaStatus'
> & {
  password: string
}

const MembersModal = (props: MembersModalProps) => {
  const { type, open, data, onOpenChange, updateData } = props
  const [form] = Form.useForm<FormType>()
  const [isModalOpen, setIsModalOpen] = useState(open)
  const passwordFormItemRef = useRef<PasswordFormItemRefProps>(null)
  const isAdmin = useIsAdmin()

  const handleOk = async () => {
    const values = await form.validateFields()
    const userID = Number(data?.key)

    if (type === 'edit' && data) {
      if (passwordFormItemRef.current?.isPasswordChanged()) {
        await v1UserResetPasswordCreate({
          newPassword: values.password,
          userID,
        })
      }

      // update 2FA status
      if (props.data?.twoFaStatus !== values.twoFaStatus) {
        if (values.twoFaStatus) {
          // enable 2FA
          await v1User2FaResetPartialUpdate(userID)
        } else {
          // disable 2FA
          await v1User2FaRemovePartialUpdate(userID)
          if (values.status === UserStatusUserStatus.PendingSetup2FA) {
            values.status = UserStatusUserStatus.Active
          }
        }
      }

      // TODO:Tanner: update the user info. There is'n update API.
      if (props.data?.email !== values.email) {
        await v1UserUpdateEmailPartialUpdate({ email: values.email })
      }
      if (props.data?.name !== values.name) {
        await v1UserUpdateNamePartialUpdate({ newName: values.name })
      }
      if (props.data?.role !== values.role) {
        await v1UserUpdateRolePartialUpdate({ roleID: values.role })
      }
      if (props.data?.status !== values.status) {
        await v1UserUpdateStatusPartialUpdate({
          newStatus: values.status,
          userID,
        })
      }

      // const updateResp = await v1OrganizationUpdateUpdate({
      // })
      // if (!updateResp) return
    } else {
      const createResp = await v1UserCreateCreate({
        ...values,
        organizationID: userStore.loginUser?.organizationID ?? 0,
        roleID: values.role,
        timezone: currentTimezone(),
      })
      if (!createResp) return
    }

    onOpenChange(false)
    updateData?.()
  }
  const handleCancel = () => {
    onOpenChange(false)
  }

  useEffect(() => {
    setIsModalOpen(open)
    form.resetFields()
    passwordFormItemRef.current?.reset()

    if (type === 'edit' && data) {
      form.setFieldsValue(data)
    }
  }, [props])

  return (
    <Modal
      title={`${type === 'create' ? 'Create' : 'Edit'} User`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={form} autoComplete="off">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input a name!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input an email!' },
            { type: 'email', message: 'The input is not valid e-mail!' },
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="role"
          label="Role"
          rules={[{ required: true, message: 'Please select a role!' }]}
        >
          <Select
            options={roleStore.roleList.roles.map((role) => ({
              value: role.id,
              label: role.name,
            }))}
            disabled={!isAdmin}
            style={{ textTransform: 'capitalize' }}
          />
        </Form.Item>
        {type === 'edit' && (
          <PasswordFormItem
            ref={passwordFormItemRef}
            form={form}
            password={''}
          />
        )}
        <Form.Item name="twoFaStatus" label="2FA Enabled">
          <Switch disabled={!isAdmin} />
        </Form.Item>
        {type === 'edit' && (
          <Form.Item name="status" label="Status">
            <Select
              options={UserStatusOptions}
              disabled={!isAdmin}
              style={{ textTransform: 'capitalize' }}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default memo(MembersModal)
