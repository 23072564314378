import React, { useEffect, useState } from 'react'
import { Handle, Position, useReactFlow } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { BankOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import { observer } from 'mobx-react'
import {
  OutputField,
  InputField,
  DataType,
  InputType,
  CustomNodeProps,
  NodeData,
} from 'views/portal/agent/studio/workflow/model'
import CustomHandle from '../../components/custom-handle'

// Define default values
const defaultInputs: InputField[] = [
  {
    name: 'chat_to_document',
    type: 'input' as InputType,
    dataType: 'Boolean' as DataType,
    value: false,
    reference: '',
  },
  {
    name: 'chat_to_image',
    type: 'input' as InputType,
    dataType: 'Boolean' as DataType,
    value: false,
    reference: '',
  },
  {
    name: 'voice_conversation',
    type: 'input' as InputType,
    dataType: 'Boolean' as DataType,
    value: false,
    reference: '',
  },
  {
    name: 'conversation_opener',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: '',
    reference: '',
  },
  {
    name: 'opening_questions',
    type: 'input' as InputType,
    dataType: 'Array<String>' as DataType,
    value: [],
    reference: '',
  },
  {
    name: 'pii_enabled',
    type: 'input' as InputType,
    dataType: 'Boolean' as DataType,
    value: false,
    reference: '',
  },
  {
    name: 'pii_placeholder',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: '***',
    reference: '',
  },
  {
    name: 'pii_threshold',
    type: 'input' as InputType,
    dataType: 'Number' as DataType,
    value: 0.3,
    reference: '',
  },
  {
    name: 'pii_labels',
    type: 'input' as InputType,
    dataType: 'Array<String>' as DataType,
    value: [],
    reference: '',
  },
  {
    name: 'keywords_blacklist',
    type: 'input' as InputType,
    dataType: 'Array<String>' as DataType,
    value: [],
    reference: '',
  },
]

const defaultOutputs: OutputField[] = [
  {
    name: 'text',
    type: 'String' as DataType,
    description: 'Generated Text',
    required: true,
    children: [],
  },
]

const defaultNodeData: NodeData = {
  label: 'Template',
  description: 'Define the initial parameters for launching a workflow',
  input: defaultInputs,
  output: defaultOutputs,
  intentBranch: [],
  conditionBranch: [],
  branchOutput: {},
}

const TemplateNode: React.FC<CustomNodeProps> = observer((node) => {
  const { getNodes } = useReactFlow()
  const [nodeData, setNodeData] = useState<NodeData>(
    node.data.data || defaultNodeData
  )

  useEffect(() => {
    if (!node.data.data) {
      const newNodeData = defaultNodeData
      setNodeData(newNodeData)
      if (node.data.onChange) {
        node.data.onChange(node.id, { data: newNodeData })
      }
    } else if (node.data.data.label !== nodeData.label) {
      setNodeData(node.data.data)
    }
  }, [node.data, getNodes])

  useEffect(() => {
    if (node.selected) {
      WorkflowStore.selectNode(node)
    }
  }, [node.selected])

  return (
    <div
      className={
        node.id === WorkflowStore.selectedNode?.id
          ? 'custom-node template-node active'
          : 'custom-node template-node'
      }
    >
      <CustomHandle
        node={node}
        type="target"
        position={Position.Left}
        isConnectable={node.isConnectable}
      />
      <div className="node-title">
        <div className="icon">
          <BankOutlined />
        </div>
        <div className="text">{nodeData.label}</div>
      </div>
      <div className="node-desc">{nodeData.description}</div>
      <CustomHandle
        node={node}
        type="source"
        position={Position.Right}
        isConnectable={node.isConnectable}
      />
    </div>
  )
})
export default TemplateNode
