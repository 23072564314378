import {
  DeleteOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import {
  App,
  Avatar,
  Button,
  Dropdown,
  Flex,
  MenuProps,
  Spin,
  Tooltip,
} from 'antd'
import FolderSvg from 'assets/images/folder.svg'
import { useExNavigate } from 'hooks/use-ex-navigate'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import knowledgeStore from 'stores/knowledge'
import { getMessageFromError } from 'utils/common'
import { formatFileSize } from 'utils/filter'
import KnowledgeModal from '../knowledge-modal'
import styles from './index.scss'

export enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

export interface KnowledgeCardProps {
  data: {
    id: number
    name: string
    description: string
    documentCount: number
    documentSize: number
  }
  onAction?: (type: ActionType, knowledge: KnowledgeCardProps['data']) => void
}

const KnowledgeCard: React.FC<KnowledgeCardProps> = (props) => {
  const [isKnowledgeModalOpen, setIsKnowledgeModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { message, modal } = App.useApp()
  const navigate = useExNavigate()
  const [data, setData] = useState<KnowledgeCardProps['data']>(props.data)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  const handleOpenClick = () => {
    navigate(`/portal/knowledge/document`, {
      state: { knowledge: data },
    })
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'edit') {
      props.onAction?.(e.key as ActionType, data)
    } else if (e.key === 'delete') {
      modal.confirm({
        title: 'Are you sure you want to delete this knowledge?',
        content:
          'This action cannot be undone and will permanently delete the knowledge from the system.',
        okText: 'Yes, Delete it',
        okType: 'danger',
        cancelText: 'No, Cancel',
        async onOk() {
          setLoading(true)
          try {
            await knowledgeStore.delete(data.id)
            message.success('The knowledge has been successfully deleted.')
            props.onAction?.(e.key as ActionType, data)
          } catch (error) {
            message.error(getMessageFromError(error))
          }
          setLoading(false)
        },
      })
    }

    e.domEvent.stopPropagation()
  }

  const items: MenuProps['items'] = [
    {
      label: 'Edit Info',
      key: 'edit',
      icon: <SettingOutlined />,
    },
    {
      label: 'Delete',
      key: 'delete',
      icon: <DeleteOutlined />,
    },
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Flex
        className={styles.knowledgeCardWrapper}
        gap={16}
        vertical
        onClick={handleOpenClick}
      >
        <Flex gap={8}>
          <Avatar
            className={styles.knowledgeAvatar}
            shape="square"
            size={54}
            icon={<FolderSvg />}
          />
          <Flex
            justify="space-between"
            gap={8}
            flex={1}
            vertical
            style={{ padding: '4px 0', overflow: 'hidden' }}
          >
            <div className={styles.knowledgeName}>
              <Tooltip title={data.name}>{data.name}</Tooltip>
            </div>
            <Flex className={styles.knowledgeMetaInfo} gap={4}>
              <span>{data.documentCount} docs</span>
              <span>{formatFileSize(data.documentSize)}</span>
            </Flex>
          </Flex>
          <Dropdown trigger={['click']} menu={menuProps}>
            <Button
              type="text"
              className={styles.knowledgeMenuButton}
              onClick={(e) => e.stopPropagation()}
            >
              <EllipsisOutlined style={{ transform: 'rotate(90deg)' }} />
            </Button>
          </Dropdown>
        </Flex>
        <div className={styles.knowledgeDescription}>{data.description}</div>
      </Flex>
    </Spin>
  )
}

export default observer(KnowledgeCard)
