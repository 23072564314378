/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AgentTypeAgentType {
  Chatbot = 'chatbot',
  SmartAPI = 'smart_api',
}

export enum BodyPayloadTypeBodyPayloadType {
  JSON = 'json',
  FormData = 'form-data',
}

export enum DocumentMappingStatusDocumentMappingStatus {
  Pending = 'pending',
  Failed = 'failed',
  Processing = 'processing',
  Processed = 'processed',
}

export enum DocumentSourceDocumentSource {
  Web = 'web',
  Document = 'document',
  Article = 'article',
}

export enum EvaluationEvaluationMappingStatus {
  Pending = 'pending',
  Failed = 'failed',
  Processing = 'processing',
  Completed = 'completed',
}

export enum EvaluationEvaluationResponsesStatus {
  ResponsesPartial = 'partial',
  ResponsesFailed = 'failed',
  ResponsesCompleted = 'completed',
}

export enum EvaluationEvaluationType {
  EvalTypeGEval = 'g_eval',
  EvalTypeContextualPrecision = 'contextual_precision',
  EvalTypeAnswerRelevancy = 'answer_relevancy',
  EvalTypeFaithfulness = 'faithfulness',
  EvalTypeContextualRecall = 'contextual_recall',
  EvalTypeConversationRelevancy = 'conversation_relevancy',
  EvalTypeSummarization = 'summarization',
  EvalTypeRagas = 'ragas',
}

export interface EvaluationModelsEvaluationSettings {
  evaluationParams: string[]
  evaluationSteps: string[]
  name: string
  threshold: number
  windowSize: number
}

export interface EvaluationModelsLLMMessage {
  content: string
  role: ModelsConversationRoleType
}

export enum FileBelongsFileBelongs {
  Session = 'session',
  Audio = 'audio',
  Document = 'document',
  AgentIcon = 'agent_icon',
  Avatar = 'avatar',
  Plugin = 'plugin',
  PluginCategory = 'plugin_category',
}

export enum FileTypeFileType {
  JPG = 'jpg',
  GIF = 'gif',
  PNG = 'png',
  DOCX = 'docx',
  XLSX = 'xlsx',
  PPTX = 'pptx',
  CSV = 'csv',
  TXT = 'txt',
  MP3 = 'mp3',
  WAV = 'wav',
  MP4 = 'mp4',
  M4A = 'm4a',
  MPGA = 'mpga',
  OPUS = 'opus',
  WEBM = 'webm',
}

export interface GenieCoreAppHandlersAgentResponsesPublishChannelResponse {
  agentID: number
  channelType: PublishChannelTypePublishChannelType
  createdAt: string
  enabled: boolean
  extraData: string
  id: number
  main: boolean
  organizationID: number
  title: string
  updatedAt: string
  uuid: string
}

export interface GenieCoreAppHandlersChatRequestsAddFeedbackRequest {
  feedback: string
  feedbackLabel: string
}

export interface GenieCoreAppHandlersChatRequestsLikeUnlikeRequest {
  /** @example true */
  status: boolean
}

export interface GenieCoreAppHandlersChatRequestsSendRequest {
  agentUUID: string
  audioID: string
  customFields: Record<string, any>
  documentID: string
  imageID: string
  language: string
  sessionUUID: string
  userPrompt: string
}

export interface GenieCoreAppHandlersChatResponsesChatConversationResponse {
  agentID: number
  content: string
  createdAt: string
  dislike: boolean
  error: string
  feedback: string
  feedbackLabel: string
  id: string
  intents: ResponsesIntentEntityInfoResponse[]
  like: boolean
  nodeInfos: ModelsLightNodeInfo[]
  organizationID: number
  responseDurationInMilliseconds: number
  role: ModelsConversationRoleType
  session: GenieCoreAppHandlersChatResponsesChatSessionResponse
  sessionUUID: string
  userID: number
  userTokens: number
}

export interface GenieCoreAppHandlersChatResponsesChatSessionResponse {
  agentID: number
  channel: ResponsesChatSessionChannelResponse
  channelID: number
  createdAt: string
  id: number
  latestConversationDatetime: string
  organizationID: number
  title: string
  updatedAt: string
  userID: number
  uuid: string
}

export interface GenieCoreAppHandlersChatResponsesDocumentResponse {
  content: string
  file_size: number
  file_type: string
  filename: string
  id: string
  status: string
}

export interface GenieCoreAppHandlersChatResponsesSuccessfulResponseMessage {
  message: string
}

export interface GenieCoreAppHandlersDocumentResponsesDocumentResponse {
  createdAt: string
  fileType: string
  filename: string
  id: string
  processedContent: string
  size: number
}

export interface GenieCoreAppHandlersFileResponsesFileResponse {
  createdAt: string
  fileBelongs: FileBelongsFileBelongs
  fileType: FileTypeFileType
  filename: string
  id: number
  organizationID: number
  path: string
  size: number
  updatedAt: string
  userID: number
  username: string
  uuid: string
}

export interface GenieCoreAppHandlersKnowledgeResponsesFileResponse {
  createdAt: string
  filename: string
  id: number
  organizationID: number
  path: string
  size: number
  updatedAt: string
  userID: number
  username: string
}

export interface GenieCoreAppHandlersPublicRequestsAddFeedbackRequest {
  feedback: string
  feedbackLabel: string
  id: string
}

export interface GenieCoreAppHandlersPublicRequestsLikeUnlikeRequest {
  /** @example "5f7b1a2b3c4d5e6f7a8b9c0d" */
  _id: string
  /** @example true */
  status: boolean
}

export interface GenieCoreAppHandlersPublicRequestsSendRequest {
  audioID: string
  customParameters: Record<string, any>
  documentID: string
  imageID: string
  language: string
  sessionUUID: string
  userPrompt: string
  uuid: string
}

export interface GenieCoreAppHandlersPublicResponsesChatConversationResponse {
  content: string
  createdAt: string
  id: string
  organizationID: string
  role: ModelsConversationRoleType
  sessionUUID: string
  tokens: number
  userID: string
}

export interface GenieCoreAppHandlersPublicResponsesDocumentResponse {
  content: string
  file_size: number
  file_type: string
  filename: string
  id: string
  status: string
}

export interface GenieCoreAppHandlersPublicResponsesPublishChannelResponse {
  agentID: number
  channelType: PublishChannelTypePublishChannelType
  createdAt: string
  edges: string
  enabled: boolean
  extraData: string
  id: number
  nodes: string
  organizationID: number
  title: string
  updatedAt: string
  uuid: string
  workflowVersion: string
}

export interface GenieCoreAppHandlersPublicResponsesSuccessfulResponseMessage {
  message: string
}

export enum HttpMethodSmartAPIURLMethod {
  SmartAPIURLMethodGET = 'GET',
  SmartAPIURLMethodPOST = 'POST',
  SmartAPIURLMethodPATCH = 'PATCH',
  SmartAPIURLMethodDELETE = 'DELETE',
  SmartAPIURLMethodPUT = 'PUT',
}

export enum JobStatusJobStatus {
  JobStatusQueued = 'queued',
  JobStatusProcessing = 'processing',
  JobStatusCompleted = 'completed',
  JobStatusFailed = 'failed',
  JobStatusCancelled = 'cancelled',
}

export interface ModelsChatbotResponse {
  answer: string
  completionTokens: number
  endTime: string
  errorMessage: string
  intents: Record<string, ModelsIntentEntityInfo>
  nodeInfos: ModelsLightNodeInfo[]
  promptTokens: number
  requestMessageID: string
  responseMessageID: string
  startTime: string
  status: WorkflowRunningStatusWorkflowRunningStatus
  title: string
  userTokens: number
  uuid: string
}

export enum ModelsConversationRoleType {
  RoleUser = 'user',
  RoleAssistant = 'assistant',
}

export interface ModelsIntentEntityInfo {
  label: string
  nameEntity: string
}

export interface ModelsIntentExample {
  example: string
  parameters: string
}

export interface ModelsIntentParameter {
  description: string
  name: string
  required: boolean
  type: string
}

export interface ModelsJobError {
  code: string
  details: string
  message: string
}

export interface ModelsLightNodeInfo {
  durationInMicroSeconds: number
  endTime: string
  error: string
  /**
   * Config                 interface{}            `json:"config,omitempty"`
   * Input                  interface{}            `json:"input,omitempty"`
   * Output                 interface{}            `json:"output,omitempty"`
   * NodeOutputParams       map[string]NodeValue   `json:"nodeOutputParams,omitempty"`
   */
  logs: ModelsNodeLogInfo[]
  nodeId: string
  nodeName: string
  nodeType: string
  startTime: string
  status: NodeStatusNodeStatus
}

export interface ModelsNodeLogInfo {
  endTime: string
  error: string
  message: string
  startTime: string
  status: NodeLogStatusNodeLogStatus
}

export interface ModelsNodeValue {
  type: string
  value: any
}

export interface ModelsOutputField {
  children: ModelsOutputField[]
  description: string
  expanded: boolean
  name: string
  required: boolean
  type: string
}

export enum NodeLogStatusNodeLogStatus {
  Processing = 'processing',
  Completed = 'completed',
  Failed = 'failed',
}

export enum NodeStatusNodeStatus {
  Initiating = 'initiating',
  Processing = 'processing',
  Success = 'success',
  Failed = 'failed',
}

export enum OrganizationStatusOrganizationStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum OrganizationTypeOrganizationType {
  Owner = 'owner',
  Tenant = 'tenant',
}

export interface PluginFormItemConfig {
  label: string
  name: string
  required: boolean
}

export enum PluginFormSelectorType {
  FormTypeRoot = 'root',
  FormTypeSection = 'section',
  FormTypeInput = 'input',
  FormTypeInputNumber = 'inputNumber',
  FormTypeSwitch = 'switch',
  FormTypeSlider = 'slider',
  FormTypeCheckbox = 'checkbox',
  FormTypeRadio = 'radio',
  FormTypeSelect = 'select',
  FormTypeTimePicker = 'timePicker',
  FormTypeDatePicker = 'datePicker',
  FormTypeUpload = 'upload',
  FormTypeCode = 'code',
  FormTypeReferenceSelect = 'referenceSelect',
  FormTypeList = 'list',
  FormTextArea = 'textArea',
}

export interface PluginGeneralConfig {
  disabled: boolean
  hidden: boolean
}

export enum PluginPluginDataType {
  String = 'String',
  Integer = 'Integer',
  Boolean = 'Boolean',
  Number = 'Number',
  Date = 'Date',
  Datetime = 'Datetime',
  Object = 'Object',
  Array = 'Array',
  Document = 'Document',
  Image = 'Image',
  Audio = 'Audio',
  Video = 'Video',
}

export enum PluginPluginFormStatus {
  Draft = 'draft',
  PendingReview = 'pending_review',
  InReview = 'in_review',
  ChangeRequest = 'change_request',
  Approved = 'approved',
  Published = 'published',
  Deprecated = 'deprecated',
}

export enum PluginPluginOutputFormType {
  Fixed = 'fixed',
  Customized = 'customized',
}

export enum PluginPluginType {
  Internal = 'internal',
  External = 'external',
}

export interface PluginProps {
  checkboxConfig?: any
  codeConfig?: any
  datePickerConfig?: any
  formItemConfig: PluginFormItemConfig
  generalConfig?: PluginGeneralConfig
  inputConfig?: any
  inputNumberConfig?: any
  listConfig?: any
  radioConfig?: any
  referenceSelectConfig?: any
  selectConfig?: any
  sliderConfig?: any
  switchConfig?: any
  timePickerConfig?: any
  uploadConfig?: any
}

export interface PluginModelsPluginFormItem {
  /**
   * @Description Nested PluginFormItems (recursive structure)
   * @Schema(type=array,items=ref(PluginFormItem))
   */
  children: PluginModelsPluginFormItem[]
  formItemId: string
  path: string[]
  props: PluginProps
  type: PluginFormSelectorType
}

export interface PluginModelsPluginOutputForm {
  data: PluginModelsTreeNode[]
  type: PluginPluginOutputFormType
}

export interface PluginModelsTreeNode {
  /**
   * @Description Nested TreeNodes (recursive structure)
   * @Schema(type=array,items=ref(TreeNode))
   */
  children: PluginModelsTreeNode[]
  deep: number
  key: string
  name: string
  required: boolean
  type: PluginPluginDataType
}

export enum PublishChannelTypePublishChannelType {
  ChatbotPage = 'chatbot_page',
  ChatbotBubble = 'chatbot_bubble',
}

export interface RepositoryPaginationResponse {
  currentPage: number
  hasNext: boolean
  hasPrevious: boolean
  nextPage: number
  perPage: number
  previousPage: number
  totalItems: number
  totalPages: number
}

export interface RequestsAddDocumentChunkRequest {
  content: string
  documentID: number
  fileID: number
  knowledgeID: number
  metadata: Record<string, any>
}

export interface RequestsChangePasswordRequest {
  newPassword: string
  oldPassword: string
}

export interface RequestsChangePasswordWithSessionIDRequest {
  newPassword: string
  sessionID: string
  userID: number
}

export interface RequestsChatSessionUpdateTitleRequest {
  title: string
}

export interface RequestsCreateAPIRequest {
  apiName: string
  description: string
  method: string
  pathParams: string
  queryParams: string
  requestHeaders: string
  requestParams: string
  responseParams: string
  timeout: number
  uri: string
}

export interface RequestsCreateAgentRequest {
  agentIconID: number
  agentType: 'smart_api' | 'chatbot'
  description: string
  name: string
  smartAPI?: RequestsSmartAPIDefinitionRequest
}

export interface RequestsCreateDocumentRequest {
  chunkLength: number
  chunkOverlap: number
  embeddingModelID: number
  fileID: number
  knowledgeID: number
  replaceConsecutiveSpaces: boolean
  replaceEmails: boolean
  replaceNewlines: boolean
  replaceTabs: boolean
  replaceURLs: boolean
  segmentIdentifier: string
}

export interface RequestsCreateEvaluationMetricRequest {
  description: string
  evaluationType: EvaluationEvaluationType
  name: string
  settings: EvaluationModelsEvaluationSettings
}

export interface RequestsCreateIntentRequest {
  description: string
  examples: ModelsIntentExample[]
  intent: string
  parameters: ModelsIntentParameter[]
}

export interface RequestsCreateKnowledgeRequest {
  description: string
  knowledgeName: string
}

export interface RequestsCreateOrganizationRequest {
  expireDate: string
  languageCode: string
  name: string
  organizationType: OrganizationTypeOrganizationType
  required2FA: boolean
  /**
   * @min -12
   * @max 14
   * @default 0
   */
  timezone: number
}

export interface RequestsCreatePermissionRequest {
  identity: string
  name: string
  organization_type: OrganizationTypeOrganizationType
  parent_id: number
}

export interface RequestsCreatePublishChannelRequest {
  agentID: number
  channelType: PublishChannelTypePublishChannelType
  /** @default false */
  enabled: boolean
  extraData: string
  /** @default false */
  main: boolean
  title: string
}

export interface RequestsCreateQuestionRequest {
  agentID: number
  expectedAnswer: string
  questionAnswers: EvaluationModelsLLMMessage[]
  testCase: string
}

export interface RequestsCreateRoleRequest {
  name: string
  owner: boolean
  permissions: string
}

export interface RequestsCreateUserRequest {
  email: string
  name: string
  organizationID: number
  roleID: number
  /**
   * @min -12
   * @max 14
   * @default 0
   */
  timezone: number
}

export interface RequestsEvaluationMetricWithWeightRequest {
  evaluationMetricID: number
  /**
   * @min 0
   * @max 1
   */
  weight: number
}

export interface RequestsEvaluationRequest {
  agentID: number
  evaluationMetrics: RequestsEvaluationMetricWithWeightRequest[]
  name: string
  questionIDs: number[]
}

export interface RequestsLockRequest {
  key: string
  ttl: number
  value: string
}

export interface RequestsLoginRequest {
  email: string
  otp: string
  password: string
}

export interface RequestsPluginCategoryRequest {
  description: string
  iconID?: number
  name: string
}

export interface RequestsPluginRequest {
  categoryID: number
  description?: string
  endpoint?: string
  iconID?: number
  name: string
  type: PluginPluginType
}

export interface RequestsPluginWithFormRequest {
  categoryID: number
  configurationForm: PluginModelsPluginFormItem
  description?: string
  endpoint?: string
  iconID?: number
  name: string
  outputForm: PluginModelsPluginOutputForm
  type: PluginPluginType
  workflowForm: PluginModelsPluginFormItem
}

export interface RequestsPopulateConversationStatisticsRequest {
  agent_id: number
  end_at: string
  overwrite: boolean
  start_at: string
}

export interface RequestsResendEmailForUserCreationRequest {
  userID: number
}

export interface RequestsResetPasswordRequest {
  newPassword: string
  userID: number
}

export interface RequestsRestoreRequest {
  id: number
}

export interface RequestsSavePluginFormRequest {
  configurationForm: PluginModelsPluginFormItem
  outputForm: PluginModelsPluginOutputForm
  workflowForm: PluginModelsPluginFormItem
}

export interface RequestsSaveRequest {
  edges: string
  id: number
  nodes: string
}

export interface RequestsSetMainChannelRequest {
  isMain: boolean
}

export interface RequestsSetup2FARequest {
  passcode: string
}

export interface RequestsSetup2FAWithSessionRequest {
  passcode: string
  sessionID: string
  userID: number
}

export interface RequestsSmartAPIDefinitionRequest {
  method: HttpMethodSmartAPIURLMethod
  resourceName: string
  version: string
}

export interface RequestsSmartAPIUpdateStatus {
  status: StatusSmartAPIStatus
}

export interface RequestsUpdateAPIRequest {
  apiName: string
  description: string
  id: number
  method: string
  pathParams: string
  queryParams: string
  requestHeaders: string
  requestParams: string
  responseParams: string
  timeout: number
  uri: string
}

export interface RequestsUpdateAgentRequest {
  agentType?: 'smart_api' | 'chatbot'
  description?: string
  iconID?: number
  id: number
  name?: string
  smartAPI?: RequestsSmartAPIDefinitionRequest
}

export interface RequestsUpdateAvatarRequest {
  fileID: number
}

export interface RequestsUpdateDocumentChunkRequest {
  _id: string
  content: string
  knowledgeID: number
  metadata: Record<string, any>
}

export interface RequestsUpdateDocumentChunkStatusRequest {
  _id: string
  enabled: boolean
  knowledgeID: number
}

export interface RequestsUpdateDocumentRequest {
  documentName: string
  id: number
}

export interface RequestsUpdateEmailRequest {
  email: string
}

export interface RequestsUpdateEvaluationMetricRequest {
  description: string
  evaluationType: EvaluationEvaluationType
  id: number
  name: string
  settings: EvaluationModelsEvaluationSettings
}

export interface RequestsUpdateIntentRequest {
  description: string
  examples: ModelsIntentExample[]
  id: number
  intent: string
  parameters: ModelsIntentParameter[]
}

export interface RequestsUpdateKnowledgeRequest {
  description: string
  id: number
  knowledgeName: string
}

export interface RequestsUpdateOrganizationExpireDateRequest {
  expireDate: string
  id: number
}

export interface RequestsUpdateOrganizationLanguageCodeRequest {
  id: number
  language_code: string
}

export interface RequestsUpdateOrganizationNameRequest {
  id: number
  name: string
}

export interface RequestsUpdateOrganizationRequest {
  expireDate: string
  id: number
  languageCode: string
  name: string
  organizationType: OrganizationTypeOrganizationType
  required2FA: boolean
  status: OrganizationStatusOrganizationStatus
  /**
   * @min -12
   * @max 14
   */
  timezone: number
}

export interface RequestsUpdateOrganizationRequired2FARequest {
  id: number
  required_2fa: boolean
}

export interface RequestsUpdateOrganizationStatusRequest {
  id: number
  status: OrganizationStatusOrganizationStatus
}

export interface RequestsUpdateOrganizationTimezoneRequest {
  id: number
  /**
   * @min -12
   * @max 14
   * @default 8
   */
  timezone: number
}

export interface RequestsUpdatePermissionRequest {
  id: number
  identity: string
  name: string
  organization_type: OrganizationTypeOrganizationType
  parent_id: number
}

export interface RequestsUpdatePublishChannelRequest {
  agentID: number
  channelType: PublishChannelTypePublishChannelType
  /** @default false */
  enabled: boolean
  extraData: string
  id: number
  /** @default false */
  main: boolean
  title: string
}

export interface RequestsUpdateQuestionRequest {
  agentID: number
  expectedAnswer: string
  id: number
  questionAnswers: EvaluationModelsLLMMessage[]
  testCase: string
}

export interface RequestsUpdateRoleRequest {
  id: number
  name: string
  owner: boolean
  permissions: string
}

export interface RequestsUpdateTimezoneRequest {
  /**
   * @min -12
   * @max 14
   * @default 8
   */
  timezone: number
}

export interface RequestsUpdateUserNameRequest {
  newName: string
}

export interface RequestsUpdateUserRoleRequest {
  roleID: number
}

export interface RequestsUpdateUserStatusRequest {
  newStatus: UserStatusUserStatus
  userID: number
}

export interface RequestsValidate2FARequest {
  passcode: string
  sessionID: string
  userID: number
}

export interface RequestsValidate2FASetupSessionRequest {
  sessionID: string
  userID: number
}

export interface ResponsesAPIListResponse {
  apis: ResponsesAPIResponse[]
  totalCount: number
}

export interface ResponsesAPIResponse {
  apiName: string
  description: string
  id: number
  method: string
  organizationID: number
  pathParams: string
  queryParams: string
  requestHeaders: string
  requestParams: string
  responseParams: string
  timeout: number
  uri: string
}

export interface ResponsesAgentListResponse {
  agents: ResponsesAgentResponse[]
  totalCount: number
}

export interface ResponsesAgentResponse {
  agentIconID: number
  agentIconUUID: string
  agentName: string
  agentType: string
  createdAt: string
  description: string
  id: number
  organizationId: number
  smartAPI: ResponsesSmartAPIResponse
  updatedAt: string
  uuid: string
}

export interface ResponsesAllLocksResponse {
  locks: ResponsesLockResponse[]
  total: number
}

export interface ResponsesAudioResponse {
  createdAt: string
  filename: string
  id: string
  path: string
  size: number
  transcriptions: Record<string, string>
}

export interface ResponsesChatSessionChannelResponse {
  agentID: number
  channelType: PublishChannelTypePublishChannelType
  createdAt: string
  enabled: boolean
  extraData: string
  id: number
  main: boolean
  organizationID: number
  title: string
  updatedAt: string
  uuid: string
}

export interface ResponsesConversationStatsJobResponse {
  jobID: string
  message: string
  progress: number
  status: string
}

export interface ResponsesConversationStatsWithDetailResponse {
  agentID: number
  averageConversationDurationInSeconds: number
  averageInteractions: number
  averageResponseTimeInMilliseconds: number
  dislikes: ResponsesFeedbackDetailResponse[]
  endAt: string
  fallbackRateInPercentage: number
  feedbacks: ResponsesFeedbackWithCommentResponse[]
  intents: ResponsesIntentStatResponse[]
  likes: ResponsesFeedbackDetailResponse[]
  startAt: string
  totalConversations: number
  totalDislikes: number
  totalErrors: number
  totalFeedbacks: number
  totalInteractions: number
  totalLikes: number
  totalUnrecognizedIntent: number
  totalUserInteractions: number
}

export interface ResponsesEmbeddingModelResponse {
  description: string
  developer: string
  dimensionSize: number
  id: number
  modelID: string
  name: string
  provider: string
}

export interface ResponsesEvaluationAPIResponse {
  agentID: number
  averageScore: number
  completedQuestions: number
  endTime: string
  evaluationResponses: ResponsesEvaluationResponseAPIResponse[]
  id: number
  name: string
  organizationID: number
  startTime: string
  status: EvaluationEvaluationMappingStatus
  totalQuestions: number
  totalScore: number
  workflowId: number
  workflowVersion: string
}

export interface ResponsesEvaluationMetricAPIResponse {
  description: string
  evaluationType: string
  id: number
  name: string
  settings: EvaluationModelsEvaluationSettings
}

export interface ResponsesEvaluationMetricScoreResponse {
  error: string
  evaluationDetails: string
  metricDetails: ResponsesEvaluationMetricAPIResponse
  metricScoreID: number
  reason: string
  score: number
  weight: number
}

export interface ResponsesEvaluationResponseAPIResponse {
  agentAnswer: string
  completedMetrics: number
  evaluationId: number
  evaluationMetricScores: ResponsesEvaluationMetricScoreResponse[]
  evaluationQuestion: ResponsesQuestionResponse
  evaluationQuestionId: number
  evaluationStatus: EvaluationEvaluationResponsesStatus
  finalScore: number
  id: number
  sessionUUID: string
  totalMetrics: number
  workflowPath: string
}

export interface ResponsesFeedbackDetailResponse {
  agentID: number
  createdAt: string
  messageID: string
  sessionUUID: string
}

export interface ResponsesFeedbackWithCommentResponse {
  agentID: number
  createdAt: string
  feedback: string
  messageID: string
  sessionUUID: string
}

export interface ResponsesIntentEntityInfoResponse {
  nameEntity: string
  nodeID: string
  nodesName: string
}

export interface ResponsesIntentListResponse {
  intents: ResponsesIntentResponse[]
  totalCount: number
}

export interface ResponsesIntentResponse {
  description: string
  examples: ModelsIntentExample[]
  id: number
  intent: string
  organizationID: number
  parameters: ModelsIntentParameter[]
}

export interface ResponsesIntentStatResponse {
  count: number
  intentID: string
  intentLabel: string
  intentName: string
}

export interface ResponsesJobAPIResponse {
  completedAt: string
  error: ModelsJobError
  id: string
  progress: number
  result: any
  status: JobStatusJobStatus
}

export interface ResponsesKnowledgeDocumentChunkListResponse {
  chunks: ResponsesKnowledgeDocumentChunkResponse[]
  totalCount: number
}

export interface ResponsesKnowledgeDocumentChunkResponse {
  _id: string
  content: string
  documentID: number
  enabled: boolean
  fileID: number
  knowledgeID: number
  metadata: Record<string, any>
}

export interface ResponsesKnowledgeDocumentResponse {
  chunkLength: number
  chunkOverlap: number
  createdAt: string
  documentName: string
  embeddingModelID: number
  file: GenieCoreAppHandlersKnowledgeResponsesFileResponse
  fileID: number
  hitCount: number
  id: number
  knowledgeID: number
  organizationID: number
  replaceConsecutiveSpaces: boolean
  replaceEmail: boolean
  replaceNewlines: boolean
  replaceTabs: boolean
  replaceURL: boolean
  segmentIdentifier: string
  source: DocumentSourceDocumentSource
  status: DocumentMappingStatusDocumentMappingStatus
  updatedAt: string
}

export interface ResponsesKnowledgeListResponse {
  knowledges: ResponsesKnowledgeResponse[]
  pagination: RepositoryPaginationResponse
  totalCount: number
}

export interface ResponsesKnowledgeMappingListResponse {
  documents: ResponsesKnowledgeDocumentResponse[]
  totalCount: number
}

export interface ResponsesKnowledgeResponse {
  createdAt: string
  description: string
  documentCount: number
  documentSize: number
  hitCount: number
  id: number
  knowledgeName: string
  updatedAt: string
}

export interface ResponsesLLMResponse {
  contextWindow: number
  description: string
  developer: string
  id: number
  modelDomain: string
  modelID: string
  name: string
  provider: string
}

export interface ResponsesListEvaluationTypesResponse {
  types: string[]
}

export interface ResponsesLockResponse {
  exist: boolean
  key: string
  value: any
}

export interface ResponsesLoginResponse {
  avatarID: number
  avatarUUID: string
  createdAt?: string
  email: string
  expireDate: string
  id?: number
  languageCode: string
  name: string
  organizationID: number
  organizationName: string
  organizationType: OrganizationTypeOrganizationType
  permissions: string
  roleID: number
  roleName: string
  status?: UserStatusUserStatus
  timezone: number
  updatedAt?: string
}

export interface ResponsesMetricsResponse {
  createdAt: string
  description: string
  evaluationType: EvaluationEvaluationType
  id: number
  name: string
  settings: EvaluationModelsEvaluationSettings
  updatedAt: string
}

export interface ResponsesNodeInfoResponse {
  config: any
  durationInMicroSeconds: number
  endTime: string
  error: string
  input: any
  logs: ModelsNodeLogInfo[]
  nodeId: string
  nodeName: string
  nodeType: string
  node_output_params: Record<string, ModelsNodeValue>
  output: any
  startTime: string
  status: NodeStatusNodeStatus
}

export interface ResponsesOrganizationListResponse {
  organizations: ResponsesOrganizationResponse[]
  totalCount: number
}

export interface ResponsesOrganizationResponse {
  createdAt: string
  expireDate: string
  id: number
  languageCode: string
  name: string
  organizationType: OrganizationTypeOrganizationType
  required2FA: boolean
  status: OrganizationStatusOrganizationStatus
  timezone: number
  updatedAt: string
}

export interface ResponsesPaginatedChatConversationResponse {
  data: GenieCoreAppHandlersChatResponsesChatConversationResponse[]
  pagination: RepositoryPaginationResponse
}

export interface ResponsesPaginatedChatSessionResponse {
  data: GenieCoreAppHandlersChatResponsesChatSessionResponse[]
  pagination: RepositoryPaginationResponse
}

export interface ResponsesPaginatedEvaluationAPIResponse {
  data: ResponsesEvaluationAPIResponse[]
  pagination: RepositoryPaginationResponse
}

export interface ResponsesPaginatedEvaluationMetricResponse {
  data: ResponsesMetricsResponse[]
  pagination: RepositoryPaginationResponse
}

export interface ResponsesPaginatedFileResponse {
  files: GenieCoreAppHandlersFileResponsesFileResponse[]
  totalRecords: number
}

export interface ResponsesPaginatedPluginCategoryResponse {
  data: ResponsesPluginCategoryResponse[]
  pagination: RepositoryPaginationResponse
}

export interface ResponsesPaginatedPluginResponse {
  data: ResponsesPluginResponse[]
  pagination: RepositoryPaginationResponse
}

export interface ResponsesPaginatedQuestionResponse {
  data: ResponsesQuestionResponse[]
  pagination: RepositoryPaginationResponse
}

export interface ResponsesPaginatedTemplateResponse {
  data: ResponsesTemplateResponse[]
  pagination: RepositoryPaginationResponse
}

export interface ResponsesPaginatedWorkflowResponse {
  page: number
  pageSize: number
  totalCount: number
  workflows: ResponsesWorkflowResponse[]
}

export interface ResponsesPermissionResponse {
  created_at: string
  id: number
  identity: string
  name: string
  organization_type: OrganizationTypeOrganizationType
  parent_id: number
  subs?: ResponsesPermissionResponse[]
  updated_at: string
}

export interface ResponsesPermissionTreeResponse {
  permissions: ResponsesPermissionResponse[]
}

export interface ResponsesPluginCategoryResponse {
  description: string
  icon: GenieCoreAppHandlersFileResponsesFileResponse
  iconID: number
  id: number
  name: string
  organizationID: number
  userID: number
  uuid: string
}

export interface ResponsesPluginFormResponse {
  configurationForm: PluginModelsPluginFormItem
  /** WorkflowFormJson      plugin_models.PluginFormItem   `json:"workflowForm"` */
  createdAt: string
  id: number
  outputForm: PluginModelsPluginOutputForm
  status: PluginPluginFormStatus
  updatedAt: string
  version: string
  workflowForm: PluginModelsPluginFormItem
}

export interface ResponsesPluginResponse {
  category: ResponsesPluginCategoryResponse
  categoryID: number
  createdAt: string
  description: string
  endpoint: string
  icon: GenieCoreAppHandlersFileResponsesFileResponse
  iconID: number
  id: number
  name: string
  organizationID: number
  type: PluginPluginType
  updatedAt: string
  userID: number
  uuid: string
}

export interface ResponsesPluginWithFormResponse {
  category: ResponsesPluginCategoryResponse
  categoryID: number
  createdAt: string
  description: string
  endpoint: string
  icon: GenieCoreAppHandlersFileResponsesFileResponse
  iconID: number
  id: number
  latestPluginFormID: number
  name: string
  organizationID: number
  type: PluginPluginType
  updatedAt: string
  userID: number
  uuid: string
}

export interface ResponsesPublishChannelListResponse {
  publishChannels: GenieCoreAppHandlersAgentResponsesPublishChannelResponse[]
  totalCount: number
}

export interface ResponsesQuestionResponse {
  agentID: number
  createdAt: string
  expectedAnswer: string
  id: number
  organizationID: number
  questionAnswers: EvaluationModelsLLMMessage[]
  testCase: string
  updatedAt: string
}

export interface ResponsesRerankModelResponse {
  description: string
  developer: string
  id: number
  modelID: string
  name: string
}

export interface ResponsesRoleListResponse {
  roles: ResponsesRoleResponse[]
  total_count: number
}

export interface ResponsesRoleResponse {
  created_at: string
  id: number
  name: string
  organization_id: number
  owner: boolean
  permissions: string
  updated_at: string
}

export interface ResponsesSettingResponse {
  allowed_document_extension: string[]
  allowed_document_size: number
  allowed_image_extension: string[]
  allowed_image_size: number
}

export interface ResponsesSmartAPIResponse {
  agentId: number
  bodyPayload: ModelsOutputField[]
  bodyPayloadType: BodyPayloadTypeBodyPayloadType
  createdAt: string
  deletedAt: string
  description: string
  id: number
  method: HttpMethodSmartAPIURLMethod
  name: string
  organizationId: number
  pathParams: string
  queryParams: ModelsOutputField[]
  requestHeaders: string
  resourceName: string
  responsePayload: ModelsOutputField[]
  status: StatusSmartAPIStatus
  updatedAt: string
  url: string
  version: string
}

export interface ResponsesTemplateResponse {
  content: string
  createdAt: string
  description: string
  id: number
  languageCode: string
  languageID: number
  organizationID: number
  templateCode: string
  templateType: string
  title: string
  updatedAt: string
}

export interface ResponsesUpdateAvatarResponse {
  avatarID: number
  avatarUUID: string
}

export interface ResponsesUpdateEmailResponse {
  email: string
  userID: number
}

export interface ResponsesUpdateTimezoneResponse {
  timezone: number
  userID: number
}

export interface ResponsesUpdateUserRoleResponse {
  roleID: number
  userID: number
}

export interface ResponsesUploadDocumentResponse {
  data: ResponsesQuestionResponse[]
  message: string
  success: boolean
}

export interface ResponsesUserListResponse {
  totalCount: number
  users: ResponsesUserResponse[]
}

export interface ResponsesUserResponse {
  createdAt?: string
  email?: string
  hasActive2FA?: boolean
  id?: number
  languageCode?: string
  name?: string
  organizationID?: number
  roleID?: number
  status?: UserStatusUserStatus
  timezone?: number
  updatedAt?: string
}

export interface ResponsesVersionResponse {
  version: number
}

export interface ResponsesWorkflowResponse {
  agent: ResponsesAgentResponse
  agentID: number
  createdAt: string
  edges: string
  id: number
  nodes: string
  organizationID: number
  status: WorkflowStatusWorkflowStatus
  updatedAt: string
  version: string
}

export interface ServicesRouteInfo {
  method: HttpMethodSmartAPIURLMethod
  url: string
}

export enum StatusSmartAPIStatus {
  Inactive = 'inactive',
  Beta = 'beta',
  Active = 'active',
  Maintainence = 'maintainence',
  Deprecated = 'deprecated',
  Delisted = 'delisted',
}

export enum UserStatusUserStatus {
  Active = 'active',
  Inactive = 'inactive',
  PendingChangePassword = 'pending_change_password',
  PendingSetup2FA = 'pending_setup_2fa',
}

export interface UtilsErrorCode {
  code: number
  detail: string
  message: string
  name: string
}

export enum WorkflowRunningStatusWorkflowRunningStatus {
  Processing = 'processing',
  Completed = 'completed',
  Failed = 'failed',
}

export enum WorkflowStatusWorkflowStatus {
  Draft = 'draft',
  Published = 'published',
  Deprecated = 'deprecated',
}
