import React, { useState, useEffect } from 'react'
import {
  Select,
  Input,
  TreeSelect,
  Button,
  Space,
  DatePicker,
  Flex,
} from 'antd'
import { MinusCircleOutlined, FunctionOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { Condition, ConditionBranch, NodeData, OutputField } from '../../model'
import WorkflowStore from 'stores/workflow'
import { isEmpty } from 'utils/common'
import { useWorkflowFunctions } from '../../hooks'
import styles from './condition-row.scss'

const { Option } = Select
const { TreeNode } = TreeSelect

interface ConditionRowProps {
  condition: Condition
  branch: ConditionBranch
  branchIndex: number
  conditionIndex: number
  handleConditionChange: (
    branchIndex: number,
    conditionIndex: number,
    name: string,
    value: string
  ) => void
  removeCondition: (branchIndex: number, conditionIndex: number) => void
}

const conditionOptions: { [key: string]: string[] } = {
  String: [
    '==',
    '!=',
    'contain',
    'not_contain',
    'is_empty',
    'is_not_empty',
    'length==',
    'length!=',
    'length>',
    'length>=',
    'length<',
    'length<=',
  ],
  Integer: ['==', '!=', '>', '>=', '<', '<=', 'is_empty', 'is_not_empty'],
  Boolean: ['==', '!=', 'is_empty', 'is_not_empty'],
  Number: ['==', '!=', '>', '>=', '<', '<=', 'is_empty', 'is_not_empty'],
  Date: ['==', '!=', '>', '>=', '<', '<=', 'is_empty', 'is_not_empty'],
  Datetime: ['==', '!=', '>', '>=', '<', '<=', 'is_empty', 'is_not_empty'],
  Object: [
    '==',
    '!=',
    'is_empty',
    'is_not_empty',
    'length==',
    'length!=',
    'length>',
    'length>=',
    'length<',
    'length<=',
  ],
  'Array<String>': [
    'contain',
    'not_contain',
    'is_empty',
    'is_not_empty',
    'length==',
    'length!=',
    'length>',
    'length>=',
    'length<',
    'length<=',
  ],
  'Array<Integer>': [
    'contain',
    'not_contain',
    'is_empty',
    'is_not_empty',
    'length==',
    'length!=',
    'length>',
    'length>=',
    'length<',
    'length<=',
  ],
  'Array<Boolean>': [
    'contain',
    'not_contain',
    'is_empty',
    'is_not_empty',
    'length==',
    'length!=',
    'length>',
    'length>=',
    'length<',
    'length<=',
  ],
  'Array<Number>': [
    'contain',
    'not_contain',
    'is_empty',
    'is_not_empty',
    'length==',
    'length!=',
    'length>',
    'length>=',
    'length<',
    'length<=',
  ],
  'Array<Date>': [
    'contain',
    'not_contain',
    'is_empty',
    'is_not_empty',
    'length==',
    'length!=',
    'length>',
    'length>=',
    'length<',
    'length<=',
  ],
  'Array<Datetime>': [
    'contain',
    'not_contain',
    'is_empty',
    'is_not_empty',
    'length==',
    'length!=',
    'length>',
    'length>=',
    'length<',
    'length<=',
  ],
  'Array<Object>': [
    'contain',
    'not_contain',
    'is_empty',
    'is_not_empty',
    'length==',
    'length!=',
    'length>',
    'length>=',
    'length<',
    'length<=',
  ],
}

const ConditionRow: React.FC<ConditionRowProps> = ({
  condition,
  branch,
  branchIndex,
  conditionIndex,
  handleConditionChange,
  removeCondition,
}) => {
  const [fieldType, setFieldType] = useState<string | undefined>(undefined)
  const { getParentNodes, getNodeOutput, getOutputField, groupNodes } =
    useWorkflowFunctions()
  const node = WorkflowStore.selectedNode

  useEffect(() => {
    handleVariableChange(condition.variable)
  }, [condition.variable])

  const renderTreeNodes = () => {
    if (!node) {
      return <></>
    }
    const parentNodes = getParentNodes(node.id, [], true)
    return groupNodes(parentNodes).map((data) => {
      const nodeData = data.node.data?.data as NodeData
      const title = nodeData?.label ?? 'Node'
      const renderBranchesOutput = (
        branches: (typeof data)['branches'],
        ignoreEmpty: boolean = false
      ) => {
        return branches.map((branch) => {
          let branchName: string | null = null
          let branchOutput: OutputField[] = []

          // If branch has no output, then it is a normal node, it shouldn't
          // have a branch name, and the output should be the node's output.
          if (branch.output) {
            branchName = branch.name
            branchOutput = branch.output
          } else {
            branchName = null
            branchOutput = nodeData.output
          }

          const value = `${data.node.id},${branchName}`
          const renderOutput = (data: OutputField[]) => {
            return data.map((output) => (
              <TreeNode
                key={`${value},${output.name}`}
                value={`${value},${output.name}`}
                title={
                  <Flex gap={4}>
                    <FunctionOutlined />
                    {output.name}
                    <span>[{output.type}]</span>
                  </Flex>
                }
              />
            ))
          }

          return branchName ? (
            <TreeNode
              key={value}
              value={value}
              title={branchName}
              selectable={false}
            >
              {renderOutput(branchOutput)}
            </TreeNode>
          ) : (
            renderOutput(branchOutput)
          )
        })
      }
      return (
        <TreeNode
          key={data.node.id}
          value={data.node.id}
          title={title}
          selectable={false}
        >
          {renderBranchesOutput(data.branches)}
        </TreeNode>
      )
    })
  }

  const handleVariableChange = (value: string) => {
    const [nodeId, branchName, outputName] = value.split(',') || []
    if (isEmpty(nodeId)) {
      return
    }
    handleConditionChange(branchIndex, conditionIndex, 'variable', value)
    const nodes = getParentNodes(node?.id || '', ['Condition'])
    const output = getOutputField(nodes, outputName)
    const selectedFieldType = output?.type || 'String'
    setFieldType(selectedFieldType)
  }

  const handleTreeNodeSelect = (value: string) => {
    handleVariableChange(value)
  }

  const renderComparisonInput = () => {
    if (
      condition.condition === 'is_empty' ||
      condition.condition === 'is_not_empty'
    ) {
      return (
        <Input
          placeholder="Value"
          style={{ width: '50%' }}
          disabled={
            condition.condition === 'is_empty' ||
            condition.condition === 'is_not_empty'
          }
        />
      )
    }

    switch (condition.type) {
      case 'input':
        if (condition.condition.startsWith('length')) {
          return (
            <Input
              type="number"
              placeholder="Value"
              value={condition.value}
              onChange={(e) =>
                handleConditionChange(
                  branchIndex,
                  conditionIndex,
                  'value',
                  e.target.value
                )
              }
              style={{ width: '50%' }}
              disabled={
                condition.condition === 'is_empty' ||
                condition.condition === 'is_not_empty'
              }
            />
          )
        } else if (fieldType === 'Boolean') {
          return (
            <Select
              placeholder="Value"
              value={condition.value}
              onChange={(value) =>
                handleConditionChange(
                  branchIndex,
                  conditionIndex,
                  'value',
                  value
                )
              }
              style={{ width: '50%' }}
            >
              <Option value="TRUE">TRUE</Option>
              <Option value="FALSE">FALSE</Option>
            </Select>
          )
        } else if (fieldType === 'Date' || fieldType === 'Datetime') {
          return (
            <DatePicker
              placeholder="Select Date"
              value={condition.value ? dayjs(condition.value) : null}
              onChange={(date) =>
                handleConditionChange(
                  branchIndex,
                  conditionIndex,
                  'value',
                  date ? date.toISOString() : ''
                )
              }
              style={{ width: '50%' }}
              showTime={fieldType === 'Datetime'}
            />
          )
        } else {
          return (
            <Input
              placeholder="Value"
              value={condition.value}
              onChange={(e) =>
                handleConditionChange(
                  branchIndex,
                  conditionIndex,
                  'value',
                  e.target.value
                )
              }
              style={{ width: '50%' }}
              disabled={
                condition.condition === 'is_empty' ||
                condition.condition === 'is_not_empty'
              }
            />
          )
        }
      case 'reference':
        return (
          <TreeSelect
            value={condition.reference || ''}
            onChange={(value) =>
              handleConditionChange(
                branchIndex,
                conditionIndex,
                'reference',
                value
              )
            }
            style={{ width: '50%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            popupMatchSelectWidth={false}
            className="nodrag nopan"
          >
            {renderTreeNodes()}
          </TreeSelect>
        )
      default:
        return null
    }
  }

  return (
    <>
      <Flex>
        <span className={styles.rowTitle}>Variable</span>
        <span className={styles.rowInput}>
          <TreeSelect
            value={condition.variable}
            onChange={handleTreeNodeSelect}
            style={{ marginRight: '5px', width: '100%' }}
            placeholder="Variable"
            className="nodrag nopan"
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            popupMatchSelectWidth={false}
          >
            {renderTreeNodes()}
          </TreeSelect>
        </span>
      </Flex>
      <Flex>
        <span className={styles.rowTitle}>Condition</span>
        <span className={styles.rowInput}>
          <Select
            value={condition.condition}
            onChange={(value) =>
              handleConditionChange(
                branchIndex,
                conditionIndex,
                'condition',
                value
              )
            }
            style={{ width: '100%' }}
            placeholder="Condition"
            className="nodrag nopan"
          >
            {fieldType &&
              conditionOptions[fieldType].map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
          </Select>
        </span>
      </Flex>
      <Flex>
        <span className={styles.rowTitle}>Comparison</span>
        <span className={styles.rowInput}>
          <Space.Compact style={{ width: '100%' }}>
            <Select
              value={condition.type}
              onChange={(value) =>
                handleConditionChange(
                  branchIndex,
                  conditionIndex,
                  'type',
                  value
                )
              }
              style={{ width: '50%' }}
              className="nodrag nopan"
              disabled={
                condition.condition === 'is_empty' ||
                condition.condition === 'is_not_empty'
              }
            >
              <Option value="reference">Reference</Option>
              <Option value="input">Input</Option>
            </Select>
            {renderComparisonInput()}
          </Space.Compact>
        </span>
      </Flex>
      <Flex>
        <span className={styles.rowTitle}></span>
        <span className={styles.rowInput}>
          <Button
            onClick={() => removeCondition(branchIndex, conditionIndex)}
            icon={<MinusCircleOutlined />}
            disabled={branch.conditions.length === 1}
          ></Button>
        </span>
      </Flex>
    </>
  )
}

export default ConditionRow
