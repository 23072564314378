import { ConfigSectionType } from 'components/new-dynamic-form/types'
import { CommonConfig } from '../constants'

const ItemReferenceSelectConfig: ConfigSectionType[] = [
  ...CommonConfig,
  {
    sectionLabel: 'referenceSelectConfig',
    items: [
      { itemLabel: 'Disabled', itemName: 'disabled', type: 'switch' },
      {
        itemLabel: 'Reference Options',
        itemName: 'options',
        type: 'compactInput',
        defaultValue: [
          [
            { label: 'Label', value: '' },
            { label: 'Value', value: '' },
          ],
        ],
        minCount: 1,
      },
    ],
  },
]

export default ItemReferenceSelectConfig
