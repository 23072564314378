import { memo } from 'react'
import cls from 'classnames'
import { Pagination, PaginationProps } from 'antd'
import ArrowSvg from 'assets/images/arrow.svg'

import styles from './index.scss'

type CustomPaginationProps = PaginationProps

const itemRender: PaginationProps['itemRender'] = (
  _,
  type,
  originalElement
) => {
  if (type === 'prev') {
    return (
      <ArrowSvg
        className={cls({
          [styles.customPaginationArrow]: true,
          [styles.customPaginationArrowLeft]: true,
        })}
      />
    )
  }
  if (type === 'next') {
    return (
      <ArrowSvg className={cls({ [styles.customPaginationArrow]: true })} />
    )
  }
  return originalElement
}

const CustomPagination = (props: CustomPaginationProps) => {
  return (
    <Pagination
      showSizeChanger={true}
      showQuickJumper={true}
      {...props}
      className={cls(styles.customPaginationWrapper, props.className)}
      itemRender={itemRender}
    />
  )
}

export default memo(CustomPagination)
