import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import dayjs from 'dayjs'
import { Button, Popconfirm, Space, TableProps, Tag } from 'antd'

import { v1UserDelete, v1UserListList } from 'api/Api'
import EditSvg from 'assets/images/edit.svg'
import DeleteSvg from 'assets/images/delete.svg'
import CustomTable from 'components/custom-table'
import MembersModal from './modal'
import { capitalizeFirstLetter } from 'utils/format'
import roleStore from 'stores/role'
import { MembersModalProps } from './modal'
import { UserStatusUserStatus } from 'api/data-contracts'
import { useIsAdmin } from 'hooks/use-is-admin'

import styles from './index.scss'

export type MembersDataType = {
  key: string
  name: string
  email: string
  role: number
  twoFaStatus: boolean
  status: UserStatusUserStatus
  createdAt: string
  updatedAt: string
}

export type MembersHandles = {
  setModalVisible: (open: boolean) => void
  setModalType: (type: MembersModalProps['type']) => void
}

const Members = forwardRef((props, ref) => {
  const [data, setData] = useState<MembersDataType[]>([])
  const [totalCount, setTotalCount] = useState<number>()
  const [modalType, setModalType] = useState<MembersModalProps['type']>('edit')
  const [modalVisible, setModalVisible] = useState(false)
  const [curMember, setCurMember] = useState<MembersDataType>()
  const [curPage, setCurPage] = useState(1)
  const [curPageSize, setCurPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const isAdmin = useIsAdmin()
  const columns: TableProps<MembersDataType>['columns'] = useMemo(
    () => [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      {
        title: 'Role',
        dataIndex: 'role',
        minWidth: 120,
        key: 'role',
        render: (val) => roleStore.getRoleById(val)?.name,
      },
      {
        key: 'twoFaStatus',
        title: '2FA Status',
        dataIndex: 'twoFaStatus',
        minWidth: 120,
        render: (v: boolean, record: MembersDataType) =>
          v ? (
            <Tag className={styles.tagActive}>Enabled</Tag>
          ) : (
            <Tag className={styles.tagInactive}>Disabled</Tag>
          ),
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        render: (val) =>
          val === 'active' ? (
            <Tag className={styles.tagActive}>Active</Tag>
          ) : (
            <Tag className={styles.tagInactive}>
              {val.split('_').map(capitalizeFirstLetter).join(' ')}
            </Tag>
          ),
      },
      {
        key: 'createdAt',
        title: 'Created At',
        minWidth: 120,
        dataIndex: 'createdAt',
        render: (val) => dayjs(val).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        key: 'updatedAt',
        title: 'Updated At',
        minWidth: 120,
        dataIndex: 'updatedAt',
        render: (val) => dayjs(val).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: 'Actions',
        width: 100,
        render: (_: any, record: MembersDataType) => {
          const { key } = record
          return (
            <Space>
              <Button
                type="text"
                icon={<EditSvg className={styles.tableIcon} />}
                onClick={() => handleActionEdit(key)}
              />
              <Popconfirm
                title="Are you sure to delete the member?"
                onConfirm={() => handleActionDelete(key)}
              >
                <Button
                  type="text"
                  icon={<DeleteSvg className={styles.tableIcon} />}
                  disabled={!isAdmin}
                  danger
                />
              </Popconfirm>
            </Space>
          )
        },
      },
    ],
    [data]
  )

  const handleActionEdit = (id: string) => {
    setModalType('edit')
    const member = data.find((o) => `${o.key}` === id)

    if (!member) return
    setCurMember(member)
    setModalVisible(true)
  }

  const handleActionDelete = async (id: string) => {
    const deleteResp = await v1UserDelete(Number(id))
    if (!deleteResp) return
    return handleUpdateData()
  }

  const handleUpdateData = (page = curPage, pageSize = curPageSize) => {
    fetchData(page, pageSize)
    if (page) setCurPage(page)
    if (pageSize) setCurPageSize(pageSize)
  }

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true)
    const userResp = await v1UserListList({
      page,
      page_size: pageSize,
    }).finally(() => setLoading(false))
    if (!userResp.data) return

    const tableData = userResp.data.users.map<MembersDataType>((u) => {
      return {
        key: `${u.id}`,
        name: u.name ?? '',
        email: u.email ?? '',
        role: u.roleID ?? 0,
        twoFaStatus:
          u.hasActive2FA || u.status === UserStatusUserStatus.PendingSetup2FA,
        status: u.status ?? UserStatusUserStatus.Inactive,
        createdAt: u.createdAt ?? '',
        updatedAt: u.updatedAt ?? '',
      }
    })
    setData(tableData)
    setTotalCount(userResp.data.totalCount)
  }

  useImperativeHandle(ref, () => ({
    setModalVisible,
    setModalType,
  }))

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <CustomTable<MembersDataType>
        wrapperClassName={styles.membersWrapper}
        columns={columns}
        data={data}
        loading={loading}
        paginationOnChange={handleUpdateData}
        paginationProps={{
          total: totalCount,
          align: 'center',
          current: curPage,
          pageSize: curPageSize,
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
          // page margin + title + title margin + tab content + tab margin + pagination content + pagination margin + table title
          y: 'calc(100vh - 2*24px - 38px - 24px - 46px - 16px - 32px - 16px - 55px)',
        }}
      />
      <MembersModal
        data={modalType === 'edit' ? curMember : undefined}
        type={modalType}
        open={modalVisible}
        onOpenChange={setModalVisible}
        updateData={handleUpdateData}
      />
    </>
  )
})

export default memo(Members)
