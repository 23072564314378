import { memo, useMemo } from 'react'
import { TimePicker, TimePickerProps } from 'antd'
import ComponentWrapper from '../lib/component-wrapper'
import { omitBy } from 'lodash-es'
import { getCommonDefaultValue } from '../constants'

type ItemTimePickerProps = {
  timePickerConfig?: TimePickerProps & {
    rangePicker: boolean
    [key: string]: any
  }
} & Record<string, any>

export const getDefaultValue = () => getCommonDefaultValue('timePicker')

const ItemTimePicker = (props: ItemTimePickerProps) => {
  const { timePickerConfig } = props

  const filterProps = useMemo(
    () => omitBy(props, (_, k) => k !== 'onChange' && /[A-Z]/.test(k)),
    [props]
  )

  return <TimePicker {...filterProps} {...timePickerConfig} />
}

export default ComponentWrapper(memo(ItemTimePicker))
