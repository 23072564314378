import { HeaderTabsProps } from 'layouts/portal/header'
import { useEffect } from 'react'
import globalStore from 'stores/global'

const useHeaderSubMenu = (menuItems: HeaderTabsProps) => {
  useEffect(() => {
    if (!menuItems) return

    globalStore.setHeaderMenuItems(menuItems)

    return () => {
      globalStore.setHeaderMenuItems([])
    }
  }, [menuItems])
}

export default useHeaderSubMenu
