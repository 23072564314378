import { useEffect, useState } from 'react'
import {
  App,
  Button,
  DatePicker,
  Dropdown,
  Flex,
  Modal,
  Progress,
  Space,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { PeriodItems, TimezoneItems } from '../section-card'
import {
  convertJsonBlobResponse,
  downloadFile,
  getMessageFromError,
  sleep,
} from 'utils/common'
import { currentTimezone, dayjsUTC, formatDate } from 'utils/filter'
import { DATETIME_FORMAT } from '..'
import {
  v1ChatConversationsStatisticsPeriodicDownloadJobCreate,
  v1ChatConversationsStatisticsPeriodicDownloadJobDetail,
} from 'api/Api'
import { ResponsesJobAPIResponse } from 'api/data-contracts'
import styles from './index.scss'

const { RangePicker } = DatePicker

export interface DownloadModalProps {
  agentId: number
  startAt: string
  endAt: string
  timezoneOffset?: number
  open: boolean
  onFinish: () => void
}

const DownloadModal: React.FC<DownloadModalProps> = (props) => {
  const { message } = App.useApp()
  const [loading, setLoading] = useState(false)
  const [startAt, setStartAt] = useState<string>('')
  const [endAt, setEndAt] = useState<string>('')
  const [periodItem, setPeriodItem] = useState(
    () => PeriodItems?.find((item) => item?.key === 'daily') || {}
  )
  const [timezoneItem, setTimezoneItem] = useState(() =>
    TimezoneItems?.find((item) => item?.key === currentTimezone())
  )
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setProgress(0)
  }, [props.open])

  useEffect(() => {
    setStartAt(props.startAt)
    setEndAt(props.endAt)
    setTimezoneItem(
      TimezoneItems?.find((item) => item?.key === props.timezoneOffset)
    )
  }, [props.startAt, props.endAt, props.timezoneOffset])

  const downloadReportFile = async () => {
    try {
      setLoading(true)
      const resp = await v1ChatConversationsStatisticsPeriodicDownloadJobCreate(
        {
          agent_id: props.agentId,
          start_at: formatDate(startAt, DATETIME_FORMAT),
          end_at: formatDate(endAt, DATETIME_FORMAT),
          timezone_offset: (timezoneItem as any).key ?? currentTimezone(),
          period: (periodItem as any).key ?? 'hourly',
          day_of_week: 1,
          day_of_month: 1,
          month_of_year: 1,
          report_extension: 'excel',
        }
      )
      const job = resp.data

      for (let i = 0; i < 60 * 60 * 24; i++) {
        await sleep(1000)
        const jobResp =
          await v1ChatConversationsStatisticsPeriodicDownloadJobDetail(job.id, {
            responseType: 'blob',
          })
        if (jobResp.status !== 200) {
          const data =
            await convertJsonBlobResponse<ResponsesJobAPIResponse>(jobResp)
          if (data.progress) {
            setProgress(data.progress)
          }
          continue
        }
        setProgress(100)
        await sleep(500)
        downloadFile(jobResp)
        break
      }
    } catch (e) {
      message.error(getMessageFromError(e))
    } finally {
      setLoading(false)
    }
  }

  const handleOk = async () => {
    setLoading(true)
    try {
      await downloadReportFile()
      props.onFinish()
    } catch (e) {
      message.error(getMessageFromError(e))
    }
    setLoading(false)
  }

  const handleCancel = () => {
    if (loading) {
      message.warning('Download is in progress, please wait...')
      return
    }
    props.onFinish()
  }

  return (
    <Modal
      width={'40%'}
      open={props.open}
      title={'Download Analytics'}
      okText={'Download'}
      okButtonProps={{ loading }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Flex className={styles.contentWrapper} gap={16} vertical>
        <span>
          You can download analytics data for the period of time selected.
        </span>
        <Flex className={styles.inputRow} gap={8}>
          <span>Date Range:</span>
          <RangePicker
            showTime
            value={[dayjsUTC(startAt), dayjsUTC(endAt)]}
            onChange={(_, dateStrings) => {
              setStartAt(dateStrings[0])
              setEndAt(dateStrings[1])
            }}
            disabled={loading}
          />
        </Flex>
        <Flex className={styles.inputRow} gap={8}>
          <span>Timezone:</span>
          <Dropdown
            overlayClassName={styles.timezoneDropdownWrapper}
            menu={{
              items: TimezoneItems,
              onClick: (e) => {
                const item = TimezoneItems?.find(
                  (item) => item?.key?.toString() === e.key
                )
                if (item) {
                  setTimezoneItem(item)
                }
              },
            }}
            disabled={loading}
          >
            <Button>
              <Space>
                {(timezoneItem as any)?.label}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Flex>
        <Flex className={styles.inputRow} gap={8}>
          <span>Granularity:</span>
          <Dropdown
            menu={{
              items: PeriodItems,
              onClick: (e) => {
                const item = PeriodItems?.find((item) => item?.key === e.key)
                if (item) {
                  setPeriodItem(item)
                }
              },
            }}
            disabled={loading}
          >
            <Button>
              <Space>
                {(periodItem as any)?.label}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Flex>
        {loading && (
          <>
            <div>
              Progress:{' '}
              <Progress
                percent={parseFloat(progress.toFixed(2))}
                percentPosition={{ align: 'center', type: 'inner' }}
                size={['100%', 18]}
                status="active"
              />
            </div>
          </>
        )}
      </Flex>
    </Modal>
  )
}

export default DownloadModal
