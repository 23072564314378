import { useContext } from 'react'
import { observer } from 'mobx-react'
import { Space, Input, List, Typography, App } from 'antd'
import { FunctionOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import Section from '../../components/section'
import {
  InputField as InputFieldType,
  DataType,
  OutputField,
  CustomNodeProps,
  NodeData,
} from '../../model'
import NodeSidebar from '../../components/node-sidebar'

import TemplateEditor from '../../components/template-editor'
import { FlowContext } from '../../context'

const { TextArea } = Input
const { Text } = Typography

const TemplateNodeSidebar = () => {
  const context = useContext(FlowContext)
  const selectedNode = WorkflowStore.selectedNode
  const nodeData = selectedNode?.data?.data

  const handleNodeDataChange = (
    field: string,
    value: any,
    dataType: DataType = 'String'
  ) => {
    if (!nodeData) return

    const currentNodeData = { ...nodeData }
    let updatedExtends = { ...currentNodeData.extends }
    let updatedInput = [...(currentNodeData.input || [])]
    let updatedOutput = [...(currentNodeData.output || [])]

    if (field === 'output') {
      updatedOutput = value
    } else if (field === 'label' || field === 'description') {
      // Directly update label or description
      currentNodeData[field] = value
    } else if (field === 'input') {
      updatedInput = value
    } else if (field === 'extends') {
      updatedExtends = value
    } else {
      // Handle other input changes
      const inputIndex = updatedInput.findIndex((input) => input.name === field)
      if (inputIndex !== -1) {
        updatedInput[inputIndex] = { ...updatedInput[inputIndex], value }
      } else {
        const newInputField: InputFieldType = {
          name: field,
          type: 'input',
          dataType: dataType,
          value: value,
          reference: '',
        }
        updatedInput.push(newInputField)
      }
    }

    const updatedNodeData = {
      ...(selectedNode.data as NodeData),
      data: {
        ...currentNodeData,
        extends: updatedExtends,
        input: updatedInput,
        output: updatedOutput,
      },
    }

    const updatedNode = {
      ...selectedNode,
      data: updatedNodeData,
    }

    WorkflowStore.selectNode(updatedNode as CustomNodeProps)
    selectedNode.data.onChange?.(selectedNode.id, updatedNodeData)
  }

  const handleClose = () => {
    WorkflowStore.selectNode(null)
  }

  if (selectedNode?.type !== 'Template') return null

  return (
    <NodeSidebar
      nodeType={'template'}
      onClose={handleClose}
      nodeData={nodeData}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={nodeData?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>

      <Section title="Template">
        <TemplateEditor
          value={nodeData?.extends?.['code'] ?? ''}
          onChange={(value) => handleNodeDataChange('code', value)}
          currentNodeID={WorkflowStore.selectedNode?.id || ''}
          nodes={context?.nodes ?? []}
          edges={context?.edges ?? []}
        />
      </Section>

      <Section title="Output" defaultCollapsed={true}>
        <List
          dataSource={nodeData?.output}
          renderItem={(item: OutputField) => (
            <List.Item>
              <Space>
                <FunctionOutlined />
                <Text>{item.name}</Text>
              </Space>
              <Text type="secondary">{item.type}</Text>
            </List.Item>
          )}
        />
      </Section>
    </NodeSidebar>
  )
}

export default observer(TemplateNodeSidebar)
