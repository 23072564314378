import { Checkbox, Flex } from 'antd'
import globalStore from 'stores/global'

const DeveloperSettings = () => {
  return (
    <div className="main-layout-main">
      <div className="navigation">
        <div className="title">
          <h1>Developer Settings</h1>
          <p>You can manage your developer settings here.</p>
        </div>
      </div>
      <Flex>
        <Checkbox
          defaultChecked={globalStore.globalSettings.isDeveloperMode}
          onChange={(e) => {
            globalStore.globalSettings.isDeveloperMode = e.target.checked
          }}
        >
          Enable developer mode to see extra features
        </Checkbox>
      </Flex>
    </div>
  )
}

export default DeveloperSettings
