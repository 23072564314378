import useModal from 'hooks/use-modal'
import { useState } from 'react'

export const useQuestionsModals = () => {
  const createQuestionModal = useModal()
  const importQuestionsModal = useModal()
  const [isEditingQuestion, setIsEditingQuestion] = useState(false)

  const openCreateQuestionModal = (isEditing = false) => {
    setIsEditingQuestion(isEditing)
    createQuestionModal.open()
  }

  return {
    createQuestionModalOpen: createQuestionModal.isOpen,
    importQuestionsModalOpen: importQuestionsModal.isOpen,
    isEditingQuestion,

    openCreateQuestionModal,
    closeCreateQuestionModal: createQuestionModal.close,
    openImportQuestionsModal: importQuestionsModal.open,
    closeImportQuestionsModal: importQuestionsModal.close,
  }
}

export default useQuestionsModals
