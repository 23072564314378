import { useCallback, useEffect, useRef, useState } from 'react'
import * as echarts from 'echarts'
import { PeriodType } from '../../section-card'
import { currentTimezone, formatDate } from 'utils/filter'
import { isEmpty, fetchWithCache } from 'utils/common'
import { v1ChatConversationsStatisticsPeriodicList } from 'api/Api'
import { ResponsesConversationStatsWithDetailResponse } from 'api/data-contracts'
import LoadingWrapper from '../../loading-wrapper'
import { DATETIME_FORMAT } from '../..'

export interface ConversationsChartProps {
  agentId: number
  startAt: string
  endAt: string
  timezoneOffset?: number
  period: PeriodType
}

const ConversationsChart: React.FC<ConversationsChartProps> = (props) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const [chart, setChart] = useState<echarts.ECharts>()
  const [resData, setResData] =
    useState<ResponsesConversationStatsWithDetailResponse[]>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      chart?.resize()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [chart])

  useEffect(() => {
    if (isEmpty(props.startAt) || isEmpty(props.endAt)) {
      return
    }

    const timezoneOffset = props.timezoneOffset ?? currentTimezone()

    setLoading(true)
    fetchWithCache<typeof v1ChatConversationsStatisticsPeriodicList>(
      v1ChatConversationsStatisticsPeriodicList,
      {
        agent_id: props.agentId,
        start_at: formatDate(props.startAt, DATETIME_FORMAT),
        end_at: formatDate(props.endAt, DATETIME_FORMAT),
        timezone_offset: timezoneOffset,
        period: props.period,
        day_of_week: 1,
        day_of_month: 1,
        month_of_year: 1,
      }
    )
      .then((res) => {
        setResData(res.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [
    props.agentId,
    props.startAt,
    props.endAt,
    props.timezoneOffset,
    props.period,
  ])

  useEffect(() => {
    const chart = echarts.init(chartRef.current as HTMLDivElement)
    setChart(chart)
  }, [chartRef])

  useEffect(() => {
    const option: echarts.EChartsOption = {
      tooltip: {
        trigger: 'axis',
        position: function (pt) {
          return [pt[0], '10%']
        },
      },
      color: ['#3278d9'],
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: resData?.map((item) => formatDate(item.startAt)) || [],
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: resData?.map((item) => item.totalConversations) || [],
          type: 'line',
          symbol: 'none',
          areaStyle: {},
        },
      ],
    }
    chart?.setOption(option)
  }, [chart, resData])

  return (
    <LoadingWrapper loading={loading}>
      <div ref={chartRef} style={{ width: '100%', height: '100%' }}></div>
    </LoadingWrapper>
  )
}

export default ConversationsChart
