import { v4 as uuidV4 } from 'uuid'
import { nanoid } from 'nanoid'
import {
  ConfigFormItemType,
  ConfigGeneralType,
  FormItemType,
  FormSelectorType,
} from '../types'
import { capitalizeFirstLetter } from 'utils/format'
import { merge } from 'lodash-es'

export const CommonConfig: [ConfigFormItemType, ConfigGeneralType] = [
  {
    sectionLabel: 'formItemConfig',
    items: [
      { itemLabel: 'name', itemName: 'name', type: 'input' },
      { itemLabel: 'label', itemName: 'label', type: 'input' },
      { itemLabel: 'required', itemName: 'required', type: 'switch' },
      {
        itemLabel: 'rules',
        itemName: 'rules',
        type: 'subObject',
        children: [
          {
            itemLabel: '',
            itemName: 'pattern',
            type: 'input',
            placeholder: 'Pattern',
            style: { width: '100%' },
          },
          {
            itemLabel: '',
            itemName: 'max',
            type: 'inputNumber',
            placeholder: 'Max Length',
            style: { width: '100%' },
          },
        ],
      },
    ],
  },
  {
    sectionLabel: 'generalConfig',
    items: [
      { itemLabel: 'Condition', itemName: 'condition', type: 'condition' },
    ],
  },
]

export const getCommonDefaultValue = (
  type: FormSelectorType,
  defaultValues?: Record<string, any>
): FormItemType => {
  const id = uuidV4()

  return merge(
    {
      path: [],
      formItemId: id,
      type: type,
      children: [],
      props: {
        formItemConfig: {
          name: `${type}-${nanoid(4)}`,
          label: capitalizeFirstLetter(type),
        },
      },
    },
    defaultValues
  )
}
