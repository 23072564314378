import React, { useRef } from 'react'
import {
  App,
  Button,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from 'antd'
import {
  EditOutlined,
  AlignLeftOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { ReactNode, useEffect, useState } from 'react'
import { deepCopy, getMessageFromError, isEmpty } from 'utils/common'
import { observer } from 'mobx-react'
import { v1EvaluationMetricsCreate, v1EvaluationMetricsUpdate } from 'api/Api'
import {
  EvaluationEvaluationType,
  EvaluationModelsEvaluationSettings,
} from 'api/data-contracts'
import { capitalizeFirstLetter } from 'utils/format'

const { TextArea } = Input

export const EvaluationTypes = Object.entries(EvaluationEvaluationType).reduce(
  (acc, [_, value]) => {
    acc[value] = value.split('_').map(capitalizeFirstLetter).join(' ')
    return acc
  },
  {} as Record<string, string>
)

export interface MetricModalProps {
  data: {
    id?: number
    name: string
    description: string
    evaluationType: EvaluationEvaluationType
    settings: EvaluationModelsEvaluationSettings
    createdAt?: string
    updatedAt?: string
  }
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (metricId?: number) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export interface EvaluationSettingsFormItemProps {
  name?: string
  value?: EvaluationModelsEvaluationSettings
  onChange?: (value: EvaluationModelsEvaluationSettings) => void
}

export const DEFAULT_EVALUATION_SETTINGS: EvaluationModelsEvaluationSettings = {
  evaluationParams: [],
  evaluationSteps: [],
  name: '',
  threshold: 0,
  windowSize: 0,
}

const EvaluationSettingsFormItem: React.FC<EvaluationSettingsFormItemProps> = (
  props
) => {
  const [settings, setSettings] = useState<EvaluationModelsEvaluationSettings>(
    deepCopy(DEFAULT_EVALUATION_SETTINGS)
  )

  useEffect(() => {
    setSettings(props.value ?? deepCopy(DEFAULT_EVALUATION_SETTINGS))
  }, [props.value])

  const renderInputItems = (
    label: string,
    key: 'evaluationParams' | 'evaluationSteps'
  ) => {
    return (
      <Flex gap={8} vertical>
        <span>Evaluation {label}s:</span>
        {settings[key]?.map((param, index) => (
          <Row gutter={[16, 16]}>
            <Col
              span={2}
              style={{ paddingTop: '4px', textTransform: 'capitalize' }}
            >
              {index + 1}
            </Col>
            <Col span={20}>
              <TextArea
                value={param}
                placeholder="Please input your value..."
                onChange={(e) => {
                  const newSettings = { ...settings }
                  newSettings[key][index] = e.target.value
                  setSettings(newSettings)
                  props.onChange?.(newSettings)
                }}
                required
              />
            </Col>
            <Col span={2}>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  const newSettings = { ...settings }
                  newSettings[key].splice(index, 1)
                  setSettings(newSettings)
                  props.onChange?.(newSettings)
                }}
              />
            </Col>
          </Row>
        ))}
        <Button
          onClick={() => {
            setSettings({
              ...settings,
              [key]: [...(settings[key] ?? []), ''],
            })
          }}
        >
          <PlusOutlined />
          Add {label}
        </Button>
      </Flex>
    )
  }

  return (
    <Flex gap={16} vertical>
      <Row gutter={[16, 16]}>
        <Col span={6}>Name:</Col>
        <Col span={18}>
          <Input
            value={settings.name}
            onChange={(e) => {
              const newSettings = { ...settings }
              newSettings.name = e.target.value
              setSettings(newSettings)
              props.onChange?.(newSettings)
            }}
            required
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>Threshold:</Col>
        <Col span={18}>
          <InputNumber
            value={settings.threshold}
            onChange={(value) => {
              const newSettings = { ...settings }
              newSettings.threshold = value ?? 0
              setSettings(newSettings)
              props.onChange?.(newSettings)
            }}
            min={0}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>Window Size:</Col>
        <Col span={18}>
          <InputNumber
            value={settings.windowSize}
            onChange={(value) => {
              const newSettings = { ...settings }
              newSettings.windowSize = value ?? 0
              setSettings(newSettings)
              props.onChange?.(newSettings)
            }}
            min={0}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      {renderInputItems('Parameter', 'evaluationParams')}
      {renderInputItems('Step', 'evaluationSteps')}
    </Flex>
  )
}

const MetricModal: React.FC<MetricModalProps> = (props) => {
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    if (props.open) {
      form.setFieldsValue({ ...props.data })
    }
  }, [props.open, form, props.data])

  const handleSave = async (values: MetricModalProps['data']) => {
    setConfirmLoading(true)
    try {
      if (props.data.id == undefined) {
        const response = await v1EvaluationMetricsCreate({
          name: values.name,
          description: values.description,
          evaluationType: values.evaluationType,
          settings: values.settings,
        })
        const data = response.data
        message.success('Metric is created successfully!')
        return data.id
      } else {
        await v1EvaluationMetricsUpdate({
          id: props.data.id,
          name: values.name,
          description: values.description,
          evaluationType: values.evaluationType,
          settings: values.settings,
        })
        message.success('Metric is updated successfully!')
      }
      return props.data.id
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async (values: MetricModalProps['data']) => {
    const metricId = await handleSave(values)
    if (metricId === undefined) {
      return
    }
    props.onFinish?.(metricId)
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    props.onCancel?.(e)
  }

  return (
    <Modal
      title={props.title ?? 'Metric Edit'}
      open={props.open}
      okText="Save"
      onOk={() => form.submit()}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      width={820}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        style={{ maxWidth: 820 }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input a name!' }]}
        >
          <Input prefix={<EditOutlined />} placeholder="Category" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: 'Please input your description!' },
          ]}
        >
          <Input prefix={<AlignLeftOutlined />} placeholder="Description" />
        </Form.Item>
        <Form.Item
          name="evaluationType"
          label="Evaluation Type"
          rules={[
            { required: true, message: 'Please select an evaluation type!' },
          ]}
        >
          <Select
            placeholder="Select an evaluation type"
            style={{ width: '100%' }}
            options={Object.keys(EvaluationTypes).map((key) => ({
              label: EvaluationTypes[key],
              value: key,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="settings"
          label="Settings"
          rules={[{ required: true, message: 'Please complete the settings!' }]}
        >
          <EvaluationSettingsFormItem />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default observer(MetricModal)
