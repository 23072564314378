import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import {
  App,
  Button,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  SelectProps,
  Space
} from 'antd'
import {
  v1EvaluationEvaluateAgentCreate,
  v1EvaluationMetricsListList,
  v1EvaluationQuestionsListList,
} from 'api/Api'
import React, { ReactNode, useEffect, useState } from 'react'
import { deepCopy, getMessageFromError } from 'utils/common'

import { RequestsEvaluationMetricWithWeightRequest } from 'api/data-contracts'
import styles from '../modal.scss'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

type EvaluationMetricWithWeight = Optional<
  RequestsEvaluationMetricWithWeightRequest,
  'evaluationMetricID'
>

export const DEFAULT_METRIC: EvaluationMetricWithWeight = {
  evaluationMetricID: undefined,
  weight: 1,
}

export interface ReportModalProps {
  data: {
    id?: number
    name: string
    evaluationMetrics: EvaluationMetricWithWeight[]
    questionIDs: number[]
  }
  agentId: number
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (evaluationId?: number) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export interface MetricsFormItemProps {
  name?: string
  options?: SelectProps['options']
  value?: EvaluationMetricWithWeight[]
  onChange?: (value: EvaluationMetricWithWeight[]) => void
}

const MetricsFormItem: React.FC<MetricsFormItemProps> = (props) => {
  const [metrics, setMetrics] = useState<EvaluationMetricWithWeight[]>([])
  useEffect(() => {
    setMetrics(props.value ?? [])
  }, [props.value])

  return (
    <Flex gap={8} vertical>
      {React.Children.toArray(metrics.map((metric, index) => (
        <Row gutter={[8, 8]}>
          <Col span={14}>
            <Select
              showSearch
              optionFilterProp="label"
              options={props.options}
              value={metric.evaluationMetricID}
              onChange={(value) => {
                const newMetrics = [...metrics]
                newMetrics[index].evaluationMetricID = value
                setMetrics(newMetrics)
                props.onChange?.(newMetrics)
              }}
              placeholder="Please select a metric"
            />
          </Col>
          <Col span={7}>
            <Space>
              Weight:
              <InputNumber
                value={metric.weight}
                onChange={(value) => {
                  const newMetrics = [...metrics]
                  newMetrics[index].weight = value ?? 0
                  setMetrics(newMetrics)
                  props.onChange?.(newMetrics)
                }}
                style={{ width: '100%' }}
                min={0}
              />
            </Space>
          </Col>
          <Col span={3}>
            <Button
              key={'delete_' + index}
              icon={<MinusOutlined />}
              style={{ width: '100%' }}
              onClick={() => {
                const newMetrics = [...metrics]
                newMetrics.splice(index, 1)
                setMetrics(newMetrics)
                props.onChange?.(newMetrics)
              }}
            />
          </Col>
        </Row>
      )))}
      <Button
        variant="dashed"
        color="default"
        className={styles.addButton}
        icon={<PlusOutlined style={{ fontSize: 14 }} />}
        onClick={() => {
          setMetrics([...metrics, deepCopy(DEFAULT_METRIC)])
        }}
      >
        Add
      </Button>
    </Flex>
  )
}

const ReportModal: React.FC<ReportModalProps> = (props) => {
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [metricOptions, setMetricOptions] = useState<SelectProps['options']>([])
  const [questionOptions, setQuestionOptions] = useState<
    SelectProps['options']
  >([])

  useEffect(() => {
    if (props.open) {
      form.setFieldsValue({ ...props.data })

      // Initial metrics
      {
        ; (async () => {
          const resp = await v1EvaluationMetricsListList({
            page: 1,
            limit: 99999,
          })
          setMetricOptions(
            resp.data.data.map((metric) => ({
              label: metric.name,
              value: metric.id,
            }))
          )
        })()
      }

      // Initial questions
      {
        ; (async () => {
          const resp = await v1EvaluationQuestionsListList({
            page: 1,
            limit: 99999,
            conditions: JSON.stringify([
              {
                field: 'agent_id',
                operator: '=',
                value: props.agentId,
              },
            ]),
          })
          setQuestionOptions(
            resp.data.data.map((question) => ({
              label: question.testCase,
              value: question.id,
            }))
          )
        })()
      }
    }
  }, [props.open, form, props.data])

  const handleSave = async (values: ReportModalProps['data']) => {
    setConfirmLoading(true)
    try {
      if (props.data.id == undefined) {
        const response = await v1EvaluationEvaluateAgentCreate({
          agentID: props.agentId,
          name: values.name,
          evaluationMetrics:
            values.evaluationMetrics as RequestsEvaluationMetricWithWeightRequest[],
          questionIDs: values.questionIDs,
        })
        const data = response.data
        message.success('Evaluation is created successfully!')
        return data.id
      }
      return props.data.id
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async (values: ReportModalProps['data']) => {
    const evaluationId = await handleSave(values)
    if (evaluationId === undefined) {
      return
    }
    props.onFinish?.(evaluationId)
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    props.onCancel?.(e)
  }

  return (
    <Modal
      title={props.title ?? 'Report Edit'}
      open={props.open}
      okText="Submit"
      okButtonProps={{ loading: confirmLoading }}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      className={styles.modal}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        style={{ maxWidth: 620 }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input the name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="evaluationMetrics"
          label="Metrics"
          rules={[{ required: true, message: 'Please select some metrics!' }]}
        >
          <MetricsFormItem options={metricOptions} />
        </Form.Item>
        <Form.Item
          name="questionIDs"
          label="Questions"
          rules={[
            { required: true, message: 'Please input select questions!' },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            mode="multiple"
            options={questionOptions}
            placeholder="Please select some questions"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ReportModal
