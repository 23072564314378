import { useState } from 'react'
import { Avatar, Button, ButtonProps, Flex, Radio } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import cls from 'classnames'
import styles from './index.scss'

export interface MenuItemProps {
  text: string
  icon?: React.ReactNode
  value: any
  default?: boolean
}

export interface ActionItemProps extends ButtonProps {
  text: string
  isPrimary?: boolean
}

export interface MainLayoutProps {
  title?: React.ReactNode
  caption?: React.ReactNode
  icon?: React.ReactNode
  onClickBack?: () => void
  onClickMenu?: (value: any) => void
  menu?: MenuItemProps[]
  actions?: ActionItemProps[]
  children?: React.ReactNode
  noBack?: boolean
  noHeaderBottomLine?: boolean
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const [menuValue, setMenuValue] = useState(
    props.menu?.find((item) => item.default)?.value
  )

  return (
    <Flex className={styles.mainLayoutWrapper} vertical>
      <Flex
        className={cls({
          [styles.mainLayoutWrapperHeader]: true,
          [styles.mainLayoutWrapperHeaderNoBack]: props.noBack,
          [styles.mainLayoutWrapperHeaderNoHeaderBottomLine]:
            props.noHeaderBottomLine,
        })}
        justify="space-between"
      >
        <Flex align="center" gap={8}>
          <Button
            type="text"
            icon={<LeftOutlined />}
            onClick={props.onClickBack}
            style={{ padding: 0 }}
          />
          <Avatar
            className={styles.mainLayoutWrapperHeaderAvatar}
            shape="square"
            size={32}
            icon={props.icon}
          />
          <Flex gap={2} vertical>
            <div className={styles.mainLayoutWrapperHeaderTitle}>
              {props.title}
            </div>
            <div className={styles.mainLayoutWrapperHeaderCaption}>
              {props.caption}
            </div>
          </Flex>
        </Flex>
        <Radio.Group
          className={styles.mainLayoutWrapperHeaderMenu}
          value={menuValue}
          onChange={(e) => {
            setMenuValue(e.target.value)
            props.onClickMenu?.(e.target.value)
          }}
          options={props.menu?.map((item) => ({
            value: item.value,
            label: (
              <Flex align="center" gap={8}>
                {item.icon}
                <span>{item.text}</span>
              </Flex>
            ),
          }))}
        />
        <Flex gap={8}>
          {props.actions?.map((item) => (
            <Button
              key={item.text}
              type={item.type ?? (item.isPrimary ? 'primary' : 'default')}
              icon={item.icon}
              onClick={item.onClick}
            >
              {item.text}
            </Button>
          ))}
        </Flex>
      </Flex>
      {props.children}
    </Flex>
  )
}

export default MainLayout
