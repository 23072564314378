import { memo, useMemo } from 'react'
import { Button, Upload, UploadProps } from 'antd'
import ComponentWrapper from '../lib/component-wrapper'
import { omitBy } from 'lodash-es'
import { getCommonDefaultValue } from '../constants'

type ItemUploadProps = {
  uploadConfig?: UploadProps & Record<string, any>
} & Record<string, any>

export const getDefaultValue = () => getCommonDefaultValue('upload')

const ItemUpload = (props: ItemUploadProps) => {
  const { uploadConfig } = props

  const filterProps = useMemo(
    () => omitBy(props, (_, k) => k !== 'onChange' && /[A-Z]/.test(k)),
    [props]
  )

  return (
    <Upload {...filterProps} {...uploadConfig}>
      <Button>{uploadConfig?.buttonText ?? 'Click to Upload'}</Button>
    </Upload>
  )
}

export default ComponentWrapper(memo(ItemUpload))
