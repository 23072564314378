import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useExNavigate } from 'hooks/use-ex-navigate'
import FolderSvg from 'assets/images/folder.svg'
import MainLayout from 'layouts/portal/main-layout'
import { KnowledgeCardProps } from '../knowledge-card'
import FileTable from './table'
import DocumentUpload from './upload'

const DocumentPage: React.FC = () => {
  const location = useLocation()
  const navigate = useExNavigate()
  const [knowledge, setKnowledge] = useState<KnowledgeCardProps['data']>()
  const [isUploadPageVisible, setIsUploadPageVisible] = useState(false)

  useEffect(() => {
    if (location.state.knowledge) {
      const data = location.state.knowledge as KnowledgeCardProps['data']
      setIsUploadPageVisible(location.state.is_upload_page_visible)
      setKnowledge(data)
    } else {
      navigate('/portal/knowledge')
    }
  }, [location.state.knowledge])

  return (
    <MainLayout
      title={knowledge?.name}
      icon={<FolderSvg />}
      onClickBack={() =>
        isUploadPageVisible
          ? setIsUploadPageVisible(false)
          : navigate('/portal/knowledge')
      }
      onClickMenu={(value) => {
        console.log(value)
      }}
      actions={
        isUploadPageVisible
          ? []
          : [
              {
                text: 'Add Document',
                isPrimary: true,
                onClick: () => setIsUploadPageVisible(true),
              },
            ]
      }
    >
      {isUploadPageVisible && knowledge ? (
        <DocumentUpload
          data={knowledge}
          onUploadSuccess={() => setIsUploadPageVisible(false)}
        />
      ) : (
        <FileTable />
      )}
    </MainLayout>
  )
}

export default DocumentPage
