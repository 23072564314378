import { Button, Form, Input } from 'antd'
import cls from 'classnames'
import { memo, useState } from 'react'
import { Conversation as MessageItemType } from 'stores/models/public-chat'

import styles from './index.scss'

type FeedbackType = {
  message?: MessageItemType
  feedbackRows?: number
  options: { label: string; value: any }[]
  wrapperClassName?: string
  open?: boolean
  placeHolder?: string
  onSubmit?: (values: { feedback: string; resultList: string[] }) => void
  onClose?: (submitted: boolean) => void
}

const { TextArea } = Input

const Feedback = (props: FeedbackType) => {
  const {
    message,
    feedbackRows,
    options,
    wrapperClassName,
    open = true,
    onClose,
    placeHolder,
    onSubmit,
  } = props
  const [form] = Form.useForm()
  const [resultList, setResultList] = useState<string[]>([])
  const [submitting, setSubmitting] = useState(false)

  const handleResultClick = (val: string) => {
    if (resultList.includes(val)) {
      setResultList([...resultList.filter((v) => v !== val)])
    } else {
      setResultList([...resultList, val])
    }
  }

  const handleSubmit = async () => {
    const description = form.getFieldValue('description')
    setSubmitting(true)
    onSubmit?.({ feedback: description, resultList })
    setSubmitting(false)
    onClose?.(true)
  }

  return (
    <>
      {open && (
        <Form
          className={cls(wrapperClassName, {
            [styles.feedbackWrapper]: true,
          })}
          form={form}
        >
          <div
            className={cls(
              'feedback-title-wrapper',
              styles.feedbackTitleWrapper
            )}
          >
            <span className={styles.feedbackTitle}>
              Why have you chosen this rating? (optional)
            </span>
            <i
              role="button"
              tabIndex={0}
              className={styles.feedbackIconClose}
              onClick={() => onClose?.(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault()
                  onClose?.(false)
                }
              }}
            />
          </div>

          <div
            className={cls(
              'feedback-results-wrapper',
              styles.feedbackResultsWrapper
            )}
          >
            {options.map((r) => {
              const { label, value } = r
              return (
                <Button
                  loading={submitting}
                  className={cls({
                    [styles.feedbackResultsBtn]: true,
                    [styles.feedbackResultsBtnSelected]:
                      resultList.includes(value),
                    'feedback-results-btn-selected': resultList.includes(value),
                  })}
                  onClick={() => handleResultClick(value)}
                  key={value}
                >
                  {label}
                </Button>
              )
            })}
          </div>

          <Form.Item
            className={cls(
              'feedback-results-description-wrapper',
              styles.feedbackResultsDescriptionWrapper
            )}
            name="description"
          >
            <TextArea
              placeholder={placeHolder}
              autoSize={{ minRows: feedbackRows, maxRows: 6 }}
              className={cls(
                'feedback-results-description',
                styles.feedbackResultsDescription
              )}
              rows={feedbackRows}
            />
          </Form.Item>

          <Form.Item
            className={cls(
              'feedback-submit-wrapper',
              styles.feedbackSubmitWrapper
            )}
          >
            <Button
              type="primary"
              className={cls('feedback-submit', styles.feedbackSubmit)}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  )
}

export default memo(Feedback)
