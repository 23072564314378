import React, { useState } from 'react'
import { Flex } from 'antd'
import MetricTable, { ActionType, MetricTableRef } from './metric-table'
import MetricModal, {
  DEFAULT_EVALUATION_SETTINGS,
  MetricModalProps,
} from './metric-modal'
import { deepCopy } from 'utils/common'
import { EvaluationEvaluationType } from 'api/data-contracts'
import MainLayout from 'layouts/portal/main-layout'
import styles from './index.scss'

const DEFAULT_METRIC_DATA: MetricModalProps['data'] = {
  name: '',
  description: '',
  evaluationType: EvaluationEvaluationType.EvalTypeGEval,
  settings: DEFAULT_EVALUATION_SETTINGS,
}

const QuestionPage: React.FC = () => {
  const [isMetricModalOpen, setIsMetricModalOpen] = useState(false)
  const [metric, setMetric] =
    useState<MetricModalProps['data']>(DEFAULT_METRIC_DATA)
  const metricTableRef = React.createRef<MetricTableRef>()

  const handleCreateButtonClick = () => {
    setMetric(deepCopy({ ...DEFAULT_METRIC_DATA }))
    setIsMetricModalOpen(true)
  }

  return (
    <MainLayout
      title="Metrics"
      actions={[
        {
          text: 'Create Metric',
          isPrimary: true,
          onClick: handleCreateButtonClick,
        },
      ]}
      noBack
      noHeaderBottomLine
    >
      <MetricTable
        ref={metricTableRef}
        onAction={(type, data) => {
          if (type === ActionType.Edit) {
            setMetric({
              id: data.id,
              name: data.name,
              description: data.description,
              evaluationType: data.evaluationType,
              settings: data.settings,
            })
            setIsMetricModalOpen(true)
          }
        }}
      />
      <MetricModal
        open={isMetricModalOpen}
        title={!!metric.id ? 'Edit Metric' : 'Create Metric'}
        okText="Save"
        data={{
          ...metric,
        }}
        onFinish={() => {
          metricTableRef.current?.refresh()
          setIsMetricModalOpen(false)
        }}
        onCancel={() => setIsMetricModalOpen(false)}
      />
    </MainLayout>
  )
}

export default QuestionPage
