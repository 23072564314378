import { memo, useMemo } from 'react'
import ComponentWrapper from '../lib/component-wrapper'
import { omitBy } from 'lodash-es'

import CodeEditor, {
  CodeEditorProps,
} from 'views/portal/agent/studio/workflow/components/code-editor'
import { getCommonDefaultValue } from '../constants'

type ItemCodeProps = {
  codeConfig?: CodeEditorProps & Record<string, any>
} & Record<string, any>

export const getDefaultValue = () => getCommonDefaultValue('code')

const ItemCode = (props: ItemCodeProps) => {
  const { codeConfig } = props

  const filterProps = useMemo(
    () => omitBy(props, (_, k) => k !== 'onChange' && /[A-Z]/.test(k)),
    [props]
  )

  return <CodeEditor {...filterProps} {...codeConfig} />
}

export default ComponentWrapper(memo(ItemCode))
