import { ConfigSectionType } from 'components/new-dynamic-form/types'
import { CommonConfig } from '../constants'

const ItemUploadConfig: ConfigSectionType[] = [
  ...CommonConfig,
  {
    sectionLabel: 'uploadConfig',
    items: [
      { itemLabel: 'ButtonText', itemName: 'buttonText', type: 'input' },
      { itemLabel: 'Name', itemName: 'name', type: 'input' },
      { itemLabel: 'Accept', itemName: 'accept', type: 'input' },
      { itemLabel: 'Action', itemName: 'action', type: 'input' },
      { itemLabel: 'Directory', itemName: 'directory', type: 'switch' },
      { itemLabel: 'Disabled', itemName: 'disabled', type: 'switch' },
      { itemLabel: 'MaxCount', itemName: 'maxCount', type: 'inputNumber' },
      {
        itemLabel: 'Method',
        itemName: 'method',
        type: 'select',
        options: [
          { label: 'GET', value: 'get' },
          { label: 'POST', value: 'post' },
        ],
      },
      { itemLabel: 'Multiple', itemName: 'multiple', type: 'switch' },
    ],
  },
]

export default ItemUploadConfig
