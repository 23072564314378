import React, {
  ComponentType,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import cls from 'classnames'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import { FormItemType } from 'components/new-dynamic-form/types'

import styles from './index.scss'
import { Button, Collapse, Form } from 'antd'
import { useFormContext } from 'components/new-dynamic-form/context'
import { get } from 'lodash-es'
import { isVisible } from '../condition'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { deepCopy } from 'utils/common'
import {
  getItemByPath,
  updateItemListByPath,
} from 'components/new-dynamic-form/util'

type ComponentWrapperState =
  | { type: 'idle' }
  | { type: 'preview'; container: HTMLElement; rect: DOMRect }
  | { type: 'dragging' }

const idleState: ComponentWrapperState = { type: 'idle' }
const draggingState: ComponentWrapperState = { type: 'dragging' }

export type WithDraggingProps<P> = P & {
  index?: number
}

const getFormItemNamePathList = (
  root: FormItemType,
  idPathList: string[],
  targetPath = ''
): string[] => {
  const namePathList: string[] = []

  if (idPathList.length === 1) {
    namePathList.push(root.formItemId)
    return namePathList
  } else {
    let curItem = root
    const restPath = idPathList.slice(1)
    for (const p of restPath) {
      const fItem = curItem.children.find((i) => i.formItemId === p)
      if (!fItem) return namePathList
      namePathList.push(get(fItem, targetPath))
      curItem = fItem
    }
  }

  return namePathList
}

function ComponentWrapper<P extends FormItemType>(Cmp: ComponentType<any>) {
  function WithDraggingWrapper(props: WithDraggingProps<P>) {
    const { formItemId, props: itemProps, type, path } = props
    const { formItemConfig, generalConfig, ...itemRestConfig } = itemProps
    const mainForm = useFormInstance()
    const { root, onItemClick, setRoot } = useFormContext() ?? {}
    const [closestEdge, setClosestEdge] = useState<Edge | null>(null)
    const [state, setState] = useState<ComponentWrapperState>(idleState)

    const itemVisible =
      generalConfig?.condition && !!generalConfig?.condition.length
        ? isVisible(generalConfig.condition, mainForm.getFieldsValue())
        : true

    const pathList = useMemo(() => {
      if (!root) return []
      return getFormItemNamePathList(root, path, 'props.formItemConfig.name')
    }, [root, path])

    const wrapperRef = useRef<HTMLDivElement>(null)
    const extraFormItemConfig = useMemo(
      () => ({
        valuePropName: ['switch', 'checkbox'].includes(type)
          ? 'checked'
          : ['upload'].includes(type)
            ? 'fileList'
            : undefined,
      }),
      [type]
    )

    const handleDeleteClick = (item: WithDraggingProps<P>) => {
      if (!root) return
      const { path, formItemId } = item
      const newRoot = deepCopy(root)
      const delFormItemParent = getItemByPath(newRoot, path.slice(0, -1))
      if (!delFormItemParent) return
      const newItem = updateItemListByPath(
        newRoot,
        delFormItemParent.path,
        delFormItemParent.children.filter((i) => i.formItemId !== formItemId)
      )

      setRoot?.(newItem)
    }

    useEffect(() => {
      const element = wrapperRef.current
      if (!element) return

      return combine(
        draggable({
          element,
          getInitialData: () => props,
          onDragStart: () => setState(draggingState),
          onDrop: () => setState(idleState),
        }),
        dropTargetForElements({
          element,
          getIsSticky: () => true,
          getData: ({ input }) => {
            return attachClosestEdge(props, {
              input,
              element,
              allowedEdges: ['bottom', 'top'],
            })
          },
          onDrag: ({ self, source, location }) => {
            if (source.data?.formItemId === formItemId) setClosestEdge(null)
            else setClosestEdge(extractClosestEdge(self.data))
          },
          onDropTargetChange: ({ self, source, location }) => {
            setClosestEdge(null)
          },
          onDragLeave: ({ self, source }) => {
            setClosestEdge(null)
          },
          onDrop: () => setClosestEdge(null),
        })
      )
    }, [props, formItemId])

    const RenderContent = useMemo(
      () => (
        <div
          className={cls({
            [styles.componentWrapperItemWrapper]: true,
            [styles.componentWrapperItemWrapperDisabled]:
              state.type === 'dragging',
          })}
          onClick={(e) => {
            onItemClick?.(props)
            e.stopPropagation()
          }}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <CloseCircleOutlined
            className={styles.componentWrapperDelete}
            onClick={(e) => {
              handleDeleteClick(props)
              e.stopPropagation()
            }}
          />
          <Form.Item
            className={styles.componentWrapperItem}
            {...formItemConfig}
            {...extraFormItemConfig}
            labelCol={{ span: 8 }}
            labelAlign="left"
            rules={[formItemConfig?.rules]}
            name={pathList}
          >
            <Cmp {...itemRestConfig} itemProps={props} />
          </Form.Item>
        </div>
      ),
      [props]
    )

    return (
      <div ref={wrapperRef} className={styles.componentWrapper}>
        {!!generalConfig?.condition?.length ? (
          <div
            className={cls({
              [styles.componentWrapperNotVisible]: !itemVisible,
            })}
          >
            {RenderContent}
          </div>
        ) : (
          RenderContent
        )}

        {state.type === 'idle' && closestEdge && (
          <DropIndicator edge={closestEdge} gap={'1px'} />
        )}
      </div>
    )
  }
  return WithDraggingWrapper
}

export default ComponentWrapper
