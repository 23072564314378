import { memo, useMemo } from 'react'
import { Input } from 'antd'
import ComponentWrapper from '../lib/component-wrapper'
import { omitBy } from 'lodash-es'
import { TextAreaProps } from 'antd/es/input'
import { getCommonDefaultValue } from '../constants'

type ItemTextAreaProps = { textAreaConfig?: TextAreaProps } & Record<
  string,
  any
>

export const getDefaultValue = () => getCommonDefaultValue('textArea')

const ItemTextArea = (props: ItemTextAreaProps) => {
  const { textAreaConfig } = props

  const filterProps = useMemo(
    () => omitBy(props, (_, k) => k !== 'onChange' && /[A-Z]/.test(k)),
    [props]
  )

  return <Input.TextArea {...filterProps} {...textAreaConfig} />
}

export default ComponentWrapper(memo(ItemTextArea))
