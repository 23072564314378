import { makeAutoObservable, runInAction } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { HeaderTabsProps } from 'layouts/portal/header'

interface GlobalSettings {
  collapsed: boolean
  selectedMenu: string
  isDeveloperMode: boolean
}

interface HeaderBarProps {
  menuItems: NonNullable<HeaderTabsProps>
  selectedKey: string
  visible: boolean
}

class GlobalStore {
  globalSettings: GlobalSettings = {
    collapsed: false,
    selectedMenu: '',
    isDeveloperMode: false,
  }

  header: HeaderBarProps = {
    menuItems: [],
    selectedKey: '',
    visible: true,
  }

  constructor() {
    makeAutoObservable(this)
  }

  async init() {
    await makePersistable(this, {
      name: 'GlobalStore',
      properties: ['globalSettings'],
      storage: window.localStorage,
    })
  }

  setHeaderMenuItems(menuItems: NonNullable<HeaderTabsProps>) {
    runInAction(() => {
      this.header.menuItems = menuItems
    })
  }

  setHeaderSelectedKey(selectedKey: string): void {
    runInAction(() => {
      this.header.selectedKey = selectedKey
    })
  }

  // toggle(): void {
  //   runInAction(() => {
  //     this.globalSettings.collapsed = !this.globalSettings.collapsed
  //   })
  // }

  toggleDeveloperMode(): void {
    runInAction(() => {
      this.globalSettings.isDeveloperMode = !this.globalSettings.isDeveloperMode
    })
  }

  // selectMenu(key: string): void {
  //   runInAction(() => {
  //     this.globalSettings.selectedMenu = key
  //   })
  // }
}

export default new GlobalStore()
