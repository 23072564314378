import { makeAutoObservable } from 'mobx'
import apiConfig from 'services/api'
import { createApiRequest, RequestData } from 'services/request'
import { isEmpty, normalizePath } from 'utils/common'
import config from 'config'

// Define the file document structure according to API response
export interface FileDocument {
  id: number
  filename: string
  path: string
  size: number
  organizationID: number
  userID: number
  username: string
  createdAt: string
  updatedAt: string
}

export enum FileBelongsType {
  AgentIcon = 'agent_icon',
  Avatar = 'avatar',
  Document = 'document',
  Plugin = 'plugin',
}

// Adjust the DocumentList to handle file documents
interface DocumentList {
  totalRecords: number
  files: FileDocument[]
}

class DocumentStore {
  documents: DocumentList = {
    totalRecords: 0,
    files: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  async getList(
    organizationID: number,
    page: number,
    size: number,
    filename: string,
    fileBelongs: string,
    orderBy: string,
    desc: boolean,
    indexID: number
  ) {
    const params: RequestData = {
      queryParams: {
        filename,
        organizationID,
        page,
        pageSize: size,
        fileBelongs,
        orderBy,
        desc,
        indexID,
      },
    }

    const response = await createApiRequest(apiConfig.filePaginate, params)
    this.documents = {
      totalRecords: response.data.totalRecords,
      files: response.data.files,
    }

    return response
  }

  async delete(id: number) {
    const response = await createApiRequest(apiConfig.fileDelete, {
      queryParams: { id },
    })
    return response
  }

  async update(id: number, filename: string) {
    const params: RequestData = {
      body: {
        filename,
        id,
      },
    }

    const response = await createApiRequest(apiConfig.fileUpdate, params)
    const index = this.documents.files.findIndex(
      (document) => document.id === id
    )
    if (index !== -1) {
      this.documents.files[index].filename = filename
    }

    return response
  }

  async getStaticUrl(uuid: string) {
    const response = await createApiRequest({
      url: normalizePath(apiConfig.file.url, '/' + uuid),
      method: apiConfig.file.method,
    })
    return response
  }

  getUploadUrl = (fileBelongs: FileBelongsType) => {
    if (isEmpty(config.API_GATEWAY)) {
      return ''
    }
    if (
      [
        FileBelongsType.AgentIcon,
        FileBelongsType.Avatar,
        FileBelongsType.Plugin,
      ].includes(fileBelongs)
    ) {
      return normalizePath(
        config.API_GATEWAY,
        '/api/v1/file/images/upload?fileBelongs=' + fileBelongs
      )
    } else if ([FileBelongsType.Document].includes(fileBelongs)) {
      return normalizePath(
        config.API_GATEWAY,
        '/api/v1/file/documents/upload?fileBelongs=' + fileBelongs
      )
    }
    return ''
  }
}

export default new DocumentStore()
