import { memo } from 'react'
import cls from 'classnames'
import { Spin, SpinProps } from 'antd'
import styles from './index.scss'

type LoadingProps = SpinProps & {
  wrapperClassName?: string
}

const Loading = (props: LoadingProps) => {
  const { wrapperClassName, ...spinProps } = props

  return (
    <div className={cls(wrapperClassName, styles.loadingWrapper)}>
      <Spin {...spinProps} />
    </div>
  )
}

export default memo(Loading)
