import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { createApiRequest, RequestData } from 'services/request'
import { Chunk, ChunkList, MetadataType } from 'stores/models/chunk'

class ChunkStore {
  chunkList: ChunkList = {
    totalCount: 0,
    chunks: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  async create(
    knowledgeId: number,
    documentId: number,
    fileId: number,
    content: string,
    metadata: MetadataType
  ) {
    const params: RequestData = {
      body: {
        content,
        documentID: documentId,
        fileID: fileId,
        knowledgeID: knowledgeId,
        metadata,
      },
    }

    const response = await createApiRequest<Chunk>(
      apiConfig.chunkCreate,
      params
    )

    return response
  }

  async update(
    id: string,
    knowledgeId: number,
    content: string,
    metadata?: MetadataType
  ) {
    const params: RequestData = {
      body: {
        _id: id,
        content,
        knowledgeID: knowledgeId,
        metadata,
      },
    }

    const response = await createApiRequest<Chunk>(
      apiConfig.chunkUpdate,
      params
    )
    const index = this.chunkList.chunks.findIndex((chunk) => chunk._id === id)
    if (index !== -1) {
      this.chunkList.chunks[index] = {
        ...this.chunkList.chunks[index],
        ...params.body,
      }
    }
    return response
  }

  async getList(
    knowledgeId: number,
    documentId: number,
    page: number,
    size: number
  ) {
    const params: RequestData = {
      queryParams: {
        knowledgeID: knowledgeId,
        documentID: documentId,
        page,
        size,
      },
    }

    const response = await createApiRequest<ChunkList>(
      apiConfig.chunkList,
      params
    )

    runInAction(() => {
      this.chunkList = {
        totalCount: response.data.totalCount,
        chunks: response.data.chunks,
      }
    })

    return response
  }

  async delete(id: string, knowledgeId: number) {
    const response = await createApiRequest(apiConfig.chunkDelete, {
      queryParams: { _id: id, knowledgeID: knowledgeId },
    })

    runInAction(() => {
      this.chunkList = {
        totalCount: this.chunkList.totalCount - 1,
        chunks: this.chunkList.chunks.filter((chunk) => chunk._id !== id),
      }
    })
    return response
  }

  async updateStatus(id: string, knowledgeId: number, enabled: boolean) {
    const params: RequestData = {
      body: {
        _id: id,
        knowledgeID: knowledgeId,
        enabled,
      },
    }
    const response = await createApiRequest(apiConfig.chunkStatusUpdate, params)
    return response
  }
}

export default new ChunkStore()
