import React, { useState, useEffect, MouseEventHandler } from 'react'
import {
  Button,
  Pagination,
  Skeleton,
  PaginationProps,
  App,
  Empty,
  Flex,
} from 'antd'
import KnowledgeCard from './knowledge-card'
import { getMessageFromError } from 'utils/common'
import KnowledgeModal, { KnowledgeModalProps } from './knowledge-modal'
import { useExNavigate } from 'hooks/use-ex-navigate'
import CustomPagination from 'components/custom-pagination'
import { v1KnowledgeListList } from 'api/Api'
import { ResponsesKnowledgeResponse } from 'api/data-contracts'
import MainLayout from 'layouts/portal/main-layout'
import styles from './index.scss'

const DEFAULT_KNOWLEDGE_DATA: KnowledgeModalProps['data'] = {
  id: undefined,
  name: '',
  description: '',
}

const Knowledge: React.FC = () => {
  const navigate = useExNavigate()
  const { message } = App.useApp()
  const [curPage, setCurPage] = useState(1)
  const [curPageSize, setCurPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [knowledgeList, setKnowledgeList] = useState<
    ResponsesKnowledgeResponse[]
  >([])
  const [loading, setLoading] = useState(true)
  const [isKnowledgeModalOpen, setIsKnowledgeModalOpen] = useState(false)
  const [knowledgeData, setKnowledgeData] = useState<
    KnowledgeModalProps['data']
  >(DEFAULT_KNOWLEDGE_DATA)

  const load = async (page: number, size: number) => {
    setLoading(true)
    try {
      const resp = await v1KnowledgeListList({
        page,
        size,
      })
      setKnowledgeList(resp.data.knowledges)
      setTotal(resp.data.totalCount)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setLoading(false)
  }

  const onChange: PaginationProps['onChange'] = (
    page: number,
    size: number
  ) => {
    if (page) setCurPage(page)
    if (size) setCurPageSize(size)
  }

  const handleCreateFinish = (knowledge: ResponsesKnowledgeResponse) => {
    setIsKnowledgeModalOpen(false)
    navigate('/portal/knowledge/document', {
      state: {
        knowledge: {
          id: knowledge.id,
          name: knowledge.knowledgeName,
          description: knowledge.description,
          documentCount: knowledge.documentCount,
          documentSize: knowledge.documentSize,
        },
        is_upload_page_visible: true,
      },
    })
  }

  const handleCreateButtonClick: MouseEventHandler<HTMLElement> = (e) => {
    setKnowledgeData(DEFAULT_KNOWLEDGE_DATA)
    setIsKnowledgeModalOpen(true)
  }

  useEffect(() => {
    load(curPage, curPageSize)
  }, [curPage, curPageSize])

  return (
    <MainLayout
      title="Knowledge"
      actions={[
        {
          text: 'Create Knowledge',
          isPrimary: true,
          onClick: handleCreateButtonClick,
        },
      ]}
      noBack
      noHeaderBottomLine
    >
      <Skeleton
        loading={loading}
        style={{ padding: '16px', height: '100%' }}
        active
      >
        {knowledgeList.length > 0 ? (
          <Flex className={styles.knowledgeCardWrapper} gap={16} wrap>
            {knowledgeList.map((item, i) => (
              <KnowledgeCard
                key={i}
                data={{
                  id: item.id,
                  name: item.knowledgeName,
                  description: item.description,
                  documentCount: item.documentCount,
                  documentSize: item.documentSize,
                }}
                onAction={(action) => {
                  if (action === 'edit') {
                    setKnowledgeData({
                      id: item.id,
                      name: item.knowledgeName,
                      description: item.description,
                    })
                    setIsKnowledgeModalOpen(true)
                  } else if (action === 'delete') {
                    setKnowledgeList(
                      knowledgeList.filter((k) => k.id !== item.id)
                    )
                  }
                }}
              />
            ))}
          </Flex>
        ) : (
          <Flex justify="start" style={{ width: '100%' }}>
            <Empty />
          </Flex>
        )}
      </Skeleton>
      <CustomPagination
        total={total}
        current={curPage}
        pageSize={curPageSize}
        onChange={onChange}
        align="center"
        className={styles.knowledgePagination}
      />
      <KnowledgeModal
        open={isKnowledgeModalOpen}
        data={knowledgeData}
        onFinish={(knowledge) => {
          // check if knowledge is created or updated
          if (knowledgeData.id) {
            setKnowledgeList(
              knowledgeList.map((item) => {
                if (item.id === knowledge.id) {
                  return knowledge
                }
                return item
              })
            )
          } else {
            handleCreateFinish(knowledge)
          }
          setIsKnowledgeModalOpen(false)
        }}
        onCancel={() => setIsKnowledgeModalOpen(false)}
      />
    </MainLayout>
  )
}

export default Knowledge
