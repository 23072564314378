import { Flex, Spin } from 'antd'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import styles from './index.scss'

export interface LoadingWrapperProps {
  children: React.ReactNode
  loading?: boolean
  size?: 'small' | 'default' | 'large'
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({
  children,
  loading = false,
  size = 'large',
}) => {
  return (
    <>
      {children}
      {loading && (
        <Flex className={styles.loadingWrapper}>
          <Spin indicator={<LoadingOutlined spin />} size={size} />
        </Flex>
      )}
    </>
  )
}

export default LoadingWrapper
