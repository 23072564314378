import { App, Button, Flex, Popconfirm, Table, Typography } from 'antd'
import {
  v1EvaluationQuestionsDelete,
  v1EvaluationQuestionsListList,
} from 'api/Api'
import {
  EvaluationModelsLLMMessage,
  ResponsesQuestionResponse,
} from 'api/data-contracts'
import DeleteIcon from 'assets/images/delete.svg'
import EditIcon from 'assets/images/edit.svg'
import CustomTable from 'components/custom-table'
import { useIsAdmin } from 'hooks/use-is-admin'
import { observer } from 'mobx-react'
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { getMessageFromError } from 'utils/common'
import { formatDate } from 'utils/filter'

export enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

interface QuestionTableProps {
  agentId: number
  onAction?: (type: ActionType, question: ResponsesQuestionResponse) => void
}

export interface QuestionTableRef {
  refresh: () => void
}

const QuestionTable: ForwardRefRenderFunction<
  QuestionTableRef,
  QuestionTableProps
> = (props, ref) => {
  const { message } = App.useApp()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [questions, setQuestions] = useState<ResponsesQuestionResponse[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [deletingId, setDeletingId] = useState(0)
  const isAdmin = useIsAdmin()

  useImperativeHandle(
    ref,
    () => ({
      refresh: () => load(page, size),
    }),
    [page, size]
  )

  const load = async (page: number, size: number) => {
    setLoading(true)
    try {
      const resp = await v1EvaluationQuestionsListList({
        page,
        limit: size,
        conditions: JSON.stringify([
          {
            field: 'agent_id',
            operator: '=',
            value: props.agentId,
          },
        ]),
      })
      setQuestions(resp.data.data)
      setTotal(resp.data.pagination.totalItems)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setLoading(false)
  }

  useEffect(() => {
    load(page, size)
  }, [page, size])

  const deleteQuestion = async (key: number) => {
    setDeletingId(key)
    try {
      await v1EvaluationQuestionsDelete({ id: key })
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setDeletingId(0)
    load(page, size)
  }

  const columns = [
    {
      title: 'Test Case',
      dataIndex: 'testCase',
      editable: false,
      width: '200px',
      render: (text: string, record: ResponsesQuestionResponse) => {
        return (
          <Typography.Text title={text} key={'test_case_' + record.id} ellipsis>
            {text}
          </Typography.Text>
        )
      },
    },
    {
      title: 'Expected Answer',
      dataIndex: 'expectedAnswer',
      editable: false,
      minWidth: 168,
      render: (text: string, record: ResponsesQuestionResponse) => {
        return (
          <Typography.Paragraph
            title={text}
            key={'expected_answer_' + record.id}
            ellipsis={{
              rows: 2,
              expandable: false,
              symbol: 'more',
            }}
          >
            {text}
          </Typography.Paragraph>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      minWidth: 120,
      render: (text: string, record: ResponsesQuestionResponse) => (
        <div key={'created_at_' + record.id}>{formatDate(text)}</div>
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      minWidth: 120,
      render: (text: string, record: ResponsesQuestionResponse) => (
        <div key={'updated_at_' + record.id}>{formatDate(text)}</div>
      ),
    },
    {
      title: 'Actions',
      width: '120px',
      render: (_: any, record: ResponsesQuestionResponse) => {
        return (
          <Flex key={'actions_' + record.id} gap={16}>
            <Button
              type="text"
              key={'edit_' + record.id}
              icon={<EditIcon fontSize={32} />}
              size="small"
              disabled={false}
              onClick={() => {
                props.onAction && props.onAction(ActionType.Edit, record)
              }}
            />
            <Popconfirm
              key={'popconfirm_' + record.id}
              title="Are you sure to delete the question?"
              onConfirm={() => deleteQuestion(record.id)}
            >
              <Button
                type="text"
                key={'delete_' + record.id}
                icon={<DeleteIcon fontSize={32} />}
                size="small"
                disabled={!isAdmin}
                loading={deletingId === record.id}
              />
            </Popconfirm>
          </Flex>
        )
      },
    },
  ]

  const expandedRowRender = (record: ResponsesQuestionResponse) => {
    const columns = [
      {
        title: 'Role',
        dataIndex: 'role',
        editable: false,
        render: (
          text: string,
          record: EvaluationModelsLLMMessage,
          index: number
        ) => {
          return (
            <div key={'role_' + index} style={{ textTransform: 'capitalize' }}>
              {text}
            </div>
          )
        },
      },
      {
        title: 'Content',
        dataIndex: 'content',
        editable: false,
        render: (
          text: string,
          record: EvaluationModelsLLMMessage,
          index: number
        ) => {
          return <div key={'prompt_' + index}>{text}</div>
        },
      },
    ]
    return (
      <Table
        rowKey={(_, index) => index ?? 0}
        dataSource={record.questionAnswers}
        columns={columns}
        loading={loading}
        pagination={false}
      />
    )
  }

  return (
    <div className="file-table">
      <CustomTable
        rowKey={(record) => record.id}
        data={questions}
        paginationProps={{
          current: page,
          pageSize: size,
          total: total,
          align: 'center',
          onChange: (page, size) => {
            setPage(page)
            setSize(size)
          },
        }}
        scroll={{
          x: true,
          y: 'calc(100vh - 285px)',
        }}
        columns={columns}
        loading={loading}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => true,
        }}
      />
    </div>
  )
}

export default observer(forwardRef(QuestionTable))
