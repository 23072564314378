import { memo, useEffect, useState } from 'react'
import { Form, Input, Select, Upload, UploadFile, UploadProps } from 'antd'
import { v4 as uuidV4 } from 'uuid'
import { FormInstance } from 'antd/es/form/Form'
import { PlusOutlined } from '@ant-design/icons'
import fileStore, { FileBelongsType } from 'stores/file'
import styles from './index.scss'
import { message } from 'components/antd-static-function'
import { getBase64, getFileUrl, isEmpty, UploadFileType } from 'utils/common'
import { v1PluginCategoriesCreate, v1PluginCategoriesList } from 'api/Api'
import {
  PluginPluginType,
  ResponsesPluginCategoryResponse,
} from 'api/data-contracts'

export type PluginInformationType = {
  pluginId: string
  name: string
  pluginType: 'internal' | 'external'
  description: string
  icon: {
    name: string
    uuid: string
    type: string // png jpeg ...
  }
}

export type FormInformationType = {
  categoryID: number
  type: PluginPluginType
  name: string

  description?: string
  iconList?: UploadFile[]
}

const baseFormItemName = 'pluginInformation'
const PluginInformation = (props: { form: FormInstance }) => {
  const { form } = props
  const [avatarUrl, setAvatarUrl] = useState('')
  const [categoryList, setCategoryList] =
    useState<ResponsesPluginCategoryResponse[]>()

  const handleIconBeforeUpload: UploadProps['beforeUpload'] = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt10M = file.size / 1024 / 1024 < 10
    if (!isLt10M) {
      message.error('Image must smaller than 10MB!')
    }
    return isJpgOrPng && isLt10M
  }
  const handleIconStatusChange: UploadProps['onChange'] = (info) => {
    switch (info.file.status) {
      case 'uploading':
        break
      case 'done':
        getBase64(info.file.originFileObj as UploadFileType, setAvatarUrl)
        break
      case 'error':
        message.error(`${info.file.name} file upload failed.`)
        break
      case 'removed':
        break
    }
  }

  const initPage = async () => {
    const categoryResp = await v1PluginCategoriesList({ page: 1, limit: 100 })
    if (!categoryResp?.data) return
    setCategoryList(categoryResp.data.data)
  }

  useEffect(() => {
    form.setFieldValue([baseFormItemName, 'pluginId'], uuidV4())
    initPage()
  }, [])

  return (
    <div className={styles.informationWrapper}>
      {/* TODO@Tanner: remove the feature */}
      {/* <Button onClick={handleCreateCategory}>Create Category</Button> */}

      <Form form={form} layout="horizontal" labelCol={{ span: 4 }}>
        <Form.Item
          name={[baseFormItemName, 'iconList']}
          label={'Agent Icon'}
          valuePropName="fileList"
          getValueFromEvent={(e) => e.fileList}
        >
          <Upload
            name="file"
            listType="picture-card"
            showUploadList={false}
            action={fileStore.getUploadUrl(FileBelongsType.Plugin)}
            multiple={false}
            beforeUpload={handleIconBeforeUpload}
            onChange={handleIconStatusChange}
          >
            {isEmpty(avatarUrl) ? (
              <button style={{ border: 0, background: 'none' }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </button>
            ) : (
              <img
                src={avatarUrl}
                alt="avatar"
                style={{ height: '100%', width: '100%', borderRadius: '8px' }}
              />
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          name={[baseFormItemName, 'name']}
          label={'Plugin Name'}
          rules={[{ required: true, message: 'Please input a name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={[baseFormItemName, 'type']}
          label={'Type'}
          initialValue={'external'}
        >
          <Select
            options={[
              { label: 'Internal', value: PluginPluginType.Internal },
              { label: 'External', value: PluginPluginType.External },
            ]}
          />
        </Form.Item>

        <Form.Item
          name={[baseFormItemName, 'categoryID']}
          label={'Category'}
          rules={[{ required: true, message: 'Please Select the category!' }]}
        >
          <Select
            options={categoryList?.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
          />
        </Form.Item>

        <Form.Item
          name={[baseFormItemName, 'description']}
          label={'Description'}
        >
          <Input />
        </Form.Item>
      </Form>
    </div>
  )
}

export default memo(PluginInformation)
