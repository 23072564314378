import { useRef, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import cls from 'classnames'
import {
  Avatar,
  Button,
  FormInstance,
  Space,
  Steps,
  Tabs,
  TabsProps,
} from 'antd'
import DynamicForm from 'components/new-dynamic-form'
import { message } from 'components/antd-static-function'

import BackspaceSvg from 'assets/images/backspace.svg'
import SaveSvg from 'assets/images/save.svg'
import SendSvg from 'assets/images/send.svg'
import FileSvg from 'assets/images/file.svg'
import FormSvg from 'assets/images/form.svg'
import BranchSvg from 'assets/images/branch.svg'

import styles from './index.scss'
import { getFileUrl, getQuery, setQuery } from 'utils/common'
import PluginBasicInfo, { PluginInformationFormType } from '../basic-info'
import {
  v1PluginFormsPublishCreate,
  v1PluginFormsSaveCreate,
  v1PluginsDetail,
  v1PluginsPluginFormsLatestDetail,
  v1PluginsUpdate,
  v1PluginWithFormCreate,
} from 'api/Api'
import { FormItemType } from 'components/new-dynamic-form/types'
import {
  PluginModelsTreeNode,
  ResponsesPluginFormResponse,
  ResponsesPluginResponse,
} from 'api/data-contracts'
import { getRootDefaultValue } from 'components/new-dynamic-form/constants'
import Loading from 'layouts/loading'
import dayjs from 'dayjs'

type PluginDetailPageParameters = {
  opType: 'edit' | 'create' | 'view'
  curTab: string
}

enum TabItems {
  basicInfo = 'Basic Info',
  configurationForm = 'Configuration Form',
  workflowForm = 'Workflow Form',
}

const PluginTabItems: TabsProps['items'] = [
  {
    key: 'basicInfo',
    label: TabItems.basicInfo,
    icon: <FormSvg className={styles.pluginDetailTabItemIcon} />,
  },
  {
    key: 'configurationForm',
    label: TabItems.configurationForm,
    icon: <FileSvg className={styles.pluginDetailTabItemIcon} />,
  },
  {
    key: 'workflowForm',
    label: TabItems.workflowForm,
    icon: <BranchSvg className={styles.pluginDetailTabItemIcon} />,
  },
]

type PluginDetailType = {
  baseInfo: ResponsesPluginResponse
  formInfo: ResponsesPluginFormResponse
}

const PluginDetail = () => {
  const navigate = useNavigate()
  const { opType, curTab: defaultCurTab = 'basicInfo' } =
    getQuery<PluginDetailPageParameters>(location.search)
  const { pluginId } = useParams()
  const [loading, setLoading] = useState(false)
  const [curTab, setCurTab] = useState(defaultCurTab)
  const basicInfoRef = useRef<{
    form: FormInstance<PluginInformationFormType>
  }>()
  const [configTreeData, setConfigTreeData] = useState<FormItemType>()
  const [workflowTreeData, setWorkflowTreeData] = useState<FormItemType>()
  const [pluginDetail, setPluginDetail] = useState<PluginDetailType>()
  const [structureDefaultTreeData, setStructureDefaultTreeData] =
    useState<PluginModelsTreeNode[]>()

  const initPage = async () => {
    if (opType === 'create') {
      setConfigTreeData(getRootDefaultValue())
      setWorkflowTreeData(getRootDefaultValue())
      return
    }
    setLoading(true)
    const baseInfoResp = await v1PluginsDetail(Number(pluginId))
    const detailResp = await v1PluginsPluginFormsLatestDetail(
      Number(pluginId)
    ).finally(() => setLoading(false))
    // if (!detailResp.data && detailResp.data) return
    setPluginDetail({
      baseInfo: baseInfoResp.data,
      formInfo: detailResp.data,
    })

    setStructureDefaultTreeData(detailResp.data?.outputForm?.data as any)
    setConfigTreeData(
      (detailResp.data?.configurationForm as any) ?? getRootDefaultValue()
    )
    setWorkflowTreeData(
      (detailResp.data?.workflowForm as any) ?? getRootDefaultValue()
    )
  }

  const handleBackspaceClick = () => {
    navigate(`/portal/plugin`)
  }

  const handleSaveClick = async () => {
    if (basicInfoRef.current) {
      await basicInfoRef.current?.form.validateFields()
    }
    const basicInfo = basicInfoRef.current?.form.getFieldsValue()
    if (!basicInfo) return
    if (opType === 'create') {
      // base info
      const createResp = await v1PluginWithFormCreate({
        ...basicInfo.pluginInformation,
        iconID: basicInfo.pluginInformation.iconList?.[0]?.response?.[0]?.id,
        outputForm: {
          data: basicInfo.pluginOutput.structure,
          ...basicInfo.pluginOutput,
        },
        configurationForm: configTreeData as any,
        workflowForm: workflowTreeData as any,
      })

      if (!createResp) return
      message.success('Create Successful!')
      handleBackspaceClick()
      return
    } else {
      if (!pluginDetail) return
      if (!Number(pluginId)) return

      // update base info
      await v1PluginsUpdate(Number(pluginId), {
        ...basicInfo.pluginInformation,
        iconID:
          basicInfo?.pluginInformation?.iconList?.[0]?.response?.[0]?.id ??
          pluginDetail.baseInfo?.iconID,
      })
      // update form
      if (pluginDetail.formInfo) {
        await v1PluginFormsSaveCreate(pluginDetail.formInfo?.id, {
          outputForm: {
            data: basicInfo.pluginOutput.structure,
            type: basicInfo.pluginOutput.type,
          },
          configurationForm: configTreeData as any,
          workflowForm: workflowTreeData as any,
        })
      }
      message.success('Update Successful!')
    }
  }

  const handlePublishClick = async () => {
    // console.error(basicInfoRef.current?.form.getFieldsValue())

    if (!pluginDetail) return
    const publishResp = await v1PluginFormsPublishCreate(
      pluginDetail.formInfo?.id,
      pluginDetail.formInfo
    ).catch(() => message.success('Publish Failure!'))
    if (!publishResp) return
    message.success('Publish Successful!')
  }

  useEffect(() => {
    initPage()
  }, [])

  useEffect(() => {
    const search = getQuery(location.search)
    const params = setQuery({ ...search, curTab })
    navigate({ search: params })
  }, [curTab])

  useEffect(() => {
    // set default values
    basicInfoRef.current?.form.setFieldsValue({
      pluginInformation: pluginDetail?.baseInfo,
      pluginOutput: {
        ...pluginDetail?.formInfo.outputForm,
        structure: pluginDetail?.formInfo.outputForm.data,
      },
    })
  }, [pluginDetail])

  return (
    <div className={styles.pluginDetailWrapper}>
      <div className={styles.pluginDetailHeaderWrapper}>
        <div className={styles.pluginDetailHeaderLeft}>
          <BackspaceSvg
            className={styles.pluginDetailHeaderLeftBackIcon}
            onClick={handleBackspaceClick}
          />
          <Avatar
            shape="square"
            src={getFileUrl(pluginDetail?.baseInfo?.icon?.uuid ?? '')}
          />
          <div className={styles.pluginDetailHeaderLeftInfoWrapper}>
            <div className={styles.pluginDetailHeaderLeftInfoName}>
              {pluginDetail?.baseInfo?.name}
            </div>
            <div className={styles.pluginDetailHeaderLeftInfoSubWrapper}>
              <span>
                Saved {dayjs(pluginDetail?.baseInfo?.updatedAt).fromNow()}
              </span>
              {pluginDetail?.formInfo?.status && (
                <span className={styles.pluginDetailHeaderLeftInfoStatus}>
                  ({pluginDetail?.formInfo?.status})
                </span>
              )}
            </div>
          </div>
        </div>

        {opType !== 'create' ? (
          <Tabs
            defaultActiveKey={curTab}
            animated={{ inkBar: false }}
            tabBarGutter={12}
            items={PluginTabItems}
            rootClassName={styles.pluginDetailTabWrapper}
            onChange={setCurTab}
          />
        ) : (
          <Steps
            size="small"
            className={styles.pluginDetailStepsWrapper}
            current={PluginTabItems.findIndex((i) => i.key === curTab)}
            items={PluginTabItems.map((i) => ({ title: i.label }))}
            onChange={(idx) => setCurTab(PluginTabItems[idx].key)}
          />
        )}

        <Space className={styles.pluginDetailHeaderRight}>
          <Button
            className={styles.pluginDetailHeaderRightBtn}
            icon={<SaveSvg className={styles.pluginDetailHeaderRightIcon} />}
            onClick={handleSaveClick}
            disabled={curTab !== 'workflowForm'}
          >
            Save
          </Button>
          {opType !== 'edit' && (
            <Button
              className={styles.pluginDetailHeaderRightBtn}
              icon={<SendSvg className={styles.pluginDetailHeaderRightIcon} />}
              onClick={handlePublishClick}
            >
              Publish
            </Button>
          )}
        </Space>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div
            className={cls({
              [styles.pluginDetailHide]: curTab !== 'basicInfo',
            })}
          >
            <PluginBasicInfo
              ref={basicInfoRef}
              defaultStructureTreeData={structureDefaultTreeData}
            />
          </div>

          {curTab === 'configurationForm' && configTreeData && (
            <DynamicForm
              key={'configurationForm'}
              wrapperClassName={styles.pluginDetailDynamicFormWrapper}
              setTreeRoot={setConfigTreeData}
              treeRoot={configTreeData}
            />
          )}

          {curTab === 'workflowForm' && workflowTreeData && (
            <DynamicForm
              key={'workflowForm'}
              wrapperClassName={styles.pluginDetailDynamicFormWrapper}
              setTreeRoot={setWorkflowTreeData}
              treeRoot={workflowTreeData}
            />
          )}
        </>
      )}
    </div>
  )
}

export default PluginDetail
