import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider,
} from 'react-router-dom'
import Agent from 'views/portal/agent'
import Chat from 'views/portal/agent/chat'
import Studio from 'views/portal/agent/studio'
import Analytics from 'views/portal/agent/studio/analytics'
import Evaluation from 'views/portal/agent/studio/evaluation'
import Workflow from 'views/portal/agent/studio/workflow'
import Metric from 'views/portal/metric'
// import Permission from 'views/portal/permission'
// import OrganizationManagement from 'views/portal/organization/o-management'
// import Role from 'views/portal/organization/o-role'
// import User from 'views/portal/organization/o-user'
import MainLayout from 'layouts/portal'
import NotFound from 'views/not-found'
import Dashboard from 'views/portal/dashboard'
import Integration from 'views/portal/integration'
import Intent from 'views/portal/intent'
import Knowledge from 'views/portal/knowledge'
import Chunk from 'views/portal/knowledge/chunk'
import Document from 'views/portal/knowledge/document'
import Organization from 'views/portal/organization'
import Plugin from 'views/portal/plugin'
import PluginDetail from 'views/portal/plugin/detail'
// import PluginCreate from 'views/portal/plugin/create'
import DeveloperSettings from 'views/portal/developer-settings'

import { lazy } from 'react'
import { isDev } from 'utils/env'

const routes: RouteObject[] = [
  // Extra Pages
  {
    path: 'chat-bubble/:uuid',
    Component: lazy(() => import('views/chat-bubble')),
  },
  {
    path: 'chat/:uuid',
    Component: lazy(() => import('views/new-chat')),
  },
  {
    path: 'login',
    Component: lazy(() => import('views/login')),
  },

  // Portal Pages
  { path: '', element: <Navigate to="portal" /> },
  {
    path: 'portal',
    element: <MainLayout />,
    children: [
      { index: true, element: <Navigate to="dashboard" replace /> },
      { path: 'dashboard', element: <Dashboard /> },
      // { path: 'permission', element: <Permission /> },
      {
        path: 'organization',
        element: <Organization />,
        // children: [
        //   { index: true, element: <Navigate to="management" replace /> },
        //   { path: 'management', element: <OrganizationManagement /> },
        //   { path: 'members', element: <User /> },
        //   { path: 'roles', element: <Role /> },
        // ],
      },

      // agent
      { path: 'agent', element: <Agent /> },
      { path: 'agent/chat', element: <Chat /> },
      {
        path: 'agent/studio',
        element: <Studio />,
        children: [
          { index: true, element: <Navigate to="workflow" replace /> },
          { path: 'workflow', element: <Workflow /> },
          {
            path: 'evaluation',
            element: <Evaluation />,
            children: [
              { index: true, element: <Navigate to="questions" replace /> },
              { path: ':tab', element: null }
            ]
          },
          { path: 'analytics', element: <Analytics /> },
        ],
      },

      { path: 'intent', element: <Intent /> },
      { path: 'metric', element: <Metric /> },
      { path: 'knowledge', element: <Knowledge /> },
      { path: 'knowledge/document', element: <Document /> },
      { path: 'knowledge/document/chunk', element: <Chunk /> },
      { path: 'integration', element: <Integration /> },
      // plugin
      { path: 'plugin', element: <Plugin /> },
      { path: 'plugin/detail/:pluginId?', element: <PluginDetail /> },

      // developer settings
      { path: 'developer-settings', element: <DeveloperSettings /> },
    ],
  },

  // Not found
  { path: '*', element: <NotFound /> },
]

if (isDev) {
  routes.push({
    path: '/portal/test',
    Component: lazy(() => import('views/test')),
  })

  console.groupCollapsed('routes')
  console.log(routes)
  console.groupEnd()
}

const router = createBrowserRouter(routes)

const DefaultRouter = () => {
  return <RouterProvider router={router} />
}

export default DefaultRouter
