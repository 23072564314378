import { makeAutoObservable, runInAction } from 'mobx'
// import apiConfig from 'services/api'
// import { RequestData } from 'services/request'
// import { createApiRequest } from 'services/request'
// import { Role, RoleList } from 'stores/models/role'
// import { CodeMsgResponse } from './models/common'
// import { PermissionType } from './models/permission'
import { v1RoleListList } from 'api/Api'
import { ResponsesRoleResponse } from 'api/data-contracts'

type RoleType = Omit<ResponsesRoleResponse, 'permissions'> & {
  permissions: string[]
}

type RoleList = {
  total_count: number
  roles: RoleType[]
}

class RoleStore {
  roleList: RoleList = {
    total_count: 0,
    roles: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  initRoleList = async (page = 1, size = 100) => {
    const roleResp = await v1RoleListList()
    if (!roleResp.data) return
    const { total_count, roles } = roleResp.data

    runInAction(() => {
      this.roleList = {
        total_count: total_count,
        roles: roles.map((role) => ({
          ...role,
          permissions: role.permissions.split(','),
        })),
      }
    })
    return roleResp.data
  }

  getRoleById = (id: number) =>
    this.roleList.roles.find((role) => role.id === id)

  // async create(name: string, owner: boolean, permissions: PermissionType[]) {
  //   const params: RequestData = {
  //     body: {
  //       name,
  //       owner,
  //       permissions,
  //     },
  //   }
  //   const response = await createApiRequest<RoleType | CodeMsgResponse>(
  //     apiConfig.roleCreate,
  //     params
  //   )
  //   return response
  // }

  // async update(
  //   id: number,
  //   name: string,
  //   owner: boolean,
  //   permissions: PermissionType[]
  // ) {
  //   const params: RequestData = {
  //     body: {
  //       id,
  //       name,
  //       owner,
  //       permissions,
  //     },
  //   }

  //   const response = await createApiRequest<RoleType | CodeMsgResponse>(
  //     apiConfig.roleUpdate,
  //     {
  //       body: {
  //         ...params.body,
  //         permissions: permissions.join(','),
  //       },
  //     }
  //   )
  //   const index = this.roleList.roles.findIndex((role) => role.id === id)
  //   if (index !== -1) {
  //     runInAction(() => {
  //       this.roleList.roles[index] = {
  //         ...this.roleList.roles[index],
  //         ...params.body,
  //       }
  //     })
  //   }
  //   return response
  // }

  // TODO@Tanner: remove it
  // async getList(page = 1, size = 100) {
  //   const response = await v1RoleListList()

  //   if (!response.data) return

  //   runInAction(() => {
  //     this.roleList = {
  //       total_count: response.data.total_count,
  //       roles: response.data.roles.map((role: any) => ({
  //         ...role,
  //         permissions: role.permissions.split(','),
  //       })),
  //     }
  //   })

  //   return response
  // }

  // async delete(id: number) {
  //   const response = await createApiRequest<string | CodeMsgResponse>(
  //     apiConfig.roleDelete,
  //     {
  //       queryParams: { id },
  //     }
  //   )
  //   runInAction(() => {
  //     this.roleList = {
  //       total_count: this.roleList.total_count - 1,
  //       roles: this.roleList.roles.filter((role) => role.id !== id),
  //     }
  //   })
  //   return response
  // }
}

export default new RoleStore()
