import { forwardRef, memo, useEffect, useImperativeHandle } from 'react'
import { Anchor, Col, Row, Form, FormInstance } from 'antd'
import PluginInformation, { FormInformationType } from './information'
import PluginOutputStructure, { FormOutputType } from './output'

import styles from './index.scss'
import { PluginModelsTreeNode } from 'api/data-contracts'
const AnchorItems = [
  { key: 'pluginInfo', href: '#pluginInfo', title: 'Pugin Info' },
  {
    key: 'Output Structure',
    href: '#outputStructure',
    title: 'Output Structure',
  },
]

export type PluginInformationFormType = {
  pluginInformation: FormInformationType
  pluginOutput: FormOutputType
}

type PluginBasicInfoProps = {
  defaultStructureTreeData?: PluginModelsTreeNode[]
}

const PluginBasicInfo = forwardRef<unknown, PluginBasicInfoProps>(
  (props, ref) => {
    const { defaultStructureTreeData } = props
    const [form] = Form.useForm()

    useImperativeHandle(ref, () => ({ form }))

    return (
      <div className={styles.basicInfoWrapper}>
        <Row>
          <Col span={21}>
            <div className={styles.basicInfoItemWrapper} id="pluginInfo">
              <div className={styles.basicInfoItemTitle}>Plugin Info</div>
              <PluginInformation form={form} />
            </div>

            <div className={styles.basicInfoItemWrapper} id="outputStructure">
              <div className={styles.basicInfoItemTitle}>Output Structure</div>
              <PluginOutputStructure
                form={form}
                defaultStructureTreeData={defaultStructureTreeData}
              />
            </div>
          </Col>

          <Col span={3} className={styles.basicInfoAnchorWrapper}>
            <Anchor items={AnchorItems} replace />
          </Col>
        </Row>
      </div>
    )
  }
)

export default memo(PluginBasicInfo)
