import { ConfigSectionType } from 'components/new-dynamic-form/types'
import { CommonConfig } from '../constants'

const ItemInputConfig: ConfigSectionType[] = [
  ...CommonConfig,
  {
    sectionLabel: 'inputConfig',
    items: [
      { itemLabel: 'AllowClear', itemName: 'allowClear', type: 'switch' },
      { itemLabel: 'Disabled', itemName: 'disabled', type: 'switch' },
      { itemLabel: 'Placeholder', itemName: 'placeholder', type: 'input' },
      {
        itemLabel: 'MaxLength',
        itemName: 'maxLength',
        type: 'inputNumber',
        min: 0,
      },
      { itemLabel: 'ShowCount', itemName: 'showCount', type: 'switch' },
      {
        itemLabel: 'Type',
        itemName: 'type',
        type: 'select',
        options: [
          { label: 'Text', value: 'text' },
          { label: 'Password', value: 'password' },
          { label: 'Number', value: 'number' },
        ],
        defaultValue: 'text',
      },
    ],
  },
]

export default ItemInputConfig
