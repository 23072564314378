import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  RocketOutlined,
  ProfileOutlined,
  InboxOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import {
  Button,
  Upload,
  Form,
  Input,
  Space,
  Tag,
  Radio,
  Checkbox,
  InputNumber,
  App,
  Flex,
} from 'antd'
import type { RadioChangeEvent, UploadFile, UploadProps } from 'antd'
import RadioCard from './radio-card'
import { getMessageFromError } from 'utils/common'
import { useExNavigate } from 'hooks/use-ex-navigate'
import fileStore, { FileBelongsType } from 'stores/file'
import { KnowledgeCardProps } from '../knowledge-card'
import { v1KnowledgeDocumentCreate } from 'api/Api'
import styles from './upload.scss'

const { Dragger } = Upload

type DocumentUploadProps = {
  data: KnowledgeCardProps['data']
  onUploadSuccess?: () => void
}

const CHUNK_SETTING_RADIO_VALUES = {
  auto: 'automatic',
  custom: 'custom',
}

const FORM_INITIAL_VALUE = {
  segment_identifier: '\\n',
  maximum_chunk_length: 500,
  chunk_over_lap: 50,
  replace_consecutive_spaces: true,
  replace_newlines: true,
  replace_tabs: true,
  delete_all_urls: false,
  delete_all_email_addresses: false,
}

const DocumentUpload: React.FC<DocumentUploadProps> = (props) => {
  const { message, modal } = App.useApp()
  const navigate = useExNavigate()
  const [form] = Form.useForm()
  const [knowledge, setKnowledge] = useState<KnowledgeCardProps['data']>(
    props.data
  )

  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [chunkSettingRadioValue, setChunkSettingRadioValue] = useState<string>(
    CHUNK_SETTING_RADIO_VALUES.auto
  )
  const [maxChunkOverLap, setMaxChunkOverLap] = useState(
    FORM_INITIAL_VALUE.maximum_chunk_length / 2
  )

  useEffect(() => {
    if (props.data) {
      setKnowledge(props.data)
    }
  }, [props.data])

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    action: fileStore.getUploadUrl(FileBelongsType.Document),
    fileList,
    onChange(info) {
      const { status } = info.file
      const fileIndex = fileList.findIndex((file) => file.uid === info.file.uid)
      if (fileIndex < 0) {
        setFileList([...fileList, info.file])
      } else {
        fileList[fileIndex] = info.file
        setFileList([...fileList])
      }
      if (status === 'uploading') {
      } else if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
    onRemove(e) {
      setFileList(fileList.filter((file) => file.uid !== e.uid))
    },
    beforeUpload(file) {
      const isLt50M = file.size / 1024 / 1024 < 50
      const allowedExtensions =
        /(\.pdf|\.docx|\.pptx|\.csv|\.txt|\.xlsx|\.json)$/i
      if (!isLt50M) {
        message.error('File must be smaller than 50MB!')
        return Upload.LIST_IGNORE
      }
      if (!allowedExtensions.test(file.name)) {
        message.error(
          'Invalid file type. Only PDF, DOCX, PPTX, CSV, TXT, XLSX, JSON files are allowed!'
        )
        return Upload.LIST_IGNORE
      }
      return true
    },
  }

  const hasFileUploading = useMemo(() => {
    return (
      fileList.filter((file) => ['uploading'].includes(file.status ?? ''))
        .length > 0
    )
  }, [fileList])

  const handleChunkSettingChange = useCallback((e: RadioChangeEvent) => {
    setChunkSettingRadioValue(e.target.value)
  }, [])

  const onFinish = useCallback(
    async (values: typeof FORM_INITIAL_VALUE) => {
      try {
        console.log('Received values of form: ', values)
        const files = fileList.filter(
          (file) =>
            file.status === 'done' &&
            !!file.response &&
            file.response[0]?.id !== undefined
        )
        if (files.length === 0) {
          message.error('Please upload a file first!')
          return
        }
        if (knowledge === undefined) {
          message.error('Knowledge not found!')
          return
        }
        files.forEach(async ({ response }) => {
          const file = response[0]
          console.log(`Processed file: ${file.filename}`)
          const resp = await v1KnowledgeDocumentCreate({
            knowledgeID: knowledge.id,
            fileID: file.id,
            chunkLength: values.maximum_chunk_length,
            chunkOverlap: values.chunk_over_lap,
            embeddingModelID: 1,
            replaceConsecutiveSpaces: values.replace_consecutive_spaces,
            replaceEmails: values.delete_all_email_addresses,
            replaceNewlines: values.replace_newlines,
            replaceTabs: values.replace_tabs,
            replaceURLs: values.delete_all_urls,
            segmentIdentifier: values.segment_identifier,
          })
          if (resp.status !== 200) {
            message.error(getMessageFromError(resp))
          }
        })
        setFileList([])
        props.onUploadSuccess?.()
      } catch (error) {
        message.error(getMessageFromError(error))
      }
    },
    [fileList, knowledge, props.onUploadSuccess]
  )

  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean }
  ) => (
    <>
      <Space>
        {label}
        {required ? (
          <Tag color="error">Required</Tag>
        ) : (
          <Tag color="warning">optional</Tag>
        )}
      </Space>
    </>
  )

  return (
    <Flex className={styles.documentUploadWrapper} gap={32} vertical>
      <div>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Supports uploading PDF, PPTX, DOCX, XLSX, CSV, JSON, and TXT files
            only. Single file limit: 50MB.
          </p>
        </Dragger>
      </div>
      <Flex className={styles.documentUploadSettings} vertical>
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          requiredMark={customizeRequiredMark}
          initialValues={FORM_INITIAL_VALUE}
          onFinish={onFinish}
        >
          <h2>Chunk settings</h2>
          <Radio.Group
            value={chunkSettingRadioValue}
            onChange={handleChunkSettingChange}
          >
            <RadioCard
              icon={<RocketOutlined />}
              title={'Automatic'}
              description={
                'Automatically set chunk and preprocessing rules. Unfamiliar users are recommended to select this.'
              }
              value={CHUNK_SETTING_RADIO_VALUES.auto}
              avatarSize={64}
              avatarStyle={{ background: 'cornflowerblue' }}
            ></RadioCard>
            <RadioCard
              icon={<ProfileOutlined />}
              title={'Custom'}
              description={
                'Customize chunks rules, chunks length, and preprocessing rules, etc.'
              }
              value={CHUNK_SETTING_RADIO_VALUES.custom}
              avatarSize={64}
              avatarStyle={{ background: 'lightcoral' }}
            >
              <Form.Item
                name="segment_identifier"
                label="Segment identifier"
                rules={[{ required: true }]}
              >
                <Input placeholder="Default value is \n" />
              </Form.Item>
              <Form.Item
                name="maximum_chunk_length"
                label="Maximum chunk length"
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={300}
                  placeholder={`Value range is 300 - 1200`}
                  onChange={(value) => setMaxChunkOverLap((value ?? 2) / 2)}
                />
              </Form.Item>
              <Form.Item
                name="chunk_over_lap"
                label="Chunk over lap"
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={1}
                  max={maxChunkOverLap}
                  placeholder={`Value range is 1 to ${maxChunkOverLap}`}
                />
              </Form.Item>
              <div className="text-processing-rules">
                <h3>Text processing rules</h3>
                <Form.Item
                  name="replace_consecutive_spaces"
                  valuePropName="checked"
                >
                  <Checkbox>Replace consecutive spaces</Checkbox>
                </Form.Item>
                <Form.Item name="replace_newlines" valuePropName="checked">
                  <Checkbox>Replace newlines</Checkbox>
                </Form.Item>
                <Form.Item name="replace_tabs" valuePropName="checked">
                  <Checkbox>Replace tabs</Checkbox>
                </Form.Item>
                <Form.Item name="delete_all_urls" valuePropName="checked">
                  <Checkbox>Delete all URLs</Checkbox>
                </Form.Item>
                <Form.Item
                  name="delete_all_email_addresses"
                  valuePropName="checked"
                >
                  <Checkbox>Delete all email addresses</Checkbox>
                </Form.Item>
              </div>
            </RadioCard>
          </Radio.Group>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              icon={<UploadOutlined />}
              disabled={hasFileUploading}
            >
              Upload & Process
            </Button>
          </Form.Item>
        </Form>
      </Flex>
    </Flex>
  )
}

export default DocumentUpload
