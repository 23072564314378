import { useEffect, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import {
  Tree,
  Input,
  Select,
  Button,
  FormInstance,
  Space,
  Form,
  Radio,
} from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import styles from './index.scss'
import {
  PluginModelsTreeNode,
  PluginPluginDataType,
  PluginPluginOutputFormType,
} from 'api/data-contracts'

export type DataTypes =
  | `${PluginPluginDataType}`
  | `Array<${PluginPluginDataType}>`

export type FormOutputType = {
  type: PluginPluginOutputFormType
  structure: PluginModelsTreeNode[]
}

const getDefaultValue: () => PluginModelsTreeNode = () => ({
  key: uuidV4(),
  name: '',
  required: false,
  type: PluginPluginDataType.String,
  children: [],
  deep: 1,
})

const baseFormItemName = 'pluginOutput'
const Output = (props: {
  form: FormInstance
  defaultStructureTreeData?: PluginModelsTreeNode[]
}) => {
  const { form, defaultStructureTreeData } = props
  const [treeData, setTreeData] = useState([getDefaultValue()])

  const typeSelectOptionList = [
    ...Object.values(PluginPluginDataType),
    ...Object.values(PluginPluginDataType).map((i) => `Array<${i}>`),
  ]

  const updateNode = (
    nodes: PluginModelsTreeNode[],
    opType: 'add' | 'delete' | 'select' | 'input' | 'required',
    key: string,
    value?: any
  ) => {
    nodes.forEach((node, index) => {
      if (node.key === key) {
        switch (opType) {
          case 'add': {
            const defaultVal = getDefaultValue()
            node.children?.push({ ...defaultVal, deep: node.deep + 1 })
            break
          }
          case 'delete': {
            nodes.splice(index, 1)
            break
          }
          case 'input': {
            node.name = value
            break
          }
          case 'select': {
            node.type = value
            if (
              node.type !== PluginPluginDataType.Object ||
              !/^Array<.*?>$/.test(node.type)
            )
              node.children = []
            break
          }
          case 'required': {
            node.required = value
            break
          }
        }
      } else if (node.children?.length) {
        updateNode(node.children, opType, key, value)
        return
      }
    })
  }

  const handleNameValueChange = (key: string, value: string) => {
    const newTreeData = [...treeData]
    updateNode(newTreeData, 'input', key, value)
    setTreeData(newTreeData)
  }

  const handleTypeValueChange = (key: string, value: string) => {
    const newTreeData = [...treeData]
    updateNode(newTreeData, 'select', key, value)
    setTreeData(newTreeData)
  }

  const handleOptionalValueChange = (key: string, value: boolean) => {
    const newTreeData = [...treeData]
    updateNode(newTreeData, 'required', key, value)
    setTreeData(newTreeData)
  }

  const handleAddNodeItem = (key: string) => {
    const newTreeData = [...treeData]
    updateNode(newTreeData, 'add', key)
    setTreeData(newTreeData)
  }

  const handleDeleteNodeItem = (key: string) => {
    const newTreeData = [...treeData]
    updateNode(newTreeData, 'delete', key)
    setTreeData(newTreeData)
  }

  const handleAddTreeItem = () => {
    setTreeData([...treeData, getDefaultValue()])
  }

  useEffect(() => {
    if (!defaultStructureTreeData) return
    setTreeData(defaultStructureTreeData)
  }, [defaultStructureTreeData])

  useEffect(() => {
    form.setFieldValue([baseFormItemName, 'structure'], treeData)
  }, [treeData])

  return (
    <div className={styles.outputWrapper}>
      <Form form={form} layout="vertical">
        <Form.Item
          name={[baseFormItemName, 'type']}
          label={'Type'}
          initialValue={'customized'}
        >
          <Radio.Group
            options={[
              { label: 'Customized', value: 'customized' },
              { label: 'Fixed', value: 'fixed' },
            ]}
          />
        </Form.Item>

        <Form.Item name={[baseFormItemName, 'structure']} label={'Structure'}>
          {/* Single Element in Form.Item */}
          <span>
            <Tree
              rootClassName={styles.outputTreeWrapper}
              treeData={treeData}
              autoExpandParent
              selectable={false}
              height={640}
              showLine
              titleRender={(node) => (
                <div className={styles.outputTreeItemWrapper}>
                  <Space.Compact block>
                    <Input
                      className={styles.outputTreeItemName}
                      onChange={(e) =>
                        handleNameValueChange(`${node.key}`, e.target.value)
                      }
                      defaultValue={node.name}
                    />
                    <Select
                      className={styles.outputTreeItemRequired}
                      options={[
                        { label: 'True', value: true },
                        { label: 'False', value: false },
                      ]}
                      defaultValue={node.required}
                      onChange={(value) =>
                        handleOptionalValueChange(`${node.key}`, value)
                      }
                    />
                    <Select
                      className={styles.outputTreeItemType}
                      options={typeSelectOptionList.map((i) => ({
                        label: i,
                        value: i,
                      }))}
                      defaultValue={node.type ?? PluginPluginDataType.String}
                      onChange={(value) =>
                        handleTypeValueChange(`${node.key}`, value)
                      }
                    />
                  </Space.Compact>

                  {(node.type === PluginPluginDataType.Object ||
                    /^Array<.*?>$/.test(node.type)) && (
                    <Button
                      style={{ width: '32px' }}
                      onClick={() => handleAddNodeItem(node.key)}
                    >
                      <PlusOutlined />
                    </Button>
                  )}
                  <Button
                    style={{ width: '32px' }}
                    onClick={() => handleDeleteNodeItem(node.key)}
                  >
                    <MinusOutlined />
                  </Button>
                </div>
              )}
            />

            <Button
              onClick={handleAddTreeItem}
              type="dashed"
              icon={<PlusOutlined />}
              className={styles.outputTreeAddBtn}
            >
              Add
            </Button>
          </span>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Output
