import { useEffect, useState } from 'react'
import { Flex, Modal, Skeleton, Space } from 'antd'
import { ReadOutlined } from '@ant-design/icons'
import ReactJson from 'react-json-view'
import { v1ChatConversationsWorkflowNodeInfosDetail } from 'api/Api'
import { ResponsesNodeInfoResponse } from 'api/data-contracts'

export interface DetailsModalProps {
  msgId: string
  nodeIndex: number
  open: boolean
  onCancel?: () => void
}

const DetailsModal: React.FC<DetailsModalProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [nodeInfos, setNodeInfos] = useState<ResponsesNodeInfoResponse[]>()

  useEffect(() => {
    if (!props.msgId) {
      return
    }

    // fetch details
    setLoading(true)
    v1ChatConversationsWorkflowNodeInfosDetail(props.msgId)
      .then((res) => {
        setNodeInfos(res.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [props.msgId])

  return (
    <Modal
      title={
        <Space>
          <ReadOutlined />
          Node Information Details
        </Space>
      }
      open={props.open}
      width="80%"
      footer={null}
      onCancel={() => props.onCancel?.()}
    >
      <Flex style={{ padding: '12px 0', overflow: 'auto' }}>
        {loading ? (
          <Skeleton active />
        ) : (
          <ReactJson src={nodeInfos?.[props.nodeIndex] ?? {}} />
        )}
      </Flex>
    </Modal>
  )
}

export default DetailsModal
