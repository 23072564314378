import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import type { InputRef, TableColumnType } from 'antd'
import { App, Button, Flex, Input, Popconfirm, Space } from 'antd'
import {
  v1KnowledgeDocumentDelete,
  v1KnowledgeDocumentListList,
  v1KnowledgeDocumentPartialUpdate,
} from 'api/Api'
import {
  DocumentMappingStatusDocumentMappingStatus,
  ResponsesKnowledgeDocumentResponse,
} from 'api/data-contracts'
import DeleteSvg from 'assets/images/delete.svg'
import EditSvg from 'assets/images/edit.svg'
import CustomTable from 'components/custom-table'
import { useExNavigate } from 'hooks/use-ex-navigate'
import { useIsTabActive } from 'hooks/use-is-tab-active'
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { getFileIcon, getMessageFromError } from 'utils/common'
import { formatDate, formatFileSize } from 'utils/filter'
import { KnowledgeCardProps } from '../knowledge-card'
import styles from './table.scss'

const getDocumentStatusIcon = (
  status: DocumentMappingStatusDocumentMappingStatus
) => {
  let icon = <></>
  switch (status) {
    case DocumentMappingStatusDocumentMappingStatus.Processed:
      icon = <CheckCircleOutlined />
      break
    case DocumentMappingStatusDocumentMappingStatus.Failed:
      icon = <ExclamationCircleOutlined />
      break
    case DocumentMappingStatusDocumentMappingStatus.Processing:
      icon = <LoadingOutlined />
      break
    case DocumentMappingStatusDocumentMappingStatus.Pending:
      icon = <ClockCircleOutlined />
      break
  }
  return icon
}

const EditableCell: React.FC<{
  editing: boolean
  loading: boolean
  dataIndex: string
  title: any
  inputType: 'text' | 'number' | 'date'
  record: ResponsesKnowledgeDocumentResponse
  children: React.ReactNode
  onSave: (value: string) => void
  onCancel: () => void
}> = ({
  editing,
  loading,
  dataIndex,
  title,
  inputType,
  record,
  children,
  onSave,
  onCancel,
  ...restProps
}) => {
    const [inputValue, setInputValue] = useState(record.documentName)
    return (
      <div {...restProps}>
        {editing ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Input
                size="small"
                style={{ flexGrow: 1, flexBasis: 0 }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onPressEnter={() => onSave(inputValue)}
              />
              <div
                style={{
                  flexShrink: 0,
                  display: 'flex',
                  gap: '0px',
                  width: '140px',
                }}
              >
                <Button
                  type="link"
                  onClick={() => onSave(inputValue)}
                  loading={loading}
                >
                  Save
                </Button>
                <Button type="link" onClick={onCancel} loading={loading}>
                  Cancel
                </Button>
              </div>
            </div>
          </>
        ) : (
          children
        )}
      </div>
    )
  }

const FileTable: ForwardRefRenderFunction<{ refresh: () => void }> = (
  props,
  ref
) => {
  const navigate = useExNavigate()
  const location = useLocation()
  const { message } = App.useApp()
  const [filename, setFilename] = useState('')
  const [curPage, setCurPage] = useState(1)
  const [curPageSize, setCurPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<ResponsesKnowledgeDocumentResponse[]>([])
  const [loading, setLoading] = useState(false)
  const [updatingID, setUpdatingID] = useState(0)
  const [deletingID, setDeletingID] = useState(0)
  const isTabActive = useIsTabActive()
  const knowledge = location.state.knowledge as KnowledgeCardProps['data']

  useImperativeHandle(
    ref,
    () => ({
      refresh: () => fetchData(curPage, curPageSize),
    }),
    [curPage, curPageSize]
  )

  const fetchData = async (page: number, size: number, loading = true) => {
    if (!knowledge) {
      message.error('Oops! No specific knowledge card.')
      return
    }
    if (loading) {
      setLoading(true)
    }
    try {
      const response = await v1KnowledgeDocumentListList({
        knowledgeID: knowledge.id,
        page,
        size,
      })
      setData(response.data.documents)
      setTotal(response.data.totalCount)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    if (loading) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(curPage, curPageSize)
  }, [curPage, curPageSize, filename])

  const handleUpdateData = (page = curPage, pageSize = curPageSize) => {
    if (page) setCurPage(page)
    if (pageSize) setCurPageSize(pageSize)
  }

  const needUpdateList = useMemo(() => {
    return (
      data.filter((document) =>
        [
          DocumentMappingStatusDocumentMappingStatus.Pending,
          DocumentMappingStatusDocumentMappingStatus.Processing,
        ].includes(document.status)
      ).length > 0
    )
  }, [data])

  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined

    if (needUpdateList && isTabActive) {
      interval = setInterval(() => {
        fetchData(curPage, curPageSize, false)
      }, 3000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [curPage, curPageSize, needUpdateList, isTabActive])

  const [editingKey, setEditingKey] = useState(0)
  const searchInput = useRef<InputRef>(null)
  const isEditing = (record: ResponsesKnowledgeDocumentResponse) =>
    record.id === editingKey

  const edit = (record: ResponsesKnowledgeDocumentResponse) => {
    setEditingKey(record.id)
  }

  const handleEditButtonClick = (
    record: ResponsesKnowledgeDocumentResponse
  ) => {
    navigate('/portal/knowledge/document/chunk', {
      state: { knowledge, document: record },
    })
  }

  const cancel = () => {
    setEditingKey(0)
  }

  const deleteFile = async (key: number) => {
    setDeletingID(key)
    try {
      await v1KnowledgeDocumentDelete({ id: key })
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setDeletingID(0)
    fetchData(curPage, curPageSize)
  }

  const save = async (key: number, name: string) => {
    setUpdatingID(key)
    try {
      await v1KnowledgeDocumentPartialUpdate({
        id: key,
        documentName: name,
      })
      setData(
        data.map((item) => {
          if (item.id === key) {
            return { ...item, documentName: name }
          }
          return item
        })
      )
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setUpdatingID(0)
    setEditingKey(0)
  }

  const getColumnSearchProps =
    (): TableColumnType<ResponsesKnowledgeDocumentResponse> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div
          style={{ padding: 8 }}
          onKeyDown={(e) => e.stopPropagation()}
          role="searchbox"
          tabIndex={0}
        >
          <Input
            ref={searchInput}
            placeholder={`Search Filename`}
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
              onClick={() => fetchData(1, curPageSize)}
            >
              Search
            </Button>
            <Button
              size="small"
              style={{ width: 90 }}
              onClick={() => {
                setFilename('')
                fetchData(1, curPageSize)
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close()
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
      ),
      render: (text) => text,
    })

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'filename',
      width: 320,
      editable: true,
      ...getColumnSearchProps(),
      render: (text: string, record: ResponsesKnowledgeDocumentResponse) => {
        const editable = isEditing(record)
        const icon = getFileIcon(record.file.path)
        const documentName = text ? text : record.documentName

        return editable ? (
          <EditableCell
            editing={editable}
            loading={record.id === updatingID}
            dataIndex="documentName"
            title="Filename"
            inputType="text"
            record={record}
            onSave={(newValue) => save(record.id, newValue)}
            onCancel={cancel}
            key={'cell_' + record.id}
          >
            <Flex gap={5}>
              {icon}
              {documentName}
            </Flex>
          </EditableCell>
        ) : (
          <Flex
            key={'edit_' + record.id}
            justify="space-between"
            align="center"
            gap={6}
          >
            <Flex gap={5}>
              {icon}
              {documentName}
            </Flex>
            <Button
              type="link"
              className={styles.fileNameEditButton}
              onClick={() => edit(record)}
            >
              Edit
            </Button>
          </Flex>
        )
      },
    },
    {
      title: 'File Size',
      render: (_: any, record: ResponsesKnowledgeDocumentResponse) =>
        formatFileSize(record.file.size),
    },
    {
      title: 'Status',
      render: (_: any, record: ResponsesKnowledgeDocumentResponse) => {
        return (
          <Flex
            className={`file-status status-${record.status}`}
            align="center"
            gap={6}
          >
            {getDocumentStatusIcon(record.status)}
            {record.status}
          </Flex>
        )
      },
    },
    {
      title: 'Created By',
      minWidth: 120,
      render: (_: any, record: ResponsesKnowledgeDocumentResponse) => (
        <span>{record.file.username}</span>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (text: string) => formatDate(text),
    },
    {
      title: 'Actions',
      width: 120,
      render: (_: any, record: ResponsesKnowledgeDocumentResponse) => {
        return (
          <Flex gap="6px">
            <Button
              key={'edit_' + record.id}
              icon={<EditSvg className={styles.tableIcon} />}
              type="text"
              disabled={[
                DocumentMappingStatusDocumentMappingStatus.Pending,
                DocumentMappingStatusDocumentMappingStatus.Processing,
                DocumentMappingStatusDocumentMappingStatus.Failed,
              ].includes(record.status)}
              onClick={() => handleEditButtonClick(record)}
            />
            <Popconfirm
              key={'popconfirm_' + record.id}
              title="Are you sure to delete the document?"
              onConfirm={() => deleteFile(record.id)}
            >
              <Button
                key={'delete_' + record.id}
                icon={<DeleteSvg className={styles.tableIcon} />}
                type="text"
                disabled={[
                  DocumentMappingStatusDocumentMappingStatus.Pending,
                  DocumentMappingStatusDocumentMappingStatus.Processing,
                ].includes(record.status)}
                loading={deletingID === record.id}
              />
            </Popconfirm>
          </Flex>
        )
      },
    },
  ]

  return (
    <CustomTable<ResponsesKnowledgeDocumentResponse>
      wrapperClassName={styles.fileTable}
      columns={columns}
      loading={loading}
      data={data}
      paginationOnChange={handleUpdateData}
      paginationProps={{
        total,
        align: 'center',
        current: curPage,
        pageSize: curPageSize,
      }}
      scroll={{
        scrollToFirstRowOnChange: true,
        x: true,
        // page margin + title + title margin + tab content + tab margin + pagination content + pagination margin + table title
        y: 'calc(100vh - 2*24px - 38px - 24px - 46px - 16px - 32px - 64px)',
      }}
    />
  )
}

export default forwardRef(FileTable)
