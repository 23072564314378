import { Layout, Menu } from 'antd'
import { useExNavigate } from 'hooks/use-ex-navigate'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import globalStore from 'stores/global'

import './header.css'
import styles from './index.scss'

export type HeaderTabsProps = {
  link?: string
  key: string
  label: React.ReactNode
  children?: HeaderTabsProps
}[]

interface HeaderBarProps {
  tabItems?: HeaderTabsProps
  defaultActiveKey?: string
}

const HeaderBar = (props: HeaderBarProps) => {
  const { tabItems, defaultActiveKey } = props
  const { Header } = Layout

  const navigate = useExNavigate()
  const [selectedKey, setSelectedKey] = useState(
    defaultActiveKey ?? globalStore.header.selectedKey
  )

  const menuItems = tabItems ?? globalStore.header.menuItems

  useEffect(() => {
    if (!menuItems?.length) return

    const pathArr = location.pathname.split('/')
    const item = menuItems.find((i) => {
      if (!i?.key) return false
      return pathArr.includes(`${i.key}`)
    })
    const curTab = `${item?.key ? item?.key : menuItems?.[0]?.key}`

    setSelectedKey(curTab)
    globalStore.setHeaderSelectedKey(curTab)
  }, [menuItems])

  const handleTabChange = (tab: string) => {
    const find = (items: HeaderTabsProps): HeaderTabsProps[0] | undefined => {
      for (const item of items) {
        if (item.key === tab) return item
        if (!item.children) continue
        const found = find(item.children)
        if (found) return found
      }
    }
    const curItem = find(menuItems)
    if (!curItem?.link) return
    setSelectedKey(tab)
    globalStore.setHeaderSelectedKey(tab)
    navigate(curItem.link)
  }

  return (
    <Header className="main-layout-header">
      {menuItems.length ? (
        <div className="sub-menu">
          <Menu
            mode="horizontal"
            selectedKeys={[selectedKey]}
            activeKey={selectedKey}
            items={menuItems}
            className={styles.headerTabsWrapper}
            onClick={(info) => handleTabChange(info.key)}
          />
        </div>
      ) : null}
    </Header>
  )
}

export default observer(HeaderBar)
