import { makeAutoObservable, observable } from 'mobx'
import dayjs from 'dayjs'

import apiConfig from 'services/api'
import {
  createApiRequest,
  createStreamingApiRequest,
  RequestData,
} from 'services/request'
import { getUserLanguage } from 'utils/common'
import { AxiosResponse } from 'axios'

interface Agent {
  id: number
  uuid: string
  title: string
  agentID: number
  userID: number
  organizationID: number
  createdAt: Date
  updatedAt: Date
  agentIconID: number
  agentIconPath: string
  agentName: string
  agentRole: string
  llmID: number
  llmName: string
  stopSequence: string
  systemPrompt: string
  temperature: number
  topP: number
}

interface ChatSession {
  id: number
  uuid: string
  title: string
  agentID: number
  userID: number
  organizationID: number
  createdAt: Date
  updatedAt: Date
}

interface SessionGroup {
  period: string
  sessions: ChatSession[]
}

interface Conversation {
  id: string
  sessionUUID: string
  userID: string
  organizationID: string
  content: string
  role: string
  tokens: number
  createdAt: string
  error: boolean
  isLoading: boolean
  currentNodeLabel: string
  currentNodeID: string
  startTime: string
  endTime: string
}
interface SendResponse {
  uuid: string
  title: string
  answer: string
  requestMessageID: string
  responseMessageID: string
  promptTokens: number
  completionTokens: number
  nodeID: string
  nodeLabel: string
  nodeType: string
  startTime: string
  endTime: string
  status: string
  errorMessage: string
  nodeStatus: {
    nodeID: string
    startTime: string
    endTime: string
    output: {
      text: {
        type: string
        value: string
      }
    }
  }
}

class ChatStore {
  groupedSessions: SessionGroup[] = []
  @observable conversations: Conversation[] = []
  currentSessionUUID: string | null = null
  agent: Agent | null = null

  constructor() {
    this.groupedSessions = [
      { period: 'Today', sessions: [] },
      { period: 'Yesterday', sessions: [] },
      { period: 'Previous 7 Days', sessions: [] },
      { period: 'Previous 30 Days', sessions: [] },
    ]
    makeAutoObservable(this)
  }

  getSessions(agentUUID: string): Promise<void> {
    const params: RequestData = {
      queryParams: {
        agent_uuid: agentUUID,
      },
    }
    return new Promise((resolve, reject) => {
      createApiRequest(apiConfig.chatSessionsRecent, params)
        .then((response) => {
          this.groupSessions(response.data)
          resolve()
        })
        .catch((error) => {
          reject(new Error(error))
        })
    })
  }

  clearConversations(): void {
    this.conversations = []
  }

  groupSessions(sessions: ChatSession[]): void {
    this.groupedSessions.forEach((group) => (group.sessions = []))
    const now = dayjs()

    sessions.forEach((session) => {
      const updatedAt = dayjs(session.updatedAt)

      if (updatedAt.isSame(now, 'day')) {
        this.groupedSessions
          .find((g) => g.period === 'Today')
          ?.sessions.push(session)
      } else if (updatedAt.isSame(now.subtract(1, 'day'), 'day')) {
        this.groupedSessions
          .find((g) => g.period === 'Yesterday')
          ?.sessions.push(session)
      } else if (updatedAt.isAfter(now.subtract(7, 'day'))) {
        this.groupedSessions
          .find((g) => g.period === 'Previous 7 Days')
          ?.sessions.push(session)
      } else if (updatedAt.isAfter(now.subtract(30, 'day'))) {
        this.groupedSessions
          .find((g) => g.period === 'Previous 30 Days')
          ?.sessions.push(session)
      }
    })
  }

  getConversations(sessionUUID: string, lastID?: string): Promise<void> {
    const params: RequestData = {
      queryParams: {
        session_uuid: sessionUUID,
        last_id: lastID ?? '',
      },
    }
    return new Promise((resolve, reject) => {
      createApiRequest(apiConfig.chatConversations, params)
        .then((response) => {
          const data = response.data
          if (lastID) {
            this.conversations = [...this.conversations, ...data]
          } else {
            this.conversations = data
          }
          this.currentSessionUUID = sessionUUID
          resolve()
        })
        .catch((error) => {
          reject(new Error(error))
        })
    })
  }

  getAgent(uuid: string): Promise<AxiosResponse['data']> {
    const params: RequestData = {
      queryParams: {
        uuid: uuid,
      },
    }
    return new Promise((resolve, reject) => {
      createApiRequest(apiConfig.agentGet, params)
        .then((response) => {
          this.agent = response.data
          resolve(response.data)
        })
        .catch((error) => {
          reject(new Error(error))
        })
    })
  }

  newSession(): void {
    this.currentSessionUUID = ''
    this.conversations = []
  }

  setCurrentSession(uuid: string): void {
    this.currentSessionUUID = uuid
  }
  async send(query: string) {
    let safeSessionUUID = this.currentSessionUUID ?? ''
    const tempConversation: Conversation = {
      id: '',
      sessionUUID: safeSessionUUID,
      userID: '',
      organizationID: '',
      content: query,
      role: 'user',
      tokens: 0,
      createdAt: dayjs().toISOString(),
      error: false,
      isLoading: false,
      currentNodeID: '',
      currentNodeLabel: '',
      startTime: '',
      endTime: '',
    }

    const newConversation: Conversation = {
      id: '',
      sessionUUID: safeSessionUUID,
      userID: '',
      organizationID: '',
      content: '',
      role: 'assistant',
      tokens: 0,
      createdAt: dayjs().toISOString(),
      error: false,
      isLoading: true,
      currentNodeID: '',
      currentNodeLabel: '',
      startTime: '',
      endTime: '',
    }

    this.conversations.push(tempConversation)
    this.conversations.push(newConversation)

    const params = {
      agentUUID: this.agent?.uuid ?? '',
      userPrompt: query,
      sessionUUID: safeSessionUUID,
      language: getUserLanguage(),
      documentID: '',
      imageID: '',
      audioID: '',
    }

    let session: ChatSession | undefined
    // let currentGroup: SessionGroup | undefined
    for (let group of this.groupedSessions) {
      session = group.sessions.find((s) => s.uuid === safeSessionUUID)
      if (session) {
        // currentGroup = group
        break
      }
    }

    try {
      await createStreamingApiRequest(apiConfig.chatSend, {
        body: params,
        onMessage: (parsedData: SendResponse) => {
          tempConversation.id = parsedData.requestMessageID
          tempConversation.tokens = parsedData.promptTokens
          if (parsedData.status !== 'completed') {
            this.conversations[this.conversations.length - 1].content +=
              parsedData.answer
          }
          this.conversations[this.conversations.length - 1].currentNodeID =
            parsedData.nodeID
          this.conversations[this.conversations.length - 1].currentNodeLabel =
            parsedData.nodeLabel
          this.conversations[this.conversations.length - 1].startTime =
            parsedData.startTime
          this.conversations[this.conversations.length - 1].endTime =
            parsedData.endTime
          if (!session) {
            session = {
              id: Date.now(),
              uuid: parsedData.uuid,
              title: parsedData.title,
              agentID: this.agent?.id ?? 0,
              userID: 0,
              organizationID: 0,
              createdAt: new Date(),
              updatedAt: new Date(),
            }
            this.currentSessionUUID = parsedData.uuid
            const todayGroup = this.groupedSessions.find(
              (g) => g.period === 'Today'
            )
            if (todayGroup) {
              todayGroup.sessions.unshift(session)
            } else {
              this.groupedSessions.unshift({
                period: 'Today',
                sessions: [session],
              })
            }
          } else {
            session.updatedAt = new Date()
            const todayGroup = this.groupedSessions.find(
              (g) => g.period === 'Today'
            )
            const sessionExistsInToday = todayGroup?.sessions.find(
              (s) => s.uuid === session?.uuid
            )
            if (!sessionExistsInToday) {
              this.groupedSessions.forEach((group) => {
                group.sessions = group.sessions.filter(
                  (s) => s.uuid !== session?.uuid
                )
              })
              if (todayGroup) {
                todayGroup.sessions.unshift(session)
              }
            }
          }
        },
        onError: (error) => {
          console.error('Error occurred:', error)
          this.conversations[this.conversations.length - 1].error = true
          this.conversations[this.conversations.length - 1].isLoading = false
        },
        onComplete: () => {
          this.conversations[this.conversations.length - 1].error = false
          this.conversations[this.conversations.length - 1].isLoading = false
        },
      })
    } catch (error) {
      console.error('Request failed:', error)
    }
  }

  // }

  // async retry(query: string): Promise<SendResponse> {
  //   this.conversations.pop()
  //   return this.send(query)
  // }
}

export default new ChatStore()
