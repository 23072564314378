import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react'
import { observer } from 'mobx-react'
import { Button, Popconfirm, App, Flex, Tag } from 'antd'
import EditSvg from 'assets/images/edit.svg'
import DeleteSvg from 'assets/images/delete.svg'
import { formatDate } from 'utils/filter'
import { getMessageFromError } from 'utils/common'
import { v1EvaluationMetricsListList, v1EvaluationMetricsDelete } from 'api/Api'
import { ResponsesMetricsResponse } from 'api/data-contracts'
import { useIsAdmin } from 'hooks/use-is-admin'
import { EvaluationTypes } from './metric-modal'
import CustomTable from 'components/custom-table'
import styles from './metric-table.scss'

export enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

interface MetricTableProps {
  onAction?: (type: ActionType, metric: ResponsesMetricsResponse) => void
}

export interface MetricTableRef {
  refresh: () => void
}

const MetricTable: ForwardRefRenderFunction<
  MetricTableRef,
  MetricTableProps
> = (props, ref) => {
  const { message } = App.useApp()
  const [curPage, setCurPage] = useState(1)
  const [curPageSize, setCurPageSize] = useState(20)
  const [data, setData] = useState<ResponsesMetricsResponse[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [deletingId, setDeletingId] = useState(0)
  const isAdmin = useIsAdmin()

  useImperativeHandle(
    ref,
    () => ({
      refresh: () => fetchData(curPage, curPageSize),
    }),
    [curPage, curPageSize]
  )

  const fetchData = async (page: number, size: number) => {
    setLoading(true)
    try {
      const response = await v1EvaluationMetricsListList({
        page,
        limit: size,
      })
      setData(response.data.data)
      setTotal(response.data.pagination.totalItems)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData(curPage, curPageSize)
  }, [curPage, curPageSize])

  const handleUpdateData = (page = curPage, pageSize = curPageSize) => {
    if (page) setCurPage(page)
    if (pageSize) setCurPageSize(pageSize)
  }

  const deleteMetric = async (key: number) => {
    setDeletingId(key)
    try {
      await v1EvaluationMetricsDelete({ id: key })
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setDeletingId(0)
    fetchData(curPage, curPageSize)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      editable: false,
      render: (text: string, record: ResponsesMetricsResponse) => {
        return <div key={'name_' + record.id}>{text}</div>
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: false,
      render: (text: string, record: ResponsesMetricsResponse) => {
        return <div key={'description' + record.id}>{text}</div>
      },
    },
    {
      title: 'Evaluation Type',
      dataIndex: 'evaluationType',
      minWidth: 160,
      render: (text: string, record: ResponsesMetricsResponse) => {
        return (
          <Tag
            key={'evaluation_type_' + record.id}
            className={styles.evaluationTypeTag}
          >
            {EvaluationTypes[text] ?? text}
          </Tag>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (text: string, record: ResponsesMetricsResponse) => (
        <div key={'created_at_' + record.id}>{formatDate(text)}</div>
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (text: string, record: ResponsesMetricsResponse) => (
        <div key={'updated_at_' + record.id}>{formatDate(text)}</div>
      ),
    },
    {
      title: 'Actions',
      render: (_: any, record: ResponsesMetricsResponse) => {
        return (
          <Flex key={'actions_' + record.id} gap="6px">
            <Button
              key={'edit_' + record.id}
              type="text"
              icon={<EditSvg className={styles.tableIcon} />}
              disabled={false}
              onClick={() => {
                props.onAction && props.onAction(ActionType.Edit, record)
              }}
            />
            <Popconfirm
              key={'popconfirm_' + record.id}
              title="Are you sure to delete the metric?"
              onConfirm={() => deleteMetric(record.id)}
            >
              <Button
                key={'delete_' + record.id}
                type="text"
                icon={<DeleteSvg className={styles.tableIcon} />}
                disabled={!isAdmin}
                loading={deletingId === record.id}
              />
            </Popconfirm>
          </Flex>
        )
      },
    },
  ]

  return (
    <CustomTable<ResponsesMetricsResponse>
      wrapperClassName={styles.metricsTable}
      columns={columns}
      data={data}
      loading={loading}
      paginationOnChange={handleUpdateData}
      paginationProps={{
        total,
        align: 'center',
        current: curPage,
        pageSize: curPageSize,
      }}
      scroll={{
        scrollToFirstRowOnChange: true,
        x: true,
        // page margin + title + title margin + tab content + tab margin + pagination content + pagination margin + table title
        y: 'calc(100vh - 2*24px - 38px - 24px - 46px - 16px - 32px - 32px)',
      }}
    />
  )
}

export default observer(forwardRef(MetricTable))
