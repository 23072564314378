import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Input, Space } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'

import WorkflowStore from 'stores/workflow'
import Section from '../../components/section'
import {
  CustomNodeProps,
  DataType,
  NodeData,
  InputField as InputFieldType,
  OutputField,
  InputType,
} from '../../model'
import TemplateEditor from '../../components/template-editor'
import { FlowContext } from '../../context'
import * as monaco from 'monaco-editor'
import { loader } from '@monaco-editor/react'
import NodeSidebar from '../../components/node-sidebar'
import { getQuery } from 'utils/common'
import { StudioPageQueryType } from 'views/portal/agent/agent-card'
import InputField from '../../components/input-field'
loader.config({ monaco })

const { TextArea } = Input

const EndNodeSidebar = () => {
  const { agentType } = getQuery<StudioPageQueryType>(location.search)
  const context = useContext(FlowContext)
  const selectedNode = WorkflowStore.selectedNode
  const nodeData = selectedNode?.data?.data

  const isSmartApi = useMemo(() => agentType === 'smart_api', [agentType])

  const handleChange = (value: string | Array<any> | undefined) => {
    handleNodeDataChange('answer', value || '')
  }

  const handleClose = () => {
    WorkflowStore.selectNode(null)
  }

  const handleNodeDataChange = (
    field: string,
    value: any,
    dataType: DataType = 'String'
  ) => {
    if (nodeData) {
      const currentNodeData = { ...selectedNode.data.data }
      let updatedInput = [...(currentNodeData.input || [])]
      let updatedOutput = [...(currentNodeData.output || [])]
      let updatedAnswer = []

      if (field === 'label' || field === 'description') {
        currentNodeData[field] = value
      } else if (field === 'input') {
        updatedInput = value
      } else if (field === 'output') {
        updatedOutput = value
      } else {
        const inputIndex = updatedInput.findIndex(
          (input) => input.name === field
        )
        if (inputIndex !== -1) {
          updatedInput[inputIndex] = { ...updatedInput[inputIndex], value }
        } else {
          const newInputField: InputFieldType = {
            name: field,
            type: 'input',
            dataType: dataType,
            value: value,
            reference: '',
          }
          updatedInput.push(newInputField)
        }
      }

      if (field === 'answer') {
        updatedAnswer = value
      }

      const updatedNodeData = {
        ...(selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          input: updatedInput,
          output: updatedOutput,
          answer: updatedAnswer,
        },
      }

      // Update using WorkflowStore.selectNode
      const updatedNode = {
        ...selectedNode,
        data: updatedNodeData,
      }
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)

      // Update using WorkflowStore.selectedNode.data.onChange
      if (selectedNode.data.onChange) {
        selectedNode.data.onChange(selectedNode.id, updatedNodeData)
      }
    }
  }

  const getInputValue = (name: string) => {
    const input = nodeData?.input?.find((i: InputFieldType) => i.name === name)
    return input ? input.value : undefined
  }

  const handleRemoveField = (index: number) => {
    const currentTypeField: InputFieldType[] = nodeData?.input ?? []

    const updatedFields = currentTypeField.filter((_, i: number) => i !== index)
    handleNodeDataChange('input', updatedFields)
  }

  const handleFieldChange = (index: number, key: string, value: any) => {
    const currentFields = nodeData?.input || []
    if (currentFields[index]) {
      Reflect.set(currentFields[index], key, value)
    }
    handleNodeDataChange('input', currentFields)
    handleNodeDataChange(
      'output',
      currentFields.map((item) => {
        return {
          name: item.name,
          type: item.dataType,
          description: '',
          required: false,
          children: [],
          expanded: false,
        }
      })
    )
  }

  const handleAddItemClick = () => {
    const oldItems = nodeData?.input ?? []
    const newItem: InputFieldType = {
      name: '',
      type: 'input',
      dataType: 'String',
      value: '',
      reference: '',
    }
    handleNodeDataChange('input', [...oldItems, newItem])
  }

  if (selectedNode?.type !== 'End') return null

  return (
    <NodeSidebar
      nodeType={'end'}
      onClose={handleClose}
      nodeData={nodeData}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={nodeData?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          placeholder="Enter node description"
          autoSize={{ minRows: 2, maxRows: 10 }}
        />
      </div>
      {isSmartApi ? (
        <Section title="Output">
          {nodeData?.input?.map((field: InputFieldType, index: number) => (
            <div key={index} style={{ marginBlockEnd: '8px' }}>
              <Space>
                <InputField
                  index={index}
                  item={field}
                  currentNodeId={selectedNode?.id || ''}
                  nodes={context?.nodes || []}
                  edges={context?.edges || []}
                  allowEditFieldName={true}
                  supportArray={false}
                  supportObject={false}
                  displayDataType={true}
                  displayFieldName={true}
                  handleFormChange={(_, key, value) =>
                    handleFieldChange(index, key, value)
                  }
                />
                <Button
                  icon={<MinusCircleOutlined />}
                  onClick={() => handleRemoveField(index)}
                />
              </Space>
            </div>
          ))}
          <Button
            icon={<PlusOutlined />}
            onClick={handleAddItemClick}
            type="dashed"
            block
          >
            Add Output
          </Button>
        </Section>
      ) : (
        <Section title="Output">
          <TemplateEditor
            value={getInputValue('answer') || ''}
            onChange={handleChange}
            currentNodeID={selectedNode?.id || ''}
            nodes={context?.nodes ?? []}
            edges={context?.edges ?? []}
          />
        </Section>
      )}
    </NodeSidebar>
  )
}

export default observer(EndNodeSidebar)
