import {
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  FunctionOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  App,
  Button,
  Flex,
  Input,
  InputNumber,
  List,
  Radio,
  Select,
  Space,
  Switch,
  Tree,
  Typography,
} from 'antd'
import DynamicForm, { Field } from 'components/dynamic-form'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import WorkflowStore from 'stores/workflow'
import { getQuery, isEmpty, validateRegex } from 'utils/common'
import { StudioPageQueryType } from 'views/portal/agent/agent-card'
import NodeSidebar from '../../components/node-sidebar'
import Section from '../../components/section'
import {
  CustomNodeProps,
  DataType,
  InputField as InputFieldType,
  NodeData,
  OutputField,
} from '../../model'
import renderTreeNodes from '../code/tree-node'

import styles from './index.scss'

const { TextArea } = Input
const { Text } = Typography
const MAX_LABELS = 10
const MAX_WHITELIST_ITEMS = 50
const MAX_KEYWORDS = 20

const CONSTANT_OUTPUT = {
  document: {
    name: 'document',
    type: 'Document' as DataType,
    description: 'Document',
    required: false,
    children: [],
  },
  image: {
    name: 'image',
    type: 'Image' as DataType,
    description: 'Image',
    required: false,
    children: [],
  },
  voice: {
    name: 'voice',
    type: 'Audio' as DataType,
    description: 'Voice',
    required: false,
    children: [],
  },
}

// 1 Byte = 8 Bit
// 1 KB = 1024 Bytes
// 1 MB = 1,024 KB
// 1 GB = 1,024 MB
type DataStorageUnitType = 'Bit' | 'KB' | 'MB' | 'GB'

type RegexKeywordType = {
  regex: string
  placeholder: string
  isValid: boolean
}

const StartNodeSidebar = () => {
  const { agentType } = getQuery<StudioPageQueryType>(location.search)
  const { message } = App.useApp()
  const selectedNode = WorkflowStore.selectedNode
  const nodeData = selectedNode?.data?.data
  const [labels, setLabels] = useState<string[]>([])
  const [keywordsBlacklist, setKeywordsBlacklist] = useState<
    RegexKeywordType[]
  >([])
  const [customVariables, setCustomVariables] = useState<OutputField[]>([])
  const [queryParameters, setQueryParameters] = useState<OutputField[]>([])
  const [bodyPayload, setBodyPayload] = useState<OutputField[]>([])
  const isSmartApi = useMemo(() => agentType === 'smart_api', [agentType])

  const dynamicFormRef = useRef(null)
  const oldOutputRef = useRef(nodeData?.output)
  const [fileSizeNumber, setFileSizeNumber] = useState<number>(0)
  const [fileSizeUnit, setFileSizeUnit] = useState<DataStorageUnitType>('KB')

  const updateOutputs = (
    inputs: InputFieldType[],
    outputs: OutputField[]
  ): OutputField[] => {
    const chatToDocument = inputs.find(
      (input) => input.name === 'chat_to_document'
    )?.value
    const chatToImage = inputs.find(
      (input) => input.name === 'chat_to_image'
    )?.value
    const voiceConversation = inputs.find(
      (input) => input.name === 'voice_conversation'
    )?.value

    let updatedOutputs = outputs.filter(
      (output) => !['document', 'image', 'voice'].includes(output.name)
    )

    if (chatToDocument) {
      updatedOutputs.push(CONSTANT_OUTPUT.document)
    }
    if (chatToImage) {
      updatedOutputs.push(CONSTANT_OUTPUT.image)
    }
    if (voiceConversation) {
      updatedOutputs.push(CONSTANT_OUTPUT.voice)
    }

    return updatedOutputs
  }

  const handleNodeDataChange = (
    field: string,
    value: any,
    dataType: DataType = 'String'
  ) => {
    if (!nodeData) return

    const currentNodeData = { ...nodeData }
    let updatedExtends = { ...currentNodeData.extends }
    let updatedInput = [...(currentNodeData.input || [])]
    let updatedOutput = [...(currentNodeData.output || [])]

    if (
      field === 'chat_to_document' ||
      field === 'chat_to_image' ||
      field === 'voice_conversation' ||
      field === 'file_size_limit' ||
      field === 'file_length_limit'
    ) {
      const inputIndex = updatedInput.findIndex((input) => input.name === field)
      if (inputIndex !== -1) {
        updatedInput[inputIndex] = { ...updatedInput[inputIndex], value }
      } else {
        const newInputField: InputFieldType = {
          name: field,
          type: 'input',
          dataType: dataType,
          value: value,
          reference: '',
        }
        updatedInput.push(newInputField)
      }

      // Update outputs based on input changes
      updatedOutput = updateOutputs(updatedInput, updatedOutput)
    } else if (field === 'output') {
      updatedOutput = value
    } else if (field === 'label' || field === 'description') {
      // Directly update label or description
      currentNodeData[field] = value
    } else if (field === 'input') {
      updatedInput = value
    } else if (field === 'extends') {
      updatedExtends = value
    } else {
      // Handle other input changes
      const inputIndex = updatedInput.findIndex((input) => input.name === field)
      if (inputIndex !== -1) {
        updatedInput[inputIndex] = { ...updatedInput[inputIndex], value }
      } else {
        const newInputField: InputFieldType = {
          name: field,
          type: 'input',
          dataType: dataType,
          value: value,
          reference: '',
        }
        updatedInput.push(newInputField)
      }
    }

    const updatedNodeData = {
      ...(selectedNode.data as NodeData),
      data: {
        ...currentNodeData,
        extends: updatedExtends,
        input: updatedInput,
        output: updatedOutput,
      },
    }

    const updatedNode = {
      ...selectedNode,
      data: updatedNodeData,
    }

    WorkflowStore.selectNode(updatedNode as CustomNodeProps)
    selectedNode.data.onChange?.(selectedNode.id, updatedNodeData)
  }

  function extractBracesContent(input: string): string[] {
    // 正则表达式匹配大括号内的内容
    const pattern = /\{([^}]+)\}/g
    const matches: string[] = []
    let match

    // 使用 exec 方法循环提取所有匹配的内容
    while ((match = pattern.exec(input)) !== null) {
      matches.push(match[1]) // match[1] 是捕获组中的内容
    }

    return matches
  }

  useEffect(() => {
    const powerNumber = ['KB', 'MB'].indexOf(fileSizeUnit) + 1
    const totalBytes = fileSizeNumber * Math.pow(1024, powerNumber)

    handleNodeDataChange('file_size_limit', totalBytes, 'Number')
  }, [fileSizeNumber, fileSizeUnit])

  useEffect(() => {
    setCustomVariables(nodeData?.extends?.customVariables || [])
  }, [nodeData?.extends?.customVariables])

  useEffect(() => {
    if (nodeData?.input) {
      const labelsInput = nodeData.input.find(
        (input: InputFieldType) => input.name === 'pii_labels'
      )
      if (labelsInput && Array.isArray(labelsInput.value)) {
        setLabels(labelsInput.value)
      }
      const keywordsBlacklistInput = nodeData.input.find(
        (input: InputFieldType) => input.name === 'regex_keywords_blacklist'
      )
      if (
        keywordsBlacklistInput &&
        Array.isArray(keywordsBlacklistInput.value)
      ) {
        setKeywordsBlacklist(keywordsBlacklistInput.value)
      }
    }

    const newOldOutput: OutputField[] =
      nodeData?.output?.filter(
        (item) =>
          !(
            nodeData?.extends?.customVariables?.some(
              (f: OutputField) => f.name === item.name
            ) ||
            nodeData?.extends?.queryParameters?.some(
              (f: OutputField) => f.name === item.name
            ) ||
            nodeData?.extends?.bodyPayload?.some(
              (f: OutputField) => f.name === item.name
            )
          )
      ) ?? []
    oldOutputRef.current = newOldOutput
  }, [selectedNode])

  useEffect(() => {
    if (agentType === 'smart_api') {
      const resourceNameStr = extractBracesContent(
        WorkflowStore.workflow?.agent.smartAPI?.resourceName ?? ''
      )
      setQueryParameters(
        resourceNameStr.map((i) => ({
          name: i,
          type: 'String',
          description: '',
          required: false,
          children: [],
        }))
      )
    }

    if (!nodeData) return
    const updatedOutputs = updateOutputs(
      nodeData.input || [],
      nodeData.output || []
    )
    handleNodeDataChange('output', updatedOutputs)
  }, [])

  const handleClose = () => {
    WorkflowStore.selectNode(null)
  }

  const getInputValue = (name: string) => {
    const input = nodeData?.input.find((i: InputFieldType) => i.name === name)
    return input ? input.value : undefined
  }

  const handleAddKeyword = () => {
    if (keywordsBlacklist.length >= MAX_KEYWORDS) {
      return
    }
    const newKeywords = [
      ...keywordsBlacklist,
      { regex: '', placeholder: '', isValid: false },
    ]
    setKeywordsBlacklist(newKeywords)
    handleNodeDataChange(
      'regex_keywords_blacklist',
      newKeywords,
      'Array<Object>'
    )
  }

  const handleRemoveKeyword = (index: number) => {
    const newKeywords = keywordsBlacklist.filter((_, i) => i !== index)
    setKeywordsBlacklist(newKeywords)
    handleNodeDataChange(
      'regex_keywords_blacklist',
      newKeywords,
      'Array<Object>'
    )
  }

  const handleKeywordChange = (
    index: number,
    key: keyof RegexKeywordType,
    value: string | boolean
  ) => {
    const newKeywords = [...keywordsBlacklist]
    if (key === 'isValid') {
      newKeywords[index][key] = value as boolean
    } else {
      newKeywords[index][key] = value as string
    }
    setKeywordsBlacklist(newKeywords)
    handleNodeDataChange(
      'regex_keywords_blacklist',
      newKeywords,
      'Array<Object>'
    )
  }

  const handleAddLabel = () => {
    if (labels.length < MAX_LABELS) {
      const newLabels = [...labels, '']
      setLabels(newLabels)
      handleNodeDataChange('pii_labels', newLabels, 'Array<String>')
    }
  }

  const handleRemoveLabel = (index: number) => {
    const newLabels = labels.filter((_, i) => i !== index)
    setLabels(newLabels)
    handleNodeDataChange('pii_labels', newLabels, 'Array<String>')
  }

  const handleLabelChange = (index: number, value: string) => {
    const newLabels = [...labels]
    newLabels[index] = value
    setLabels(newLabels)
    handleNodeDataChange('pii_labels', newLabels, 'Array<String>')
  }

  const handleCustomVariablesChange = (fields: Field[]) => {
    if (!oldOutputRef.current) {
      oldOutputRef.current = nodeData?.output
    }

    // Update customVariables state
    setCustomVariables(fields)

    if (nodeData) {
      const currentNodeData = { ...nodeData }

      // Update extends.customVariables
      const updatedExtends = {
        ...currentNodeData.extends,
        customVariables: fields,
      }

      // Update output
      const updatedOutputs = updateOutputs(currentNodeData.input || [], [
        ...(oldOutputRef.current || []),
        ...fields.filter((f) => f.name),
      ])

      const updatedNodeData = {
        ...(selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          extends: updatedExtends,
          output: updatedOutputs,
        },
      }

      const updatedNode = { ...selectedNode, data: updatedNodeData }

      // Update the node in the store
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      selectedNode.data.onChange?.(selectedNode.id, updatedNodeData)
    }
  }
  const handleQueryParametersChange = (fields: Field[]) => {
    if (!oldOutputRef.current) {
      oldOutputRef.current = nodeData?.output
    }

    setQueryParameters(fields)

    if (nodeData) {
      const currentNodeData = { ...nodeData }

      // Update extends.customVariables
      const updatedExtends = {
        ...currentNodeData.extends,
        queryParameters: fields,
      }

      // Update output
      const updatedOutputs = updateOutputs(currentNodeData.input || [], [
        ...(oldOutputRef.current || []),
        ...currentNodeData.extends.bodyPayload,
        ...fields.filter((f) => f.name),
      ])

      const updatedNodeData = {
        ...(selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          extends: updatedExtends,
          output: updatedOutputs,
        },
      }

      const updatedNode = { ...selectedNode, data: updatedNodeData }

      // Update the node in the store
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      selectedNode.data.onChange?.(selectedNode.id, updatedNodeData)
    }
  }
  const handleBodyPayloadChange = (fields: Field[]) => {
    if (!oldOutputRef.current) {
      oldOutputRef.current = nodeData?.output
    }

    setBodyPayload(fields)

    if (nodeData) {
      const currentNodeData = { ...nodeData }

      // Update extends.customVariables
      const updatedExtends = {
        ...currentNodeData.extends,
        bodyPayload: fields,
      }

      // Update output
      const updatedOutputs = updateOutputs(currentNodeData.input || [], [
        ...(oldOutputRef.current || []),
        ...currentNodeData.extends.queryParameters,
        ...fields.filter((f) => f.name),
      ])

      const updatedNodeData = {
        ...(selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          extends: updatedExtends,
          output: updatedOutputs,
        },
      }

      const updatedNode = { ...selectedNode, data: updatedNodeData }

      // Update the node in the store
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      selectedNode.data.onChange?.(selectedNode.id, updatedNodeData)
    }
  }

  const handleBodyPayloadTypeChange = (type: string) => {
    if (nodeData) {
      const currentNodeData = { ...nodeData }

      // Update extends.bodyPayload
      const updatedExtends = {
        ...currentNodeData.extends,
        bodyPayloadType: type,
      }

      const updatedNodeData = {
        ...(selectedNode.data as NodeData),
        data: { ...currentNodeData, extends: updatedExtends },
      }

      const updatedNode = { ...selectedNode, data: updatedNodeData }

      // Update the node in the store
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      selectedNode.data.onChange?.(selectedNode.id, updatedNodeData)
    }
  }
  const handleWhitelistAdd = () => {
    const currentWhitelist = getInputValue('pii_whitelist') || []
    if (currentWhitelist.length < MAX_WHITELIST_ITEMS) {
      handleNodeDataChange(
        'pii_whitelist',
        [...currentWhitelist, ''],
        'Array<String>'
      )
    } else {
      message.warning(
        `You can add a maximum of ${MAX_WHITELIST_ITEMS} whitelist.`
      )
    }
  }

  const handleWhitelistRemove = (index: number) => {
    const currentWhitelist = getInputValue('pii_whitelist') || []
    handleNodeDataChange(
      'pii_whitelist',
      currentWhitelist.filter((_: string, i: number) => i !== index),
      'Array<String>'
    )
  }

  const handleWhitelistChange = (index: number, value: string) => {
    const currentWhitelist = getInputValue('pii_whitelist') || []
    const newLabels = [...currentWhitelist]
    newLabels[index] = value
    handleNodeDataChange('pii_whitelist', newLabels, 'Array<String>')
  }

  if (selectedNode?.type !== 'Start') return null

  const chatToDocumentEnabled = getInputValue('chat_to_document')

  return (
    <NodeSidebar
      nodeType={'start'}
      onClose={handleClose}
      nodeData={nodeData}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={nodeData?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />

        {agentType === 'smart_api' && (
          <div>
            Resource Name:
            {WorkflowStore.workflow?.agent.smartAPI?.resourceName}
          </div>
        )}
      </div>

      {!isSmartApi && (
        <>
          <Section title="Settings">
            <div className="setting-item">
              <span>Chat to Document</span>
              <Switch
                checked={getInputValue('chat_to_document')}
                onChange={(checked) =>
                  handleNodeDataChange('chat_to_document', checked, 'Boolean')
                }
              />
            </div>
            {getInputValue('chat_to_document') && (
              <div className="setting-item">
                <span>Max Document Size</span>
                <div
                  className={styles.startNodeSidebarSettingsDocumentMaxFileSize}
                >
                  <Input
                    defaultValue={getInputValue('file_size_limit') / 1024}
                    type="number"
                    min={0}
                    addonAfter={
                      <Select
                        defaultValue={
                          'KB'
                          // getInputValue('file_size_limit') > (1024 ^ 3)
                          //   ? 'MB'
                          //   : 'KB'
                        }
                        onChange={setFileSizeUnit}
                      >
                        <Select.Option value="KB">KB</Select.Option>
                        <Select.Option value="MB">MB</Select.Option>
                      </Select>
                    }
                    onChange={(e) => setFileSizeNumber(Number(e.target.value))}
                  />
                </div>
              </div>
            )}

            {getInputValue('chat_to_document') && (
              <div className="setting-item">
                <span>Max Length Of Characters</span>
                <div
                  className={
                    styles.startNodeSidebarSettingsDocumentMaxFileCharacters
                  }
                >
                  <Input
                    defaultValue={getInputValue('file_length_limit')}
                    type="number"
                    min={0}
                    onChange={(e) =>
                      handleNodeDataChange(
                        'file_length_limit',
                        Number(e.target.value),
                        'Number'
                      )
                    }
                  />
                </div>
              </div>
            )}

            <div className="setting-item">
              <span>Chat to Image</span>
              <Switch
                checked={getInputValue('chat_to_image')}
                onChange={(checked) =>
                  handleNodeDataChange('chat_to_image', checked, 'Boolean')
                }
              />
            </div>
            <div className="setting-item">
              <span>Voice Conversation</span>
              <Switch
                checked={getInputValue('voice_conversation')}
                onChange={(checked) =>
                  handleNodeDataChange('voice_conversation', checked, 'Boolean')
                }
              />
            </div>
          </Section>

          <Section title="Custom Variables">
            <DynamicForm
              ref={dynamicFormRef}
              maxLevel={3}
              allowObject={false}
              allowArray={true}
              value={customVariables}
              onChange={handleCustomVariablesChange}
              size="small"
            />
          </Section>
          <Section title="PII Filter">
            <div className="setting-item">
              <span>Enable PII Filter</span>
              <Switch
                checked={getInputValue('pii_enabled')}
                onChange={(checked) =>
                  handleNodeDataChange('pii_enabled', checked, 'Boolean')
                }
              />
            </div>

            {getInputValue('pii_enabled') && (
              <>
                {chatToDocumentEnabled && (
                  <div className="setting-item">
                    <span>Enable for documents</span>
                    <Switch
                      checked={getInputValue('pii_for_documents_enabled')}
                      onChange={(checked) =>
                        handleNodeDataChange(
                          'pii_for_documents_enabled',
                          checked,
                          'Boolean'
                        )
                      }
                    />
                  </div>
                )}
                <div className="setting-item">
                  <span className="label">Placeholder</span>
                  <div className="element">
                    <Input
                      value={getInputValue('pii_placeholder')}
                      onChange={(e) =>
                        handleNodeDataChange(
                          'pii_placeholder',
                          e.target.value,
                          'String'
                        )
                      }
                      placeholder="Enter placeholder (default: ***)"
                      style={{ width: '205px' }}
                    />
                  </div>
                </div>
                <div className="setting-item">
                  <span className="label">Threshold</span>
                  <div className="element">
                    <InputNumber
                      value={getInputValue('pii_threshold')}
                      onChange={(value) =>
                        handleNodeDataChange('pii_threshold', value, 'Number')
                      }
                      min={0}
                      max={1}
                      step={0.1}
                      style={{ width: '205px' }}
                    />
                  </div>
                </div>
                <div className="template-editor-item">
                  <span className="label">
                    Labels ({(getInputValue('pii_labels') || []).length}/
                    {MAX_LABELS})
                  </span>
                  <div className="element" style={{ maxWidth: '400px' }}>
                    {(getInputValue('pii_labels') || []).map(
                      (label: string, index: number) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: '8px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Input
                            value={label}
                            onChange={(e) =>
                              handleLabelChange(index, e.target.value)
                            }
                            className={'styles'}
                            style={{ flexGrow: 1, marginRight: '8px', flex: 1 }}
                          />
                          <Button
                            icon={<MinusCircleOutlined />}
                            onClick={() => handleRemoveLabel(index)}
                          />
                        </div>
                      )
                    )}
                    <div style={{ marginTop: '8px' }}>
                      {(getInputValue('pii_labels') || []).length <
                        MAX_LABELS && (
                          <Button
                            icon={<PlusOutlined />}
                            onClick={handleAddLabel}
                            type="dashed"
                            style={{ width: '100%' }}
                          >
                            Add Label
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
                <div className="template-editor-item">
                  <span className="label">
                    Whitelist ({(getInputValue('pii_whitelist') || []).length}/
                    {MAX_WHITELIST_ITEMS})
                  </span>
                  <div className="element" style={{ maxWidth: '400px' }}>
                    {(getInputValue('pii_whitelist') || []).map(
                      (label: string, index: number) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: '8px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Input
                            value={label}
                            onChange={(e) =>
                              handleWhitelistChange(index, e.target.value)
                            }
                            style={{ flexGrow: 1, marginRight: '8px' }}
                          />
                          <Button
                            icon={<MinusCircleOutlined />}
                            onClick={() => handleWhitelistRemove(index)}
                          />
                        </div>
                      )
                    )}
                    <div style={{ marginTop: '8px' }}>
                      {(getInputValue('pii_whitelist') || []).length >=
                        MAX_WHITELIST_ITEMS ? null : (
                        <Button
                          icon={<PlusOutlined />}
                          onClick={handleWhitelistAdd}
                          type="dashed"
                          style={{ width: '100%' }}
                        >
                          Add Whitelist
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </Section>
          <Section title="Regex Filter">
            <div style={{ margin: '12px 0' }}>
              {chatToDocumentEnabled && (
                <div className="setting-item">
                  <span>Enable for documents</span>
                  <Switch
                    checked={getInputValue('regex_for_documents_enabled')}
                    onChange={(checked) =>
                      handleNodeDataChange(
                        'regex_for_documents_enabled',
                        checked,
                        'Boolean'
                      )
                    }
                  />
                </div>
              )}
            </div>
            <div className="template-editor-item">
              <span className="label">
                Regex Items ({keywordsBlacklist.length}/{MAX_KEYWORDS})
              </span>
              <Flex
                className="element"
                gap="16px"
                vertical
                style={{ marginTop: '8px' }}
              >
                {keywordsBlacklist.map((item, index) => (
                  <Flex
                    className="regex-keyword-item"
                    key={index}
                    vertical
                    gap="6px"
                  >
                    <Flex gap="8px">
                      <span className="label" style={{ width: '100px' }}>Regex</span>
                      <Input
                        value={item.regex}
                        onChange={(e) => {
                          const validRet = validateRegex(e.target.value)
                          handleKeywordChange(index, 'isValid', validRet.valid)
                          handleKeywordChange(index, 'regex', e.target.value)
                        }}
                      />
                    </Flex>
                    <Flex
                      gap="8px"
                      style={{
                        display:
                          item.isValid || isEmpty(item.regex)
                            ? 'none'
                            : undefined,
                      }}
                    >
                      <span className="label" style={{ width: '76px' }}></span>
                      <Text type="danger">
                        <Space>
                          <ExclamationCircleOutlined />
                          Invalid regular expression.
                        </Space>
                      </Text>
                    </Flex>
                    <Flex gap="8px">
                      <span className="label" style={{ width: '100px' }}>Placeholder</span>
                      <Input
                        value={item.placeholder}
                        onChange={(e) =>
                          handleKeywordChange(
                            index,
                            'placeholder',
                            e.target.value
                          )
                        }
                      />
                    </Flex>
                    <Flex gap="8px">
                      <span className="label" style={{ width: '76px' }}></span>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveKeyword(index)}
                      />
                    </Flex>
                  </Flex>
                ))}
                <div style={{ marginTop: '8px' }}>
                  {keywordsBlacklist.length < MAX_KEYWORDS && (
                    <Button
                      icon={<PlusOutlined />}
                      onClick={handleAddKeyword}
                      type="dashed"
                      style={{ width: '100%' }}
                    >
                      Add Keyword
                    </Button>
                  )}
                </div>
              </Flex>
            </div>
          </Section>
        </>
      )}

      {isSmartApi ? (
        <>
          <Section title={'Query Parameters'}>
            <DynamicForm
              ref={dynamicFormRef}
              maxLevel={3}
              allowObject={false}
              allowArray={true}
              value={queryParameters}
              onChange={handleQueryParametersChange}
              size="small"
            />
          </Section>
          {['POST', 'PUT', 'PATCH'].includes(
            WorkflowStore.workflow?.agent.smartAPI?.method ?? ''
          ) && (
              <Section title={'Body Payload'}>
                <Radio.Group
                  defaultValue={'form-data'}
                  options={[
                    { value: 'form-data', label: 'form-data' },
                    { value: 'json', label: 'json' },
                  ]}
                  onChange={(e) => handleBodyPayloadTypeChange(e.target.value)}
                />

                <DynamicForm
                  ref={dynamicFormRef}
                  maxLevel={3}
                  allowObject={false}
                  allowArray={true}
                  value={bodyPayload}
                  onChange={handleBodyPayloadChange}
                  size="small"
                />
              </Section>
            )}
          <Section title="Output Variables">
            <Tree defaultExpandAll showLine switcherIcon={<DownOutlined />}>
              {renderTreeNodes(nodeData?.output || [])}
            </Tree>
          </Section>
        </>
      ) : (
        <Section title="Output Variables" defaultCollapsed={true}>
          <List
            dataSource={nodeData?.output}
            renderItem={(item: OutputField) => (
              <List.Item>
                <Space>
                  <FunctionOutlined />
                  <Text>{item.name}</Text>
                </Space>
                <Text type="secondary">{item.type}</Text>
              </List.Item>
            )}
          />
        </Section>
      )}
    </NodeSidebar>
  )
}

export default observer(StartNodeSidebar)
