import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Form, Input, Modal, Select, Switch, Tree, TreeProps } from 'antd'
import { MailOutlined, UserOutlined } from '@ant-design/icons'
import userStore from 'stores/user'
import roleStore from 'stores/role'

import { RolesDataType } from './index'
import { UserStatusOptions } from 'stores/models/user'
import PasswordFormItem, {
  PasswordFormItemRefProps,
} from 'components/password-form-item'
import {
  v1UserCreateCreate,
  v1UserResetPasswordCreate,
  v1UserUpdateNamePartialUpdate,
  v1UserUpdateEmailPartialUpdate,
  v1UserUpdateRolePartialUpdate,
  v1UserUpdateStatusPartialUpdate,
  v1RoleUpdatePartialUpdate,
  v1RoleCreateCreate,
} from 'api/Api'
import { ResponsesPermissionResponse } from 'api/data-contracts'

export type RolesModalProps = {
  type: 'edit' | 'create'
  open: boolean
  onOpenChange: (open: boolean) => void
  updateData?: () => void
  data?: RolesDataType
  permissionData?: ResponsesPermissionResponse[]
}

type FormType = Pick<RolesDataType, 'name' | 'owner' | 'permissions'>

const PermissionTree = (props: any) => {
  const { onChange } = props
  const handleValueChange = (ids: string[]) => onChange(ids.join(','))
  return <Tree checkable {...props} onCheck={handleValueChange} />
}

const RolesModal = (props: RolesModalProps) => {
  const { type, open, data, permissionData, onOpenChange, updateData } = props
  const [form] = Form.useForm<FormType>()
  const [isModalOpen, setIsModalOpen] = useState(open)
  const passwordFormItemRef = useRef<PasswordFormItemRefProps>(null)
  const isAdmin = useMemo(
    () => userStore.loginUser?.roleName.toLowerCase() === 'admin',
    [userStore.loginUser]
  )

  const permissionTreeData = useMemo(() => {
    if (!permissionData) return []
    const transferFn = (
      data: ResponsesPermissionResponse
    ): Record<any, any> => {
      const { name, identity, subs } = data
      return !subs?.length
        ? { title: name, key: identity }
        : { title: name, key: identity, children: subs.map(transferFn) }
    }
    return permissionData.map(transferFn) ?? []
  }, [permissionData])

  const handleOk = async () => {
    const values = await form.validateFields()

    if (type === 'edit' && data) {
      const updateResp = await v1RoleUpdatePartialUpdate({
        id: Number(data.key),
        ...values,
      })
      if (!updateResp) return
    } else {
      const createResp = await v1RoleCreateCreate(values)
      if (!createResp) return
    }

    onOpenChange(false)
    updateData?.()
  }
  const handleCancel = () => {
    onOpenChange(false)
  }

  useEffect(() => {
    setIsModalOpen(open)
    form.resetFields()
    passwordFormItemRef.current?.reset()

    if (type === 'edit' && data) {
      form.setFieldsValue(data)
    }
  }, [props])

  return (
    <Modal
      title={`${type === 'create' ? 'Create' : 'Edit'} Role`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={form} autoComplete="off">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input a name!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Name" />
        </Form.Item>
        <Form.Item name="owner" label="Owner" initialValue={false}>
          <Switch />
        </Form.Item>
        <Form.Item noStyle dependencies={['owner']}>
          {(fields) => {
            const isOwner = fields.getFieldValue('owner')
            return (
              !isOwner && (
                <Form.Item
                  name="permissions"
                  label="Permissions"
                  rules={[{ required: true, message: '' }]}
                >
                  <PermissionTree checkable treeData={permissionTreeData} />
                </Form.Item>
              )
            )
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(RolesModal)
