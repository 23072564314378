/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { baseRequest } from '../utils/axios'
import {
  GenieCoreAppHandlersAgentResponsesPublishChannelResponse,
  GenieCoreAppHandlersChatRequestsAddFeedbackRequest,
  GenieCoreAppHandlersChatRequestsLikeUnlikeRequest,
  GenieCoreAppHandlersChatRequestsSendRequest,
  GenieCoreAppHandlersChatResponsesChatConversationResponse,
  GenieCoreAppHandlersChatResponsesChatSessionResponse,
  GenieCoreAppHandlersChatResponsesDocumentResponse,
  GenieCoreAppHandlersChatResponsesSuccessfulResponseMessage,
  GenieCoreAppHandlersDocumentResponsesDocumentResponse,
  GenieCoreAppHandlersFileResponsesFileResponse,
  RequestsAddDocumentChunkRequest,
  RequestsChangePasswordRequest,
  RequestsChangePasswordWithSessionIDRequest,
  RequestsChatSessionUpdateTitleRequest,
  RequestsCreateAPIRequest,
  RequestsCreateAgentRequest,
  RequestsCreateDocumentRequest,
  RequestsCreateEvaluationMetricRequest,
  RequestsCreateIntentRequest,
  RequestsCreateKnowledgeRequest,
  RequestsCreateOrganizationRequest,
  RequestsCreatePermissionRequest,
  RequestsCreatePublishChannelRequest,
  RequestsCreateQuestionRequest,
  RequestsCreateRoleRequest,
  RequestsCreateUserRequest,
  RequestsEvaluationRequest,
  RequestsLockRequest,
  RequestsLoginRequest,
  RequestsPluginCategoryRequest,
  RequestsPluginRequest,
  RequestsPluginWithFormRequest,
  RequestsPopulateConversationStatisticsRequest,
  RequestsResendEmailForUserCreationRequest,
  RequestsResetPasswordRequest,
  RequestsRestoreRequest,
  RequestsSavePluginFormRequest,
  RequestsSaveRequest,
  RequestsSetMainChannelRequest,
  RequestsSetup2FARequest,
  RequestsSetup2FAWithSessionRequest,
  RequestsSmartAPIUpdateStatus,
  RequestsUpdateAPIRequest,
  RequestsUpdateAgentRequest,
  RequestsUpdateAvatarRequest,
  RequestsUpdateDocumentChunkRequest,
  RequestsUpdateDocumentChunkStatusRequest,
  RequestsUpdateDocumentRequest,
  RequestsUpdateEmailRequest,
  RequestsUpdateEvaluationMetricRequest,
  RequestsUpdateIntentRequest,
  RequestsUpdateKnowledgeRequest,
  RequestsUpdateOrganizationExpireDateRequest,
  RequestsUpdateOrganizationLanguageCodeRequest,
  RequestsUpdateOrganizationNameRequest,
  RequestsUpdateOrganizationRequest,
  RequestsUpdateOrganizationRequired2FARequest,
  RequestsUpdateOrganizationStatusRequest,
  RequestsUpdateOrganizationTimezoneRequest,
  RequestsUpdatePermissionRequest,
  RequestsUpdatePublishChannelRequest,
  RequestsUpdateQuestionRequest,
  RequestsUpdateRoleRequest,
  RequestsUpdateTimezoneRequest,
  RequestsUpdateUserNameRequest,
  RequestsUpdateUserRoleRequest,
  RequestsUpdateUserStatusRequest,
  RequestsValidate2FARequest,
  RequestsValidate2FASetupSessionRequest,
  ResponsesAPIListResponse,
  ResponsesAPIResponse,
  ResponsesAgentListResponse,
  ResponsesAgentResponse,
  ResponsesAllLocksResponse,
  ResponsesAudioResponse,
  ResponsesConversationStatsWithDetailResponse,
  ResponsesEmbeddingModelResponse,
  ResponsesEvaluationAPIResponse,
  ResponsesIntentListResponse,
  ResponsesIntentResponse,
  ResponsesJobAPIResponse,
  ResponsesKnowledgeDocumentChunkListResponse,
  ResponsesKnowledgeDocumentResponse,
  ResponsesKnowledgeListResponse,
  ResponsesKnowledgeMappingListResponse,
  ResponsesKnowledgeResponse,
  ResponsesLLMResponse,
  ResponsesListEvaluationTypesResponse,
  ResponsesLockResponse,
  ResponsesLoginResponse,
  ResponsesMetricsResponse,
  ResponsesNodeInfoResponse,
  ResponsesOrganizationListResponse,
  ResponsesOrganizationResponse,
  ResponsesPaginatedChatConversationResponse,
  ResponsesPaginatedChatSessionResponse,
  ResponsesPaginatedEvaluationAPIResponse,
  ResponsesPaginatedEvaluationMetricResponse,
  ResponsesPaginatedFileResponse,
  ResponsesPaginatedPluginCategoryResponse,
  ResponsesPaginatedPluginResponse,
  ResponsesPaginatedQuestionResponse,
  ResponsesPaginatedTemplateResponse,
  ResponsesPaginatedWorkflowResponse,
  ResponsesPermissionResponse,
  ResponsesPermissionTreeResponse,
  ResponsesPluginCategoryResponse,
  ResponsesPluginFormResponse,
  ResponsesPluginResponse,
  ResponsesPluginWithFormResponse,
  ResponsesPublishChannelListResponse,
  ResponsesQuestionResponse,
  ResponsesRerankModelResponse,
  ResponsesRoleListResponse,
  ResponsesRoleResponse,
  ResponsesSettingResponse,
  ResponsesSmartAPIResponse,
  ResponsesTemplateResponse,
  ResponsesUpdateAvatarResponse,
  ResponsesUpdateEmailResponse,
  ResponsesUpdateTimezoneResponse,
  ResponsesUpdateUserRoleResponse,
  ResponsesUploadDocumentResponse,
  ResponsesUserListResponse,
  ResponsesUserResponse,
  ResponsesVersionResponse,
  ResponsesWorkflowResponse,
  ServicesRouteInfo,
} from './data-contracts'
import { ContentType, RequestParams } from './http-client'

/**
 * @description Retrieves an agent by its ID using query parameter.
 *
 * @tags Agent
 * @name V1AgentList
 * @summary Get agent by UUID
 * @request GET:/api/v1/agent
 */
export const v1AgentList = (
  query: {
    /** Agent UUID */
    uuid: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAgentResponse>({
    url: `/api/v1/agent`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new agent with provided details.
 *
 * @tags Agent
 * @name V1AgentCreateCreate
 * @summary Create agent
 * @request POST:/api/v1/agent/create
 */
export const v1AgentCreateCreate = (request: RequestsCreateAgentRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesAgentResponse>({
    url: `/api/v1/agent/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete an agent based on the query parameter ID.
 *
 * @tags Agent
 * @name V1AgentDeleteDelete
 * @summary Delete agent
 * @request DELETE:/api/v1/agent/delete
 */
export const v1AgentDeleteDelete = (
  query: {
    /** Agent ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/agent/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of agents based on pagination parameters.
 *
 * @tags Agent
 * @name V1AgentListList
 * @summary List agents
 * @request GET:/api/v1/agent/list
 */
export const v1AgentListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAgentListResponse>({
    url: `/api/v1/agent/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates an existing agent with provided details.
 *
 * @tags Agent
 * @name V1AgentUpdatePartialUpdate
 * @summary Update agent
 * @request PATCH:/api/v1/agent/update
 */
export const v1AgentUpdatePartialUpdate = (request: RequestsUpdateAgentRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesAgentResponse>({
    url: `/api/v1/agent/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new API with provided details.
 *
 * @tags API
 * @name V1ApiCreateCreate
 * @summary Create API
 * @request POST:/api/v1/api/create
 */
export const v1ApiCreateCreate = (request: RequestsCreateAPIRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesAPIResponse>({
    url: `/api/v1/api/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an API by its ID.
 *
 * @tags API
 * @name V1ApiDeleteDelete
 * @summary Delete API
 * @request DELETE:/api/v1/api/delete
 */
export const v1ApiDeleteDelete = (
  query: {
    /** API ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/api/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of APIs based on pagination parameters.
 *
 * @tags API
 * @name V1ApiListList
 * @summary List APIs
 * @request GET:/api/v1/api/list
 */
export const v1ApiListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAPIListResponse>({
    url: `/api/v1/api/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates details of an existing API.
 *
 * @tags API
 * @name V1ApiUpdatePartialUpdate
 * @summary Update API
 * @request PATCH:/api/v1/api/update
 */
export const v1ApiUpdatePartialUpdate = (request: RequestsUpdateAPIRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesAPIResponse>({
    url: `/api/v1/api/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Find Audio File by ID
 *
 * @tags Audio
 * @name V1AudiosList
 * @summary Find Audio File
 * @request GET:/api/v1/audios
 */
export const v1AudiosList = (
  query: {
    /** Audio ID */
    id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAudioResponse>({
    url: `/api/v1/audios`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete Audio File by ID
 *
 * @tags Audio
 * @name V1AudiosDelete
 * @summary Delete Audio File
 * @request DELETE:/api/v1/audios
 */
export const v1AudiosDelete = (
  query: {
    /** Audio File ID to delete */
    id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/audios`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Upload Audio File
 *
 * @tags Audio
 * @name V1AudiosUploadCreate
 * @summary Upload Audio File
 * @request POST:/api/v1/audios/upload
 */
export const v1AudiosUploadCreate = (
  query: {
    /** Specifies the ownership of the files (audio) */
    fileBelongs: string
  },
  data: {
    /** Audio files to upload */
    file: File
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAudioResponse>({
    url: `/api/v1/audios/upload`,
    method: 'POST',
    params: query,
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Upload and Transcribe Audio File
 *
 * @tags Audio
 * @name V1AudiosUploadAndTranscribeCreate
 * @summary Upload and Transcribe Audio File
 * @request POST:/api/v1/audios/uploadAndTranscribe
 */
export const v1AudiosUploadAndTranscribeCreate = (
  query: {
    /** Specifies the ownership of the files (audio) */
    fileBelongs: string
    /** Agent identifier */
    agentUUID: string
  },
  data: {
    /** Document files to upload */
    file: File
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAudioResponse>({
    url: `/api/v1/audios/uploadAndTranscribe`,
    method: 'POST',
    params: query,
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Get Agent Chat Sessions
 *
 * @tags ChatSessions
 * @name V1ChatAgentsSessionsDetail
 * @summary Get Agent Chat Sessions
 * @request GET:/api/v1/chat/agents/{agent_id}/sessions
 */
export const v1ChatAgentsSessionsDetail = (
  agentId: number,
  query?: {
    /**
     * Page number (default: 1)
     * @min 1
     * @default 1
     */
    page?: number
    /**
     * Items per page (default: 10)
     * @min 1
     * @max 100
     * @default 10
     */
    limit?: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedChatSessionResponse>({
    url: `/api/v1/chat/agents/${agentId}/sessions`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of the most recent conversations for a given session.
 *
 * @tags ChatConversations
 * @name V1ChatConversationsList
 * @summary Get recent conversations
 * @request GET:/api/v1/chat/conversations
 */
export const v1ChatConversationsList = (
  query: {
    /** Session UUID */
    session_uuid: string
    /** Last ID for pagination */
    last_id?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersChatResponsesChatConversationResponse[]>({
    url: `/api/v1/chat/conversations`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Searches for chat sessions based on search criteria.
 *
 * @tags ChatConversations
 * @name V1ChatConversationsSearchList
 * @summary Search chat sessions
 * @request GET:/api/v1/chat/conversations/search
 */
export const v1ChatConversationsSearchList = (
  query: {
    /** Agent ID */
    agentId: number
    /** Search keyword */
    keyword?: string
    /**
     * Search keyword in user messages
     * @default true
     */
    searchUser: boolean
    /**
     * Search keyword in assistant messages
     * @default true
     */
    searchAssistant: boolean
    /**
     * Search keyword in feedback labels
     * @default false
     */
    searchFeedback: boolean
    /**
     * Include internal users
     * @default true
     */
    internalUser: boolean
    /**
     * Include external users
     * @default true
     */
    externalUser: boolean
    /**
     * Include evaluation system
     * @default false
     */
    evaluationAgent?: boolean
    /** Like filter */
    like?: boolean
    /** Dislike filter */
    dislike?: boolean
    /** Has feedback filter */
    hasFeedback?: boolean
    /** Has audio filter */
    hasAudio?: boolean
    /** Start date (format: 2024-01-01T00:00:00Z) */
    startDate?: string
    /** End date (format: 2024-12-31T23:59:59Z) */
    endDate?: string
    /**
     * Page number
     * @min 1
     * @default 1
     */
    page: number
    /**
     * Items per page
     * @min 1
     * @default 50
     */
    limit: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedChatConversationResponse>({
    url: `/api/v1/chat/conversations/search`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Sends a message to a chat session and returns the response as a stream of Server-Sent Events (SSE).
 *
 * @tags ChatConversations
 * @name V1ChatConversationsSendCreate
 * @summary Send chat message (Streaming)
 * @request POST:/api/v1/chat/conversations/send
 */
export const v1ChatConversationsSendCreate = (
  request: GenieCoreAppHandlersChatRequestsSendRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/chat/conversations/send`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves conversation statistics with detail.
 *
 * @tags ConversationStatistics
 * @name V1ChatConversationsStatisticsList
 * @summary Get conversation statistics with detail
 * @request GET:/api/v1/chat/conversations/statistics
 */
export const v1ChatConversationsStatisticsList = (
  query: {
    /** Agent ID */
    agent_id: number
    /** Start date in YYYY-MM-DD 00:00:00 format */
    start_at?: string
    /** End date in YYYY-MM-DD 00:00:00 format */
    end_at?: string
    /**
     * Timezone Offset (in hours)
     * @default 0
     */
    timezone_offset?: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesConversationStatsWithDetailResponse>({
    url: `/api/v1/chat/conversations/statistics`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Populates hourly conversation_statistics and intent_statistics tables
 *
 * @tags ConversationStatistics
 * @name V1ChatConversationsStatisticsHourlyPopulateCreate
 * @summary Populate hourly conversation statistics
 * @request POST:/api/v1/chat/conversations/statistics/hourly/populate
 */
export const v1ChatConversationsStatisticsHourlyPopulateCreate = (
  request: RequestsPopulateConversationStatisticsRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/chat/conversations/statistics/hourly/populate`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves conversation statistics with detail from Mongo.
 *
 * @tags ConversationStatistics
 * @name V1ChatConversationsStatisticsMongoList
 * @summary Get conversation statistics with detail from Mongo
 * @request GET:/api/v1/chat/conversations/statistics/mongo
 */
export const v1ChatConversationsStatisticsMongoList = (
  query: {
    /** Agent ID */
    agent_id: number
    /** Start date in YYYY-MM-DD 00:00:00 format */
    start_at?: string
    /** End date in YYYY-MM-DD 00:00:00 format */
    end_at?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesConversationStatsWithDetailResponse>({
    url: `/api/v1/chat/conversations/statistics/mongo`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves periodic conversation statistics for different time periods (hourly, daily, weekly, monthly, yearly)
 *
 * @tags ConversationStatistics
 * @name V1ChatConversationsStatisticsPeriodicList
 * @summary Get periodic conversation statistics
 * @request GET:/api/v1/chat/conversations/statistics/periodic
 */
export const v1ChatConversationsStatisticsPeriodicList = (
  query: {
    /** Agent ID */
    agent_id: number
    /** Time period (hourly, daily, weekly, monthly, yearly) */
    period: 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'
    /** Start date in YYYY-MM-DD 00:00:00 format */
    start_at?: string
    /** End date in YYYY-MM-DD 00:00:00 format */
    end_at?: string
    /**
     * Timezone Offset (in hours)
     * @default 0
     */
    timezone_offset?: number
    /**
     * Start Day of the Week (1 - Sunday, 7 - Saturday)
     * @min 1
     * @max 7
     * @default 1
     */
    day_of_week?: number
    /**
     * Start Day of the Month (1 - 31)
     * @min 1
     * @max 31
     * @default 1
     */
    day_of_month?: number
    /**
     * Start Month of the Year (1 - 12)
     * @min 1
     * @max 12
     * @default 1
     */
    month_of_year?: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesConversationStatsWithDetailResponse[]>({
    url: `/api/v1/chat/conversations/statistics/periodic`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves conversation statistics as a CSV file for different time periods (hourly, daily, weekly, monthly, yearly)
 *
 * @tags ConversationStatistics
 * @name V1ChatConversationsStatisticsPeriodicDownloadList
 * @summary Download conversation statistics
 * @request GET:/api/v1/chat/conversations/statistics/periodic/download
 */
export const v1ChatConversationsStatisticsPeriodicDownloadList = (
  query: {
    /** Agent ID */
    agent_id: number
    /** Report Extension (csv, excel) */
    report_extension: 'csv' | 'excel'
    /** Time period (hourly, daily, weekly, monthly, yearly) */
    period: 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'
    /** Start date in YYYY-MM-DD 00:00:00 format */
    start_at?: string
    /** End date in YYYY-MM-DD 00:00:00 format */
    end_at?: string
    /**
     * Timezone Offset (in hours)
     * @default 0
     */
    timezone_offset?: number
    /**
     * Start Day of the Week (1 - Sunday, 7 - Saturday)
     * @min 1
     * @max 7
     * @default 1
     */
    day_of_week?: number
    /**
     * Start Day of the Month (1 - 31)
     * @min 1
     * @max 31
     * @default 1
     */
    day_of_month?: number
    /**
     * Start Month of the Year (1 - 12)
     * @min 1
     * @max 12
     * @default 1
     */
    month_of_year?: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<File>({
    url: `/api/v1/chat/conversations/statistics/periodic/download`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Create a job to generate conversation statistics report as a CSV file for different time periods (hourly, daily, weekly, monthly, yearly)
 *
 * @tags ConversationStatistics
 * @name V1ChatConversationsStatisticsPeriodicDownloadJobCreate
 * @summary Create a job to generate conversation statistics reports
 * @request POST:/api/v1/chat/conversations/statistics/periodic/download/job
 */
export const v1ChatConversationsStatisticsPeriodicDownloadJobCreate = (
  query: {
    /** Agent ID */
    agent_id: number
    /** Report Extension (csv, excel) */
    report_extension: 'csv' | 'excel'
    /** Time period (hourly, daily, weekly, monthly, yearly) */
    period: 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'
    /** Start date in YYYY-MM-DD 00:00:00 format */
    start_at?: string
    /** End date in YYYY-MM-DD 00:00:00 format */
    end_at?: string
    /**
     * Timezone Offset (in hours)
     * @default 0
     */
    timezone_offset?: number
    /**
     * Start Day of the Week (1 - Sunday, 7 - Saturday)
     * @min 1
     * @max 7
     * @default 1
     */
    day_of_week?: number
    /**
     * Start Day of the Month (1 - 31)
     * @min 1
     * @max 31
     * @default 1
     */
    day_of_month?: number
    /**
     * Start Month of the Year (1 - 12)
     * @min 1
     * @max 12
     * @default 1
     */
    month_of_year?: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesJobAPIResponse>({
    url: `/api/v1/chat/conversations/statistics/periodic/download/job`,
    method: 'POST',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieve conversation statistics job for progress checking. When it is completed successfully, it downloads the file. The conversation statistics file is avaiable as a CSV file for different time periods (hourly, daily, weekly, monthly, yearly)
 *
 * @tags ConversationStatistics
 * @name V1ChatConversationsStatisticsPeriodicDownloadJobDetail
 * @summary Get conversation statistics job
 * @request GET:/api/v1/chat/conversations/statistics/periodic/download/job/{id}
 */
export const v1ChatConversationsStatisticsPeriodicDownloadJobDetail = (id: string, params: RequestParams = {}) => {
  return baseRequest.request<File>({
    url: `/api/v1/chat/conversations/statistics/periodic/download/job/${id}`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves periodic conversation statistics for different time periods (hourly, daily, weekly, monthly, yearly)
 *
 * @tags ConversationStatistics
 * @name V1ChatConversationsStatisticsPeriodicMongoList
 * @summary Get periodic conversation statistics from MongoDB
 * @request GET:/api/v1/chat/conversations/statistics/periodic/mongo
 */
export const v1ChatConversationsStatisticsPeriodicMongoList = (
  query: {
    /** Agent ID */
    agent_id: number
    /** Time period (hourly, daily, weekly, monthly, yearly) */
    period: 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'
    /** Start date in YYYY-MM-DD 00:00:00 format */
    start_at?: string
    /** End date in YYYY-MM-DD 00:00:00 format */
    end_at?: string
    /**
     * Timezone Offset (in hours)
     * @default 0
     */
    timezone_offset?: number
    /**
     * Start Day of the Week (1 - Sunday, 7 - Saturday)
     * @min 1
     * @max 7
     * @default 1
     */
    day_of_week?: number
    /**
     * Start Day of the Month (1 - 31)
     * @min 1
     * @max 31
     * @default 1
     */
    day_of_month?: number
    /**
     * Start Month of the Year (1 - 12)
     * @min 1
     * @max 12
     * @default 1
     */
    month_of_year?: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesConversationStatsWithDetailResponse[]>({
    url: `/api/v1/chat/conversations/statistics/periodic/mongo`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Uploads a document file and processes it immediately.
 *
 * @tags ChatConversations
 * @name V1ChatConversationsUploadDocumentCreate
 * @summary Upload a document
 * @request POST:/api/v1/chat/conversations/upload-document
 */
export const v1ChatConversationsUploadDocumentCreate = (
  data: {
    /**
     * Document file to upload
     * @format binary
     */
    file: File
    /** Agent UUID */
    agent_uuid: string
    /** Session UUID */
    session_uuid?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersChatResponsesDocumentResponse>({
    url: `/api/v1/chat/conversations/upload-document`,
    method: 'POST',
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Adds feedback to a specific conversation.
 *
 * @tags ChatConversations
 * @name V1ChatConversationsFeedbackCreate
 * @summary Add feedback to a conversation
 * @request POST:/api/v1/chat/conversations/{id}/feedback
 */
export const v1ChatConversationsFeedbackCreate = (
  id: string,
  request: GenieCoreAppHandlersChatRequestsAddFeedbackRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersChatResponsesSuccessfulResponseMessage>({
    url: `/api/v1/chat/conversations/${id}/feedback`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Sets or unsets the like status of a conversation.
 *
 * @tags ChatConversations
 * @name V1ChatConversationsLikeCreate
 * @summary Like or unlike a conversation
 * @request POST:/api/v1/chat/conversations/{id}/like
 */
export const v1ChatConversationsLikeCreate = (
  id: string,
  request: GenieCoreAppHandlersChatRequestsLikeUnlikeRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersChatResponsesSuccessfulResponseMessage>({
    url: `/api/v1/chat/conversations/${id}/like`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Sets or unsets the unlike status of a conversation.
 *
 * @tags ChatConversations
 * @name V1ChatConversationsUnlikeCreate
 * @summary Unlike or un-unlike a conversation
 * @request POST:/api/v1/chat/conversations/{id}/unlike
 */
export const v1ChatConversationsUnlikeCreate = (
  id: string,
  request: GenieCoreAppHandlersChatRequestsLikeUnlikeRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersChatResponsesSuccessfulResponseMessage>({
    url: `/api/v1/chat/conversations/${id}/unlike`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves workflow node infos for a specific conversation by conversation_id
 *
 * @tags ChatConversations
 * @name V1ChatConversationsWorkflowNodeInfosDetail
 * @summary Get workflow node infos by conversation id
 * @request GET:/api/v1/chat/conversations/{id}/workflow_node_infos
 */
export const v1ChatConversationsWorkflowNodeInfosDetail = (id: string, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesNodeInfoResponse[]>({
    url: `/api/v1/chat/conversations/${id}/workflow_node_infos`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get Paginated Chat Sessions
 *
 * @tags ChatSessions
 * @name V1ChatSessionsList
 * @summary Get Paginated Chat Sessions
 * @request GET:/api/v1/chat/sessions
 */
export const v1ChatSessionsList = (
  query?: {
    /**
     * Page number (default: 1)
     * @min 1
     * @default 1
     */
    page?: number
    /**
     * Items per page (default: 10)
     * @min 1
     * @max 100
     * @default 10
     */
    limit?: number
    /** JSON encoded sort map e.g. {'agent_id':'desc'} */
    sort?: string
    /** JSON encoded conditions array e.g. [     {'field':'channel_id','operator':'=','value':'1'} ] */
    conditions?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedChatSessionResponse>({
    url: `/api/v1/chat/sessions`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete Chat Session by UUID
 *
 * @tags ChatSessions
 * @name V1ChatSessionsDelete
 * @summary Delete Chat Session
 * @request DELETE:/api/v1/chat/sessions
 */
export const v1ChatSessionsDelete = (
  query: {
    /** Chat Session UUID to delete */
    uuid: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/chat/sessions`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of the most recent chat sessions for a given agent.
 *
 * @tags ChatSessions
 * @name V1ChatSessionsRecentList
 * @summary Get recent chat sessions
 * @request GET:/api/v1/chat/sessions/recent
 */
export const v1ChatSessionsRecentList = (
  query: {
    /** Agent UUID */
    agent_uuid: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersChatResponsesChatSessionResponse[]>({
    url: `/api/v1/chat/sessions/recent`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Update Chat Session Title by UUID
 *
 * @tags ChatSessions
 * @name V1ChatSessionsTitlePartialUpdate
 * @summary Update Chat Session Title
 * @request PATCH:/api/v1/chat/sessions/{uuid}/title
 */
export const v1ChatSessionsTitlePartialUpdate = (
  uuid: string,
  request: RequestsChatSessionUpdateTitleRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersChatResponsesChatSessionResponse>({
    url: `/api/v1/chat/sessions/${uuid}/title`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Find Document File by ID
 *
 * @tags Document
 * @name V1DocumentsList
 * @summary Find Document File
 * @request GET:/api/v1/documents
 */
export const v1DocumentsList = (
  query: {
    /** Document ID */
    id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersDocumentResponsesDocumentResponse>({
    url: `/api/v1/documents`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete Document File by ID
 *
 * @tags Document
 * @name V1DocumentsDelete
 * @summary Delete Document File
 * @request DELETE:/api/v1/documents
 */
export const v1DocumentsDelete = (
  query: {
    /** Document File ID to delete */
    id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/documents`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Upload Document File
 *
 * @tags Document
 * @name V1DocumentsUploadCreate
 * @summary Upload Document File
 * @request POST:/api/v1/documents/upload
 */
export const v1DocumentsUploadCreate = (
  data: {
    /**
     * Document files to upload
     * @format binary
     */
    file: File
    /** Specifies the ownership of the files (document) */
    fileBelongs: string
    /** Agent UUID */
    agentUUID: string
    /** Session UUID */
    sessionUUID?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersDocumentResponsesDocumentResponse>({
    url: `/api/v1/documents/upload`,
    method: 'POST',
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Get Evaluation by id
 *
 * @tags Evaluation
 * @name V1EvaluationList
 * @summary Get Evaluation by id
 * @request GET:/api/v1/evaluation
 */
export const v1EvaluationList = (
  query: {
    /** Evaluation ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesEvaluationAPIResponse>({
    url: `/api/v1/evaluation`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete Evaluation by id
 *
 * @tags Evaluation
 * @name V1EvaluationDelete
 * @summary Delete Evaluation by id
 * @request DELETE:/api/v1/evaluation
 */
export const v1EvaluationDelete = (
  query: {
    /** Evaluation ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/evaluation`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves evaluation results as a CSV file
 *
 * @tags Evaluation
 * @name V1EvaluationDownloadList
 * @summary Download evaluation results
 * @request GET:/api/v1/evaluation/download
 */
export const v1EvaluationDownloadList = (
  query: {
    /** Evaluation ID */
    id: number
    /** Report Extension (csv, excel) */
    report_extension: 'csv' | 'excel'
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<File>({
    url: `/api/v1/evaluation/download`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Evaluate agent with given questions.
 *
 * @tags Evaluation
 * @name V1EvaluationEvaluateAgentCreate
 * @summary Evaluate agent with given questions
 * @request POST:/api/v1/evaluation/evaluate_agent
 */
export const v1EvaluationEvaluateAgentCreate = (request: RequestsEvaluationRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesEvaluationAPIResponse>({
    url: `/api/v1/evaluation/evaluate_agent`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get a list of Evaluations.
 *
 * @tags Evaluation
 * @name V1EvaluationListList
 * @summary List Evaluations
 * @request GET:/api/v1/evaluation/list
 */
export const v1EvaluationListList = (
  query?: {
    /**
     * Page number (default: 1)
     * @min 1
     * @default 1
     */
    page?: number
    /**
     * Items per page (default: 10)
     * @min 1
     * @max 100
     * @default 10
     */
    limit?: number
    /** JSON encoded sort map e.g. {'id':'desc'} */
    sort?: string
    /** JSON encoded conditions array e.g. [{'field':'id','operator':'=','value':1}] */
    conditions?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedEvaluationAPIResponse>({
    url: `/api/v1/evaluation/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get evaluation metric by ID.
 *
 * @tags EvaluationMetric
 * @name V1EvaluationMetricsList
 * @summary Get evaluation metric
 * @request GET:/api/v1/evaluation/metrics
 */
export const v1EvaluationMetricsList = (
  query: {
    /** Evaluation Metric ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesMetricsResponse>({
    url: `/api/v1/evaluation/metrics`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates existing evaluation metric with provided details.
 *
 * @tags EvaluationMetric
 * @name V1EvaluationMetricsUpdate
 * @summary Update evaluation metric
 * @request PUT:/api/v1/evaluation/metrics
 */
export const v1EvaluationMetricsUpdate = (
  request: RequestsUpdateEvaluationMetricRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesMetricsResponse>({
    url: `/api/v1/evaluation/metrics`,
    method: 'PUT',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates new evaluation metric with provided details.
 *
 * @tags EvaluationMetric
 * @name V1EvaluationMetricsCreate
 * @summary Create evaluation metric
 * @request POST:/api/v1/evaluation/metrics
 */
export const v1EvaluationMetricsCreate = (
  request: RequestsCreateEvaluationMetricRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesMetricsResponse>({
    url: `/api/v1/evaluation/metrics`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes evaluation metric by its ID.
 *
 * @tags EvaluationMetric
 * @name V1EvaluationMetricsDelete
 * @summary Delete evaluation metric
 * @request DELETE:/api/v1/evaluation/metrics
 */
export const v1EvaluationMetricsDelete = (
  query: {
    /** Metrics ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/evaluation/metrics`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get a list of all valid evaluation types
 *
 * @tags EvaluationMetric
 * @name V1EvaluationMetricsEvaluationTypesList
 * @summary List evaluation types
 * @request GET:/api/v1/evaluation/metrics/evaluation_types
 */
export const v1EvaluationMetricsEvaluationTypesList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesListEvaluationTypesResponse>({
    url: `/api/v1/evaluation/metrics/evaluation_types`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get a list of Evaluation Metrics.
 *
 * @tags EvaluationMetric
 * @name V1EvaluationMetricsListList
 * @summary List Evaluation metrics
 * @request GET:/api/v1/evaluation/metrics/list
 */
export const v1EvaluationMetricsListList = (
  query?: {
    /**
     * Page number (default: 1)
     * @min 1
     * @default 1
     */
    page?: number
    /**
     * Items per page (default: 10)
     * @min 1
     * @max 100
     * @default 10
     */
    limit?: number
    /** JSON encoded sort map e.g. {'id':'desc'} */
    sort?: string
    /** JSON encoded conditions array e.g. [     {'field':'id','operator':'=','value':'1'} ] */
    conditions?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedEvaluationMetricResponse>({
    url: `/api/v1/evaluation/metrics/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get an evaluation question by ID.
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsList
 * @summary Get evaluation question
 * @request GET:/api/v1/evaluation/questions
 */
export const v1EvaluationQuestionsList = (
  query: {
    /** Question ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesQuestionResponse>({
    url: `/api/v1/evaluation/questions`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates an existing evaluation question with provided details.
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsUpdate
 * @summary Update evaluation question
 * @request PUT:/api/v1/evaluation/questions
 */
export const v1EvaluationQuestionsUpdate = (request: RequestsUpdateQuestionRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesQuestionResponse>({
    url: `/api/v1/evaluation/questions`,
    method: 'PUT',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new evaluation question with provided details.
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsCreate
 * @summary Create evaluation question
 * @request POST:/api/v1/evaluation/questions
 */
export const v1EvaluationQuestionsCreate = (request: RequestsCreateQuestionRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesQuestionResponse>({
    url: `/api/v1/evaluation/questions`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an evaluation question by its ID.
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsDelete
 * @summary Delete evaluation question
 * @request DELETE:/api/v1/evaluation/questions
 */
export const v1EvaluationQuestionsDelete = (
  query: {
    /** Question ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/evaluation/questions`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get Evaluation Question File Template with example
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsFileTemplateList
 * @summary Get Evaluation Question File Template
 * @request GET:/api/v1/evaluation/questions/file_template
 */
export const v1EvaluationQuestionsFileTemplateList = (params: RequestParams = {}) => {
  return baseRequest.request<File>({
    url: `/api/v1/evaluation/questions/file_template`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get a paginated list of evaluation questions.
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsListList
 * @summary List evaluation questions
 * @request GET:/api/v1/evaluation/questions/list
 */
export const v1EvaluationQuestionsListList = (
  query?: {
    /**
     * Page number (default: 1)
     * @min 1
     * @default 1
     */
    page?: number
    /**
     * Items per page (default: 10)
     * @min 1
     * @max 100
     * @default 10
     */
    limit?: number
    /** JSON encoded sort map e.g. {'id':'desc'} */
    sort?: string
    /** JSON encoded conditions array e.g. [     {'field':'id','operator':'=','value':'1'} ] */
    conditions?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedQuestionResponse>({
    url: `/api/v1/evaluation/questions/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Upload list of Evaluation Questions file
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsUploadCreate
 * @summary Upload Evaluation Question File
 * @request POST:/api/v1/evaluation/questions/upload
 */
export const v1EvaluationQuestionsUploadCreate = (
  query: {
    /** agent id */
    agent_id: number
  },
  data: {
    /** Document file (CSV or Excel) */
    file: File
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesUploadDocumentResponse>({
    url: `/api/v1/evaluation/questions/upload`,
    method: 'POST',
    params: query,
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Update File by ID
 *
 * @tags File
 * @name V1FilePartialUpdate
 * @summary Update File
 * @request PATCH:/api/v1/file
 */
export const v1FilePartialUpdate = (
  query: {
    /** File ID to update */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersFileResponsesFileResponse>({
    url: `/api/v1/file`,
    method: 'PATCH',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete File by ID
 *
 * @tags File
 * @name V1FileDeleteDelete
 * @summary Delete File
 * @request DELETE:/api/v1/file/delete
 */
export const v1FileDeleteDelete = (
  query: {
    /** File ID to delete */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/file/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Upload multiple documents
 *
 * @tags File
 * @name V1FileDocumentsUploadCreate
 * @summary Upload Documents
 * @request POST:/api/v1/file/documents/upload
 */
export const v1FileDocumentsUploadCreate = (
  query: {
    /** Specifies the ownership of the files */
    fileBelongs: 'session' | 'audio' | 'document' | 'agent_icon' | 'avatar' | 'plugin' | 'plugin_category'
  },
  data: {
    /** Document files to upload */
    file: File[]
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersFileResponsesFileResponse[]>({
    url: `/api/v1/file/documents/upload`,
    method: 'POST',
    params: query,
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Upload multiple images
 *
 * @tags File
 * @name V1FileImagesUploadCreate
 * @summary Upload Images
 * @request POST:/api/v1/file/images/upload
 */
export const v1FileImagesUploadCreate = (
  query: {
    /** Specifies the ownership of the files */
    fileBelongs: 'session' | 'audio' | 'document' | 'agent_icon' | 'avatar' | 'plugin' | 'plugin_category'
  },
  data: {
    /** Image files to upload */
    file: File[]
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersFileResponsesFileResponse[]>({
    url: `/api/v1/file/images/upload`,
    method: 'POST',
    params: query,
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Paginate Files with filtering and sorting options
 *
 * @tags File
 * @name V1FilePaginateList
 * @summary Paginate Files
 * @request GET:/api/v1/file/paginate
 */
export const v1FilePaginateList = (
  query: {
    /** Page number */
    page?: number
    /** Number of items per page */
    pageSize?: number
    /** Filter by Organization ID */
    organizationID?: number
    /** Filter by User ID */
    userID?: number
    /** Filter by Index ID */
    indexID?: number
    /** Filter by File Types */
    fileTypes?: any[]
    /** Specifies the ownership of the files */
    fileBelongs: 'session' | 'audio' | 'document' | 'agent_icon' | 'avatar' | 'plugin' | 'plugin_category'
    /** Filter by Filename (can be partial) */
    filename?: string
    /** Field to order by */
    orderBy?: string
    /** Sort in descending order */
    desc?: boolean
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedFileResponse>({
    url: `/api/v1/file/paginate`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Serve a static file from cloud storage
 *
 * @tags File
 * @name V1FileDetail
 * @summary Serve Static File
 * @request GET:/api/v1/file/{uuid}
 */
export const v1FileDetail = (uuid: string, params: RequestParams = {}) => {
  return baseRequest.request<File>({
    url: `/api/v1/file/${uuid}`,
    method: 'GET',
    ...params,
  })
} /**
 * @description Creates a new intent with provided details.
 *
 * @tags Intent
 * @name V1IntentCreateCreate
 * @summary Create intent
 * @request POST:/api/v1/intent/create
 */
export const v1IntentCreateCreate = (request: RequestsCreateIntentRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesIntentResponse>({
    url: `/api/v1/intent/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an intent by its ID.
 *
 * @tags Intent
 * @name V1IntentDeleteDelete
 * @summary Delete intent
 * @request DELETE:/api/v1/intent/delete
 */
export const v1IntentDeleteDelete = (
  query: {
    /** Intent ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/intent/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of intents based on pagination parameters.
 *
 * @tags Intent
 * @name V1IntentListList
 * @summary List intents
 * @request GET:/api/v1/intent/list
 */
export const v1IntentListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesIntentListResponse>({
    url: `/api/v1/intent/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates details of an existing intent.
 *
 * @tags Intent
 * @name V1IntentUpdatePartialUpdate
 * @summary Update intent
 * @request PATCH:/api/v1/intent/update
 */
export const v1IntentUpdatePartialUpdate = (request: RequestsUpdateIntentRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesIntentResponse>({
    url: `/api/v1/intent/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new Knowledge with provided details.
 *
 * @tags Knowledge
 * @name V1KnowledgeCreateCreate
 * @summary Create Knowledge
 * @request POST:/api/v1/knowledge/create
 */
export const v1KnowledgeCreateCreate = (request: RequestsCreateKnowledgeRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesKnowledgeResponse>({
    url: `/api/v1/knowledge/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an Knowledge by its ID.
 *
 * @tags Knowledge
 * @name V1KnowledgeDeleteDelete
 * @summary Delete Knowledge
 * @request DELETE:/api/v1/knowledge/delete
 */
export const v1KnowledgeDeleteDelete = (
  query: {
    /** Knowledge ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/knowledge/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new document associated with a specific Knowledge and Organization.
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentCreate
 * @summary Create a new document
 * @request POST:/api/v1/knowledge/document
 */
export const v1KnowledgeDocumentCreate = (
  CreateDocumentRequest: RequestsCreateDocumentRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesKnowledgeDocumentResponse>({
    url: `/api/v1/knowledge/document`,
    method: 'POST',
    data: CreateDocumentRequest,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an existing document associated with a specific Knowledge and Organization.
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentDelete
 * @summary Delete a document
 * @request DELETE:/api/v1/knowledge/document
 */
export const v1KnowledgeDocumentDelete = (
  query: {
    /** Document ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/knowledge/document`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates an existing document associated with a specific Knowledge and Organization.
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentPartialUpdate
 * @summary Update a document
 * @request PATCH:/api/v1/knowledge/document
 */
export const v1KnowledgeDocumentPartialUpdate = (
  UpdateDocumentRequest: RequestsUpdateDocumentRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesKnowledgeDocumentResponse>({
    url: `/api/v1/knowledge/document`,
    method: 'PATCH',
    data: UpdateDocumentRequest,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Add a new document chunk
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentChunkCreate
 * @request POST:/api/v1/knowledge/document/chunk
 */
export const v1KnowledgeDocumentChunkCreate = (
  request: RequestsAddDocumentChunkRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/knowledge/document/chunk`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete a document chunk
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentChunkDelete
 * @request DELETE:/api/v1/knowledge/document/chunk
 */
export const v1KnowledgeDocumentChunkDelete = (
  query: {
    /** Knowledge ID */
    knowledgeID: number
    /** Document Chunk ID */
    _id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/knowledge/document/chunk`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Update a document chunk with new content
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentChunkPartialUpdate
 * @request PATCH:/api/v1/knowledge/document/chunk
 */
export const v1KnowledgeDocumentChunkPartialUpdate = (
  request: RequestsUpdateDocumentChunkRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/knowledge/document/chunk`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Update a document chunk status to enabled or disabled
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentChunkStatusPartialUpdate
 * @request PATCH:/api/v1/knowledge/document/chunk/status
 */
export const v1KnowledgeDocumentChunkStatusPartialUpdate = (
  request: RequestsUpdateDocumentChunkStatusRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/knowledge/document/chunk/status`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of Knowledge document chunks based on pagination parameters and KnowledgeID.
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentChunksList
 * @summary List Knowledge document chunks
 * @request GET:/api/v1/knowledge/document/chunks
 */
export const v1KnowledgeDocumentChunksList = (
  query: {
    /** Knowledge ID */
    knowledgeID: number
    /** document ID */
    documentID: number
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesKnowledgeDocumentChunkListResponse>({
    url: `/api/v1/knowledge/document/chunks`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of Knowledge mappings based on pagination parameters and KnowledgeID.
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentListList
 * @summary List Knowledge mappings
 * @request GET:/api/v1/knowledge/document/list
 */
export const v1KnowledgeDocumentListList = (
  query: {
    /** Knowledge ID */
    knowledgeID: number
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesKnowledgeMappingListResponse>({
    url: `/api/v1/knowledge/document/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of Knowledgees based on pagination parameters.
 *
 * @tags Knowledge
 * @name V1KnowledgeListList
 * @summary List Knowledgees
 * @request GET:/api/v1/knowledge/list
 */
export const v1KnowledgeListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesKnowledgeListResponse>({
    url: `/api/v1/knowledge/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates details of an existing Knowledge.
 *
 * @tags Knowledge
 * @name V1KnowledgeUpdatePartialUpdate
 * @summary Update Knowledge
 * @request PATCH:/api/v1/knowledge/update
 */
export const v1KnowledgeUpdatePartialUpdate = (request: RequestsUpdateKnowledgeRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesKnowledgeResponse>({
    url: `/api/v1/knowledge/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of all Embedding models.
 *
 * @tags LLM
 * @name V1LlmEmbeddingModelsList
 * @summary Get all Embedding models
 * @request GET:/api/v1/llm/embedding-models
 */
export const v1LlmEmbeddingModelsList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesEmbeddingModelResponse[]>({
    url: `/api/v1/llm/embedding-models`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of all LLM models.
 *
 * @tags LLM
 * @name V1LlmModelsList
 * @summary Get all LLM models
 * @request GET:/api/v1/llm/models
 */
export const v1LlmModelsList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesLLMResponse[]>({
    url: `/api/v1/llm/models`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of all Rerank models.
 *
 * @tags LLM
 * @name V1LlmRerankModelsList
 * @summary Get all Rerank models
 * @request GET:/api/v1/llm/rerank-models
 */
export const v1LlmRerankModelsList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesRerankModelResponse[]>({
    url: `/api/v1/llm/rerank-models`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get all locks with their values
 *
 * @tags Redis
 * @name V1LocksList
 * @summary Get all locks from Redis
 * @request GET:/api/v1/locks
 */
export const v1LocksList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesAllLocksResponse>({
    url: `/api/v1/locks`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Set a lock with a value and optional TTL
 *
 * @tags Redis
 * @name V1LocksCreate
 * @summary Set lock value in Redis
 * @request POST:/api/v1/locks
 */
export const v1LocksCreate = (request: RequestsLockRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesLockResponse>({
    url: `/api/v1/locks`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get the value of a lock by its key
 *
 * @tags Redis
 * @name V1LocksDetail
 * @summary Get lock value from Redis
 * @request GET:/api/v1/locks/{key}
 */
export const v1LocksDetail = (key: string, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesLockResponse>({
    url: `/api/v1/locks/${key}`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete a lock by its key
 *
 * @tags Redis
 * @name V1LocksDelete
 * @summary Delete lock from Redis
 * @request DELETE:/api/v1/locks/{key}
 */
export const v1LocksDelete = (key: string, params: RequestParams = {}) => {
  return baseRequest.request<void>({
    url: `/api/v1/locks/${key}`,
    method: 'DELETE',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new Organization with provided details.
 *
 * @tags Organization
 * @name V1OrganizationCreateCreate
 * @summary Create Organization
 * @request POST:/api/v1/organization/create
 */
export const v1OrganizationCreateCreate = (request: RequestsCreateOrganizationRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an Organization by its ID.
 *
 * @tags Organization
 * @name V1OrganizationDeleteDelete
 * @summary Delete Organization
 * @request DELETE:/api/v1/organization/delete
 */
export const v1OrganizationDeleteDelete = (
  query: {
    /** Organization ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/organization/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of Organizations based on pagination parameters.
 *
 * @tags Organization
 * @name V1OrganizationListList
 * @summary List Organizations
 * @request GET:/api/v1/organization/list
 */
export const v1OrganizationListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationListResponse>({
    url: `/api/v1/organization/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates all fields of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateUpdate
 * @summary Update Organization
 * @request PUT:/api/v1/organization/update
 */
export const v1OrganizationUpdateUpdate = (request: RequestsUpdateOrganizationRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update`,
    method: 'PUT',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the expire date of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateExpireDatePartialUpdate
 * @summary Update Organization Expire Date
 * @request PATCH:/api/v1/organization/update-expire-date
 */
export const v1OrganizationUpdateExpireDatePartialUpdate = (
  request: RequestsUpdateOrganizationExpireDateRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-expire-date`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the language code of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateLanguageCodePartialUpdate
 * @summary Update Organization Language Code
 * @request PATCH:/api/v1/organization/update-language-code
 */
export const v1OrganizationUpdateLanguageCodePartialUpdate = (
  request: RequestsUpdateOrganizationLanguageCodeRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-language-code`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the name of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateNamePartialUpdate
 * @summary Update Organization Name
 * @request PATCH:/api/v1/organization/update-name
 */
export const v1OrganizationUpdateNamePartialUpdate = (
  request: RequestsUpdateOrganizationNameRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-name`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the required 2FA setting of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateRequired2FaPartialUpdate
 * @summary Update Organization Required 2FA
 * @request PATCH:/api/v1/organization/update-required-2fa
 */
export const v1OrganizationUpdateRequired2FaPartialUpdate = (
  request: RequestsUpdateOrganizationRequired2FARequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-required-2fa`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the status of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateStatusPartialUpdate
 * @summary Update Organization Status
 * @request PATCH:/api/v1/organization/update-status
 */
export const v1OrganizationUpdateStatusPartialUpdate = (
  request: RequestsUpdateOrganizationStatusRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-status`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the timezone of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateTimezonePartialUpdate
 * @summary Update Organization Timezone
 * @request PATCH:/api/v1/organization/update-timezone
 */
export const v1OrganizationUpdateTimezonePartialUpdate = (
  request: RequestsUpdateOrganizationTimezoneRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-timezone`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new permission with provided details.
 *
 * @tags Permission
 * @name V1PermissionCreateCreate
 * @summary Create Permission
 * @request POST:/api/v1/permission/create
 */
export const v1PermissionCreateCreate = (request: RequestsCreatePermissionRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesPermissionResponse>({
    url: `/api/v1/permission/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes a permission by its ID.
 *
 * @tags Permission
 * @name V1PermissionDeleteDelete
 * @summary Delete Permission
 * @request DELETE:/api/v1/permission/delete
 */
export const v1PermissionDeleteDelete = (
  query: {
    /** Permission ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/permission/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves the permission tree for a given organization type.
 *
 * @tags Permission
 * @name V1PermissionTreeList
 * @summary Get Permission Tree
 * @request GET:/api/v1/permission/tree
 */
export const v1PermissionTreeList = (
  query: {
    /** Organization Type */
    organizationType: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPermissionTreeResponse>({
    url: `/api/v1/permission/tree`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates details of an existing permission.
 *
 * @tags Permission
 * @name V1PermissionUpdatePartialUpdate
 * @summary Update Permission
 * @request PATCH:/api/v1/permission/update
 */
export const v1PermissionUpdatePartialUpdate = (
  request: RequestsUpdatePermissionRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPermissionResponse>({
    url: `/api/v1/permission/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get Pagination Plugin Categories
 *
 * @tags PluginCategory
 * @name V1PluginCategoriesList
 * @summary Get Pagination Plugin Categories
 * @request GET:/api/v1/plugin-categories
 */
export const v1PluginCategoriesList = (
  query?: {
    /**
     * Page number
     * @min 1
     * @default 1
     */
    page?: number
    /**
     * Items per page
     * @min 1
     * @max 100
     * @default 10
     */
    limit?: number
    /** JSON encoded sort map e.g. {'id':'desc'} */
    sort?: string
    /** JSON encoded conditions array e.g. [{'field':'id','operator':'=','value':1}] */
    conditions?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedPluginCategoryResponse>({
    url: `/api/v1/plugin-categories`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Create Plugin Category
 *
 * @tags PluginCategory
 * @name V1PluginCategoriesCreate
 * @summary Create Plugin Category
 * @request POST:/api/v1/plugin-categories
 */
export const v1PluginCategoriesCreate = (request: RequestsPluginCategoryRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesPluginCategoryResponse>({
    url: `/api/v1/plugin-categories`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get Plugin Category by id
 *
 * @tags PluginCategory
 * @name V1PluginCategoriesDetail
 * @summary Get Plugin Category by id
 * @request GET:/api/v1/plugin-categories/{id}
 */
export const v1PluginCategoriesDetail = (
  id: string,
  query: {
    /** Plugin Category ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPluginCategoryResponse>({
    url: `/api/v1/plugin-categories/${id}`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Update Plugin Category
 *
 * @tags PluginCategory
 * @name V1PluginCategoriesUpdate
 * @summary Update Plugin Category
 * @request PUT:/api/v1/plugin-categories/{id}
 */
export const v1PluginCategoriesUpdate = (
  id: string,
  query: {
    /** Plugin Category ID */
    id: number
  },
  request: RequestsPluginCategoryRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPluginCategoryResponse>({
    url: `/api/v1/plugin-categories/${id}`,
    method: 'PUT',
    params: query,
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete Plugin Category
 *
 * @tags PluginCategory
 * @name V1PluginCategoriesDelete
 * @summary Delete Plugin Category
 * @request DELETE:/api/v1/plugin-categories/{id}
 */
export const v1PluginCategoriesDelete = (
  id: string,
  query: {
    /** Plugin Category ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPluginCategoryResponse>({
    url: `/api/v1/plugin-categories/${id}`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Publishing plugin form
 *
 * @tags PluginForm
 * @name V1PluginFormsPublishCreate
 * @summary Publishing plugin form
 * @request POST:/api/v1/plugin-forms/{id}/publish
 */
export const v1PluginFormsPublishCreate = (
  id: number,
  request: RequestsSavePluginFormRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPluginFormResponse>({
    url: `/api/v1/plugin-forms/${id}/publish`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Create a new plugin form or update an existing one with provided details.
 *
 * @tags PluginForm
 * @name V1PluginFormsSaveCreate
 * @summary Create a new plugin form or update an existing one with provided details.
 * @request POST:/api/v1/plugin-forms/{id}/save
 */
export const v1PluginFormsSaveCreate = (
  id: number,
  request: RequestsSavePluginFormRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPluginFormResponse>({
    url: `/api/v1/plugin-forms/${id}/save`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Create Plugin with Form
 *
 * @tags Plugin
 * @name V1PluginWithFormCreate
 * @summary Create Plugin with Form
 * @request POST:/api/v1/plugin_with_form
 */
export const v1PluginWithFormCreate = (request: RequestsPluginWithFormRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesPluginWithFormResponse>({
    url: `/api/v1/plugin_with_form`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get Pagination Plugins
 *
 * @tags Plugin
 * @name V1PluginsList
 * @summary Get Pagination Plugins
 * @request GET:/api/v1/plugins
 */
export const v1PluginsList = (
  query?: {
    /**
     * Page number (default: 1)
     * @min 1
     * @default 1
     */
    page?: number
    /**
     * Items per page (default: 10)
     * @min 1
     * @max 100
     * @default 10
     */
    limit?: number
    /** JSON encoded sort map e.g. {'id':'desc'} */
    sort?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedPluginResponse>({
    url: `/api/v1/plugins`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get Plugin by id
 *
 * @tags Plugin
 * @name V1PluginsDetail
 * @summary Get Plugin by id
 * @request GET:/api/v1/plugins/{id}
 */
export const v1PluginsDetail = (id: number, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesPluginResponse>({
    url: `/api/v1/plugins/${id}`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Update Plugin
 *
 * @tags Plugin
 * @name V1PluginsUpdate
 * @summary Update Plugin
 * @request PUT:/api/v1/plugins/{id}
 */
export const v1PluginsUpdate = (id: number, request: RequestsPluginRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesPluginResponse>({
    url: `/api/v1/plugins/${id}`,
    method: 'PUT',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete Plugin
 *
 * @tags Plugin
 * @name V1PluginsDelete
 * @summary Delete Plugin
 * @request DELETE:/api/v1/plugins/{id}
 */
export const v1PluginsDelete = (id: number, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesPluginResponse>({
    url: `/api/v1/plugins/${id}`,
    method: 'DELETE',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description handles fetching latest plugin form  by plugin ID.
 *
 * @tags Plugin
 * @name V1PluginsPluginFormsLatestDetail
 * @summary handles fetching latest plugin form  by plugin ID.
 * @request GET:/api/v1/plugins/{id}/plugin-forms/latest
 */
export const v1PluginsPluginFormsLatestDetail = (id: number, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesPluginFormResponse>({
    url: `/api/v1/plugins/${id}/plugin-forms/latest`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description handles fetching latest published plugin form  by plugin ID.
 *
 * @tags Plugin
 * @name V1PluginsPluginFormsLatestPublishedDetail
 * @summary handles fetching latest published plugin form  by plugin ID.
 * @request GET:/api/v1/plugins/{id}/plugin-forms/latest-published
 */
export const v1PluginsPluginFormsLatestPublishedDetail = (id: number, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesPluginFormResponse>({
    url: `/api/v1/plugins/${id}/plugin-forms/latest-published`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a publish channel by its ID using query parameter.
 *
 * @tags PublishChannel
 * @name V1PublishchannelList
 * @summary Get publish channel by ID
 * @request GET:/api/v1/publishchannel
 */
export const v1PublishchannelList = (
  query: {
    /** Publish Channel ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersAgentResponsesPublishChannelResponse>({
    url: `/api/v1/publishchannel`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new publish channel with provided details.
 *
 * @tags PublishChannel
 * @name V1PublishchannelCreateCreate
 * @summary Create publish channel
 * @request POST:/api/v1/publishchannel/create
 */
export const v1PublishchannelCreateCreate = (
  request: RequestsCreatePublishChannelRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersAgentResponsesPublishChannelResponse>({
    url: `/api/v1/publishchannel/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes a publish channel based on the query parameter ID.
 *
 * @tags PublishChannel
 * @name V1PublishchannelDeleteDelete
 * @summary Delete publish channel
 * @request DELETE:/api/v1/publishchannel/delete
 */
export const v1PublishchannelDeleteDelete = (
  query: {
    /** Publish Channel ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/publishchannel/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of publish channels based on pagination parameters.
 *
 * @tags PublishChannel
 * @name V1PublishchannelListList
 * @summary List publish channels
 * @request GET:/api/v1/publishchannel/list
 */
export const v1PublishchannelListList = (
  query: {
    /** agent id */
    agent_id: number
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPublishChannelListResponse>({
    url: `/api/v1/publishchannel/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a main publish channel by AgentID.
 *
 * @tags PublishChannel
 * @name V1PublishchannelMainList
 * @summary Get publish channel by AgentID
 * @request GET:/api/v1/publishchannel/main
 */
export const v1PublishchannelMainList = (
  query: {
    /** Agent ID */
    agent_id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersAgentResponsesPublishChannelResponse>({
    url: `/api/v1/publishchannel/main`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates an existing publish channel with provided details.
 *
 * @tags PublishChannel
 * @name V1PublishchannelUpdatePartialUpdate
 * @summary Update publish channel
 * @request PATCH:/api/v1/publishchannel/update
 */
export const v1PublishchannelUpdatePartialUpdate = (
  request: RequestsUpdatePublishChannelRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersAgentResponsesPublishChannelResponse>({
    url: `/api/v1/publishchannel/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Sets a channel as the main channel for an agent
 *
 * @tags PublishChannel
 * @name V1PublishchannelSetMainCreate
 * @summary Set channel as main
 * @request POST:/api/v1/publishchannel/{id}/set-main
 */
export const v1PublishchannelSetMainCreate = (
  id: number,
  request: RequestsSetMainChannelRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersAgentResponsesPublishChannelResponse>({
    url: `/api/v1/publishchannel/${id}/set-main`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new role with provided details.
 *
 * @tags Role
 * @name V1RoleCreateCreate
 * @summary Create Role
 * @request POST:/api/v1/role/create
 */
export const v1RoleCreateCreate = (request: RequestsCreateRoleRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesRoleResponse>({
    url: `/api/v1/role/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes a role by its ID.
 *
 * @tags Role
 * @name V1RoleDeleteDelete
 * @summary Delete Role
 * @request DELETE:/api/v1/role/delete
 */
export const v1RoleDeleteDelete = (
  query: {
    /** Role ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/role/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of roles based on organization ID.
 *
 * @tags Role
 * @name V1RoleListList
 * @summary List Roles
 * @request GET:/api/v1/role/list
 */
export const v1RoleListList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesRoleListResponse>({
    url: `/api/v1/role/list`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of roles based on pagination parameters.
 *
 * @tags Role
 * @name V1RolePaginationList
 * @summary List Roles
 * @request GET:/api/v1/role/pagination
 */
export const v1RolePaginationList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesRoleListResponse>({
    url: `/api/v1/role/pagination`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates details of an existing role.
 *
 * @tags Role
 * @name V1RoleUpdatePartialUpdate
 * @summary Update Role
 * @request PATCH:/api/v1/role/update
 */
export const v1RoleUpdatePartialUpdate = (request: RequestsUpdateRoleRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesRoleResponse>({
    url: `/api/v1/role/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Register Smart API Route
 *
 * @tags SmartAPI
 * @name V1SmartApiRegisterAgentCreate
 * @summary Register Smart API Route
 * @request POST:/api/v1/smart-api/register/agent/{agent_uuid}
 */
export const v1SmartApiRegisterAgentCreate = (agentUuid: string, params: RequestParams = {}) => {
  return baseRequest.request<void>({
    url: `/api/v1/smart-api/register/agent/${agentUuid}`,
    method: 'POST',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get Registered SmartAPI Routes
 *
 * @tags SmartAPI
 * @name V1SmartApiRoutesList
 * @summary Get Registered SmartAPI Routes
 * @request GET:/api/v1/smart-api/routes
 */
export const v1SmartApiRoutesList = (params: RequestParams = {}) => {
  return baseRequest.request<ServicesRouteInfo[]>({
    url: `/api/v1/smart-api/routes`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Activate Smart API for public usage
 *
 * @tags SmartAPI
 * @name V1SmartApiActivateCreate
 * @summary Activate Smart API
 * @request POST:/api/v1/smart-api/{id}/activate
 */
export const v1SmartApiActivateCreate = (
  id: string,
  query: {
    /** Smart API ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesSmartAPIResponse>({
    url: `/api/v1/smart-api/${id}/activate`,
    method: 'POST',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Update Smart API Status
 *
 * @tags SmartAPI
 * @name V1SmartApiStatusPartialUpdate
 * @summary Update Smart API Status
 * @request PATCH:/api/v1/smart-api/{id}/status
 */
export const v1SmartApiStatusPartialUpdate = (
  id: string,
  query: {
    /** Smart API ID */
    id: number
  },
  request: RequestsSmartAPIUpdateStatus,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesSmartAPIResponse>({
    url: `/api/v1/smart-api/${id}/status`,
    method: 'PATCH',
    params: query,
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get Settings
 *
 * @tags System
 * @name V1SystemSettingsList
 * @summary Get Settings
 * @request GET:/api/v1/system/settings
 */
export const v1SystemSettingsList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesSettingResponse>({
    url: `/api/v1/system/settings`,
    method: 'GET',
    ...params,
  })
} /**
 * @description Get Version
 *
 * @tags System
 * @name V1SystemVersionList
 * @summary Get Version
 * @request GET:/api/v1/system/version
 */
export const v1SystemVersionList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesVersionResponse>({
    url: `/api/v1/system/version`,
    method: 'GET',
    ...params,
  })
} /**
 * @description Get paginated templates with filters
 *
 * @tags Template
 * @name V1TemplatesList
 * @summary Get Paginated Templates With Filters
 * @request GET:/api/v1/templates
 */
export const v1TemplatesList = (
  query?: {
    /**
     * Page number (default: 1)
     * @min 1
     * @default 1
     */
    page?: number
    /**
     * Items per page (default: 10)
     * @min 1
     * @max 100
     * @default 10
     */
    limit?: number
    /** JSON encoded sort map e.g. {'language_id':'desc'} */
    sort?: string
    /** JSON encoded conditions array e.g. [     {'field':'language_id','operator':'=','value':'1'} ] */
    conditions?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedTemplateResponse>({
    url: `/api/v1/templates`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description List all templates
 *
 * @tags Template
 * @name V1TemplatesAllList
 * @summary List Templates
 * @request GET:/api/v1/templates/all
 */
export const v1TemplatesAllList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesTemplateResponse[]>({
    url: `/api/v1/templates/all`,
    method: 'GET',
    ...params,
  })
} /**
 * @description Get a specific template by its ID
 *
 * @tags Template
 * @name V1TemplatesContentDetail
 * @summary Get Content Template by ID
 * @request GET:/api/v1/templates/{id}/content
 */
export const v1TemplatesContentDetail = (id: number, params: RequestParams = {}) => {
  return baseRequest.request<string>({
    url: `/api/v1/templates/${id}/content`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Generates a 2FA QR code URL for the authenticated user.
 *
 * @tags User
 * @name V1User2FaQrList
 * @summary Generate 2FA QR Code
 * @request GET:/api/v1/user/2fa/qr
 */
export const v1User2FaQrList = (params: RequestParams = {}) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/2fa/qr`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Generates a 2FA QR code URL for the user with the given session ID.
 *
 * @tags User
 * @name V1User2FaQrSessionList
 * @summary Generate 2FA QR Code with Session ID
 * @request GET:/api/v1/user/2fa/qr/session
 */
export const v1User2FaQrSessionList = (
  query: {
    /** Session ID */
    session_id: string
    /** User ID */
    user_id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/2fa/qr/session`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Sets up 2FA for a user
 *
 * @tags User
 * @name V1User2FaSetupCreate
 * @summary Set up 2FA
 * @request POST:/api/v1/user/2fa/setup
 */
export const v1User2FaSetupCreate = (request: RequestsSetup2FARequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/2fa/setup`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Sets up 2FA for a user using a session ID
 *
 * @tags User
 * @name V1User2FaSetupSessionCreate
 * @summary Set up 2FA with Session
 * @request POST:/api/v1/user/2fa/setup/session
 */
export const v1User2FaSetupSessionCreate = (
  request: RequestsSetup2FAWithSessionRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/2fa/setup/session`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Validates a 2FA code for a user
 *
 * @tags User
 * @name V1User2FaValidateCreate
 * @summary Validate 2FA Code
 * @request POST:/api/v1/user/2fa/validate
 */
export const v1User2FaValidateCreate = (request: RequestsValidate2FARequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/2fa/validate`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Validates a 2FA setup session
 *
 * @tags User
 * @name V1User2FaValidateSetupSessionCreate
 * @summary Validate 2FA Setup Session
 * @request POST:/api/v1/user/2fa/validate-setup-session
 */
export const v1User2FaValidateSetupSessionCreate = (
  request: RequestsValidate2FASetupSessionRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/2fa/validate-setup-session`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Changes the password of the authenticated user.
 *
 * @tags User
 * @name V1UserChangePasswordCreate
 * @summary Change User Password
 * @request POST:/api/v1/user/change-password
 */
export const v1UserChangePasswordCreate = (request: RequestsChangePasswordRequest, params: RequestParams = {}) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/change-password`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Changes the password of a user using a session ID.
 *
 * @tags User
 * @name V1UserChangePasswordSessionCreate
 * @summary Change User Password with Session ID
 * @request POST:/api/v1/user/change-password/session
 */
export const v1UserChangePasswordSessionCreate = (
  request: RequestsChangePasswordWithSessionIDRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/change-password/session`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new user (admin only).
 *
 * @tags User
 * @name V1UserCreateCreate
 * @summary Create New User
 * @request POST:/api/v1/user/create
 */
export const v1UserCreateCreate = (request: RequestsCreateUserRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves the information of the currently authenticated user.
 *
 * @tags User
 * @name V1UserCurrentList
 * @summary Get Current User
 * @request GET:/api/v1/user/current
 */
export const v1UserCurrentList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesLoginResponse>({
    url: `/api/v1/user/current`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Gets a paginated list of users for the authenticated user's organization.
 *
 * @tags User
 * @name V1UserListList
 * @summary List Users by Organization
 * @request GET:/api/v1/user/list
 */
export const v1UserListList = (
  query?: {
    /** Page number */
    page?: number
    /** Page size */
    page_size?: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesUserListResponse>({
    url: `/api/v1/user/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Logs in a user with email and password, optionally with a OTP for 2FA.
 *
 * @tags User
 * @name V1UserLoginCreate
 * @summary User Login
 * @request POST:/api/v1/user/login
 */
export const v1UserLoginCreate = (request: RequestsLoginRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesLoginResponse>({
    url: `/api/v1/user/login`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Logs out the currently authenticated user.
 *
 * @tags User
 * @name V1UserLogoutList
 * @summary User Logout
 * @request GET:/api/v1/user/logout
 */
export const v1UserLogoutList = (params: RequestParams = {}) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/logout`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Resends the user creation email with a new generated password for users who haven't changed their password yet.
 *
 * @tags User
 * @name V1UserResendCreationEmailCreate
 * @summary Resend User Creation Email
 * @request POST:/api/v1/user/resend-creation-email
 */
export const v1UserResendCreationEmailCreate = (
  request: RequestsResendEmailForUserCreationRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/resend-creation-email`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Resets the password of a user (admin only).
 *
 * @tags User
 * @name V1UserResetPasswordCreate
 * @summary Reset User Password
 * @request POST:/api/v1/user/reset-password
 */
export const v1UserResetPasswordCreate = (request: RequestsResetPasswordRequest, params: RequestParams = {}) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/reset-password`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the avatar of the authenticated user.
 *
 * @tags User
 * @name V1UserUpdateAvatarPartialUpdate
 * @summary Update User Avatar
 * @request PATCH:/api/v1/user/update-avatar
 */
export const v1UserUpdateAvatarPartialUpdate = (request: RequestsUpdateAvatarRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUpdateAvatarResponse>({
    url: `/api/v1/user/update-avatar`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the email of the authenticated user.
 *
 * @tags User
 * @name V1UserUpdateEmailPartialUpdate
 * @summary Update User Email
 * @request PATCH:/api/v1/user/update-email
 */
export const v1UserUpdateEmailPartialUpdate = (request: RequestsUpdateEmailRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUpdateEmailResponse>({
    url: `/api/v1/user/update-email`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the name of the authenticated user.
 *
 * @tags User
 * @name V1UserUpdateNamePartialUpdate
 * @summary Update User Name
 * @request PATCH:/api/v1/user/update-name
 */
export const v1UserUpdateNamePartialUpdate = (request: RequestsUpdateUserNameRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/update-name`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the role of the authenticated user.
 *
 * @tags User
 * @name V1UserUpdateRolePartialUpdate
 * @summary Update User Role
 * @request PATCH:/api/v1/user/update-role
 */
export const v1UserUpdateRolePartialUpdate = (request: RequestsUpdateUserRoleRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUpdateUserRoleResponse>({
    url: `/api/v1/user/update-role`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the status of a user (admin only).
 *
 * @tags User
 * @name V1UserUpdateStatusPartialUpdate
 * @summary Update User Status
 * @request PATCH:/api/v1/user/update-status
 */
export const v1UserUpdateStatusPartialUpdate = (
  request: RequestsUpdateUserStatusRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/update-status`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the timezone of the authenticated user.
 *
 * @tags User
 * @name V1UserUpdateTimezonePartialUpdate
 * @summary Update User Timezone
 * @request PATCH:/api/v1/user/update-timezone
 */
export const v1UserUpdateTimezonePartialUpdate = (
  request: RequestsUpdateTimezoneRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesUpdateTimezoneResponse>({
    url: `/api/v1/user/update-timezone`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete an user based on the query parameter ID.
 *
 * @tags User
 * @name V1UserDelete
 * @summary Delete user
 * @request DELETE:/api/v1/user/{id}
 */
export const v1UserDelete = (id: number, params: RequestParams = {}) => {
  return baseRequest.request<void>({
    url: `/api/v1/user/${id}`,
    method: 'DELETE',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Remove 2FA code for a particular user by user_id
 *
 * @tags User
 * @name V1User2FaRemovePartialUpdate
 * @summary Remove 2FA Code
 * @request PATCH:/api/v1/user/{id}/2fa/remove
 */
export const v1User2FaRemovePartialUpdate = (id: number, params: RequestParams = {}) => {
  return baseRequest.request<void>({
    url: `/api/v1/user/${id}/2fa/remove`,
    method: 'PATCH',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Reset 2FA code for a particular user by user_id. User will need to re-setup the 2FA again
 *
 * @tags User
 * @name V1User2FaResetPartialUpdate
 * @summary Reset 2FA Code
 * @request PATCH:/api/v1/user/{id}/2fa/reset
 */
export const v1User2FaResetPartialUpdate = (id: number, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/${id}/2fa/reset`,
    method: 'PATCH',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description handles fetching latest workflow by agent ID.
 *
 * @tags Workflow
 * @name V1WorkflowGetLatestWorkflowByAgentList
 * @summary handles fetching latest workflow by agent ID.
 * @request GET:/api/v1/workflow/get-latest-workflow-by-agent
 */
export const v1WorkflowGetLatestWorkflowByAgentList = (
  query: {
    /** Agent ID */
    agentID: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesWorkflowResponse>({
    url: `/api/v1/workflow/get-latest-workflow-by-agent`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description handles fetching published workflow by agent ID.
 *
 * @tags Workflow
 * @name V1WorkflowGetPublishedWorkflowByAgentList
 * @summary handles fetching published workflow by agent ID.
 * @request GET:/api/v1/workflow/get-published-workflow-by-agent
 */
export const v1WorkflowGetPublishedWorkflowByAgentList = (
  query: {
    /** Agent ID */
    agentID: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesWorkflowResponse>({
    url: `/api/v1/workflow/get-published-workflow-by-agent`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Fetches a paginated list of workflows for a specific agent.
 *
 * @tags Workflow
 * @name V1WorkflowPaginatedList
 * @summary Fetch paginated workflows by agent ID
 * @request GET:/api/v1/workflow/paginated
 */
export const v1WorkflowPaginatedList = (
  query: {
    /** Agent ID */
    agentID: number
    /** Page number */
    page: number
    /** Page size */
    pageSize: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedWorkflowResponse>({
    url: `/api/v1/workflow/paginated`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Publishing a workflow
 *
 * @tags Workflow
 * @name V1WorkflowPublishCreate
 * @summary Publishing a workflow
 * @request POST:/api/v1/workflow/publish
 */
export const v1WorkflowPublishCreate = (request: RequestsSaveRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesWorkflowResponse>({
    url: `/api/v1/workflow/publish`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Restores a workflow to published status and deprecates other workflows for the same agent.
 *
 * @tags Workflow
 * @name V1WorkflowRestoreCreate
 * @summary Restore a workflow
 * @request POST:/api/v1/workflow/restore
 */
export const v1WorkflowRestoreCreate = (request: RequestsRestoreRequest, params: RequestParams = {}) => {
  return baseRequest.request<void>({
    url: `/api/v1/workflow/restore`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Create a new workflow or update an existing one with provided details.
 *
 * @tags Workflow
 * @name V1WorkflowSaveCreate
 * @summary Create a new workflow or update an existing one with provided details.
 * @request POST:/api/v1/workflow/save
 */
export const v1WorkflowSaveCreate = (request: RequestsSaveRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesWorkflowResponse>({
    url: `/api/v1/workflow/save`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
}
