import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { createApiRequest, RequestData } from 'services/request'
import { API, APIList } from './models/api'
import { CodeMsgResponse } from './models/common'

class APIStore {
  apiList: APIList = {
    totalCount: 0,
    apis: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  async create(
    apiName: string,
    description: string,
    uri: string,
    method: string,
    timeout: number,
    requestHeaders: string,
    requestParams: string,
    queryParams: string,
    pathParams: string,
    responseParams: string
  ) {
    const params: RequestData = {
      body: {
        apiName,
        description,
        uri,
        method,
        timeout,
        requestHeaders,
        requestParams,
        queryParams,
        pathParams,
        responseParams,
      },
    }

    const response = await createApiRequest<API | CodeMsgResponse>(
      apiConfig.apiCreate,
      params
    )
    return response
  }

  async update(
    id: number,
    apiName: string,
    description: string,
    uri: string,
    method: string,
    timeout: number,
    requestHeaders: string,
    requestParams: string,
    queryParams: string,
    pathParams: string,
    responseParams: string
  ) {
    const params: RequestData = {
      body: {
        id,
        apiName,
        description,
        uri,
        method,
        timeout,
        requestHeaders,
        requestParams,
        queryParams,
        pathParams,
        responseParams,
      },
    }

    const response = await createApiRequest<API | CodeMsgResponse>(
      apiConfig.apiUpdate,
      params
    )

    const index = this.apiList.apis.findIndex((api) => api.id === id)
    if (index !== -1) {
      runInAction(() => {
        this.apiList.apis[index] = {
          ...this.apiList.apis[index],
          ...params.body,
        }
      })
    }

    return response
  }

  async delete(id: number) {
    const response = await createApiRequest(apiConfig.apiDelete, {
      queryParams: { id },
    })
    runInAction(() => {
      this.apiList = {
        totalCount: this.apiList.totalCount - 1,
        apis: this.apiList.apis.filter((api) => api.id !== id),
      }
    })
    return response
  }

  async getList(page: number, size: number) {
    const params: RequestData = {
      queryParams: {
        page,
        size,
      },
    }

    const response = await createApiRequest<APIList>(apiConfig.apiList, params)
    runInAction(() => {
      this.apiList = {
        totalCount: response.data.totalCount,
        apis: response.data.apis,
      }
    })
    return response
  }
}

export default new APIStore()
