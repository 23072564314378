import { useEffect, useMemo, useRef, useState } from 'react'
import { Empty, Flex, Typography } from 'antd'
import * as echarts from 'echarts'
import styles from './index.scss'
import LoadingWrapper from '../../loading-wrapper'

const { Paragraph } = Typography

type IntentData = {
  label: string
  name: string
  count: number
  expanded?: boolean
}

export interface FeedbackChartProps {
  likes: number
  dislikes: number
  intents: IntentData[]
  loading?: boolean
}

const TOP_INTENT_LIMIT = 10

const getTopIntentStyle = (total: number, count: number) => {
  const rate = (count * 1.0) / total
  let tier = 0
  if (rate > 0.2) {
    tier = 1
  } else if (rate > 0.1) {
    tier = 2
  }
  return `count-tier-${tier}`
}

const FeedbackChart: React.FC<FeedbackChartProps> = (props) => {
  const totalLikesAndDislikes = useMemo(() => {
    return props.likes + props.dislikes
  }, [props.likes, props.dislikes])
  const totalIntentCount = useMemo(() => {
    return props.intents.reduce((acc, intent) => acc + intent.count, 0)
  }, [props.intents])
  const [intents, setIntents] = useState<IntentData[]>(props.intents)

  const chartRef = useRef<HTMLDivElement>(null)
  const [chart, setChart] = useState<echarts.ECharts>()

  useEffect(() => {
    const handleResize = () => {
      chart?.resize()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [chart])

  useEffect(() => {
    const chart = echarts.init(chartRef.current as HTMLDivElement)
    setChart(chart)
  }, [chartRef])

  useEffect(() => {
    const totalLikes = props.likes
    const totalDislikes = props.dislikes
    const option: echarts.EChartsOption = {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: 'center',
        right: '24',
        orient: 'vertical',
        itemWidth: 16,
      },
      series: [
        {
          name: 'Likes & Dislikes From',
          type: 'pie',
          radius: ['48%', '70%'],
          center: ['30%', '70%'],
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          // adjust the start and end angle
          startAngle: 180,
          endAngle: 360,
          padAngle: 1,
          itemStyle: {
            borderRadius: 4,
          },
          // color palette
          color: ['#3278d9', '#2a2c2f'],
          data: [
            { value: totalLikes, name: `Likes (${totalLikes})` },
            { value: totalDislikes, name: `Dislikes (${totalDislikes})` },
          ],
        },
      ],
    }
    chart?.setOption(option)
  }, [chart, props.likes, props.dislikes])

  useEffect(() => {
    setIntents(props.intents)
  }, [props.intents])

  return (
    <LoadingWrapper loading={props.loading}>
      <div className={styles.feedbackWrapper}>
        <div
          className={styles.feedbackWrapperLikesDislikesDiagram}
          ref={chartRef}
        ></div>
        <Flex className={styles.feedbackWrapperLikesDislikesLabel} vertical>
          {totalLikesAndDislikes > 0 ? (
            <>
              <span className={styles.feedbackWrapperLikesDislikesLabelCount}>
                {totalLikesAndDislikes}
              </span>
              <span className={styles.feedbackWrapperLikesDislikesLabelText}>
                Interaction
              </span>
            </>
          ) : (
            <Empty />
          )}
        </Flex>{' '}
        <Flex className={styles.feedbackWrapperTopIntents} gap={8} vertical>
          <div className={styles.feedbackWrapperTopIntentsTitle}>
            Top {TOP_INTENT_LIMIT} Intents
          </div>
          <Flex className={styles.feedbackWrapperTopIntentsList} vertical>
            {intents.length > 0 ? (
              intents.slice(0, TOP_INTENT_LIMIT).map((intent, index) => (
                <Flex
                  className={styles.feedbackWrapperTopIntentsListItem}
                  key={index}
                  gap={12}
                >
                  {index + 1}.
                  <Flex
                    gap={12}
                    flex={1}
                    style={{ justifyContent: 'space-between' }}
                  >
                    <Paragraph
                      className={getTopIntentStyle(
                        totalIntentCount,
                        intent.count
                      )}
                      ellipsis={{
                        rows: 2,
                        expandable: 'collapsible',
                        expanded: intent.expanded,
                        onExpand: (_, info) => {
                          const newIntents = [...intents]
                          newIntents[index].expanded = info.expanded
                          setIntents(newIntents)
                        },
                      }}
                    >
                      {intent.name}
                    </Paragraph>
                    <span>{intent.count}</span>
                  </Flex>
                </Flex>
              ))
            ) : (
              <Empty />
            )}
          </Flex>
        </Flex>
      </div>
    </LoadingWrapper>
  )
}

export default FeedbackChart
