import Icon, {
  ApiOutlined,
  BranchesOutlined,
  EllipsisOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import {
  App,
  Avatar,
  Button,
  Dropdown,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import { v1AgentDeleteDelete } from 'api/Api'
import { AgentTypeAgentType } from 'api/data-contracts'
import RobotColor from 'assets/images/robot-color.svg'
import Robot from 'assets/images/robot.svg'
import SmartApiColor from 'assets/images/smart-api-color.svg'
import { useExNavigate } from 'hooks/use-ex-navigate'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import globalStore from 'stores/global'
import { Agent } from 'stores/models/agent'
import {
  getFileUrl,
  getMessageFromError,
  isEmpty,
  setQuery,
} from 'utils/common'
import { formatDate } from 'utils/filter'
import styles from './agent-card.scss'

export enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

interface AgentCardProps {
  data: {
    id: number
    uuid: string
    name: string
    description: string
    agentType: AgentTypeAgentType
    iconId?: number
    iconUuid?: string
    organizationId?: number
    updatedAt: string
    createdAt: string
  }
  onAction?: (type: ActionType, agent: Agent) => void
}

const AgentTypes = {
  chatbot: 'Chatbot',
  smart_api: 'Smart API',
} as const

const AgentTypeIcons = {
  chatbot: <Icon component={() => <Robot fill="transparent" />} />,
  smart_api: <ApiOutlined />,
} as const

export type StudioPageQueryType = {
  id: string
  agentType: keyof typeof AgentTypes
  uuid: string
}

const AgentCard: React.FC<AgentCardProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const { message, modal } = App.useApp()
  const navigate = useExNavigate()

  const handleModifyButtonClick = () => {
    const params = setQuery({
      id: props.data.id,
      uuid: props.data.uuid,
      agentType: props.data.agentType,
    })

    const basePath = globalStore.globalSettings.isDeveloperMode
      ? '/portal/agent/studio/workflow'
      : '/portal/agent/studio/analytics'

    navigate(`${basePath}?${params}`, {
      state: { agent: props.data },
    })
  }

  const deleteAgent = async (id: number) => {
    try {
      await v1AgentDeleteDelete({ id })
      return true
    } catch (error) {
      throw error
    }
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'delete') {
      modal.confirm({
        title: 'Are you sure you want to delete this agent?',
        content:
          'This action cannot be undone and will permanently delete the agent from the system.',
        okText: 'Yes, Delete it',
        okType: 'danger',
        cancelText: 'No, Cancel',
        async onOk() {
          setLoading(true)
          try {
            await deleteAgent(props.data.id)
            message.success('The agent has been successfully deleted.')
            props.onAction?.(ActionType.Delete, {
              ...props.data,
              organizationId: props.data.organizationId ?? 0,
              agentName: props.data.name,
              agentIconID: props.data.iconId ?? 0,
              agentIconUUID: props.data.iconUuid ?? '',
              updatedAt: props.data.updatedAt,
              createdAt: props.data.createdAt,
            })
          } catch (error) {
            message.error(getMessageFromError(error))
          }
          setLoading(false)
        },
      })
    } else if (e.key === 'edit') {
      props.onAction?.(ActionType.Edit, {
        ...props.data,
        organizationId: props.data.organizationId ?? 0,
        agentName: props.data.name,
        agentIconID: props.data.iconId ?? 0,
        agentIconUUID: props.data.iconUuid ?? '',
      })
    }
  }

  const getAgentIcon = () => {
    if (props.data.agentType === 'chatbot') {
      return <Icon component={RobotColor} style={{ fontSize: '52px' }} />
    } else if (props.data.agentType === 'smart_api') {
      return <Icon component={SmartApiColor} style={{ fontSize: '52px' }} />
    } else {
      return <QuestionCircleOutlined style={{ fontSize: '36px' }} />
    }
  }

  return (
    <Spin tip="Loading..." spinning={loading}>
      <article className={styles.agentCard}>
        <header className={styles.agentHeader}>
          <section className={styles.agentInfo}>
            <Typography.Title
              level={5}
              ellipsis={{
                tooltip: props.data.name,
              }}
              className={styles.agentName}
            >
              {props.data.name}
            </Typography.Title>
            <time
              className={styles.agentUpdateTime}
              dateTime="2024-12-12T12:00:00"
            >
              Update time {formatDate(props.data.updatedAt)}
            </time>
          </section>
          <figure className={styles.agentIcon}>
            {isEmpty(props.data.iconUuid) ? (
              <Avatar
                shape="square"
                size={54}
                icon={getAgentIcon()}
                alt={`${props.data.name} default icon`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                }}
              />
            ) : (
              <Avatar
                shape="square"
                size={54}
                src={getFileUrl(props.data.iconUuid ?? '')}
                alt={`${props.data.name} icon`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            )}
          </figure>
        </header>

        <section className={styles.agentDescription}>
          <Typography.Paragraph ellipsis={{ rows: 3 }}>
            {props.data.description}
          </Typography.Paragraph>
        </section>

        <footer className={styles.agentFooter}>
          <Tag
            icon={AgentTypeIcons[props.data.agentType]}
            color="default"
            bordered={false}
          >
            {AgentTypes[props.data.agentType] ?? props.data.agentType}
          </Tag>
          <nav className={styles.agentActions}>
            <div className={styles.actionButtons}>
              <Tooltip title="Start">
                <Link to={`/portal/agent/chat?agent_uuid=${props.data.uuid}`}>
                  <Button
                    type="text"
                    icon={<MessageOutlined />}
                    aria-label="Start chat with agent"
                  />
                </Link>
              </Tooltip>
              <Tooltip title="Studio">
                <Button
                  type="text"
                  icon={<BranchesOutlined />}
                  onClick={handleModifyButtonClick}
                  aria-label="Open agent studio"
                />
              </Tooltip>
            </div>
            <Dropdown
              menu={{
                items: [
                  {
                    label: 'Edit Info',
                    key: 'edit',
                  },
                  {
                    label: 'Delete',
                    key: 'delete',
                  },
                ],
                onClick: handleMenuClick,
              }}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                type="text"
                icon={<EllipsisOutlined />}
                aria-label="Agent actions menu"
                aria-haspopup="true"
              />
            </Dropdown>
          </nav>
        </footer>
      </article>
    </Spin>
  )
}

export default observer(AgentCard)
