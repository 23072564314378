import { App, Button, Form, Input, Modal } from 'antd'
import { ReactNode, useEffect, useState } from 'react'
import { getMessageFromError } from 'utils/common'
import {
  v1KnowledgeCreateCreate,
  v1KnowledgeUpdatePartialUpdate,
} from 'api/Api'
import { ResponsesKnowledgeResponse } from 'api/data-contracts'

export interface KnowledgeModalProps {
  data: {
    id?: number
    name: string
    description: string
  }
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (knowledge: ResponsesKnowledgeResponse) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const KnowledgeModal: React.FC<KnowledgeModalProps> = (props) => {
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      name: props.data.name,
      description: props.data.description,
    })
  }, [form, props.data])

  const handleSave = async (values: KnowledgeModalProps['data']) => {
    setConfirmLoading(true)
    try {
      let knowledgeId = props.data.id
      if (knowledgeId === undefined) {
        const response = await v1KnowledgeCreateCreate({
          knowledgeName: values.name,
          description: values.description,
        })
        return response.data
      } else {
        const response = await v1KnowledgeUpdatePartialUpdate({
          id: knowledgeId,
          knowledgeName: values.name,
          description: values.description,
        })
        return response.data
      }
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async (values: KnowledgeModalProps['data']) => {
    const knowledge = await handleSave(values)
    if (!knowledge) {
      return
    }
    if (props.onFinish) {
      props.onFinish(knowledge)
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  return (
    <Modal
      title={
        props.title ?? (props.data.id ? 'Edit Knowledge' : 'New Knowledge')
      }
      open={props.open}
      okText="Save"
      onOk={form.submit}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        style={{ maxWidth: 600, paddingBottom: '8px' }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: false, message: '' }]}
        >
          <Input.TextArea showCount maxLength={150} rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default KnowledgeModal
