import {
  DownOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import {
  Alert,
  Button,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Tree,
  Typography,
} from 'antd'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import WorkflowStore from 'stores/workflow'
import InputField from '../../components/input-field'
import NodeSidebar from '../../components/node-sidebar'
import Section from '../../components/section'
import { FlowContext } from '../../context'
import {
  APIData,
  CustomNodeProps,
  DataType,
  HTTPRequestType,
  InputField as InputFieldType,
  NodeData,
  OutputField,
} from '../../model'
import ResponseModal, { ResponseModalRef } from './response'

const { TextArea } = Input
const { Text } = Typography
const { Option } = Select
const { TreeNode } = Tree

const HTTPRequestNodeSidebar: React.FC = observer(() => {
  const context = useContext(FlowContext)
  const responseModalRef = useRef<ResponseModalRef>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const selectedNode = WorkflowStore.selectedNode
  const nodeData = selectedNode?.data?.data

  const getExtendsValue = (name: string) => nodeData?.extends?.[name]

  const [urlInputValue, setUrlInputValue] = useState(
    getExtendsValue('url') || ''
  )

  useEffect(() => {
    setUrlInputValue(getExtendsValue('url') || '')
  }, [nodeData])

  const handleNodeDataChange = (
    field: string,
    value: any,
    dataType: DataType = 'String'
  ) => {
    if (selectedNode?.data?.data) {
      const currentNodeData = { ...selectedNode.data.data }
      let updatedExtends = { ...currentNodeData.extends } as APIData
      let updatedOutput = [...(currentNodeData.output || [])]

      if (field === 'label' || field === 'description') {
        currentNodeData[field] = value
      } else if (field === 'extends') {
        updatedExtends = value
      } else if (field in updatedExtends) {
        Reflect.set(updatedExtends, field, value)
        if (field === 'type') {
          updatedOutput = updateOutputs(value as HTTPRequestType, updatedOutput)
        }
      } else if (field === 'output') {
        updatedOutput = value
      }

      const updatedNodeData = {
        ...(selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          extends: updatedExtends,
          output: updatedOutput,
        },
      }

      const updatedNode = {
        ...selectedNode,
        data: updatedNodeData,
      }
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      selectedNode.data.onChange?.(selectedNode.id, updatedNodeData)
    }
  }

  const extractPathParameters = (
    url: string,
    existingParams: InputFieldType[]
  ): InputFieldType[] => {
    const regex = /{([^}]+)}/g
    const matches = url.match(regex) || []
    return matches.map((match) => {
      const paramName = match.slice(1, -1)
      return (
        existingParams.find((param) => param.name === paramName) || {
          name: paramName,
          type: 'input',
          dataType: 'String',
          value: '',
          reference: '',
        }
      )
    })
  }

  const handleUrlChange = (url: string) => {
    const currentNodeData = { ...nodeData }
    let updatedExtends = { ...currentNodeData.extends } as APIData
    const existingPathParams = updatedExtends.pathParameters || []

    updatedExtends.url = url
    updatedExtends.pathParameters = extractPathParameters(
      url,
      existingPathParams
    )

    handleNodeDataChange('extends', updatedExtends)
  }

  const handleUrlInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrlInputValue(e.target.value)
  }

  const handleUrlInputBlur = () => {
    handleUrlChange(urlInputValue)
  }

  const updateOutputs = (
    type: HTTPRequestType,
    outputs: OutputField[]
  ): OutputField[] => {
    if (type === 'webpage') {
      return [
        {
          name: 'text',
          type: 'String' as DataType,
          description: 'Webpage content',
          required: true,
          children: [],
        },
      ]
    }
    return outputs
  }

  const handleOutputVariablesChange = (fields: OutputField[]) => {
    handleNodeDataChange('output', fields)
  }

  const showModal = () => setIsModalVisible(true)
  const handleModalOk = (fields: OutputField[]) => {
    handleOutputVariablesChange(fields)
    setIsModalVisible(false)
  }
  const handleModalCancel = () => setIsModalVisible(false)
  const handleClose = () => WorkflowStore.selectNode(null)

  const handleAddField = (
    fieldType:
      | 'requestHeader'
      | 'requestBody'
      | 'queryParameters'
      | 'pathParameters'
  ) => {
    const currentFields = getExtendsValue(fieldType) || []
    const newField: InputFieldType = {
      name: '',
      type: 'input',
      dataType: 'String',
      value: '',
      reference: '',
    }
    handleNodeDataChange(fieldType, [...currentFields, newField])
  }

  const handleRemoveField = (
    fieldType:
      | 'requestHeader'
      | 'requestBody'
      | 'queryParameters'
      | 'pathParameters',
    index: number
  ) => {
    const currentFields = getExtendsValue(fieldType) || []
    handleNodeDataChange(
      fieldType,
      currentFields.filter((_: any, i: number) => i !== index)
    )
  }

  const handleFieldChange = (
    fieldType:
      | 'requestHeader'
      | 'requestBody'
      | 'queryParameters'
      | 'pathParameters',
    index: number,
    key: keyof InputFieldType,
    value: any
  ) => {
    const currentFields = getExtendsValue(fieldType) || []
    const updatedFields = currentFields.map(
      (field: InputFieldType, i: number) =>
        i === index ? { ...field, [key]: value } : field
    )
    handleNodeDataChange(fieldType, updatedFields)
  }

  const renderTreeNodes = (data: OutputField[]) => {
    return data.map((item) => {
      const title = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Space>
            <span>{item.name}</span>
            <span style={{ color: '#888' }}>[{item.type}]</span>
          </Space>
        </div>
      )

      if (item.children && item.children.length) {
        return (
          <TreeNode title={title} key={item.name}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        )
      }
      return <TreeNode title={title} key={item.name} />
    })
  }

  useEffect(() => {
    if (nodeData) {
      const updatedOutputs = updateOutputs(
        nodeData.extends?.type,
        nodeData.output || []
      )
      handleNodeDataChange('output', updatedOutputs)
    }
  }, [])

  if (selectedNode?.type !== 'HTTPRequest') {
    return null
  }

  return (
    <NodeSidebar
      nodeType={'http'}
      onClose={handleClose}
      nodeData={WorkflowStore.selectedNode?.data?.data}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={nodeData?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>

      <Section title="Settings">
        <div className="template-editor-item">
          <span className="label">URL</span>
          <div className="element">
            <Input
              value={urlInputValue}
              onChange={handleUrlInputChange}
              onBlur={handleUrlInputBlur}
              placeholder="Enter URL"
            />
          </div>
        </div>
        <div className="setting-item">
          <span>Request Type</span>
          <div className="element">
            <Select
              value={getExtendsValue('type')}
              onChange={(value) => handleNodeDataChange('type', value)}
              style={{ width: '205px' }}
            >
              <Option value="webpage">Web Page</Option>
              <Option value="restapi">REST API</Option>
            </Select>
          </div>
        </div>
        {getExtendsValue('type') === 'webpage' && (
          <div className="setting-item">
            <span>Remove HTML Tags</span>
            <Switch
              checked={getExtendsValue('htmlFilter')}
              onChange={(checked: boolean) =>
                handleNodeDataChange('htmlFilter', checked)
              }
            />
          </div>
        )}
        <div className="setting-item">
          <span>Method</span>
          <div className="element">
            <Select
              value={getExtendsValue('method')}
              onChange={(value) => handleNodeDataChange('method', value)}
              style={{ width: '205px' }}
            >
              <Option value="GET">GET</Option>
              <Option value="POST">POST</Option>
              <Option value="PUT">PUT</Option>
              <Option value="PATCH">PATCH</Option>
              <Option value="DELETE">DELETE</Option>
            </Select>
          </div>
        </div>
        <div className="setting-item">
          <span>Timeout (seconds)</span>
          <div className="element">
            <InputNumber
              value={getExtendsValue('timeout')}
              onChange={(value) =>
                handleNodeDataChange('timeout', value, 'Number')
              }
              min={1}
              max={300}
              style={{ width: '205px' }}
            />
          </div>
        </div>
        <div className="setting-item">
          <span>Cache</span>
          <Switch
            checked={getExtendsValue('cache')}
            onChange={(checked: boolean) =>
              handleNodeDataChange('cache', checked)
            }
          />
        </div>
        {getExtendsValue('cache') && (
          <div className="setting-item">
            <span className="label">Cache Timeout(seconds)</span>
            <div className="element">
              <InputNumber
                value={getExtendsValue('cacheTimeout')}
                onChange={(value) =>
                  handleNodeDataChange('cacheTimeout', value, 'Number')
                }
                min={1}
                max={300}
                style={{ width: '205px' }}
              />
            </div>
          </div>
        )}
      </Section>

      <Section title="Path Parameters">
        {(getExtendsValue('pathParameters') || []).length === 0 && (
          <Alert
            description={
              <div>
                <div style={{ fontWeight: 'bold' }}>Adding Path Parameters</div>
                <Text>
                  To include path parameters in your URL, use curly braces like{' '}
                  {'{parameter}'}.
                </Text>
                <div
                  style={{
                    background: '#fff',
                    padding: '8px',
                    borderRadius: '4px',
                    marginTop: '8px',
                    border: '1px solid #ddd',
                  }}
                >
                  Example: https://www.google.com/search?q={'{keyword}'}
                </div>
              </div>
            }
            style={{
              backgroundColor: '#f9f9f9',
              border: '1px solid #f3f3f3',
            }}
          />
        )}
        {(getExtendsValue('pathParameters') || []).map(
          (field: InputFieldType, index: number) => (
            <div
              key={index}
              style={{
                marginBottom: '8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <InputField
                index={index}
                item={field}
                currentNodeId={selectedNode?.id || ''}
                nodes={context?.nodes || []}
                edges={context?.edges || []}
                allowEditFieldName={false}
                allowEditDataType={true}
                supportArray={false}
                supportObject={false}
                displayDataType={true}
                displayFieldName={true}
                handleFormChange={(_, key, value) =>
                  handleFieldChange('pathParameters', index, key, value)
                }
              />
            </div>
          )
        )}
      </Section>

      <Section title="Request Headers">
        {(getExtendsValue('requestHeader') || []).map(
          (field: InputFieldType, index: number) => (
            <div
              key={index}
              style={{
                marginBottom: '8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Space>
                <InputField
                  index={index}
                  item={field}
                  currentNodeId={selectedNode?.id || ''}
                  nodes={context?.nodes || []}
                  edges={context?.edges || []}
                  allowEditFieldName={true}
                  supportArray={false}
                  supportObject={false}
                  displayDataType={true}
                  displayFieldName={true}
                  handleFormChange={(_, key, value) =>
                    handleFieldChange('requestHeader', index, key, value)
                  }
                  labelInputProps={{ width: 120 }}
                />
                <Button
                  icon={<MinusCircleOutlined />}
                  onClick={() => handleRemoveField('requestHeader', index)}
                />
              </Space>
            </div>
          )
        )}
        <Button
          icon={<PlusOutlined />}
          onClick={() => handleAddField('requestHeader')}
          type="dashed"
          style={{ width: '100%' }}
        >
          Add Header
        </Button>
      </Section>

      <Section title="Query Parameters">
        {(getExtendsValue('queryParameters') || []).map(
          (field: InputFieldType, index: number) => (
            <div
              key={index}
              style={{
                marginBottom: '8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Space>
                <InputField
                  index={index}
                  item={field}
                  currentNodeId={selectedNode?.id || ''}
                  nodes={context?.nodes || []}
                  edges={context?.edges || []}
                  allowEditFieldName={true}
                  supportArray={false}
                  supportObject={false}
                  displayDataType={true}
                  displayFieldName={true}
                  handleFormChange={(_, key, value) =>
                    handleFieldChange('queryParameters', index, key, value)
                  }
                />
                <Button
                  icon={<MinusCircleOutlined />}
                  onClick={() => handleRemoveField('queryParameters', index)}
                />
              </Space>
            </div>
          )
        )}
        <Button
          icon={<PlusOutlined />}
          onClick={() => handleAddField('queryParameters')}
          type="dashed"
          style={{ width: '100%' }}
        >
          Add Query Parameter
        </Button>
      </Section>
      <Section title="Request Body">
        {(getExtendsValue('requestBody') || []).map(
          (field: InputFieldType, index: number) => (
            <div
              key={index}
              style={{
                marginBottom: '8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Space>
                <InputField
                  index={index}
                  item={field}
                  currentNodeId={WorkflowStore.selectedNode?.id || ''}
                  nodes={context?.nodes || []}
                  edges={context?.edges || []}
                  allowEditFieldName={true}
                  supportArray={false}
                  supportObject={false}
                  displayDataType={true}
                  displayFieldName={true}
                  handleFormChange={(_, key, value) =>
                    handleFieldChange('requestBody', index, key, value)
                  }
                />
                <Button
                  icon={<MinusCircleOutlined />}
                  onClick={() => handleRemoveField('requestBody', index)}
                />
              </Space>
            </div>
          )
        )}
        <Button
          icon={<PlusOutlined />}
          onClick={() => handleAddField('requestBody')}
          type="dashed"
          style={{ width: '100%' }}
        >
          Add Body Field
        </Button>
      </Section>

      <Section title="Response">
        <Tree defaultExpandAll showLine switcherIcon={<DownOutlined />}>
          {renderTreeNodes(
            WorkflowStore.selectedNode?.data?.data?.output || []
          )}
        </Tree>
        {getExtendsValue('type') === 'restapi' && (
          <Button
            onClick={showModal}
            style={{ width: '100%', marginTop: '10px' }}
            icon={<SettingOutlined />}
          >
            Response Structure Settings
          </Button>
        )}
      </Section>

      <ResponseModal
        ref={responseModalRef}
        isVisible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        initialValue={WorkflowStore.selectedNode?.data?.data?.output || []}
      />
    </NodeSidebar>
  )
})

export default HTTPRequestNodeSidebar
