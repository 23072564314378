import { ModelsConversationRoleType } from 'api/data-contracts'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { deepCopy } from 'utils/common'
import QuestionModal, { QuestionModalProps } from './question-modal'
import QuestionTable, { ActionType, QuestionTableRef } from './question-table'
import UploadModal from './upload-modal'

const DEFAULT_QUESTION_DATA: QuestionModalProps['data'] = {
  agentId: 0,
  testCase: '',
  questionAnswers: [{ role: ModelsConversationRoleType.RoleUser, content: '' }],
  expectedAnswer: '',
}

interface QuestionPageProps {
  agentId: number
  createQuestionModalOpen: boolean
  importQuestionsModalOpen: boolean
  isEditingQuestion: boolean
  onCreateQuestionModalClose: () => void
  onImportQuestionsModalClose: () => void
  onEditQuestion: () => void
}

const QuestionPage: React.FC<QuestionPageProps> = ({
  agentId,
  createQuestionModalOpen,
  importQuestionsModalOpen,
  isEditingQuestion,
  onCreateQuestionModalClose,
  onImportQuestionsModalClose,
  onEditQuestion,
}) => {
  const [question, setQuestion] = useState<QuestionModalProps['data']>(
    DEFAULT_QUESTION_DATA
  )
  const questionTableRef = React.createRef<QuestionTableRef>()

  // Reset question data when creating a new question
  useEffect(() => {
    if (createQuestionModalOpen && !isEditingQuestion) {
      setQuestion(
        deepCopy({
          ...DEFAULT_QUESTION_DATA,
          agentId: agentId,
        })
      )
    }
  }, [createQuestionModalOpen, isEditingQuestion, agentId])

  return (
    <>
      <QuestionTable
        ref={questionTableRef}
        agentId={agentId}
        onAction={(type, data) => {
          if (type === ActionType.Edit) {
            setQuestion({
              id: data.id,
              agentId: data.agentID,
              testCase: data.testCase,
              questionAnswers: data.questionAnswers,
              expectedAnswer: data.expectedAnswer,
            })
            onEditQuestion()
          }
        }}
      />
      <QuestionModal
        open={createQuestionModalOpen}
        title={!!question.id ? 'Edit Question' : 'Create Question'}
        okText="Save"
        data={{
          ...question,
        }}
        onFinish={() => {
          questionTableRef.current?.refresh()
          onCreateQuestionModalClose()
        }}
        onCancel={onCreateQuestionModalClose}
      />
      <UploadModal
        open={importQuestionsModalOpen}
        title={'Import Questions'}
        okText="Done"
        data={{
          agentId: agentId,
        }}
        onFinish={() => {
          questionTableRef.current?.refresh()
          onImportQuestionsModalClose()
        }}
        onCancel={onImportQuestionsModalClose}
      />
    </>
  )
}

export default observer(QuestionPage)
