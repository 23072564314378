import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DoubleRightOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Flex, MenuProps, Typography } from 'antd'
import { NodeStatusNodeStatus } from 'api/data-contracts'
import cls from 'classnames'
import { useMemo, useState } from 'react'
import DetailsModal from './details-modal'
import styles from './index.scss'

export type WorkflowNodeInfo = {
  nodeId: string
  nodeName: string
  nodeType: string
  startTime: string
  endTime: string
  status: NodeStatusNodeStatus
  logs: string
  details: string
  durationInMilliseconds: number
}

const getNodeStatusIcon = (status: NodeStatusNodeStatus) => {
  let icon = <></>
  switch (status) {
    case NodeStatusNodeStatus.Success:
      icon = <CheckCircleOutlined style={{ color: '#199459' }} />
      break
    case NodeStatusNodeStatus.Failed:
      icon = <ExclamationCircleOutlined style={{ color: '#D94032' }} />
      break
    case NodeStatusNodeStatus.Processing:
      icon = <LoadingOutlined style={{ color: '#3278D9' }} />
      break
    case NodeStatusNodeStatus.Initiating:
      icon = <ClockCircleOutlined />
      break
  }
  return icon
}

const renderCurrentStatus = (status: NodeStatusNodeStatus) => {
  const icon = getNodeStatusIcon(status)
  switch (status) {
    case NodeStatusNodeStatus.Success:
      return <>{icon} Workflow Execution Successes</>
    case NodeStatusNodeStatus.Failed:
      return <>{icon} Workflow Execution Failed</>
    case NodeStatusNodeStatus.Processing:
      return <>{icon} Workflow Execution Processing</>
    case NodeStatusNodeStatus.Initiating:
      return <>{icon} Workflow Execution Initiating</>
  }
}

export interface WorkflowStatusDropdownProps {
  isLoading: boolean
  msgId: string
  data: WorkflowNodeInfo[]
}

const WorkflowStatusDropdown: React.FC<WorkflowStatusDropdownProps> = (
  props
) => {
  const [initialized, setInitialized] = useState(false)
  const [logsModalVisible, setLogsModalVisible] = useState(false)
  const [detailsNodeIndex, setDetailsNodeIndex] = useState(1)

  const items: MenuProps['items'] = useMemo(
    () =>
      props.data.map((data, index) => ({
        key: `${index}_${data.nodeId}`,
        label: (
          <Flex
            className={cls({
              [styles.workflowStatusItem]: true,
              [styles.workflowStatusItemSuccess]: data.status === 'success',
              [styles.workflowStatusItemFailed]: data.status === 'failed',
              [styles.workflowStatusItemInitiating]:
                data.status === 'initiating',
              [styles.workflowStatusItemProcessing]:
                data.status === 'processing',
            })}
          >
            <Flex className="node-no">{index + 1}</Flex>
            <Flex vertical>
              <Typography.Link
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.currentTarget.classList.toggle('active')
                    e.preventDefault()
                    e.stopPropagation()
                  }
                }}
                className="node-body"
                title={`[${data.status}] ${data.nodeName}`}
                onClick={(e) => {
                  e.currentTarget.classList.toggle('active')
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <Flex>
                  <Flex className="node-status">
                    {getNodeStatusIcon(data.status)}
                  </Flex>
                  <Flex className="node-name">{data.nodeName}</Flex>
                  <Flex className="node-duration">
                    {(data.durationInMilliseconds / 1000).toFixed(2)}s
                  </Flex>
                </Flex>
              </Typography.Link>
              <Typography.Link
                className="node-logs"
                onClick={() => {
                  setDetailsNodeIndex(index)
                  setLogsModalVisible(true)
                }}
              >
                <Flex vertical>
                  <pre>{data.logs}</pre>
                </Flex>
              </Typography.Link>
            </Flex>
          </Flex>
        ),
      })),
    [props.data]
  )

  let lastNode: WorkflowNodeInfo = props.data[props.data.length - 1]

  if (!lastNode) {
    if (!props.isLoading) {
      return <></>
    } else {
      lastNode = {
        nodeId: 'start',
        nodeName: 'Start',
        nodeType: 'start',
        startTime: '',
        endTime: '',
        status: NodeStatusNodeStatus.Processing,
        logs: '',
        details: '[]',
        durationInMilliseconds: 0,
      }
    }
  }

  return (
    <>
      <Dropdown
        menu={{ items }}
        placement="bottomLeft"
        className={cls({
          [styles.workflowStatusWrapper]: true,
          [styles.workflowStatusWrapperSuccess]: lastNode.status === 'success',
          [styles.workflowStatusWrapperFailed]: lastNode.status === 'failed',
          [styles.workflowStatusWrapperInitiating]:
            lastNode.status === 'initiating',
          [styles.workflowStatusWrapperProcessing]:
            lastNode.status === 'processing',
        })}
        overlayClassName={styles.workflowStatusOverlay}
        onOpenChange={(open) => {
          if (open) {
            setInitialized(true)
          }
        }}
      >
        <Button type="text">
          {renderCurrentStatus(lastNode.status)}
          <DoubleRightOutlined />
        </Button>
      </Dropdown>
      {initialized && (
        <DetailsModal
          open={logsModalVisible}
          msgId={props.msgId}
          nodeIndex={detailsNodeIndex}
          onCancel={() => setLogsModalVisible(false)}
        />
      )}
    </>
  )
}

export default WorkflowStatusDropdown
