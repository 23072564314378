import { useEffect, useMemo, useState } from 'react'
import { Input, Select, Space, TreeSelect } from 'antd'

import ItemList from '../item-list'
import { cloneDeep, get } from 'lodash-es'
import { useFormContext } from 'components/new-dynamic-form/context'
import { FormItemType } from 'components/new-dynamic-form/types'

enum Operator {
  Equal = '===',
  NotEqual = '!==',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqual = '>=',
  LessThanOrEqual = '<=',
}
const OperatorOptions = [
  { label: Operator.Equal, value: Operator.Equal },
  { label: Operator.NotEqual, value: Operator.NotEqual },
  { label: Operator.GreaterThan, value: Operator.GreaterThan },
  { label: Operator.LessThan, value: Operator.LessThan },
  { label: Operator.GreaterThanOrEqual, value: Operator.GreaterThanOrEqual },
  { label: Operator.LessThanOrEqual, value: Operator.LessThanOrEqual },
]

export type ConditionRuleType = {
  targetKey: string
  operator: Operator
  targetVal: string
}

type ConditionProps = {
  value?: Array<Array<ConditionRuleType>>
  onChange?: (v: Array<Array<ConditionRuleType>>) => void
}

const defaultValue: ConditionRuleType[] = [
  { targetKey: '', operator: Operator.Equal, targetVal: '' },
]

const convertFormItemsToTreeData = (
  formItems: FormItemType[],
  pathPrefix = ''
): any[] => {
  return formItems.map((item) => ({
    title: item.props.formItemConfig?.label || item.formItemId,
    value: `${pathPrefix}${pathPrefix ? '.' : ''}${item.props.formItemConfig?.name || item.formItemId}`,
    children:
      item?.children?.length > 0
        ? convertFormItemsToTreeData(
            item.children,
            `${pathPrefix}${pathPrefix ? '.' : ''}${item.props.formItemConfig?.name || item.formItemId}`
          )
        : [],
  }))
}

const Condition = (props: ConditionProps) => {
  const { onChange, value } = props
  const [curValues, setCurValues] = useState(value ?? [])
  const { root } = useFormContext() ?? {}
  const curTargetKeyTreeData = useMemo(() => {
    return convertFormItemsToTreeData(root?.children ?? [])
  }, [root])

  const handleValueChange = (
    pos: [number, number],
    key: keyof ConditionRuleType,
    value: string
  ) => {
    const [groupIdx, ruleIdx] = pos
    const newValues = cloneDeep(curValues)
    const curRule = cloneDeep(newValues[groupIdx][ruleIdx])
    Reflect.set(curRule, key, value)
    newValues[groupIdx].splice(ruleIdx, 1, curRule)
    onChange?.(newValues)
  }

  const handleAddRuleGroup = () => {
    const newValues = cloneDeep(curValues)
    newValues.push(cloneDeep(defaultValue))
    onChange?.(newValues)
  }
  const handleDeleteRuleGroup = (ruleGroupIdx: number) => {
    const newValues = cloneDeep(curValues)
    newValues.splice(ruleGroupIdx, 1)
    onChange?.(newValues)
  }
  const handleAddRule = (ruleGroupIdx: number) => {
    const newValues = cloneDeep(curValues)
    newValues[ruleGroupIdx].push(cloneDeep(defaultValue[0]))
    onChange?.(newValues)
  }
  const handleDeleteRule = (ruleGroupIdx: number, ruleIdx: number) => {
    const newValues = cloneDeep(curValues)
    newValues[ruleGroupIdx].splice(ruleIdx, 1)
    onChange?.(newValues.filter((group) => group.length))
  }

  useEffect(() => {
    if (!value) return
    setCurValues(value)
  }, [value])

  return (
    <ItemList
      data={curValues?.map((ruleGroup, groupIdx) => ({
        render: (
          <ItemList
            data={ruleGroup.map((rule, ruleIdx) => {
              const { targetKey, operator, targetVal } = rule
              return {
                label: `Rule${ruleIdx}`,
                render: (
                  <Space.Compact>
                    <TreeSelect
                      value={targetKey}
                      treeData={curTargetKeyTreeData}
                      popupMatchSelectWidth={false}
                      onChange={(v) =>
                        handleValueChange([groupIdx, ruleIdx], 'targetKey', v)
                      }
                    />
                    <Select
                      value={operator}
                      options={OperatorOptions}
                      onChange={(v) =>
                        handleValueChange([groupIdx, ruleIdx], 'operator', v)
                      }
                    />
                    <Input
                      value={targetVal}
                      onChange={(e) =>
                        handleValueChange(
                          [groupIdx, ruleIdx],
                          'targetVal',
                          e.target.value
                        )
                      }
                    />
                  </Space.Compact>
                ),
              }
            })}
            onAddItem={() => handleAddRule(groupIdx)}
            onDeleteItem={(rIdx) => handleDeleteRule(groupIdx, rIdx)}
          />
        ),
      }))}
      onAddItem={handleAddRuleGroup}
      onDeleteItem={handleDeleteRuleGroup}
    />
  )
}

export const isVisible = (
  rules: ConditionRuleType[][],
  values: any,
  path?: string
) => {
  for (const ruleGroup of rules) {
    let allConditionsMet = true

    for (const rule of ruleGroup) {
      const { targetKey, operator, targetVal } = rule
      if ([targetKey, operator, targetVal].some((i) => i === '')) {
        return true
      }
      const curTargetVal = `${targetVal}`
      const realTargetValue = `${
        path ? get(values, path) : get(values, targetKey)
      }`

      switch (operator) {
        case Operator.Equal: {
          allConditionsMet = !(realTargetValue !== curTargetVal)
          break
        }
        case Operator.NotEqual: {
          allConditionsMet = !(realTargetValue === curTargetVal)
          break
        }
        case Operator.GreaterThan: {
          allConditionsMet = !(realTargetValue <= curTargetVal)
          break
        }
        case Operator.LessThan: {
          allConditionsMet = !(realTargetValue >= curTargetVal)
          break
        }
        case Operator.GreaterThanOrEqual: {
          allConditionsMet = !(realTargetValue < curTargetVal)
          break
        }
        case Operator.LessThanOrEqual: {
          allConditionsMet = !(realTargetValue > curTargetVal)
          break
        }
        default:
          break
      }
    }

    if (allConditionsMet) return true
  }

  return false
}

export default Condition
