import {
  ConfigSectionType,
  FormItemType,
} from 'components/new-dynamic-form/types'
import { CommonConfig } from '../constants'

const ItemTextAreaConfig: ConfigSectionType[] = [
  ...CommonConfig,
  {
    sectionLabel: 'textAreaConfig',
    items: [
      { itemLabel: 'DefaultValue', itemName: 'defaultValue', type: 'switch' },
      { itemLabel: 'Disabled', itemName: 'disabled', type: 'switch' },
      {
        itemLabel: 'AutoSize',
        itemName: 'autoSize',
        type: 'subObject',
        children: [
          {
            itemLabel: '',
            placeholder: 'MinRows',
            itemName: 'minRows',
            type: 'inputNumber',
            style: { width: '100%' },
          },
          {
            itemLabel: '',
            placeholder: 'MaxRows',
            itemName: 'maxRows',
            type: 'inputNumber',
            style: { width: '100%' },
          },
        ],
      },
    ],
  },
]

export default ItemTextAreaConfig
