import { memo, useMemo } from 'react'
import { Slider, SliderSingleProps } from 'antd'
import ComponentWrapper from '../lib/component-wrapper'
import { omitBy } from 'lodash-es'
import { getCommonDefaultValue } from '../constants'

type ItemSliderProps = { sliderConfig?: SliderSingleProps } & Record<
  string,
  any
>

export const getDefaultValue = () => getCommonDefaultValue('slider')

const ItemSwitch = (props: ItemSliderProps) => {
  const { sliderConfig } = props

  const filterProps = useMemo(
    () => omitBy(props, (_, k) => k !== 'onChange' && /[A-Z]/.test(k)),
    [props]
  )

  return <Slider {...filterProps} {...sliderConfig} />
}

export default ComponentWrapper(memo(ItemSwitch))
