import { Tabs } from 'antd'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Questions from './components/questions'
import Reports from './components/reports'
import TabActions from './components/tab-actions'
import useQuestionsModals from './hooks/use-questions-modal'
import useReportsModals from './hooks/use-reports-modals'
import styles from './index.scss'

const EvaluationPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { tab } = useParams()
  const agentId = parseInt(searchParams.get('id') ?? '')
  const [activeTab, setActiveTab] = useState<string>(tab ?? 'questions')
  const questionsModals = useQuestionsModals()
  const reportsModals = useReportsModals()

  if (!agentId) {
    throw new Error('Agent ID is missing from url')
  }

  const handleTabChange = (key: string) => {
    setActiveTab(key)
    navigate({
      pathname: key,
      search: location.search
    })
  }


  return (
    <div className={styles.evaluationWrapper}>
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        className={styles.evaluationTabs}
        items={[
          {
            key: 'questions',
            label: 'Questions',
            children: (
              <Questions
                agentId={agentId}
                createQuestionModalOpen={
                  questionsModals.createQuestionModalOpen
                }
                importQuestionsModalOpen={
                  questionsModals.importQuestionsModalOpen
                }
                onCreateQuestionModalClose={
                  questionsModals.closeCreateQuestionModal
                }
                onImportQuestionsModalClose={
                  questionsModals.closeImportQuestionsModal
                }
                isEditingQuestion={questionsModals.isEditingQuestion}
                onEditQuestion={() =>
                  questionsModals.openCreateQuestionModal(true)
                }
              />
            ),
          },
          {
            key: 'reports',
            label: 'Reports',
            children: (
              <Reports
                agentId={agentId}
                evaluateAgentModalOpen={reportsModals.evaluateAgentModalOpen}
                onEvaluateAgentModalClose={
                  reportsModals.closeEvaluateAgentModal
                }
              />
            ),
          },
        ]}
        tabBarExtraContent={{
          right: (
            <TabActions
              activeTab={activeTab}
              questionsModals={questionsModals}
              reportsModals={reportsModals}
            />
          ),
        }}
      />
    </div>
  )
}

export default observer(EvaluationPage)
