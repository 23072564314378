import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';

interface SectionProps {
  title: string;
  children: React.ReactNode;
  defaultCollapsed?: boolean;
}

const Section: React.FC<SectionProps> = ({ title, children, defaultCollapsed = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  return (
    <div className="custom-section">
      <div className="section-header" onClick={() => setIsCollapsed(!isCollapsed)} role="button" tabIndex={0} onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          setIsCollapsed(!isCollapsed);
        }
      }}>
        <h3>{title}</h3>
        <Button
          type="text"
          icon={isCollapsed ? <RightOutlined /> : <DownOutlined />}
        />
      </div>
      {!isCollapsed && <div className="section-content">{children}</div>}
    </div>
  );
};

export default Section;