import React, { memo, useMemo } from 'react'

import { Menu, type MenuProps } from 'antd'
import {
  RobotOutlined,
  FilterOutlined,
  AimOutlined,
  BranchesOutlined,
  CodeOutlined,
  ApiOutlined,
  BookOutlined,
  FileOutlined,
} from '@ant-design/icons'

import { type CustomNodeTypes } from '../../nodes/types'

import styles from './index.scss'

export type ToolMenuItemType = {
  icon: React.ReactNode
  name: string
  color: string
  type: CustomNodeTypes
}

export type ToolMenuSectionType = {
  title: string
  tools: ToolMenuItemType[]
}

const toolSections: ToolMenuSectionType[] = [
  {
    title: 'Models',
    tools: [
      { icon: <RobotOutlined />, name: 'LLM', color: '#1890ff', type: 'LLM' },
      {
        icon: <FilterOutlined />,
        name: 'PII Filter',
        color: '#52c41a',
        type: 'PII',
      },
    ],
  },
  {
    title: 'Logic',
    tools: [
      {
        icon: <AimOutlined />,
        name: 'Intent Detector',
        color: '#faad14',
        type: 'Intent',
      },
      {
        icon: <CodeOutlined />,
        name: 'Code Interpreter',
        color: '#eb2f96',
        type: 'Code',
      },
      {
        icon: <BranchesOutlined />,
        name: 'Logic Condition',
        color: '#722ed1',
        type: 'Condition',
      },
    ],
  },
  {
    title: 'Knowledge',
    tools: [
      {
        icon: <ApiOutlined />,
        name: 'HTTP Request',
        color: '#13c2c2',
        type: 'HTTPRequest',
      },
      {
        icon: <BookOutlined />,
        name: 'Knowledge Retrieval',
        color: '#fa8c16',
        type: 'Knowledge',
      },
      {
        icon: <FileOutlined />,
        name: 'Document Reader',
        color: '#eb0e98',
        type: 'DocumentReader',
      },
      {
        icon: <FileOutlined />,
        name: 'Template',
        color: '#eb0e98',
        type: 'Template',
      },
    ],
  },
]

type ToolMenuProps = {
  onClick: (toolKey: CustomNodeTypes) => void
}

const ToolMenu = (props: ToolMenuProps) => {
  const { onClick } = props

  const menuItems: MenuProps['items'] = useMemo(
    () =>
      toolSections.map((section, idx) => ({
        key: `section-${idx}`,
        type: 'group',
        label: section.title,
        children: section.tools.map((tool, toolIndex) => ({
          key: `${section.title}-${toolIndex}`,
          icon: <span style={{ color: tool.color }}>{tool.icon}</span>,
          label: tool.name,
          onClick: () => onClick(tool.type),
        })),
      })),
    [onClick]
  )

  return <Menu className={styles.toolMenu} mode="vertical" items={menuItems} />
}

export default memo(ToolMenu)
