export const toLowerFirstLetter = (str: string): string => {
  if (!str) throw new Error('argumment is not a string')
  return str.charAt(0).toLowerCase() + str.slice(1)
}

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) throw new Error('argumment is not a string')
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const camelToWords = (camelCaseString: string): string => {
  return camelCaseString
    .replace(/([A-Z])/g, ' $1')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
    .replace(/^./, (str) => str.toUpperCase())
    .trim()
}
