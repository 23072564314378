import { memo, useMemo } from 'react'
import { Checkbox } from 'antd'
import ComponentWrapper from '../lib/component-wrapper'
import { omitBy } from 'lodash-es'
import { CheckboxGroupProps } from 'antd/es/checkbox'
import { CustomCompactInputValueItemType } from 'components/custom-compact-input'
import { getCommonDefaultValue } from '../constants'

type ItemCheckboxProps = {
  checkboxConfig?: CheckboxGroupProps & Record<string, any>
} & Record<string, any>

export const getDefaultValue = () => getCommonDefaultValue('checkbox')

const ItemCheckbox = (props: ItemCheckboxProps) => {
  const { checkboxConfig } = props

  const filterProps = useMemo(
    () => omitBy(props, (_, k) => k !== 'onChange' && /[A-Z]/.test(k)),
    [props]
  )

  const formatCustomCompactInputValueInput = (
    value?: CustomCompactInputValueItemType[][]
  ): CheckboxGroupProps['options'] => {
    const options: CheckboxGroupProps['options'] = []
    if (!value) return options
    return value.map((row) => {
      const [l, v] = row
      return { label: l?.value, value: v?.value ?? '' }
    })
  }

  const customOptions = useMemo(
    () => formatCustomCompactInputValueInput(checkboxConfig?.options as any),
    [checkboxConfig?.options]
  )

  return (
    <Checkbox.Group
      {...filterProps}
      {...checkboxConfig}
      options={customOptions}
    />
  )
}

export default ComponentWrapper(memo(ItemCheckbox))
