import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Dropdown, MenuProps, Tag, Typography } from 'antd'
import {
  EllipsisOutlined,
  FormOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'

import { getFileUrl, setQuery } from 'utils/common'

import { ResponsesPluginResponse } from 'api/data-contracts'
import { v1PluginsDelete } from 'api/Api'

import styles from './index.scss'

type PluginCardProps = {
  data: ResponsesPluginResponse
  onUpdate?: (...args: any[]) => void
}

const { Paragraph } = Typography
const ActionMenuItem: MenuProps['items'] = [
  { key: 'edit', label: 'Edit Info', icon: <FormOutlined /> },
  { key: 'delete', label: 'Delete', icon: <DeleteOutlined /> },
]

const PluginCard = (props: PluginCardProps) => {
  const { data, onUpdate } = props
  const { icon, name, description, id, updatedAt } = data
  const navigate = useNavigate()

  const handleCardClick = () => {
    const search = setQuery({ opType: 'view' })
    navigate(`/portal/plugin/detail/${id}?${search}`)
  }

  const handleMenuItemClick: MenuProps['onClick'] = (info) => {
    const { key } = info
    if (key === 'edit') {
      const search = setQuery({ opType: 'edit' })
      navigate(`/portal/plugin/detail/${id}?${search}`)
    } else if (key === 'delete') {
      v1PluginsDelete(id).then(() => {
        onUpdate?.({})
      })
    }
  }

  return (
    <div
      className={styles.pluginCardWrapper}
      onClick={handleCardClick}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
    >
      <div className={styles.pluginCardHeaderWrapper}>
        <div className={styles.pluginCardHeaderInfo}>
          <span className={styles.pluginCardHeaderInfoName}>{name}</span>
          <span className={styles.pluginCardHeaderInfoTime}>
            Update time {dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        </div>

        <Avatar
          className={styles.pluginCardHeaderIcon}
          shape="square"
          size={54}
          src={getFileUrl(icon?.uuid ?? '')}
        />
      </div>

      <Paragraph
        ellipsis={{ rows: 3, expandable: true, symbol: null }}
        rootClassName={styles.pluginCardDescription}
      >
        {description}
      </Paragraph>

      <div
        className={styles.pluginCardFooter}
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        {/* TODO@Tanner: API don't have the status */}
        <Tag color="blue">status</Tag>
        <Dropdown
          menu={{ items: ActionMenuItem, onClick: handleMenuItemClick }}
          placement="bottomRight"
        >
          <EllipsisOutlined className={styles.pluginCardFooterActions} />
        </Dropdown>
      </div>
    </div>
  )
}

export default memo(PluginCard)
