import { memo, useMemo } from 'react'
import { DatePicker, DatePickerProps } from 'antd'
import ComponentWrapper from '../lib/component-wrapper'
import { omitBy } from 'lodash-es'
import { getCommonDefaultValue } from '../constants'

type ItemDatePickerProps = {
  datePickerConfig?: DatePickerProps & {
    rangePicker: boolean
    [key: string]: any
  }
} & Record<string, any>

export const getDefaultValue = () => getCommonDefaultValue('datePicker')

const ItemDatePicker = (props: ItemDatePickerProps) => {
  const { datePickerConfig } = props

  const filterProps = useMemo(
    () => omitBy(props, (_, k) => k !== 'onChange' && /[A-Z]/.test(k)),
    [props]
  )

  return (
    <DatePicker {...filterProps} {...(datePickerConfig as DatePickerProps)} />
  )
}

export default ComponentWrapper(memo(ItemDatePicker))
