import cls from 'classnames'
import { Table } from 'antd'
import type { PaginationProps, TableProps } from 'antd'

import styles from './index.scss'
import CustomPagination from 'components/custom-pagination'

type CustomTableProps<T> = TableProps<T> & {
  data: T[]
  paginationProps: PaginationProps
  paginationOnChange?: PaginationProps['onChange']
  wrapperClassName?: string
}

const CustomTable = <T,>(props: CustomTableProps<T>) => {
  const {
    data,
    paginationOnChange,
    wrapperClassName,
    paginationProps,
    ...tableProps
  } = props
  const {
    defaultPageSize = 10,
    align,
    current,
    pageSize,
  } = paginationProps ?? {}

  const handleTableOnChange: TableProps<T>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    const { current, pageSize } = pagination
    const { action } = extra

    if (action === 'paginate' && current && pageSize) {
      paginationOnChange?.(current, pageSize)
    } else if (action === 'filter') {
      console.log('filter')
    } else if (action === 'sort') {
      console.log('sort')
    }
  }

  const handlePaginationOnChange: PaginationProps['onChange'] = (
    page: number,
    pageSize: number
  ) => {
    handleTableOnChange({ current: page, pageSize }, {}, [], {
      action: 'paginate',
      currentDataSource: data,
    })
  }

  // Fix table expanded header issue when data is empty
  if (data.length === 0 && tableProps.scroll?.y !== undefined) {
    tableProps.scroll = {
      ...tableProps.scroll,
      y: undefined,
    }
  }

  return (
    <div className={cls(wrapperClassName, styles.customTableWrapper)}>
      <Table<T>
        tableLayout="auto"
        rootClassName={styles.customTableContentWrapper}
        dataSource={data}
        onChange={handleTableOnChange}
        pagination={false}
        {...tableProps}
      />
      <CustomPagination
        rootClassName={cls({
          [styles.customTablePaginationWrapper]: true,
          [styles.customTablePaginationAlignEnd]: align === 'end',
        })}
        showTotal={(total) =>
          `Page ${current} of ${Math.ceil(total / (pageSize ?? defaultPageSize))}`
        }
        onChange={handlePaginationOnChange}
        {...paginationProps}
        align={align === 'end' ? undefined : align}
      />
    </div>
  )
}

export default CustomTable
