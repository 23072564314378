// Comprehensive HTTP Status Code Encapsulation

// Enum for HTTP Status Codes with detailed categorization
export enum HttpStatusCode {
  // 1xx Informational
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,

  // 2xx Success
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,

  // 3xx Redirection
  MULTIPLE_CHOICES = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,

  // 4xx Client Errors
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  CONFLICT = 409,
  GONE = 410,
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,

  // 5xx Server Errors
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
}

// Enum for HTTP Status Categories
export enum HttpStatusCategory {
  INFORMATIONAL = 'Informational',
  SUCCESSFUL = 'Successful',
  REDIRECTION = 'Redirection',
  CLIENT_ERROR = 'Client Error',
  SERVER_ERROR = 'Server Error',
}

const DefaultHttpStatusCodeMessages: Partial<Record<HttpStatusCode, string>> = {
  [HttpStatusCode.OK]: 'OK - Request successful',
  [HttpStatusCode.CREATED]: 'Created - Resource created successfully',
  [HttpStatusCode.BAD_REQUEST]: 'Bad Request - Invalid syntax',
  [HttpStatusCode.UNAUTHORIZED]: 'Unauthorized - Authentication required',
  [HttpStatusCode.FORBIDDEN]: 'Forbidden - Server understands but refuses',
  [HttpStatusCode.NOT_FOUND]: 'Not Found - Resource cannot be located',
  [HttpStatusCode.INTERNAL_SERVER_ERROR]:
    'Internal Server Error - Unexpected condition',
  [HttpStatusCode.SERVICE_UNAVAILABLE]:
    'Service Unavailable - Temporary overloading',
  // Add more default messages as needed
}

// Interface for HTTP Status Code Details
export type HttpStatusDetailType = {
  code: HttpStatusCode
  category: HttpStatusCategory
  message: string
}

// HTTP Status Code Utility Class
export class HttpStatusCodeManager {
  /**
   * Determine the category of a given HTTP status code
   * @param code HTTP status code
   * @returns Status category
   */
  static getCategory(code: HttpStatusCode): HttpStatusCategory {
    if (code >= 100 && code < 200) return HttpStatusCategory.INFORMATIONAL
    if (code >= 200 && code < 300) return HttpStatusCategory.SUCCESSFUL
    if (code >= 300 && code < 400) return HttpStatusCategory.REDIRECTION
    if (code >= 400 && code < 500) return HttpStatusCategory.CLIENT_ERROR
    if (code >= 500 && code < 600) return HttpStatusCategory.SERVER_ERROR
    throw new Error(`Invalid HTTP status code: ${code}`)
  }

  /**
   * Get a comprehensive details object for a given HTTP status code
   * @param code HTTP status code
   * @returns Detailed information about the status code
   */
  static getDetails(code: HttpStatusCode): HttpStatusDetailType {
    return {
      code,
      category: this.getCategory(code),
      message:
        Reflect.get(DefaultHttpStatusCodeMessages, code) ?? 'Unknown Status',
    }
  }
}
