import {
  Button,
  Input,
  message,
  Modal,
  Flex,
  Empty,
  DatePicker,
  MenuProps,
  Dropdown,
  Space,
  Avatar,
  Pagination,
  Typography,
  InputRef,
  Skeleton,
  Tooltip,
} from 'antd'
import {
  SearchOutlined,
  CloseOutlined,
  DownOutlined,
  UserOutlined,
  LikeFilled,
  DislikeFilled,
  RollbackOutlined,
} from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import { getFileUrl, getMessageFromError, isEmpty } from 'utils/common'
import userStore from 'stores/user'
import cls from 'classnames'
import dayjs from 'dayjs'
import { dayjsUTC } from 'utils/filter'
import CustomMarkdown from 'components/custom-markdown'
import { v1ChatConversationsSearchList } from 'api/Api'
import styles from './index.scss'

const { RangePicker } = DatePicker

export interface SearchModalProps {
  data: {
    agentId: number
    agentName: string
    agentIconUUID: string
  }
  okText?: string
  open?: boolean
  onFinish?: (sessionId: string, msgId: string) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

type SearchCategory = 'all' | 'assistant' | 'user' | 'feedback'

const rangeItems: MenuProps['items'] = [
  {
    label: 'Last 1 day',
    key: '1',
  },
  {
    label: 'Last 7 days',
    key: '7',
  },
  {
    label: 'Last 14 days',
    key: '14',
  },
  {
    label: 'Last 30 days',
    key: '30',
  },
  {
    label: 'Last 90 days',
    key: '90',
  },
  {
    label: 'Custom',
    key: 'custom',
  },
]

const filterItems: MenuProps['items'] = [
  {
    label: 'All',
    key: 'all',
  },
  {
    label: 'Like',
    key: 'like',
  },
  {
    label: 'Dislike',
    key: 'dislike',
  },
]

type SearchResultItem = {
  id: string
  role: string
  content: string
  session: string
  sessionId: number
  feedback: string
  feedbackLabels: string[]
  createdAt: string
  like?: boolean
  dislike?: boolean
  feedbackExpanded?: boolean
}

const SearchModal: React.FC<SearchModalProps> = (props) => {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchCategory, setSearchCategory] = useState<SearchCategory>('all')
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [filterItem, setFilterItem] = useState(filterItems[0])
  const [rangeItem, setRangeItem] = useState(rangeItems[3])
  const [startAt, setStartAt] = useState<string>('')
  const [endAt, setEndAt] = useState<string>('')
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(5)
  const [searchResults, setSearchResults] = useState<SearchResultItem[]>([])
  const keywordInputRef = useRef<InputRef>(null)

  const executeScroll = () => keywordInputRef.current?.input?.scrollIntoView()

  useEffect(() => {
    if (!props.data.agentId) {
      return
    }

    if (searchKeyword.trim() === '') {
      setSearchResults([])
      return
    }

    const search = () => {
      setIsSearchLoading(true)

      v1ChatConversationsSearchList({
        agentId: props.data.agentId,
        keyword: searchKeyword,
        searchUser: ['all', 'user'].includes(searchCategory),
        searchAssistant: ['all', 'assistant'].includes(searchCategory),
        searchFeedback: ['all', 'feedback'].includes(searchCategory),
        internalUser: true,
        externalUser: false,
        like: ['like'].includes(filterItem?.key as string),
        dislike: ['dislike'].includes(filterItem?.key as string),
        startDate: dayjsUTC(startAt).utc().format(),
        endDate: endAt === '' ? undefined : dayjsUTC(endAt).utc().format(),
        page: page === 0 ? 1 : page,
        limit: pageSize,
      })
        .then((resp) => {
          const data: SearchResultItem[] = resp.data.data.map((item) => ({
            id: item.id,
            role: item.role,
            content: item.content,
            session: item.session.title,
            sessionId: item.session.id,
            feedback: item.feedback,
            feedbackLabels: item.feedbackLabel
              .split(',')
              .filter((item) => item.trim() !== ''),
            createdAt: item.createdAt,
            like: item.like,
            dislike: item.dislike,
            feedbackExpanded: false,
          }))
          setSearchResults(data)
          setTotal(resp.data.pagination.totalItems)
        })
        .catch((error) => {
          message.error(getMessageFromError(error))
        })
        .finally(() => {
          setIsSearchLoading(false)
        })
    }

    // Debounce search records
    const timer = setTimeout(search, 300)
    return () => clearTimeout(timer)
  }, [
    props.data.agentId,
    searchKeyword,
    searchCategory,
    filterItem,
    startAt,
    endAt,
    page,
    pageSize,
  ])

  useEffect(() => {
    if (rangeItem?.key !== 'custom') {
      setStartAt(dayjsUTC().subtract(Number(rangeItem?.key), 'day').format())
      setEndAt('')
    }
  }, [rangeItem])

  const handleFinish = async (values: SearchResultItem) => {
    console.log(values.id)
    if (props.onFinish) {
      props.onFinish(values.session, values.id)
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  const renderCategoryButton = (category: SearchCategory) => (
    <Button
      type="text"
      className={cls({
        [styles.modalSearchCategoriesButton]: true,
        [styles.modalSearchCategoriesButtonSelected]:
          searchCategory === category,
      })}
      onClick={() => setSearchCategory(category)}
    >
      {category}
    </Button>
  )

  const renderSearchResults = (results: SearchResultItem[]) => {
    const renderMessageItem = (conversation: SearchResultItem) => {
      const avatarUrl =
        conversation.role === 'assistant'
          ? getFileUrl(props.data.agentIconUUID ?? '')
          : getFileUrl(userStore.loginUser?.avatarUUID ?? '')
      const name =
        conversation.role === 'assistant' ? props.data.agentName : 'You'
      const content = conversation.content

      return (
        <Flex className={styles.modalSearchResultsItem} gap={16}>
          <Flex className={styles.modalSearchResultsItemAvatar}>
            <Avatar
              icon={isEmpty(avatarUrl) ? <UserOutlined /> : undefined}
              src={isEmpty(avatarUrl) ? undefined : avatarUrl}
              size={48}
              shape="square"
            />
          </Flex>
          <Flex gap={8} flex={1} vertical>
            <Flex gap={4} vertical>
              <Flex style={{ width: '100%', justifyContent: 'space-between' }}>
                <span className={styles.modalSearchResultsItemName}>
                  {name}
                </span>
                <span className={styles.modalSearchResultsItemTime}>
                  {dayjs(conversation.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>
              </Flex>
              <div
                className={cls(
                  'markdown-body',
                  styles.modalSearchResultsItemContent
                )}
              >
                <CustomMarkdown isLoading={isSearchLoading}>
                  {content}
                </CustomMarkdown>
              </div>
            </Flex>
            <Flex
              className={styles.modalSearchResultsItemFeedbackWrapper}
              gap={8}
              vertical
              style={{
                display:
                  isEmpty(conversation.feedback) &&
                  conversation.feedbackLabels.length === 0
                    ? 'none'
                    : undefined,
              }}
            >
              <Flex style={{ justifyContent: 'space-between' }} gap={8}>
                <Flex
                  className={styles.modalSearchResultsItemFeedbackWrapperLabels}
                  gap={8}
                >
                  {conversation.feedbackLabels?.map((label) => (
                    <span>{label}</span>
                  ))}
                </Flex>
                <Flex
                  className={styles.modalSearchResultsItemFeedbackWrapperThumbs}
                  gap={8}
                >
                  {conversation.like && <LikeFilled />}
                  {conversation.dislike && <DislikeFilled />}
                </Flex>
              </Flex>
              <Typography.Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: 'collapsible',
                  expanded: conversation.feedbackExpanded,
                  onExpand: (_, info) =>
                    (conversation.feedbackExpanded = info.expanded),
                }}
              >
                {conversation.feedback}
              </Typography.Paragraph>
            </Flex>
            <Flex
              className={styles.modalSearchResultsItemOptionsWrapper}
              gap={4}
            >
              <Tooltip title="Go to this conversation">
                <Button
                  type="text"
                  icon={<RollbackOutlined />}
                  onClick={() => {
                    handleFinish(conversation)
                  }}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>
      )
    }

    return (
      <Flex className={styles.modalSearchResultsContainer} gap={16} vertical>
        <Flex gap={16} flex={1} vertical>
          {results.map((item, index) => {
            return (
              <>
                {/* Session Name */}
                {(index === 0 ||
                  results[index - 1].session !== item.session) && (
                  <Flex className={styles.modalSearchResultsItemSession}>
                    <Typography.Paragraph
                      ellipsis={{
                        rows: 1,
                      }}
                    >
                      {item.session}
                    </Typography.Paragraph>
                  </Flex>
                )}
                {renderMessageItem(item)}
              </>
            )
          })}
        </Flex>
        <Pagination
          className={styles.modalSearchResultsPagination}
          defaultCurrent={page}
          total={total}
          pageSize={pageSize}
          onChange={(_page, _pageSize) => {
            setPage(_page)
            if (_page !== page || _pageSize < pageSize) {
              executeScroll()
            }
          }}
          onShowSizeChange={(_, size) => setPageSize(size)}
          showSizeChanger
        />
      </Flex>
    )
  }

  return (
    <Modal
      open={props.open}
      okText="Save"
      onCancel={handleCancel}
      closable={false}
      footer={[]}
      width="60%"
      className={styles.modal}
    >
      <Flex gap={12} vertical>
        <Input
          ref={keywordInputRef}
          className={styles.modalSearchInputWrapper}
          value={searchKeyword}
          prefix={<SearchOutlined style={{ paddingRight: '4px' }} />}
          suffix={
            <Button
              type="text"
              icon={<CloseOutlined />}
              size="small"
              onClick={() => setSearchKeyword('')}
            />
          }
          placeholder="Search from assistants, users, feedbacks, etc."
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
        <Flex className={styles.modalSearchCategories} gap={4}>
          {(['all', 'assistant', 'user', 'feedback'] as SearchCategory[]).map(
            (category) => renderCategoryButton(category)
          )}
        </Flex>
        <Flex className={styles.modalSearchOptionsWrapper} gap={4}>
          <Flex gap={4}>
            <Dropdown
              className={styles.modalSearchOptionsWrapperDateRangeDropdown}
              menu={{
                items: rangeItems,
                onClick: (e) => {
                  const item = rangeItems.find((item) => item?.key === e.key)
                  if (item) {
                    setRangeItem(item)
                  }
                },
              }}
            >
              <Button>
                <Space>
                  {(rangeItem as any)?.label}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <RangePicker
              showTime
              value={[
                isEmpty(startAt) ? dayjsUTC() : dayjsUTC(startAt),
                isEmpty(endAt) ? dayjsUTC() : dayjsUTC(endAt),
              ]}
              onChange={(_, dateStrings) => {
                setStartAt(dateStrings[0])
                setEndAt(dateStrings[1])
              }}
              disabled={rangeItem?.key !== 'custom'}
              style={{
                display: rangeItem?.key === 'custom' ? undefined : 'none',
              }}
            />
          </Flex>
          <Dropdown
            menu={{
              items: filterItems,
              onClick: (e) => {
                const item = filterItems.find((item) => item?.key === e.key)
                if (item) {
                  setFilterItem(item)
                }
              },
            }}
          >
            <Button>
              <Space>
                {(filterItem as any)?.label}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Flex>
        {isSearchLoading ? (
          <Skeleton active />
        ) : (
          <Flex className={styles.modalSearchResultsWrapper} vertical>
            {searchResults.length > 0 ? (
              renderSearchResults(searchResults)
            ) : (
              <Empty />
            )}
          </Flex>
        )}
      </Flex>
    </Modal>
  )
}

export default SearchModal
