import { FileAddOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import BarChartIcon from 'assets/images/bar-chart.svg'
import type useQuestionsModals from '../../hooks/use-questions-modal'
import type useReportsModals from '../../hooks/use-reports-modals'

export interface TabActionsProps {
  activeTab: string
  questionsModals: ReturnType<typeof useQuestionsModals>
  reportsModals: ReturnType<typeof useReportsModals>
}

export const TabActions: React.FC<TabActionsProps> = ({
  activeTab,
  questionsModals,
  reportsModals,
}) => {
  const { openImportQuestionsModal, openCreateQuestionModal } = questionsModals
  const { openEvaluateAgentModal } = reportsModals

  if (activeTab === 'questions') {
    return (
      <Space>
        <Button
          icon={<FileAddOutlined />}
          onClick={openImportQuestionsModal}
          style={{
            fontWeight: 700,
          }}
        >
          Import
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => openCreateQuestionModal(false)}
          style={{
            fontWeight: 700,
          }}
        >
          Create Question
        </Button>
      </Space>
    )
  }

  if (activeTab === 'reports') {
    return (
      <Button
        type="primary"
        icon={<BarChartIcon style={{ fontSize: 20 }} />}
        onClick={openEvaluateAgentModal}
        style={{
          fontWeight: 700,
        }}
      >
        Evaluate Agent
      </Button>
    )
  }

  return null
}

export default TabActions
