interface Config {
  API_GATEWAY: string
  STATIC_URL: string
}

const config: Config = {
  API_GATEWAY: new URL(
    process.env.REACT_APP_API_GATEWAY || '',
    window.location.origin
  ).toString(),
  STATIC_URL: new URL(
    process.env.REACT_APP_STATIC_URL ?? '',
    window.location.origin
  ).toString(),
}

export default config
