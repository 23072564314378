import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { Button, Popconfirm, Space, TableProps, Tag } from 'antd'
import dayjs from 'dayjs'
import RedCrossSvg from 'assets/images/red-cross.svg'
import GreenTickSvg from 'assets/images/green-tick.svg'
import EditSvg from 'assets/images/edit.svg'
import DeleteSvg from 'assets/images/delete.svg'
import { capitalizeFirstLetter } from 'utils/format'
import ManagementModal, { ManagementModalProps } from './modal'
import {
  OrganizationStatusOrganizationStatus,
  OrganizationTypeOrganizationType,
} from 'api/data-contracts'
import { v1OrganizationDeleteDelete, v1OrganizationListList } from 'api/Api'
import userStore from 'stores/user'
import CustomTable from 'components/custom-table'
import styles from './index.scss'

export type ManagementDataType = {
  key: string
  name: string
  type: OrganizationTypeOrganizationType
  languageCode: string
  required2FA: boolean
  status: OrganizationStatusOrganizationStatus
  expireDate: string
  createdAt: string
  updatedAt: string
}

export type ManagementHandles = {
  setModalVisible: (open: boolean) => void
  setModalType: (type: ManagementModalProps['type']) => void
}

const Management = forwardRef((props, ref) => {
  const [data, setData] = useState<ManagementDataType[]>([])
  const [totalCount, setTotalCount] = useState<number>()
  const [modalType, setModalType] =
    useState<ManagementModalProps['type']>('edit')
  const [modalVisible, setModalVisible] = useState(false)
  const [curOrganization, setCurOrganization] = useState<ManagementDataType>()
  const [curPage, setCurPage] = useState(1)
  const [curPageSize, setCurPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const isAdmin = useMemo(
    () => userStore.loginUser?.roleName.toLowerCase() === 'admin',
    [userStore.loginUser]
  )
  const columns: TableProps<ManagementDataType>['columns'] = useMemo(
    () => [
      { key: 'name', title: 'Name', dataIndex: 'name' },
      {
        key: 'type',
        title: 'Type',
        dataIndex: 'type',
        render: (val) => capitalizeFirstLetter(val),
      },
      {
        key: 'languageCode',
        title: 'Language Code',
        dataIndex: 'languageCode',
        width: 160,
        minWidth: 120,
        render: (val) => val.toUpperCase(),
      },
      {
        key: 'required2FA',
        title: 'Required 2FA',
        dataIndex: 'required2FA',
        width: 150,
        minWidth: 120,
        render: (text) => {
          return text ? (
            <GreenTickSvg className={styles.tableIcon} />
          ) : (
            <RedCrossSvg className={styles.tableIcon} />
          )
        },
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        render: (val) =>
          val === 'active' ? (
            <Tag className={styles.tagActive}>{capitalizeFirstLetter(val)}</Tag>
          ) : (
            <Tag className={styles.tagInactive}>
              {capitalizeFirstLetter(val)}
            </Tag>
          ),
      },
      {
        key: 'expireDate',
        title: 'Expire Date',
        minWidth: 130,
        dataIndex: 'expireDate',
        render: (val) => dayjs(val).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        key: 'createdAt',
        title: 'Created At',
        minWidth: 120,
        dataIndex: 'createdAt',
        render: (val) => dayjs(val).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        key: 'updatedAt',
        title: 'Updated At',
        minWidth: 120,
        dataIndex: 'updatedAt',
        render: (val) => dayjs(val).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: 'Actions',
        width: 100,
        render: (_: any, record: ManagementDataType) => {
          const { key } = record
          return (
            <Space className={styles.tableActions}>
              <Button
                type="text"
                icon={<EditSvg className={styles.tableIcon} />}
                onClick={() => handleActionEdit(key)}
              />
              <Popconfirm
                title="Are you sure to delete the organization?"
                onConfirm={() => handleActionDelete(key)}
              >
                <Button
                  type="text"
                  icon={<DeleteSvg className={styles.tableIcon} />}
                  disabled={!isAdmin}
                  danger
                />
              </Popconfirm>
            </Space>
          )
        },
      },
    ],
    [data]
  )

  const handleActionEdit = (id: string) => {
    setModalType('edit')
    const organization = data.find((o) => `${o.key}` === id)
    if (!organization) return
    setCurOrganization(organization)
    setModalVisible(true)
  }

  const handleActionDelete = async (id: string) => {
    const deleteResp = await v1OrganizationDeleteDelete({
      id: Number(id),
    })
    if (!deleteResp) return
    return fetchData()
  }

  const handleUpdateData = (page = curPage, pageSize = curPageSize) => {
    fetchData(page, pageSize)
    if (page) setCurPage(page)
    if (pageSize) setCurPageSize(pageSize)
  }

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true)
    const managementResp = await v1OrganizationListList({
      page,
      size: pageSize,
    }).finally(() => setLoading(false))
    if (!managementResp.data) return
    const { organizations, totalCount } = managementResp.data

    const tableData = organizations.map<ManagementDataType>((o) => {
      return {
        key: `${o.id}`,
        name: o.name,
        type: o.organizationType,
        languageCode: o.languageCode,
        required2FA: o.required2FA,
        status: o.status,
        expireDate: o.expireDate,
        createdAt: o.createdAt,
        updatedAt: o.updatedAt,
      }
    })

    setData(tableData)
    setTotalCount(totalCount)
  }

  useImperativeHandle(ref, () => ({
    setModalVisible,
    setModalType,
  }))

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (modalVisible === false) {
      setCurOrganization(undefined)
    }
  }, [modalVisible])

  return (
    <>
      <CustomTable<ManagementDataType>
        columns={columns}
        data={data}
        loading={loading}
        paginationOnChange={handleUpdateData}
        paginationProps={{
          total: totalCount,
          align: 'center',
          current: curPage,
          pageSize: curPageSize,
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
          // page margin + title + title margin + tab content + tab margin + pagination content + pagination margin + table title
          y: 'calc(100vh - 2*24px - 38px - 24px - 46px - 16px - 32px - 16px - 55px)',
        }}
      />

      <ManagementModal
        data={modalType === 'edit' ? curOrganization : undefined}
        type={modalType}
        open={modalVisible}
        onOpenChange={setModalVisible}
        updateData={handleUpdateData}
      />
    </>
  )
})

export default memo(Management)
