import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import cls from 'classnames'
import {
  Button,
  DatePicker,
  Flex,
  Input,
  InputNumber,
  Space,
  Splitter,
} from 'antd'
import {
  // v1ChatConversationsStatisticsDailyDownloadList,
  // v1ChatConversationsStatisticsDailyList,
  v1ChatConversationsStatisticsList,
} from 'api/Api'
import { ResponsesConversationStatsWithDetailResponse } from 'api/data-contracts'
import UsageAndEngagement from './usage'
import Feedback from './feedback'

import styles from './index.scss'
import BoardWrapper from './board-wrapper'

const todayStart = dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss')
const lastWeekStart = dayjs()
  .subtract(1, 'week')
  .startOf('day')
  .format('YYYY-MM-DD HH:mm:ss')

const Dashboard: React.FC = () => {
  const [dataList, setDataList] = useState<
    ResponsesConversationStatsWithDetailResponse[]
  >([])
  const [agentId, setAgentId] = useState<number | null>(null)
  const [reportDateRange, setReportDateRange] = useState<[string, string]>()

  const fetchData = async () => {
    // const resp = await v1ChatConversationsStatisticsDailyList()
    // if (!resp) return
    // setDataList(resp.data)
  }

  const handleDateChange = (_: any, dateString: [string, string]) => {
    setReportDateRange(dateString)
  }

  const handleDownloadReportClick = async () => {
    const [startTime, endTime] = reportDateRange ?? []

    // const resp = await v1ChatConversationsStatisticsDailyDownloadList(
    // { agent_id: agentId, start_at: startTime, end_at: endTime },
    //   { responseType: 'blob' }
    // )
    // console.error(reportDateRange, resp)
    // const blob = new Blob([resp.data], {
    //   type: 'application/pdf',
    // })
    // const fileUrl = URL.createObjectURL(blob)
    // const link = document.createElement('a')
    // link.href = fileUrl
    // link.setAttribute('download', 'report.csv')
    // document.body.appendChild(link)
    // link.click()
    // document.body.removeChild(link)
  }

  useEffect(() => {
    fetchData()
  }, [])

  // TODO: Return a neat text before it's implemented
  return (
    <Flex style={{ padding: '16px' }}>
      <h1>Dashboard</h1>
    </Flex>
  )

  // return (
  //   <>
  //     <Space.Compact>
  //       <InputNumber
  //         min={0}
  //         placeholder="AgentId"
  //         style={{ width: '10%' }}
  //         precision={0}
  //         onChange={setAgentId}
  //       />
  //       <DatePicker.RangePicker
  //         showTime
  //         showNow
  //         preserveInvalidOnBlur
  //         format={'YYYY-MM-DD HH:mm:ss'}
  //         onChange={handleDateChange}
  //       />
  //
  //       <Button onClick={handleDownloadReportClick}>Download Report</Button>
  //     </Space.Compact>
  //
  //     <Splitter rootClassName={styles.dashboardWrapper}>
  //       <Splitter.Panel resizable={false} defaultSize={'70%'}>
  //         <Splitter layout="vertical">
  //           <Splitter.Panel defaultSize={'100%'}>
  //             {/* <Splitter.Panel defaultSize={'60%'}> */}
  //             <BoardWrapper title={'Usage & Engagement'}>
  //               <UsageAndEngagement
  //                 data={dataList.filter(
  //                   (item) =>
  //                     dayjs(item.startAt).isBefore(dayjs(todayStart)) &&
  //                     dayjs(item.endAt).isAfter(dayjs(lastWeekStart))
  //                 )}
  //               />
  //             </BoardWrapper>
  //           </Splitter.Panel>
  //           <Splitter.Panel resizable={false}>Left Bottom</Splitter.Panel>
  //         </Splitter>
  //       </Splitter.Panel>
  //
  //       <Splitter.Panel>
  //         <Splitter layout="vertical">
  //           <Splitter.Panel defaultSize={'100%'}>
  //             {/* <Splitter.Panel defaultSize={'75%'}> */}
  //             <BoardWrapper title={'Feedback'}>
  //               <Feedback
  //                 data={dataList.filter(
  //                   (item) =>
  //                     dayjs(item.startAt).isBefore(dayjs(todayStart)) &&
  //                     dayjs(item.endAt).isAfter(dayjs(lastWeekStart))
  //                 )}
  //               />
  //             </BoardWrapper>
  //           </Splitter.Panel>
  //           <Splitter.Panel resizable={false}>Right Bottom</Splitter.Panel>
  //         </Splitter>
  //       </Splitter.Panel>
  //     </Splitter>
  //   </>
  // )
}

export default Dashboard
