import Icon, {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { App, Button, Flex, Popconfirm, Space, Tag } from 'antd'
import {
  v1EvaluationDelete,
  v1EvaluationDownloadList,
  v1EvaluationListList,
} from 'api/Api'
import {
  EvaluationEvaluationMappingStatus,
  ResponsesEvaluationAPIResponse,
} from 'api/data-contracts'
import BarChartIcon from 'assets/images/bar-chart.svg'
import CustomTable from 'components/custom-table'
import { useIsAdmin } from 'hooks/use-is-admin'
import { useIsTabActive } from 'hooks/use-is-tab-active'
import { observer } from 'mobx-react'
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { downloadFile, getMessageFromError, isEmpty } from 'utils/common'
import { formatDate } from 'utils/filter'
import styles from './report-table.scss'
import { renderProgressBar } from './report-viewer'

export enum ActionType {
  View = 'view',
}

interface ReportTableProps {
  agentId: number
  onAction?: (
    type: ActionType,
    question: ResponsesEvaluationAPIResponse
  ) => void
}

export interface ReportTableRef {
  refresh: () => void
}

const getEvaluationStatusIcon = (status: EvaluationEvaluationMappingStatus) => {
  let icon = <></>
  switch (status) {
    case EvaluationEvaluationMappingStatus.Completed:
      icon = <CheckCircleOutlined style={{ color: 'green' }} />
      break
    case EvaluationEvaluationMappingStatus.Failed:
      icon = <ExclamationCircleOutlined style={{ color: 'red' }} />
      break
    case EvaluationEvaluationMappingStatus.Processing:
      icon = <LoadingOutlined style={{ color: 'blue' }} />
      break
    case EvaluationEvaluationMappingStatus.Pending:
      icon = <ClockCircleOutlined />
      break
  }
  return icon
}

const ReportTable: ForwardRefRenderFunction<
  ReportTableRef,
  ReportTableProps
> = (props, ref) => {
  const { message } = App.useApp()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [reports, setReports] = useState<ResponsesEvaluationAPIResponse[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [deletingId, setDeletingId] = useState(0)
  const isAdmin = useIsAdmin()
  const isTabActive = useIsTabActive()

  useImperativeHandle(
    ref,
    () => ({
      refresh: () => load(page, size),
    }),
    [page, size]
  )

  const load = async (page: number, size: number, loading: boolean = true) => {
    if (loading) {
      setLoading(true)
    }
    try {
      const resp = await v1EvaluationListList({
        page,
        limit: size,
        sort: JSON.stringify({ id: 'desc' }),
        conditions: JSON.stringify([
          {
            field: 'agent_id',
            operator: '=',
            value: props.agentId,
          },
        ]),
      })
      setReports(resp.data.data)
      setTotal(resp.data.pagination.totalItems)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    if (loading) {
      setLoading(false)
    }
  }

  useEffect(() => {
    load(page, size)
  }, [page, size])

  const needUpdateList = useMemo(() => {
    return (
      reports.filter((report) =>
        [
          EvaluationEvaluationMappingStatus.Pending,
          EvaluationEvaluationMappingStatus.Processing,
        ].includes(report.status)
      ).length > 0
    )
  }, [reports])

  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined

    if (needUpdateList && isTabActive) {
      interval = setInterval(() => {
        load(page, size, false)
      }, 3000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [page, size, needUpdateList, isTabActive])

  const deleteEvaluation = async (key: number) => {
    setDeletingId(key)
    try {
      await v1EvaluationDelete({ id: key })
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setDeletingId(0)
    load(page, size)
  }

  const downloadReportFile = async (id: number) => {
    const resp = await v1EvaluationDownloadList(
      { id, report_extension: 'excel' },
      { responseType: 'blob' }
    )
    downloadFile(resp)
  }

  const columns = [
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      width: '180px',
      editable: false,
      render: (text: string, record: ResponsesEvaluationAPIResponse) => {
        return <div key={'start_time_' + record.id}>{formatDate(text)}</div>
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '180px',
      minWidth: 80,
      render: (text: string, record: ResponsesEvaluationAPIResponse) => {
        return (
          <div key={'name_' + record.id}>{isEmpty(text) ? 'NA' : text}</div>
        )
      },
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      width: '180px',
      render: (text: string, record: ResponsesEvaluationAPIResponse) => {
        return <div key={'end_time_' + record.id}>{formatDate(text)}</div>
      },
    },
    {
      title: 'Workflow Version',
      dataIndex: 'workflowVersion',
      minWidth: 170,
      width: '205px',
      render: (text: string, record: ResponsesEvaluationAPIResponse) => {
        return (
          <div key={'workflow_version_' + record.id}>
            {isEmpty(text) ? 'NA' : text}
          </div>
        )
      },
    },
    {
      title: 'Test Cases',
      width: '320px',
      minWidth: 120, // prevent collapse
      render: (_: string, record: ResponsesEvaluationAPIResponse) => {
        return (
          <Flex key={'test_cases_' + record.id} gap={2} vertical>
            {record.status === EvaluationEvaluationMappingStatus.Pending &&
              record.evaluationResponses.length === 0 &&
              'Pending...'}
            {record.evaluationResponses.map((evaluation, index) => {
              return (
                <Tag
                  key={'evaluation_' + index}
                  color={
                    evaluation.evaluationStatus === 'completed'
                      ? 'success'
                      : 'processing'
                  }
                  icon={
                    evaluation.evaluationStatus === 'completed' ? (
                      <CheckCircleOutlined />
                    ) : (
                      <LoadingOutlined />
                    )
                  }
                >
                  {isEmpty(evaluation.evaluationQuestion.testCase)
                    ? 'NA'
                    : evaluation.evaluationQuestion.testCase}
                </Tag>
              )
            })}
          </Flex>
        )
      },
    },
    {
      title: 'Total Questions',
      minWidth: 180,
      width: 180,
      render: (_: any, record: ResponsesEvaluationAPIResponse) => {
        return (
          <div key={'questions_' + record.id}>{renderProgressBar(record)}</div>
        )
      },
    },
    {
      title: 'Scores',
      dataIndex: 'averageScore',
      minWidth: 86,
      width: '180px',
      render: (value: number, record: ResponsesEvaluationAPIResponse) => {
        return (
          <div key={'average_score_' + record.id}>
            {(value * 100).toFixed(2)}%
          </div>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '180px',
      render: (
        status: EvaluationEvaluationMappingStatus,
        record: ResponsesEvaluationAPIResponse
      ) => (
        <Space>
          {getEvaluationStatusIcon(record.status)}
          <span style={{ textTransform: 'capitalize' }}>
            {status.replaceAll('_', ' ')}
          </span>
        </Space>
      ),
    },
    {
      title: 'Actions',
      width: '130px',
      render: (_: any, record: ResponsesEvaluationAPIResponse) => {
        return (
          <Flex key={'actions_' + record.id} gap="16px">
            <Button
              type="text"
              key={'view_' + record.id}
              icon={
                <Icon
                  component={() => <BarChartIcon />}
                  className={styles.barChartIcon}
                  style={{ fontSize: 20 }}
                />
              }
              size="small"
              onClick={() => {
                props.onAction && props.onAction(ActionType.View, record)
              }}
              disabled={
                record.status !== EvaluationEvaluationMappingStatus.Completed
              }
            />
            <Button
              type="text"
              key={'download_' + record.id}
              icon={<DownloadOutlined style={{ fontSize: 20 }} />}
              size="small"
              onClick={() => {
                downloadReportFile(record.id)
              }}
              disabled={
                record.status !== EvaluationEvaluationMappingStatus.Completed
              }
            />
            <Popconfirm
              key={'popconfirm_' + record.id}
              title="Are you sure to delete the report?"
              onConfirm={() => deleteEvaluation(record.id)}
            >
              <Button
                type="text"
                key={'delete_' + record.id}
                icon={<DeleteOutlined style={{ fontSize: 20 }} />}
                size="small"
                disabled={!isAdmin}
                loading={deletingId === record.id}
              />
            </Popconfirm>
          </Flex>
        )
      },
    },
  ]

  return (
    <div className="file-table">
      <CustomTable
        data={reports}
        columns={columns}
        loading={loading}
        paginationProps={{
          current: page,
          pageSize: size,
          total: total,
          align: 'center',
          onChange: (page, size) => {
            setPage(page)
            setSize(size)
          },
        }}
        scroll={{
          x: true,
          y: 'calc(100vh - 285px)',
        }}
      />
    </div>
  )
}

export default observer(forwardRef(ReportTable))
