import {
  ConfigFormItemType,
  ConfigSectionType,
} from 'components/new-dynamic-form/types'
import { CommonConfig } from '../constants'

const ItemSelectConfig: ConfigSectionType[] = [
  ...CommonConfig,
  {
    sectionLabel: 'selectConfig',
    items: [
      { itemLabel: 'AllowClear', itemName: 'allowClear', type: 'switch' },
      { itemLabel: 'Disabled', itemName: 'disabled', type: 'switch' },
      {
        itemLabel: 'Mode',
        itemName: 'mode',
        type: 'select',
        options: [
          { label: 'Multiple', value: 'multiple' },
          { label: 'Tags', value: 'tags' },
          { label: 'Single', value: 'single' },
        ],
      },
      { itemLabel: 'Placeholder', itemName: 'placeholder', type: 'input' },
      { itemLabel: 'DefaultValue', itemName: 'defaultValue', type: 'input' },
      {
        itemLabel: 'Options',
        itemName: 'options',
        type: 'compactInput',
        defaultValue: [
          [
            { label: 'Label', value: '' },
            { label: 'Value', value: '' },
          ],
        ],
        minCount: 1,
      },
    ],
  },
]

export default ItemSelectConfig
