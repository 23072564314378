import { Layout, Menu } from 'antd'
import ArrowDirectorSvg from 'assets/images/arrow-director.svg'
import IconWithNameSvg from 'assets/images/icon-with-name.svg'
import IconSvg from 'assets/images/icon.svg'
import SiderTriggerBgSvg from 'assets/images/sider-trigger-bg.svg'
import UnfoldMoreSvg from 'assets/images/unfold-more.svg'
import UserListSvg from 'assets/images/user-list.svg'
import cls from 'classnames'
import SidebarMenu from 'layouts/portal/menu'
import UserProfileMenu from 'layouts/portal/user-profile-menu'
import { observer } from 'mobx-react'
import React, { ReactNode, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import userStore from 'stores/user'
import styles from './index.scss'

const { Sider, Content } = Layout

interface MainLayoutProps {
  children?: ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(false)

  const handleLogout = async () => {
    await userStore.logout()
    navigate('/login')
  }

  return (
    <Layout className={styles.layoutWrapper}>
      <Sider
        width={280}
        collapsedWidth={112}
        collapsible
        collapsed={collapsed}
        breakpoint="xl"
        onBreakpoint={setCollapsed}
        theme={'light'}
        className={styles.layoutSiderWrapper}
        trigger={null}
      >
        <span className={cls(styles.layoutSiderTriggerLine)}>
          <div
            className={styles.layoutSiderTriggerBox}
            onClick={() => setCollapsed(!collapsed)}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <SiderTriggerBgSvg className={styles.layoutSiderTriggerBg} />
            <ArrowDirectorSvg
              className={cls(styles.layoutSiderTriggerArrow, {
                [styles.layoutSiderTriggerArrowReverse]: collapsed,
              })}
            />
          </div>
        </span>

        <div className={styles.layoutSiderContent}>
          <div className={styles.layoutSiderHeader}>
            {collapsed ? (
              <IconSvg className={styles.layoutSiderHeaderIcon} />
            ) : (
              <IconWithNameSvg
                className={styles.layoutSiderHeaderIconWithName}
              />
            )}
          </div>

          <Menu
            rootClassName={styles.layoutSiderOrganizationListWrapper}
            forceSubMenuRender
            triggerSubMenuAction="click"
            items={[
              {
                key: 'dashboard',
                label: (
                  <span className={styles.layoutSiderOrganizationListText}>
                    {userStore.loginUser?.organizationName}
                  </span>
                ),
                icon: (
                  <UserListSvg
                    className={styles.layoutSiderOrganizationListIcon}
                  />
                ),
                expandIcon: !collapsed && (
                  <UnfoldMoreSvg
                    className={styles.layoutSiderOrganizationListIconUnfold}
                  />
                ),
                children: [
                  // { key: '1', label: <div>'Option 1'</div> },
                  // { key: '2', label: 'Option 2' },
                  // { key: '3', label: 'Option 3' },
                ],
              },
            ]}
            getPopupContainer={(node) => node.parentNode as HTMLElement}
          />

          <SidebarMenu />

          <UserProfileMenu onLogout={handleLogout} />
        </div>
      </Sider>

      <Layout>
        <Content className={styles.layoutContentWrapper}>
          {children ?? <Outlet />}
        </Content>
      </Layout>
    </Layout>
  )
}

export default observer(MainLayout)
