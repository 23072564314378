import React, { ComponentType, memo, useEffect } from 'react'

import styles from './index.scss'
import { ConfigFormItemMapType, FormItemType } from '../types'
import {
  Button,
  Collapse,
  Form,
  Input,
  Switch,
  InputNumber,
  Slider,
  Checkbox,
  Select,
} from 'antd'
import { FormItemConfigMap } from '../components/constants-map'
import { cloneDeep } from 'lodash-es'
import { camelToWords, toLowerFirstLetter } from 'utils/format'
import CustomCompactInput from 'components/custom-compact-input'
import CustomCondition from '../components/lib/condition'
import SubObject from '../components/lib/sub-object'

type FormItemConfigProps = {
  data: FormItemType | null
  onUpdate?: (item: FormItemType) => void
}

export const FormItemMap: Record<ConfigFormItemMapType, ComponentType<any>> = {
  switch: Switch,
  input: Input,
  inputNumber: InputNumber,
  slider: Slider,
  checkbox: Checkbox,
  select: Select,
  // config
  compactInput: CustomCompactInput,
  condition: CustomCondition,
  subObject: SubObject,
}

const FormItemConfig = (props: FormItemConfigProps) => {
  const { data, onUpdate } = props
  const { formItemId, type } = data ?? {}

  const [form] = Form.useForm()

  const handleFormValueChange = (changedValues: any, values: any) => {
    if (!data) return
    const newData = cloneDeep(data)

    onUpdate?.({ ...newData, props: values })
  }

  useEffect(() => {
    form.setFieldsValue(data?.props)
  }, [])

  return (
    <div className={styles.dynamicFormItemConfigWrapper}>
      <div className={styles.dynamicFormItemConfigHeader}>
        {data?.type} Configuration
      </div>
      <Form
        form={form}
        onFinish={(v) => console.log(v)}
        onValuesChange={handleFormValueChange}
      >
        {formItemId && type && (
          <Collapse
            ghost
            defaultActiveKey={FormItemConfigMap[type].map(
              (c) => c.sectionLabel
            )}
            items={FormItemConfigMap[type].map((s) => {
              const { sectionLabel, items } = s

              return {
                key: sectionLabel,
                label: (
                  <div
                    className={styles.dynamicFormItemConfigCollapseItemTitle}
                  >
                    {camelToWords(sectionLabel)}
                  </div>
                ),
                children: items.map((item) => {
                  const {
                    itemLabel,
                    itemName,
                    type: itemType,
                    defaultValue,
                    formItemConfigProps,
                    ...restProps
                  } = item
                  const Cmp = Reflect.get(FormItemMap, itemType)
                  const specifyProps = defaultValue
                    ? { initialValue: defaultValue }
                    : {}

                  return Cmp ? (
                    <Form.Item
                      name={[toLowerFirstLetter(sectionLabel), itemName]}
                      label={camelToWords(itemLabel)}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      key={itemName}
                      {...formItemConfigProps}
                      {...specifyProps}
                    >
                      <Cmp {...restProps} />
                    </Form.Item>
                  ) : null
                }),
              }
            })}
          />
        )}

        {/* <Form.Item>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item> */}
      </Form>
    </div>
  )
}

export default memo(FormItemConfig)
