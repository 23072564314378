import React, { ReactElement } from 'react'
import { Flex } from 'antd'
import styles from './index.scss'
import LoadingWrapper from '../loading-wrapper'

const DataCard: React.FC<{
  icon: ReactElement
  name: string
  value: number
  diff?: number
  suffix?: string
  loading?: boolean
}> = (props) => {
  return (
    <Flex className={styles.sectionWrapper} vertical>
      <Flex gap={8}>
        <Flex className={styles.dataCardIcon}>{props.icon}</Flex>
        <span className={styles.dataCardTitle}>{props.name}</span>
      </Flex>
      <Flex className={styles.dataCardData} gap={8}>
        <LoadingWrapper loading={props.loading}>
          <span className={styles.dataCardValue}>{props.value}</span>
          {props.diff && (
            <span
              className={styles.dataCardDiff}
              style={{ color: props.diff > 0 ? 'green' : 'red' }}
            >
              {props.diff > 0 ? `+${props.diff}` : `${props.diff}`}
            </span>
          )}
          <span className={styles.dataCardSuffix}>{props.suffix}</span>
        </LoadingWrapper>
      </Flex>
    </Flex>
  )
}

export default DataCard
