import { createContext, useContext } from 'react'
import { FormItemType } from './types'
import { WithDraggingProps } from './components/lib/component-wrapper'

export type FormContextProps = {
  root: FormItemType
  setRoot: React.Dispatch<FormItemType>
  onItemClick: (item: WithDraggingProps<FormItemType>) => void
}

export const FormContext = createContext<FormContextProps | null>(null)

export const useFormContext = () => {
  const value = useContext(FormContext)
  return value
}
