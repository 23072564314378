import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  LineChartOutlined,
  RetweetOutlined,
  CalculatorOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Flex, MenuProps, DatePicker, Space, App } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import ConversationsChart from './charts/conversations-chart'
import ConversationInteractionsChart from './charts/conversation-interactions-chart'
import FeedbackChart from './charts/feedback-chart'
import DataCard from './data-card'
import SectionCard, { PeriodType, TimezoneItems } from './section-card'
import { v1ChatConversationsStatisticsList } from 'api/Api'
import { ResponsesConversationStatsWithDetailResponse } from 'api/data-contracts'
import { Dayjs } from 'dayjs'
import { currentTimezone, dayjsUTC } from 'utils/filter'
import DownloadModal from './download-modal'
import styles from './index.scss'

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const { RangePicker } = DatePicker

const rangeItems: MenuProps['items'] = [
  {
    label: 'Last 1 day',
    key: '1',
  },
  {
    label: 'Last 7 days',
    key: '7',
  },
  {
    label: 'Last 14 days',
    key: '14',
  },
  {
    label: 'Last 30 days',
    key: '30',
  },
  {
    label: 'Last 90 days',
    key: '90',
  },
  {
    label: 'Custom',
    key: 'custom',
  },
]

const AnalyticsPage: React.FC = () => {
  const { message } = App.useApp()
  const [searchParams, setSearchParams] = useSearchParams()
  const [rangeItem, setRangeItem] = useState(rangeItems[1])
  const agentId = parseInt(searchParams.get('id') || '0')
  const [timezoneItem, setTimezoneItem] = useState(
    () => TimezoneItems?.find((item) => item?.key === currentTimezone()) || {}
  )
  const [startAt, setStartAt] = useState<Dayjs>()
  const [endAt, setEndAt] = useState<Dayjs>()
  const [timezoneOffset, setTimezoneOffset] = useState(
    () => dayjsUTC().utcOffset() / 60
  )
  const [conversationStatsData, setConversationStatsData] =
    useState<ResponsesConversationStatsWithDetailResponse>()
  const [conversationsPeriod, setConversationsPeriod] =
    useState<PeriodType>('daily')
  const [conversationInteractionsPeriod, setConversationInteractionsPeriod] =
    useState<PeriodType>('daily')
  const [feedbackPeriod, setFeedbackPeriod] = useState<PeriodType>('daily')
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let startAt = dayjsUTC()
    let endAt = dayjsUTC()
    if (rangeItem?.key !== 'custom') {
      startAt = dayjsUTC().subtract(
        parseInt(rangeItem?.key?.toString() ?? '0'),
        'day'
      )
      setStartAt(startAt)
      setEndAt(endAt)
    }
  }, [rangeItem])

  useEffect(() => {
    setTimezoneOffset((timezoneItem as any).key)
  }, [timezoneItem])

  const fetchData = useCallback(async () => {
    if (!startAt || !endAt) {
      return
    }
    const response = await v1ChatConversationsStatisticsList({
      agent_id: agentId,
      start_at: startAt.format(DATETIME_FORMAT),
      end_at: endAt.format(DATETIME_FORMAT),
      timezone_offset: timezoneOffset,
    })
    return response.data
  }, [agentId, startAt, endAt, timezoneOffset])

  useEffect(() => {
    setLoading(true)
    fetchData()
      .then((data) => {
        setConversationStatsData(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [fetchData])

  const conversationsIntentData = useMemo(() => {
    return (
      conversationStatsData?.intents.map((intent) => ({
        label: intent.intentLabel,
        name: intent.intentName,
        count: intent.count,
      })) ?? []
    )
  }, [conversationStatsData?.intents])

  return (
    <>
      <Flex className={styles.analyticsWrapper} gap={16} vertical>
        <Flex
          justify="space-between"
          align="center"
          gap={16}
          className={styles.analyticsHeader}
        >
          <div className={styles.analyticsHeaderTitle}>Analytics</div>
          <Flex className="operation" gap={8} style={{ alignItems: 'center' }}>
            Date Range:
            <Dropdown
              menu={{
                items: rangeItems,
                onClick: (e) => {
                  const item = rangeItems.find((item) => item?.key === e.key)
                  if (item) {
                    setRangeItem(item)
                  }
                },
              }}
            >
              <Button>
                <Space>
                  {(rangeItem as any)?.label}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Dropdown
              overlayClassName={styles.analyticsHeaderTimezoneDropdownWrapper}
              menu={{
                items: TimezoneItems,
                onClick: (e) => {
                  const item = TimezoneItems?.find(
                    (item) => item?.key?.toString() === e.key
                  )
                  if (item) {
                    setTimezoneItem(item)
                  }
                },
              }}
            >
              <Button>
                <Space>
                  {(timezoneItem as any)?.label}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <RangePicker
              showTime
              value={[startAt ?? dayjsUTC(), endAt ?? dayjsUTC()]}
              onChange={(_, dateStrings) => {
                const startAt = dayjsUTC(dateStrings[0])
                const endAt = dayjsUTC(dateStrings[1])
                setStartAt(startAt)
                const maxDate = startAt.add(365, 'days')
                if (maxDate.isAfter(dateStrings[1])) {
                  setEndAt(endAt)
                } else {
                  message.warning('End date should be within 1 year')
                  setEndAt(maxDate)
                }
              }}
              disabled={rangeItem?.key !== 'custom'}
            />
            <Button onClick={() => setIsDownloadModalOpen(true)}>
              <DownloadOutlined />
            </Button>
          </Flex>
        </Flex>
        <Flex gap={16}>
          <DataCard
            icon={<LineChartOutlined />}
            name="Total Conversations"
            value={conversationStatsData?.totalConversations ?? 0}
          />
          <DataCard
            icon={<RetweetOutlined />}
            name="Average Interactions"
            value={parseFloat(
              (conversationStatsData?.averageInteractions ?? 0).toFixed(2)
            )}
          />
          <DataCard
            icon={<CalculatorOutlined />}
            name="Average Response Time (s)"
            value={parseFloat(
              (
                (conversationStatsData?.averageResponseTimeInMilliseconds ??
                  1) / 1000
              ).toFixed(2)
            )}
          />
        </Flex>
        <Flex gap={16}>
          <Flex style={{ width: '100%', height: '950px' }} gap={16} vertical>
            <SectionCard
              name="Conversations"
              period={conversationsPeriod}
              onPeriodChange={(period) => {
                setConversationsPeriod(period)
              }}
            >
              <ConversationsChart
                agentId={agentId}
                startAt={dayjsUTC(startAt).toISOString()}
                endAt={dayjsUTC(endAt).toISOString()}
                timezoneOffset={timezoneOffset}
                period={conversationsPeriod}
              />
            </SectionCard>
            <SectionCard
              name="Conversation Interactions"
              period={conversationInteractionsPeriod}
              onPeriodChange={(period) => {
                setConversationInteractionsPeriod(period)
              }}
            >
              <ConversationInteractionsChart
                agentId={agentId}
                startAt={dayjsUTC(startAt).toISOString()}
                endAt={dayjsUTC(endAt).toISOString()}
                timezoneOffset={timezoneOffset}
                period={conversationInteractionsPeriod}
              />
            </SectionCard>
          </Flex>
          <Flex style={{ width: '40%', minWidth: '420px' }}>
            <SectionCard
              name="Feedback"
              onPeriodChange={(period) => {
                setFeedbackPeriod(period)
              }}
            >
              <FeedbackChart
                loading={loading}
                likes={conversationStatsData?.totalLikes ?? 0}
                dislikes={conversationStatsData?.totalDislikes ?? 0}
                intents={conversationsIntentData}
              />
            </SectionCard>
          </Flex>
        </Flex>
      </Flex>
      <DownloadModal
        open={isDownloadModalOpen}
        agentId={agentId}
        startAt={dayjsUTC(startAt).toISOString()}
        endAt={dayjsUTC(endAt).toISOString()}
        timezoneOffset={timezoneOffset}
        onFinish={() => setIsDownloadModalOpen(false)}
      />
    </>
  )
}

export default AnalyticsPage
