// Enum for channel types
export enum ChannelType {
  ChatPage = 'chat_page',
  ChatBubble = 'chat_bubble',
  // SmartApi
  Endpoint = 'end_point',
}

// Interface for a single channel
export interface Channel {
  id: number
  uuid: string
  agentID: number
  channelType: ChannelType
  title: string
  description?: string
  enabled: boolean
  main: boolean
  extraData?: string
  organizationID: number
  createdAt: string
  updatedAt: string
}

// Interface for a list of channels
export interface ChannelList {
  totalCount: number
  publishChannels: Channel[]
}
