import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { Avatar, Button, Flex } from 'antd'
import {
  UserOutlined,
  LikeOutlined,
  DislikeOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import cls from 'classnames'
import { isEmpty } from 'utils/common'
import Feedback from 'views/components/feedback'
import {
  v1ChatConversationsFeedbackCreate,
  v1ChatConversationsLikeCreate,
  v1ChatConversationsUnlikeCreate,
} from 'api/Api'
import CustomMarkdown from 'components/custom-markdown'
import WorkflowStatusDropdown, {
  WorkflowNodeInfo,
} from './workflow-status-dropdown'
import { NodeStatusNodeStatus } from 'api/data-contracts'
import styles from './index.scss'

dayjs.extend(duration)

export enum MessageAction {
  Retry = 'retry',
}

export type FeedbackLabel = { label: string; value: string }

interface MessageProps {
  id: string
  avatarUrl: string
  name: string | undefined
  content: string
  role: string
  isLoading: boolean
  userTokens: number
  durationInMs: number
  feedbackLike?: boolean
  feedbackDislike?: boolean
  feedbackLikeLabels: FeedbackLabel[]
  feedbackDislikeLabels: FeedbackLabel[]
  feedbackPlaceholder: string
  nodeInfos?: WorkflowNodeInfo[]
  onAction?: (action: MessageAction) => void
}

const Message: React.FC<MessageProps> = ({
  id,
  avatarUrl,
  name,
  content,
  role,
  isLoading,
  userTokens,
  durationInMs,
  feedbackLike,
  feedbackDislike,
  feedbackLikeLabels,
  feedbackDislikeLabels,
  feedbackPlaceholder,
  nodeInfos,
  onAction,
}) => {
  const [isOpenFeedbackForm, setIsOpenFeedbackForm] = useState(false)
  const [curFeedback, setCurFeedback] = useState<'like' | 'dislike' | 'none'>(
    'none'
  )
  const isUser = role === 'user'
  const hasError = useMemo(() => {
    const nodeInfo = nodeInfos?.some(
      (node) => node.status === NodeStatusNodeStatus.Failed
    )
    return !!nodeInfo
  }, [nodeInfos])

  useEffect(() => {
    if (feedbackLike === true) {
      setCurFeedback('like')
    } else if (feedbackDislike === true) {
      setCurFeedback('dislike')
    } else if (feedbackLike === false || feedbackDislike === false) {
      setCurFeedback('none')
    }
  }, [feedbackLike, feedbackDislike])

  return (
    <Flex className={styles.messageWrapper} gap={12}>
      <Flex className={styles.messageWrapperAvatar}>
        <Avatar
          icon={isEmpty(avatarUrl) ? <UserOutlined /> : undefined}
          src={isEmpty(avatarUrl) ? undefined : avatarUrl}
          size={48}
          shape="square"
        />
      </Flex>
      <Flex gap={12} vertical>
        <Flex className={styles.messageWrapperName}>{name}</Flex>
        <WorkflowStatusDropdown isLoading={isLoading} msgId={id} data={nodeInfos ?? []}/>
        <div
          className={cls('markdown-body', styles.messageWrapperContent)}
          style={{ display: isLoading ? 'none' : undefined }}
        >
          <CustomMarkdown isLoading={isLoading}>{content}</CustomMarkdown>
        </div>
        <Flex
          className={styles.messageWrapperExtra}
          gap={32}
          style={{ display: isUser ? 'none' : undefined }}
        >
          <Flex className={styles.messageWrapperExtraButtonWrapper}>
            {durationInMs > 0 && (
              <Flex
                style={{ minWidth: '32px', justifyContent: 'space-around' }}
              >
                <span>{Math.round(durationInMs / 1000)}s</span>
              </Flex>
            )}
            {userTokens > 0 && (
              <Flex
                style={{
                  paddingLeft: '4px',
                  minWidth: '32px',
                  justifyContent: 'space-around',
                }}
              >
                {userTokens} Tokens
              </Flex>
            )}
          </Flex>
          <Flex className={styles.messageWrapperExtraButtonWrapper}>
            {hasError && (
              <Button
                danger
                type="link"
                icon={<ReloadOutlined />}
                style={{ color: 'inherit' }}
                onClick={() => {
                  // Implement retry logic here
                  console.log('Retry clicked for message:', content)
                  onAction?.(MessageAction.Retry)
                }}
              />
            )}
            {!isLoading && !hasError && (
              <>
                <Button
                  type="link"
                  icon={
                    ['none', 'dislike'].includes(curFeedback) ? (
                      <LikeOutlined />
                    ) : (
                      curFeedback === 'like' && (
                        <LikeOutlined style={{ color: '#3278D9' }} />
                      )
                    )
                  }
                  style={{ color: 'inherit' }}
                  onClick={async () => {
                    if (curFeedback === 'none') {
                      setCurFeedback('like')
                      setIsOpenFeedbackForm(true)
                    } else if (curFeedback === 'like') {
                      await v1ChatConversationsLikeCreate(id, {
                        status: false,
                      })
                      setCurFeedback('none')
                    }
                  }}
                />
                <Button
                  type="link"
                  icon={
                    ['none', 'like'].includes(curFeedback) ? (
                      <DislikeOutlined />
                    ) : (
                      curFeedback === 'dislike' && (
                        <DislikeOutlined style={{ color: '#3278D9' }} />
                      )
                    )
                  }
                  style={{ color: 'inherit' }}
                  onClick={async () => {
                    if (curFeedback === 'none') {
                      setCurFeedback('dislike')
                      setIsOpenFeedbackForm(true)
                    } else if (curFeedback === 'dislike') {
                      await v1ChatConversationsUnlikeCreate(id, {
                        status: false,
                      })
                      setCurFeedback('none')
                    }
                  }}
                />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Feedback
        open={isOpenFeedbackForm}
        onClose={(submitted) => {
          if (!submitted) {
            setCurFeedback('none')
          }
          setIsOpenFeedbackForm(false)
        }}
        wrapperClassName={styles.messageWrapperFeedbackWrapper}
        options={
          (curFeedback === 'like'
            ? feedbackLikeLabels
            : feedbackDislikeLabels) ?? []
        }
        placeHolder={feedbackPlaceholder}
        feedbackRows={6}
        onSubmit={async ({ feedback, resultList }) => {
          try {
            await v1ChatConversationsFeedbackCreate(id, {
              feedback: feedback,
              feedbackLabel: JSON.stringify(resultList),
            })
            if (curFeedback === 'like') {
              await v1ChatConversationsLikeCreate(id, {
                status: true,
              })
              setCurFeedback('like')
            } else if (curFeedback === 'dislike') {
              await v1ChatConversationsUnlikeCreate(id, {
                status: true,
              })
              setCurFeedback('dislike')
            }
          } catch (error) {
            setCurFeedback('none')
          }
        }}
      />
    </Flex>
  )
}

export default observer(Message)
