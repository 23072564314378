import React, { useContext, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import {
  Space,
  Input,
  Slider,
  Switch,
  Select,
  message,
  Tree,
} from 'antd'
import {
  DownOutlined,
} from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import Section from '../../components/section'
import KnowledgeSettings, { KnowledgeData } from './knowledge-settings'
import {
  InputField as InputFieldType,
  DataType,
  OutputField,
  CustomNodeProps,
  NodeData,
} from '../../model'
import InputField from '../../components/input-field'
import { FlowContext } from '../../context'
import NodeSidebar from '../../components/node-sidebar'
import './sidebar.css'

const { TextArea } = Input
const { TreeNode } = Tree

const KnowledgeNodeSidebar: React.FC = observer(() => {
  const context = useContext(FlowContext)
  const currentNodeId = WorkflowStore.selectedNode?.id || ''
  const [loading, setLoading] = useState(false)
  const [knowledgeList, setKnowledgeList] = useState<KnowledgeData[]>([])

  useEffect(() => {
    setLoading(true)
    WorkflowStore.getRerankModels()
      .catch((error) => {
        console.error('Failed to retrieve rerank models:', error)
        message.error('Failed to retrieve rerank models.')
      })
      .finally(() => setLoading(false))
  }, [])

  // Convert old knowledge settings format to new format
  useEffect(() => {
    if (WorkflowStore.selectedNode?.type !== 'Knowledge') {
      return
    }

    const knowledgeList = getInput('knowledge_list')

    if (knowledgeList?.dataType === 'Array<Object>') {
      setKnowledgeList(knowledgeList.value)
      return
    }

    // Convert to new format
    const knowledges = getInput('knowledges')
    const query = getInput('query')
    const value: KnowledgeData[] = [
      {
        knowledge_id: knowledges.value[0] || '',
        search_by: [
          {
            field: {
              type: 'content',
              data: {
                dataType: 'String',
                value: '',
              },
            },
            query: {
              type: query.type,
              data: {
                dataType: 'String',
                value:
                  query.type === 'reference' ? query.reference : query.value,
              },
            },
          },
        ],
        search_type: getInputValue('search_type') || 'vector_search',
        vector_score_threshold: getInputValue('vector_score_threshold') || 0.5,
        vector_top_k: getInputValue('vector_top_k') || 5,
        full_text_score_threshold:
          getInputValue('full_text_score_threshold') || 0.5,
        full_text_top_k: getInputValue('full_text_top_k') || 5,
        hybrid_weighted_score_ratio: 0.5,
        full_text_content_enabled: true,
      },
    ]
    knowledgeList.value = value
    setKnowledgeList(knowledgeList.value)
  }, [WorkflowStore.selectedNode])

  const handleClose = () => {
    WorkflowStore.selectNode(null)
  }

  const handleNodeDataChange = (
    field: string,
    value: any,
    dataType: DataType = 'String',
    action: 'update' | 'delete' = 'update'
  ) => {
    if (WorkflowStore.selectedNode?.data?.data) {
      const currentNodeData = { ...WorkflowStore.selectedNode.data.data }
      let updatedInput = [...(currentNodeData.input || [])]

      if (field === 'label' || field === 'description') {
        currentNodeData[field] = value
      } else if (field === 'extends') {
        currentNodeData[field] = value
      } else {
        const inputIndex = updatedInput.findIndex(
          (input) => input.name === field
        )
        if (inputIndex !== -1) {
          if (action === 'update') {
            updatedInput[inputIndex] = { ...updatedInput[inputIndex], ...value }
          } else if (action === 'delete') {
            updatedInput.splice(inputIndex, 1)
          }
        } else {
          const newInputField: InputFieldType = {
            name: field,
            type: 'input',
            dataType: dataType,
            reference: '',
            ...value,
          }
          updatedInput.push(newInputField)
        }
      }

      const updatedNodeData = {
        ...(WorkflowStore.selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          input: updatedInput,
        },
      }

      const updatedNode = {
        ...WorkflowStore.selectedNode,
        data: updatedNodeData,
      }
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      if (WorkflowStore.selectedNode.data.onChange) {
        WorkflowStore.selectedNode.data.onChange(
          WorkflowStore.selectedNode.id,
          updatedNodeData
        )
      }
    }
  }

  const getInputValue = (name: string) => {
    const input = WorkflowStore.selectedNode?.data?.data?.input.find(
      (i: InputFieldType) => i.name === name
    )
    return input ? input.value : undefined
  }

  const getInput = (name: string): InputFieldType => {
    const input = WorkflowStore.selectedNode?.data?.data?.input.find(
      (i: InputFieldType) => i.name === name
    )
    return (
      input || {
        name,
        type: 'input',
        dataType: 'String',
        value: '',
        reference: '',
      }
    )
  }

  const renderTreeNodes = (data: OutputField[]) => {
    return data.map((item) => {
      const title = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Space>
            <span>{item.name}</span>
            <span style={{ color: '#888' }}>[{item.type}]</span>
          </Space>
        </div>
      )

      if (item.children && item.children.length) {
        return (
          <TreeNode title={title} key={item.name}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        )
      }
      return <TreeNode title={title} key={item.name} />
    })
  }

  if (WorkflowStore.selectedNode?.type !== 'Knowledge') {
    return null
  }

  return (
    <NodeSidebar
      nodeType={'knowledge'}
      onClose={handleClose}
      nodeData={WorkflowStore.selectedNode?.data?.data}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={WorkflowStore.selectedNode?.data?.data?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>
      <Section title="Settings">
        <KnowledgeSettings
          knowledgeList={knowledgeList}
          onChange={(value) =>
            handleNodeDataChange('knowledge_list', { value }, 'Array<Object>')
          }
        />
      </Section>
      <Section title="Rerank">
        <div className="setting-item">
          <span className="label">Enable Rerank</span>
          <Switch
            checked={getInputValue('rerank_enabled')}
            onChange={(checked) =>
              handleNodeDataChange(
                'rerank_enabled',
                { value: checked },
                'Boolean'
              )
            }
          />
        </div>
        {getInputValue('rerank_enabled') && (
          <>
            <div className="setting-item rerank-query">
              <span className="label">Rerank Query</span>
              <div className="element" style={{ minWidth: 'fit-content' }}>
                <InputField
                  index={0}
                  item={getInput('rerank_query')}
                  currentNodeId={currentNodeId}
                  nodes={context?.nodes || []}
                  edges={context?.edges || []}
                  allowEditFieldName={false}
                  supportArray={false}
                  supportObject={false}
                  displayDataType={false}
                  displayFieldName={false}
                  handleFormChange={(index, key, value) => {
                    handleNodeDataChange(
                      'rerank_query',
                      { [key]: value },
                      'String'
                    )
                  }}
                />
              </div>
            </div>
            <div className="setting-item">
              <span className="label">Rerank Model</span>
              <div className="element">
                <Select
                  value={getInputValue('rerank_model')}
                  onChange={(value) =>
                    handleNodeDataChange('rerank_model', { value }, 'String')
                  }
                  style={{ width: '205px' }}
                  dropdownStyle={{ width: '250px' }}
                  loading={loading}
                >
                  {WorkflowStore.rerankModels.map((model) => (
                    <Select.Option key={model.id} value={model.modelID}>
                      {model.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="setting-item">
              <span className="label">Rerank Score Threshold</span>
              <div className="element">
                <Slider
                  value={getInputValue('rerank_score_threshold')}
                  onChange={(value) =>
                    handleNodeDataChange(
                      'rerank_score_threshold',
                      { value },
                      'Number'
                    )
                  }
                  min={0.01}
                  max={1}
                  step={0.01}
                  style={{ width: '205px' }}
                />
              </div>
            </div>
            <div className="setting-item">
              <span className="label">Top K</span>
              <div className="element">
                <Slider
                  value={getInputValue('rerank_count')}
                  onChange={(value) =>
                    handleNodeDataChange('rerank_count', { value }, 'Integer')
                  }
                  min={1}
                  max={50}
                  step={1}
                  style={{ width: '205px' }}
                />
              </div>
            </div>
          </>
        )}
      </Section>
      <Section title="Output Variables" defaultCollapsed={true}>
        <Tree defaultExpandAll showLine switcherIcon={<DownOutlined />}>
          {renderTreeNodes(
            WorkflowStore.selectedNode?.data?.data?.output || []
          )}
        </Tree>
      </Section>
    </NodeSidebar>
  )
})

export default KnowledgeNodeSidebar
