import { useEffect, useState } from 'react'
import { Menu } from 'antd'
import { observer } from 'mobx-react'
import {
  BankFilled,
  RobotFilled,
  DatabaseFilled,
  DashboardOutlined,
  MergeOutlined,
  BuildFilled,
} from '@ant-design/icons'
import { useExNavigate } from 'hooks/use-ex-navigate'
import globalStore from 'stores/global'

import styles from './index.scss'

const MenuItems = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    route: '/portal/dashboard',
    icon: <DashboardOutlined />,
  },
  {
    key: 'organization',
    label: 'Organization',
    route: '/portal/organization',
    icon: <BankFilled />,
  },
  {
    key: 'agent',
    label: 'Agent',
    route: '/portal/agent',
    icon: <RobotFilled />,
  },
  {
    key: 'intent',
    label: 'Intent',
    route: '/portal/intent',
    icon: <MergeOutlined />,
  },
  {
    key: 'metric',
    label: 'Metric',
    route: '/portal/metric',
    icon: <BuildFilled />,
  },
  {
    key: 'knowledge',
    label: 'Knowledge',
    route: '/portal/knowledge',
    icon: <DatabaseFilled />,
  },
  {
    key: 'plugin',
    label: 'Plugin',
    route: '/portal/plugin',
    icon: <DatabaseFilled />,
  },
]

const WhiteListMenuItems = ['organization', 'agent', 'metric', 'knowledge']

const SidebarMenu = () => {
  const navigate = useExNavigate()
  const [curPath, setCurPath] = useState('')

  const handleMenuClick = (path: string) => {
    const route = MenuItems.find((item) => item.key === path)?.route
    if (!route) return
    navigate(`${route}`)
  }

  useEffect(() => {
    // init the path while page refresh
    handleMenuClick(curPath)
  }, [])

  useEffect(() => {
    setCurPath(
      location.pathname.startsWith('/portal')
        ? location.pathname.split('/').slice(2).join('/')
        : ''
    )
  }, [location.pathname])

  return (
    <Menu
      rootClassName={styles.sidebarMenuWrapper}
      theme="light"
      mode="inline"
      selectedKeys={[curPath.split('/')[0] ?? '']}
      items={
        globalStore.globalSettings.isDeveloperMode
          ? MenuItems
          : MenuItems.filter((i) => WhiteListMenuItems.includes(i.key))
      }
      onClick={(info) => handleMenuClick(info.key)}
    />
  )
}

export default observer(SidebarMenu)
