import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Flex, Input } from 'antd'
import { v1ChatSessionsDelete, v1ChatSessionsTitlePartialUpdate } from 'api/Api'
import { useEffect, useRef, useState } from 'react'
import styles from './index.scss'

export enum SessionItemMenuCommand {
  Rename = 'rename',
  Delete = 'delete',
}

export interface SessionItemMenuProps {
  uuid: string
  title: string
  style?: React.CSSProperties
  className?: string
  onRename?: (uuid: string, title: string) => void
  onDelete?: (uuid: string) => void
  onClick?: (uuid: string, command: SessionItemMenuCommand) => void
}

const profileItems = [
  {
    key: SessionItemMenuCommand.Rename,
    label: (
      <button className={styles.menuItem}>
        <EditOutlined /> Rename
      </button>
    ),
  },
  {
    key: SessionItemMenuCommand.Delete,
    label: (
      <button className={styles.menuItem}>
        <DeleteOutlined /> Delete
      </button>
    ),
  },
]

const SessionItem: React.FC<SessionItemMenuProps> = (props) => {
  const ref = useRef<HTMLElement>(null)
  const [title, setTitle] = useState(props.title)
  const [editable, setEditable] = useState(false)

  useEffect(() => {
    setTitle(props.title)
  }, [props.title])

  useEffect(() => {
    // Get element of the menu item wrapper
    const el = ref.current?.parentElement?.parentElement
    if (editable) {
      el?.querySelector('input')?.focus()
      el?.setAttribute('editable', 'true')
    } else {
      el?.setAttribute('editable', 'false')
    }
  }, [editable])

  const handleClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  const handleMenuClick = (uuid: string, command: SessionItemMenuCommand) => {
    if (SessionItemMenuCommand.Rename === command) {
      setEditable(true)
    } else if (SessionItemMenuCommand.Delete === command) {
      v1ChatSessionsDelete({ uuid }).then(() => props.onDelete?.(uuid))
    }
  }

  const handleTitleChange = () => {
    setEditable(false)
    if (title === props.title) return
    v1ChatSessionsTitlePartialUpdate(props.uuid, { title })
      .then(() => props.onRename?.(props.uuid, title))
      .catch(() => setTitle(props.title))
  }

  return (
    <Flex
      ref={ref}
      className={[styles.sessionItem, props.className].join(' ')}
      style={props.style}
      gap={4}
    >
      {editable ? (
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onPressEnter={() => handleTitleChange()}
          onBlur={() => handleTitleChange()}
          disabled={!editable}
        />
      ) : (
        <>
          <span className={styles.sessionItemTitle}>{title}</span>
          <Dropdown
            menu={{
              items: profileItems,
              onClick: (item) => {
                const command = item.key as SessionItemMenuCommand
                handleMenuClick(props.uuid, command)
                props.onClick?.(props.uuid, command)
              },
            }}
            trigger={['click']}
          >
            <Button type="link" onClick={handleClick}>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </>
      )}
    </Flex>
  )
}

export default SessionItem
