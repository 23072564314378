import {
  ConfigFormItemType,
  ConfigSectionType,
} from 'components/new-dynamic-form/types'
import { CommonConfig } from '../constants'

const ItemSliderConfig: ConfigSectionType[] = [
  ...CommonConfig,
  {
    sectionLabel: 'sliderConfig',
    items: [
      {
        itemLabel: 'DefaultValue',
        itemName: 'defaultValue',
        type: 'inputNumber',
      },
      { itemLabel: 'Max', itemName: 'max', type: 'inputNumber' },
      { itemLabel: 'Min', itemName: 'min', type: 'inputNumber' },
      { itemLabel: 'Step', itemName: 'step', type: 'inputNumber' },
    ],
  },
]

export default ItemSliderConfig
