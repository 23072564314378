import { UserOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Avatar, AvatarProps, Button, Flex, Menu, Typography } from 'antd'
import LogoutIcon from 'assets/images/logout.svg'
import QrCodeIcon from 'assets/images/qr-code-icon.svg'
import SettingsIcon from 'assets/images/settings.svg'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useState } from 'react'
import userStore from 'stores/user'
import { getFileUrl, isEmpty } from 'utils/common'
import Settings from 'views/portal/settings'
import TwoFactorAuth from 'views/portal/settings/two-factor-auth'
import styles from './index.scss'

interface UserProfileMenuProps {
  onLogout?: () => Promise<void>
  className?: string
  customMenuItems?: MenuProps['items']
  onMenuClick?: MenuProps['onClick']
}

interface ProfileModalsProps {
  isSettingsOpen: boolean
  isTwoFactorAuthOpen: boolean
  onSettingsClose: () => void
  onTwoFactorAuthClose: () => void
}

const ProfileModals: React.FC<ProfileModalsProps> = ({
  isSettingsOpen,
  isTwoFactorAuthOpen,
  onSettingsClose,
  onTwoFactorAuthClose,
}) => {
  return (
    <>
      <Settings
        open={isSettingsOpen}
        okText="Save"
        onFinish={onSettingsClose}
        onCancel={onSettingsClose}
      />
      <TwoFactorAuth
        open={isTwoFactorAuthOpen}
        okText="Save"
        onFinish={onTwoFactorAuthClose}
        onCancel={onTwoFactorAuthClose}
      />
    </>
  )
}

interface UserAvatarProps extends Omit<AvatarProps, 'icon'> {
  avatarUUID?: string
  userName?: string
  showFallback?: boolean
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  avatarUUID,
  userName = 'User',
  showFallback = true,
  ...props
}) => {
  const avatarUrl = avatarUUID ? getFileUrl(avatarUUID) : ''
  const hasValidImage = !isEmpty(avatarUrl)

  return (
    <Avatar
      icon={!hasValidImage && showFallback ? <UserOutlined /> : undefined}
      src={hasValidImage ? avatarUrl : undefined}
      alt={`${userName} avatar`}
      style={{
        flexShrink: 0,
        borderRadius: '9px',
        borderColor: '#eff1f4',
        backgroundColor: '#efefef',
        ...props?.style,
      }}
      shape={props?.shape ?? 'square'}
      size={props?.size ?? 'large'}
      {...props}
    />
  )
}

const UserProfileMenu: React.FC<UserProfileMenuProps> = ({
  onLogout,
  className,
  customMenuItems,
  onMenuClick,
}) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isTwoFactorAuthOpen, setIsTwoFactorAuthOpen] = useState(false)

  const handleClickSettings = () => {
    setIsSettingsOpen(true)
  }

  const handleClickTwoFactorAuth = () => {
    setIsTwoFactorAuthOpen(true)
  }

  const handleLogout = async () => {
    if (onLogout) {
      await onLogout()
    } else {
      await userStore.logout()
      window.location.href = '/login'
    }
  }

  const handleMenuItemClick: MenuProps['onClick'] = (info) => {
    if (onMenuClick) {
      onMenuClick(info)
      return
    }

    switch (info.key) {
      case 'settings': {
        handleClickSettings()
        break
      }
      case 'two-factor-auth': {
        handleClickTwoFactorAuth()
        break
      }
      case 'logout': {
        handleLogout()
        break
      }
    }
  }

  const defaultMenuItems: MenuProps['items'] = [
    {
      key: 'profile',
      className: styles.userProfileMenuItem,
      label: (
        <div className={styles.userProfileWrapper}>
          <UserAvatar
            shape="square"
            avatarUUID={userStore.loginUser?.avatarUUID}
            userName={userStore.loginUser?.name}
          />
          <div className={styles.userProfileInfo}>
            <span>
              <b>{userStore.loginUser?.name}</b>&nbsp;(
              {userStore.loginUser?.roleName})
            </span>
            <span>{userStore.loginUser?.email}</span>
          </div>
        </div>
      ),
    },
    {
      key: 'settings',
      label: (
        <Button
          type="text"
          icon={<SettingsIcon style={{ fontSize: '18px' }} />}
          onClick={handleClickSettings}
          className={classNames(styles.menuItemButton, styles.withIcon)}
        >
          Settings
        </Button>
      ),
    },
    {
      key: 'two-factor-auth',
      label: (
        <Button
          type="text"
          icon={<QrCodeIcon style={{ fontSize: '18px' }} />}
          onClick={handleClickTwoFactorAuth}
          className={classNames(styles.menuItemButton, styles.withIcon)}
        >
          2FA Setup
        </Button>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: (
        <Button
          type="text"
          icon={<LogoutIcon style={{ fontSize: '18px' }} />}
          onClick={handleLogout}
          className={classNames(styles.menuItemButton, styles.withIcon)}
        >
          Logout
        </Button>
      ),
    },
  ]

  const menuItems = customMenuItems || defaultMenuItems

  return (
    <>
      <Menu
        className={classNames(styles.userProfileSiderWrapper, className)}
        selectedKeys={[]}
        getPopupContainer={(node) => node.parentNode as HTMLElement}
        onClick={handleMenuItemClick}
        triggerSubMenuAction="click"
        items={[
          {
            key: 'user',
            label: (
              <Flex className={styles.userProfileSiderLabel}>
                <Flex
                  align="center"
                  gap="17px"
                  className={styles.userProfileSiderLabelContent}
                >
                  <UserAvatar
                    shape="square"
                    size={36}
                    avatarUUID={userStore.loginUser?.avatarUUID}
                    userName={userStore.loginUser?.name}
                  />
                  <Typography.Text ellipsis className={styles.userProfileName}>
                    {userStore.loginUser?.name}
                  </Typography.Text>
                </Flex>
                <Button
                  type="text"
                  className={classNames(styles.userProfileLogout)}
                  icon={<LogoutIcon style={{ fontSize: '20px' }} />}
                />
              </Flex>
            ),
            expandIcon: <></>,
            children: menuItems,
          },
        ]}
      />
      <ProfileModals
        isSettingsOpen={isSettingsOpen}
        isTwoFactorAuthOpen={isTwoFactorAuthOpen}
        onSettingsClose={() => setIsSettingsOpen(false)}
        onTwoFactorAuthClose={() => setIsTwoFactorAuthOpen(false)}
      />
    </>
  )
}
export default observer(UserProfileMenu)
