import useModal from 'hooks/use-modal'

export const useReportsModals = () => {
  const evaluateAgentModal = useModal()

  return {
    evaluateAgentModalOpen: evaluateAgentModal.isOpen,
    openEvaluateAgentModal: evaluateAgentModal.open,
    closeEvaluateAgentModal: evaluateAgentModal.close,
  }
}

export default useReportsModals
