import { v4 as uuidV4 } from 'uuid'
import { FormItemType, FormSelectorType } from './types'

import { getDefaultValue as getInputDefaultValue } from './components/input'
import { getDefaultValue as getInputNumberDefaultValue } from './components/input-number'
import { getDefaultValue as getSectionDefaultValue } from './components/section'
import { getDefaultValue as getSwitchDefaultValue } from './components/switch'
import { getDefaultValue as getSliderDefaultValue } from './components/slider'
import { getDefaultValue as getCheckboxDefaultValue } from './components/checkbox'
import { getDefaultValue as getRadioDefaultValue } from './components/radio'
import { getDefaultValue as getSelectDefaultValue } from './components/select'
import { getDefaultValue as getTimePickerDefaultValue } from './components/time-picker'
import { getDefaultValue as getDatePickerDefaultValue } from './components/date-picker'
import { getDefaultValue as getUploadDefaultValue } from './components/upload'
import { getDefaultValue as getCodeDefaultValue } from './components/code'
import { getDefaultValue as getReferenceSelectDefaultValue } from './components/reference-select'
import { getDefaultValue as getListDefaultValue } from './components/list'
import { getDefaultValue as getTextAreaDefaultValue } from './components/text-area'

export const getRootDefaultValue = (): FormItemType => {
  const rootId = uuidV4()
  const defaultSection = getSectionDefaultValue()
  const defaultDescription = getInputDefaultValue()
  return {
    path: [rootId],
    formItemId: rootId,
    type: 'root',
    children: [
      // {
      //   ...defaultDescription,
      //   path: [rootId, defaultSection.formItemId],
      //   props: {
      //     formItemConfig: { name: 'description', label: 'Description' },
      //   },
      // },
      { ...defaultSection, path: [rootId, defaultSection.formItemId] },
    ],
    props: {},
  }
}

export const defaultValueMap: Record<FormSelectorType, () => FormItemType> = {
  root: getRootDefaultValue,
  section: getSectionDefaultValue,
  input: getInputDefaultValue,
  switch: getSwitchDefaultValue,
  slider: getSliderDefaultValue,
  inputNumber: getInputNumberDefaultValue,
  checkbox: getCheckboxDefaultValue,
  radio: getRadioDefaultValue,
  select: getSelectDefaultValue,
  timePicker: getTimePickerDefaultValue,
  datePicker: getDatePickerDefaultValue,
  upload: getUploadDefaultValue,
  code: getCodeDefaultValue,
  referenceSelect: getReferenceSelectDefaultValue,
  list: getListDefaultValue,
  textArea: getTextAreaDefaultValue,
}
