import { DropTargetRecord } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder'
import { cloneDeep } from 'lodash-es'

import { FormItemType } from './types'
import { BoardContextValue } from './form-item-render/context'

export const getFormItemPosition = (
  itemList: FormItemType[],
  parentId: string,
  formItemId: string
): [number, number] => {
  if (!itemList) return [-1, -1]
  const parentIndex = itemList.findIndex(
    (section) => section.formItemId === parentId
  )
  if (parentIndex === -1) return [-1, -1]
  const formItemIndex = itemList[parentIndex].children?.findIndex(
    (cards) => cards.formItemId === formItemId
  )

  return [parentIndex, formItemIndex]
}

export const getDropTarget = (
  targetList: DropTargetRecord[],
  type: 'section' | 'card' | 'card-list'
) => {
  return targetList.find((t) => t.data.type === type)
}

export const reorderSection: BoardContextValue['reorderSection'] = (
  itemList,
  args
) => {
  const { startIndex, finishIndex, trigger = 'keyboard' } = args
  return reorder({ list: itemList, startIndex, finishIndex })
}

export const transRegionalMoveCard: BoardContextValue['moveCard'] = (
  itemList,
  args
) => {
  const {
    startSectionIndex,
    finishSectionIndex,
    cardIndexInStartSection,
    cardIndexInFinishSection,
  } = args
  if (startSectionIndex === finishSectionIndex) return itemList

  const newItemList = Array.from(itemList)
  const [sourceCard] = newItemList[startSectionIndex].children.splice(
    cardIndexInStartSection,
    1
  )

  const newSourceCard = {
    ...sourceCard,
    sectionId: newItemList[finishSectionIndex].formItemId,
  }

  // new Section
  if (
    cardIndexInFinishSection === undefined ||
    cardIndexInFinishSection === -1
  ) {
    const updateSection: FormItemType = {
      ...newItemList[finishSectionIndex],
      children: [newSourceCard],
    }
    newItemList.splice(finishSectionIndex, 1, updateSection)
    return newItemList
  }

  // old Section
  newItemList[finishSectionIndex]?.children.splice(
    cardIndexInFinishSection,
    0,
    newSourceCard
  )
  return newItemList
}

export const reorderCard: BoardContextValue['reorderCard'] = (
  itemList,
  args
) => {
  const { sectionIndex, startIndex, finishIndex, trigger = 'keyboard' } = args

  const updateSection: FormItemType = {
    ...itemList[sectionIndex],
    children: reorder({
      list: itemList[sectionIndex].children,
      startIndex,
      finishIndex,
    }),
  }

  const newItemList = Array.from(itemList)
  newItemList.splice(sectionIndex, 1, updateSection)

  return newItemList
}

//
export const getItemByPath = (item: FormItemType, path: string[]) => {
  if (path.length === 1) {
    return item
  } else {
    let curItem = item
    const restPath = path.slice(1)
    for (const p of restPath) {
      const fItem = curItem.children.find((i) => i.formItemId === p)
      if (!fItem) return
      curItem = fItem
    }
    return curItem
  }
}

export const updateItemListByPath = (
  root: FormItemType,
  path: string[],
  updateItemList: FormItemType[]
) => {
  const newRoot = cloneDeep(root)
  if (path.length === 1) {
    newRoot.children = updateItemList
  } else {
    let curItem = newRoot
    const restPath = path.slice(1)
    for (const p of restPath) {
      const fItem = curItem.children.find((i) => i.formItemId === p)
      if (!fItem) break
      curItem = fItem
    }
    curItem.children = updateItemList
  }

  return newRoot
}

export const updateItemByPath = (
  root: FormItemType,
  path: string[],
  updateItem: FormItemType
) => {
  const newRoot = cloneDeep(root)
  let curItem = newRoot
  // Can't update the value of root
  if (path.length === 1) return newRoot
  if (path.length === 2) {
    curItem = newRoot
  } else {
    for (const p of path.slice(1, -1)) {
      const fItem = curItem.children.find((i) => i.formItemId === p)
      if (!fItem) break
      curItem = fItem
    }
  }

  const updateIdx = curItem.children.findIndex(
    (i) => i.formItemId === updateItem.formItemId
  )
  curItem.children.splice(updateIdx, 1, updateItem)

  return newRoot
}
