import { ResponsesEvaluationAPIResponse } from 'api/data-contracts'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { deepCopy } from 'utils/common'
import { dayjsUTC } from 'utils/filter'
import ReportModal, { DEFAULT_METRIC, ReportModalProps } from './report-modal'
import ReportTable, { ActionType, ReportTableRef } from './report-table'
import ReportViewer from './report-viewer'

const DEFAULT_REPORT_DATA: ReportModalProps['data'] = {
  name: 'New Evaluation',
  evaluationMetrics: [deepCopy(DEFAULT_METRIC)],
  questionIDs: [],
}

interface ReportPageProps {
  agentId: number
  evaluateAgentModalOpen: boolean
  onEvaluateAgentModalClose: () => void
}

const ReportPage: React.FC<ReportPageProps> = ({
  agentId,
  evaluateAgentModalOpen,
  onEvaluateAgentModalClose,
}) => {
  const [isReportViewOpen, setIsReportViewOpen] = useState(false)
  const [report, setReport] = useState<ReportModalProps['data']>(
    deepCopy(DEFAULT_REPORT_DATA)
  )
  const [reportView, setReportView] = useState<ResponsesEvaluationAPIResponse>()
  const reportTableRef = React.createRef<ReportTableRef>()

  // Initialize report data when modal opens
  useEffect(() => {
    if (evaluateAgentModalOpen) {
      setReport({
        ...deepCopy(DEFAULT_REPORT_DATA),
        name: `Evaluation_${agentId}_${dayjsUTC().format('YYYYMMDD_HHmmss')}`,
      })
    }
  }, [evaluateAgentModalOpen, agentId])

  return (
    <>
      <ReportTable
        ref={reportTableRef}
        agentId={agentId}
        onAction={(type, data) => {
          if (type === ActionType.View) {
            setReportView(data)
            setIsReportViewOpen(true)
          }
        }}
      />
      <ReportModal
        open={evaluateAgentModalOpen}
        title="Create Evaluation"
        okText="Create"
        data={{
          ...report,
        }}
        agentId={agentId}
        onFinish={() => {
          reportTableRef.current?.refresh()
          onEvaluateAgentModalClose()
        }}
        onCancel={onEvaluateAgentModalClose}
      />
      <ReportViewer
        open={isReportViewOpen}
        title="Evaluation Report"
        okText=""
        data={reportView ? { ...reportView } : undefined}
        agentId={agentId}
        onFinish={() => {
          setIsReportViewOpen(false)
        }}
        onCancel={() => setIsReportViewOpen(false)}
      />
    </>
  )
}

export default observer(ReportPage)
