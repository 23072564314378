import { App as AntApp, ConfigProvider, theme } from 'antd'
import AntdStaticFunction, {
  NotificationGlobalConfig,
} from 'components/antd-static-function'
import { useEffect } from 'react'
import roleStore from 'stores/role'
import userStore from 'stores/user'
import globalStore from 'stores/global'
import { handleAfterMounted } from './global'
import AppRoutes from './routes'

handleAfterMounted()

const App = () => {
  const initPage = async () => {
    await globalStore.init()
    await userStore.init()
    await userStore.getCurrentUser()
    await roleStore.initRoleList()
  }

  useEffect(() => {
    // Only check user and redirect when in the portal
    if (location.pathname.startsWith('/portal')) {
      initPage()
    }
  }, [])

  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        token: {
          colorPrimary: '#3278D9',
          fontFamily: `Helvetica, ${theme.defaultSeed.fontFamily}`,
          fontSize: 16,
          controlHeight: 36,
          colorSuccess: '#2abb71',
          colorSuccessBorder: '#CCEDD8',
        },
      }}
    >
      <AntApp className={'app-wrapper'} notification={NotificationGlobalConfig}>
        <AntdStaticFunction />
        <AppRoutes />
      </AntApp>
    </ConfigProvider>
  )
}

export default App
