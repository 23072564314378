import React, { memo, useEffect, useRef } from 'react'
import { FormItemType } from 'components/new-dynamic-form/types'
import ComponentWrapper, { WithDraggingProps } from '../lib/component-wrapper'
import ItemListWrapper from '../lib/item-list'
import { FormItemRenderMap } from '../constants-map'

import styles from './index.scss'
import { getCommonDefaultValue } from '../constants'

type ItemListProps = {
  listConfig?: Record<string, any>
  itemProps?: WithDraggingProps<FormItemType>
} & Record<string, any>

export const getDefaultValue = () => ({
  ...getCommonDefaultValue('list'),
})

const ItemList = (props: ItemListProps) => {
  const { itemProps } = props
  const dropAreaRef = useRef<HTMLDivElement>(null)

  // Don't need in the render
  const handleAddItem = () => {}
  const handleDeleteItem = () => {}

  return (
    <div>
      <div ref={dropAreaRef} key={itemProps?.formItemId}>
        {!itemProps?.children.length && (
          <div className={styles.listDropArea}>Replace me</div>
        )}
        {itemProps?.children.map((item, idx) => {
          const { formItemId, type } = item
          const Cmp = Reflect.get(FormItemRenderMap, type)
          return (
            <React.Fragment key={`${formItemId}`}>
              {Cmp ? <Cmp {...item} index={idx} /> : null}
            </React.Fragment>
          )
        })}
      </div>
      <ItemListWrapper
        data={[]}
        onAddItem={handleAddItem}
        onDeleteItem={handleDeleteItem}
        maxCount={0}
      />
    </div>
  )
}

export default ComponentWrapper(memo(ItemList))
