import { FormSelectorType } from '../types'
import SectionSVG from 'assets/images/plugin/section.svg'
import InputSVG from 'assets/images/plugin/input.svg'
import InputNumberSVG from 'assets/images/plugin/input-number.svg'
import SwitchSVG from 'assets/images/plugin/switch.svg'
import SliderSVG from 'assets/images/plugin/slider.svg'
import CheckboxSVG from 'assets/images/plugin/checkbox.svg'
import RadioSVG from 'assets/images/plugin/radio.svg'
import SelectSVG from 'assets/images/plugin/select.svg'
import TimePickerSVG from 'assets/images/plugin/time-picker.svg'
import UploadSVG from 'assets/images/plugin/upload.svg'
import CodeSVG from 'assets/images/plugin/code.svg'
import ReferenceSelectSVG from 'assets/images/plugin/reference-select.svg'
import ListSVG from 'assets/images/plugin/list.svg'
import DatePickerSVG from 'assets/images/plugin/date-picker.svg'
// import TextAreaSVG from 'assets/images/plugin/text-area.svg'
import TextAreaSVG from 'assets/images/plugin/input.svg'

import styles from './index.scss'

export type ItemSelectorType = Record<
  FormSelectorType,
  | Partial<{
      render: React.ReactNode | null | undefined
      icon: React.ReactNode
    }>
  | undefined
>

export const DEFAULT_ITEM_SELECTOR_MAP: ItemSelectorType = {
  root: undefined,
  section: {
    icon: <SectionSVG className={styles.defaultIconSize} />,
  },
  input: {
    icon: <InputSVG className={styles.defaultIconSize} />,
  },
  inputNumber: {
    icon: <InputNumberSVG className={styles.defaultIconSize} />,
  },
  switch: {
    icon: <SwitchSVG className={styles.defaultIconSize} />,
  },
  slider: {
    icon: <SliderSVG className={styles.defaultIconSize} />,
  },
  checkbox: {
    icon: <CheckboxSVG className={styles.defaultIconSize} />,
  },
  radio: {
    icon: <RadioSVG className={styles.defaultIconSize} />,
  },
  select: {
    icon: <SelectSVG className={styles.defaultIconSize} />,
  },
  timePicker: {
    icon: <TimePickerSVG className={styles.defaultIconSize} />,
  },
  upload: {
    icon: <UploadSVG className={styles.defaultIconSize} />,
  },
  code: {
    icon: <CodeSVG className={styles.defaultIconSize} />,
  },
  referenceSelect: {
    icon: <ReferenceSelectSVG className={styles.defaultIconSize} />,
  },
  list: {
    icon: <ListSVG className={styles.defaultIconSize} />,
  },
  datePicker: {
    icon: <DatePickerSVG className={styles.defaultIconSize} />,
  },
  textArea: {
    icon: <TextAreaSVG className={styles.defaultIconSize} />,
  },
}
