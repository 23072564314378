import useHeaderSubMenu from 'hooks/use-header-sub-menu'
import HeaderNavbar, { HeaderTabsProps } from 'layouts/portal/header'
import { observer } from 'mobx-react'
import { Outlet, useLocation } from 'react-router-dom'
import globalStore from 'stores/global'

import './index.css'

const Studio = () => {
  const location = useLocation()
  const menuItems: HeaderTabsProps = [
    {
      key: 'workflow',
      label: 'Workflow',
      link: `/portal/agent/studio/workflow${location.search}`,
    },
    {
      key: 'evaluation',
      label: 'Evaluation',
      link: `/portal/agent/studio/evaluation${location.search}`,
    },
    {
      key: 'analytics',
      label: 'Analytics',
      link: `/portal/agent/studio/analytics${location.search}`,
    },
  ]

  useHeaderSubMenu(
    menuItems.filter(
      (item) =>
        globalStore.globalSettings.isDeveloperMode ||
        ['evaluation', 'analytics'].includes(item.key)
    )
  )

  return (
    <>
      <div className="studio">
        {/* TODO@Tanner: remove the header */}
        <HeaderNavbar />
        <Outlet />
      </div>
    </>
  )
}

export default observer(Studio)
