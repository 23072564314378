import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useCallback,
  ForwardRefRenderFunction,
} from 'react'
import {
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Select,
  InputNumber,
  DatePicker,
} from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { OutputField } from 'views/portal/agent/studio/workflow/model'

const { Option } = Select

export type Field = OutputField

const getDefaultFieldValue = (field: Field) => {
  if (field.type.includes('String')) return ''
  if (field.type.includes('Integer') || field.type.includes('Number')) return 0
  if (field.type.includes('Boolean')) return false
  if (field.type.includes('Date') || field.type.includes('Datetime'))
    return null
  if (field.type.includes('Object')) return {}
  if (field.type.includes('Array')) return []
  return null
}

const renderInputComponent = (field: Field, value: any, onChange: any) => {
  const dateFormat = 'DD/MM/YYYY'
  const datetimeFormat = 'DD/MM/YYYY HH:mm'

  if (!field.name) return
  if (field.type.includes('Integer') || field.type.includes('Number')) {
    return (
      <InputNumber
        placeholder={field.description}
        value={value}
        onChange={onChange}
        style={{ width: '100%' }}
      />
    )
  } else if (field.type.includes('Boolean')) {
    return (
      <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
    )
  } else if (field.type.includes('Datetime')) {
    return (
      <div style={{ width: '100%' }}>
        <DatePicker
          showTime
          format={datetimeFormat}
          placeholder={field.description}
          value={value ? dayjs(value) : null}
          onChange={(date) => onChange(date ? date.toISOString() : null)}
          style={{ width: '100%' }}
        />
      </div>
    )
  } else if (field.type.includes('Date')) {
    return (
      <div style={{ width: '100%' }}>
        <DatePicker
          format={dateFormat}
          placeholder={field.description}
          value={value ? dayjs(value) : null}
          onChange={(date) => onChange(date ? date.toISOString() : null)}
          style={{ width: '100%' }}
        />
      </div>
    )
  } else {
    return (
      <Input
        placeholder={field.description}
        value={value}
        onChange={onChange}
      />
    )
  }
}

const renderField = (
  field: Field,
  values: any,
  setValues: (values: any) => void,
  errors: any,
  setErrors: (errors: any) => void,
  path: string[] = [],
  isArrayItem: boolean = false
) => {
  const fieldPath = [...path, field.name].join('.')
  const value = values[fieldPath]
  const error = errors[fieldPath]

  const handleChange = (e: any) => {
    const newValue = e?.target?.value ?? e
    const newValues = { ...values, [fieldPath]: newValue }
    setValues(newValues)
    if (
      field.required &&
      (!newValues[fieldPath] ||
        (Array.isArray(newValues[fieldPath]) &&
          newValues[fieldPath].length === 0))
    ) {
      setErrors({ ...errors, [fieldPath]: `Please input ${field.name}` })
    } else {
      const { [fieldPath]: _, ...rest } = errors
      setErrors(rest)
    }
  }

  if (field.type.startsWith('Array')) {
    const arrayValue = Array.isArray(value) ? value : []
    return (
      <div key={fieldPath}>
        {!isArrayItem && (
          <div
            style={{
              marginBottom: 0,
              marginTop: 20,
              height: 30,
              overflow: 'hidden',
            }}
          >
            {field.name}
            {field.required && <span style={{ color: 'red' }}> *</span>}
          </div>
        )}
        <div
          style={{
            border: '1px solid #eee',
            borderRadius: 10,
            padding: '20px',
            marginBottom: 8,
          }}
        >
          {arrayValue.map((item: any, index: number) => (
            <Row
              key={`row-${index}`}
              gutter={24}
              align="middle"
              style={{ marginBottom: 8 }}
            >
              <Col span={20}>
                {field.children.length > 0 ? (
                  <div>
                    {field.children.map((child) =>
                      renderField(
                        child,
                        values,
                        setValues,
                        errors,
                        setErrors,
                        [...path, field.name, index.toString()],
                        true
                      )
                    )}
                  </div>
                ) : (
                  <>
                    {renderInputComponent(
                      field,
                      arrayValue[index],
                      (e: any) => {
                        const newArray = [...arrayValue]
                        newArray[index] = e.target ? e.target.value : e
                        handleChange(newArray)
                      }
                    )}
                    {errors[
                      [...path, field.name, index.toString()].join('.')
                    ] && (
                      <div style={{ color: 'red' }}>
                        {
                          errors[
                            [...path, field.name, index.toString()].join('.')
                          ]
                        }
                      </div>
                    )}
                  </>
                )}
              </Col>
              <Col span={4}>
                <Button
                  type="link"
                  onClick={() => {
                    const newArray = arrayValue.filter(
                      (_: any, i: number) => i !== index
                    )
                    handleChange(newArray)
                  }}
                  icon={<MinusCircleOutlined />}
                >
                  Remove
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            type="dashed"
            onClick={() =>
              handleChange([...arrayValue, getDefaultFieldValue(field)])
            }
            block
            style={{ marginTop: 8 }}
            icon={<PlusOutlined />}
          >
            Add {field.name}
          </Button>
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </div>
      </div>
    )
  } else if (field.type === 'Object') {
    return (
      <div key={fieldPath} style={{ paddingLeft: 20, marginBottom: 8 }}>
        <strong>
          {field.name}
          {field.required && <span style={{ color: 'red' }}> *</span>}
        </strong>
        {field.children.map((child) =>
          renderField(child, values, setValues, errors, setErrors, [
            ...path,
            field.name,
          ])
        )}
      </div>
    )
  } else {
    return (
      <div key={fieldPath} style={{ marginBottom: 8 }}>
        <div>
          {field.name}
          {field.required && <span style={{ color: 'red' }}> *</span>}
        </div>
        {renderInputComponent(field, value, handleChange)}
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </div>
    )
  }
}

export interface DynamicFormRenderingRefProps {
  resetFields: () => void
  getFieldsValue: () => any
  validateFields: () => boolean
}

interface DynamicFormRenderingProps {
  fields: Field[]
  onChange?: (values: any) => void
  initialValues?: any
}

const DynamicFormRendering: ForwardRefRenderFunction<
  DynamicFormRenderingRefProps,
  DynamicFormRenderingProps
> = ({ fields, onChange, initialValues }, ref) => {
  const [values, setValues] = useState(initialValues || {})
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  const handleValuesChange = useCallback(
    (newValues: any) => {
      setValues(newValues)
      if (onChange) {
        onChange(newValues)
      }
    },
    [onChange]
  )

  const validateFields = () => {
    let isValid = true
    const newErrors: { [key: string]: string } = {}
    fields.forEach((field) => {
      const fieldPath = field.name
      const fieldValue = values[fieldPath]
      if (field.required) {
        if (
          !fieldValue ||
          (Array.isArray(fieldValue) && fieldValue.length === 0)
        ) {
          newErrors[fieldPath] = `Please input ${field.name}`
          isValid = false
        } else if (Array.isArray(fieldValue)) {
          fieldValue.forEach((item: any, index: number) => {
            if (!item) {
              newErrors[`${fieldPath}.${index}`] =
                `Please input ${field.name} item`
              isValid = false
            }
          })
        }
      }
    })
    setErrors(newErrors)
    return isValid
  }

  useImperativeHandle(ref, () => ({
    resetFields: () => setValues(initialValues || {}),
    getFieldsValue: () => values,
    validateFields,
  }))

  return (
    <div className="dynamic-form-rendering">
      {fields.map((field) =>
        renderField(field, values, handleValuesChange, errors, setErrors)
      )}
    </div>
  )
}

export default observer(forwardRef(DynamicFormRendering))
