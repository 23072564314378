import { memo, useMemo } from 'react'
import ReactMarkdown, { Options as MarkdownOptions } from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkGfm from 'remark-gfm'
import { LoadingOutlined } from '@ant-design/icons'

type CustomMarkdownType = MarkdownOptions & {
  isLoading?: boolean
}

const CustomMarkdown = (props: CustomMarkdownType) => {
  const { children, isLoading = false, ...restProps } = props

  const components = useMemo(
    () => ({
      code: ({ node, inline, className, children, ...props }: any) => {
        const match = /language-(\w+)/.exec(className || '')
        return !inline && match ? (
          <SyntaxHighlighter
            style={oneLight}
            language={match[1]}
            PreTag="div"
            {...props}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        )
      },
      a: ({ node, href, children, ...props }: any) => (
        <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
          {children}
        </a>
      ),
      p({ children, ...props }: React.HTMLProps<HTMLParagraphElement>) {
        return (
          <p {...props}>
            {children}
            {isLoading ? (
              <span style={{ paddingLeft: 5 }}>
                <LoadingOutlined />
              </span>
            ) : null}
          </p>
        )
      },
    }),
    [isLoading]
  )

  return (
    <ReactMarkdown
      components={components}
      remarkPlugins={[remarkGfm]}
      {...restProps}
    >
      {children}
    </ReactMarkdown>
  )
}

export default memo(CustomMarkdown)
