import { AxiosError } from 'axios'
import { notification } from 'components/antd-static-function'

import {
  HttpStatusCode,
  HttpStatusCodeManager,
} from 'constants/http-status-codes'

import userStore from 'stores/user'
import { ErrorCodes } from './error-codes'

const TIME_OUT: number = 10 * 1000

const GLOBAL_INTERCEPTORS = {
  REQUEST_INTERCEPTOR: (config: any) => {
    return config
  },

  REQUEST_INTERCEPTOR_CATCH: (err: any) => {
    return Promise.reject(err instanceof Error ? err : new Error(String(err)))
  },

  RESPONSE_INTERCEPTOR: (res: any) => {
    return res
  },

  RESPONSE_INTERCEPTOR_CATCH: (err: any) => {
    return Promise.reject(err instanceof Error ? err : new Error(String(err)))
  },
}

const INSTANCE_INTERCEPTORS = {
  requestInterceptor: (config: any) => {
    return config
  },

  requestInterceptorCatch: (err: any) => {
    return Promise.reject(err instanceof Error ? err : new Error(String(err)))
  },

  responseInterceptor: (res: any) => {
    return res
  },

  responseInterceptorCatch: (err: AxiosError<any>) => {
    const { name, code, config, status, response } = err
    const { data } = response ?? {}

    // The login credential is expired
    if (
      status === HttpStatusCode.UNAUTHORIZED &&
      (data?.code === ErrorCodes.AuthenticationRequired ||
        data?.code === ErrorCodes.InvalidToken)
    ) {
      if (location.pathname !== '/login') {
        userStore.setUser(null)
        location.pathname = '/login'
      }
    }

    if (status !== HttpStatusCode.OK) {
      const codeDetail = HttpStatusCodeManager.getDetails(Number(status))
      notification.error({
        message: `${name}: ${codeDetail.code}`,
        description: (
          <>
            <div>{codeDetail.code}</div>
            <div>URL: {config?.url}</div>
            <div>{data?.detail ?? data?.message ?? codeDetail.message}</div>
          </>
        ),
      })
    }

    return Promise.reject(err)
  },
}

export { GLOBAL_INTERCEPTORS, INSTANCE_INTERCEPTORS, TIME_OUT }
