import { useMemo } from 'react'
import userStore from 'stores/user'

export const useIsAdmin = () => {
  const isAdmin = useMemo(
    () => userStore.loginUser?.roleName.toLowerCase() === 'admin',
    [userStore.loginUser]
  )
  return isAdmin
}
