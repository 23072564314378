import React, { useCallback, useState } from 'react'
import { App, Col, Dropdown, MenuProps, Row, Spin, Switch, Tag } from 'antd'
import { FormOutlined, DeleteOutlined } from '@ant-design/icons'
import chunkStore from 'stores/chunk'
import { Chunk } from 'stores/models/chunk'
import { getMessageFromError } from 'utils/common'
import styles from './chunk-card.scss'

export interface ChunkCardProps {
  data: Chunk
  onAction?: (type: ActionType, chunk: Chunk) => void
}

export enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

const ChunkCard: React.FC<ChunkCardProps> = ({ data, onAction }) => {
  const [loading, setLoading] = useState(false)
  const [enabled, setEnabled] = useState(data.enabled)
  const { message, modal } = App.useApp()

  const handleSwitchChange = async () => {
    try {
      await chunkStore.updateStatus(data._id, data.knowledgeID, !enabled)
      setEnabled(!enabled)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
  }

  const handleModifyClick = () => {
    onAction?.(ActionType.Edit, data)
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'delete') {
      onAction?.(ActionType.Delete, data)
      modal.confirm({
        title: 'Are you sure you want to delete this chunk?',
        content:
          'This action cannot be undone and will permanently delete the chunk from the system.',
        okText: 'Yes, Delete it',
        okType: 'danger',
        cancelText: 'No, Cancel',
        async onOk() {
          setLoading(true)
          try {
            await chunkStore.delete(data._id, data.knowledgeID)
            message.success('The chunk has been successfully deleted.')
          } catch (error) {
            message.error(getMessageFromError(error))
          }
          setLoading(false)
        },
      })
    }
  }

  const items: MenuProps['items'] = [
    {
      label: 'Delete',
      key: 'delete',
      icon: <DeleteOutlined />,
    },
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  return (
    <Spin tip="Loading..." spinning={loading}>
      <div className={styles.chunkCard}>
        <Row gutter={24}>
          <Col className="chunk-icon" span={12}>
            <Tag># {data._id}</Tag>
          </Col>
          <Col className="chunk-icon" span={12}></Col>
        </Row>
        <Row className={styles.chunkCardContent}>{data.content}</Row>
        <Row gutter={24} align="middle">
          <Col span={12}>
            <Switch
              value={enabled}
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              onChange={handleSwitchChange}
            />
          </Col>
          <Col span={12}>
            <Dropdown.Button menu={menuProps} onClick={handleModifyClick}>
              <FormOutlined />
              Edit
            </Dropdown.Button>
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

export default ChunkCard
