import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { App, Flex, Modal, Typography, Upload, UploadFile, UploadProps } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { v1EvaluationQuestionsFileTemplateList } from 'api/Api'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { ReactNode, useMemo, useState } from 'react'
import { downloadFile } from 'utils/common'
import styles from './upload-modal.scss'

export interface UploadModalProps {
  data: { agentId: number }
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: () => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const UploadModal: React.FC<UploadModalProps> = (props) => {
  const { message } = App.useApp()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    action: `/api/v1/evaluation/questions/upload?agent_id=${props.data.agentId}`,
    onChange(info) {
      const { status } = info.file
      const fileIndex = fileList.findIndex((file) => file.uid === info.file.uid)
      if (fileIndex < 0) {
        setFileList([...fileList, info.file])
      } else {
        fileList[fileIndex] = info.file
        setFileList([...fileList])
      }
      if (status === 'uploading') {
      } else if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
    onRemove(e) {
      setFileList(fileList.filter((file) => file.uid !== e.uid))
    },
    beforeUpload(file) {
      const isLt50M = file.size / 1024 / 1024 < 10
      const allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i
      if (!isLt50M) {
        message.error('File must be smaller than 10MB!')
        return Upload.LIST_IGNORE
      }
      if (!allowedExtensions.test(file.name)) {
        message.error(
          'Invalid file type. Only CSV, XLS, XLSX files are allowed!'
        )
        return Upload.LIST_IGNORE
      }
      return true
    },
  }

  const hasFileUploading = useMemo(() => {
    return (
      fileList.filter((file) => ['uploading'].includes(file.status ?? ''))
        .length > 0
    )
  }, [fileList])

  const hasFileUploadDone = useMemo(() => {
    return (
      fileList.filter((file) => ['done'].includes(file.status ?? '')).length > 0
    )
  }, [fileList])

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (hasFileUploading) {
      message.error('Please wait for the file to finish uploading.')
      return
    }
    if (hasFileUploadDone) {
      props.onFinish?.()
    } else {
      props.onCancel?.(e)
    }
  }

  const downloadTemplate = async () => {
    const response = await v1EvaluationQuestionsFileTemplateList()
    downloadFile(response)
  }

  return (
    <Modal
      title={props.title ?? 'Upload Questions'}
      open={props.open}
      okText="Save"
      onOk={handleCancel}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      footer={[]}
    >
      <Flex gap={16} style={{ paddingTop: '24px' }} vertical>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className={classNames('ant-upload-hint', styles.uploadHint)}>
            Supports uploading CSV, XLS and XLSX files only. Single file limit:
            10MB.
          </p>
        </Dragger>
        <Typography.Paragraph className={styles.uploadHint}>
          <InfoCircleOutlined />{' '}
          Please download and use the {' '}
          <Typography.Link onClick={downloadTemplate} className={styles.questionTemplateLink}>
            question template
          </Typography.Link>
          {' '}before you upload.
        </Typography.Paragraph>
      </Flex>
    </Modal>
  )
}

export default observer(UploadModal)