import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  CSSProperties,
} from 'react'
import {
  Button,
  Menu,
  Input,
  Skeleton,
  message,
  MenuProps,
  Flex,
  Space,
  Avatar,
  Dropdown,
  Splitter,
  Empty,
} from 'antd'
import {
  CalendarOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  LeftOutlined,
  SearchOutlined,
  SendOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { observer } from 'mobx-react'
import { useSearchParams, Link } from 'react-router-dom'
import Message, { FeedbackLabel, MessageAction } from './message'
import globalStore from 'stores/global'
import userStore from 'stores/user'
import { isEmpty } from 'lodash-es'
import { getFileUrl, getUserLanguage } from 'utils/common'
import {
  v1AgentList,
  v1ChatConversationsList,
  v1ChatSessionsList,
  v1PublishchannelMainList,
} from 'api/Api'
import {
  ResponsesAgentResponse,
  GenieCoreAppHandlersChatRequestsSendRequest,
  ModelsConversationRoleType,
} from 'api/data-contracts'
import { createStreamingApiRequest } from 'services/request'
import apiConfig from 'services/api'
import {
  ChatSession,
  ChatConversation,
  SendResponse,
  ChatMainChannel,
} from './types'
import SearchModal from './search-modal'
import { useExNavigate } from 'hooks/use-ex-navigate'
import { dayjsUTC } from 'utils/filter'
import styles from './index.scss'
import SessionItem, { SessionItemMenuCommand } from './session-item'

const { TextArea } = Input

const dateRangeItems: MenuProps['items'] = [
  {
    key: '9999',
    label: <span>All days</span>,
  },
  {
    key: '1',
    label: <span>Today</span>,
  },
  {
    key: '7',
    label: <span>Last 7 days</span>,
  },
  {
    key: '14',
    label: <span>Last 14 days</span>,
  },
  {
    key: '30',
    label: <span>Last 30 days</span>,
  },
  {
    key: '90',
    label: <span>Last 90 days</span>,
  },
  {
    key: '180',
    label: <span>Last 180 days</span>,
  },
]

const groupSessions = (sessions: ChatSession[]) => {
  const groupedSessions: {
    period: string
    sessions: ChatSession[]
  }[] = [
    { period: 'Today', sessions: [] },
    { period: 'Yesterday', sessions: [] },
    { period: 'Previous 7 Days', sessions: [] },
    { period: 'Previous 30 Days', sessions: [] },
    { period: 'Previous 90 Days', sessions: [] },
    { period: 'Previous 180 Days', sessions: [] },
    { period: 'Older', sessions: [] },
  ]

  const now = dayjsUTC()

  sessions.forEach((session) => {
    const latestConversationAt = dayjsUTC(session.latestConversationDatetime)

    if (latestConversationAt.isSame(now, 'day')) {
      groupedSessions.find((g) => g.period === 'Today')?.sessions.push(session)
    } else if (latestConversationAt.isSame(now.subtract(1, 'day'), 'day')) {
      groupedSessions
        .find((g) => g.period === 'Yesterday')
        ?.sessions.push(session)
    } else if (latestConversationAt.isAfter(now.subtract(7, 'day'))) {
      groupedSessions
        .find((g) => g.period === 'Previous 7 Days')
        ?.sessions.push(session)
    } else if (latestConversationAt.isAfter(now.subtract(30, 'day'))) {
      groupedSessions
        .find((g) => g.period === 'Previous 30 Days')
        ?.sessions.push(session)
    } else if (latestConversationAt.isAfter(now.subtract(90, 'day'))) {
      groupedSessions
        .find((g) => g.period === 'Previous 90 Days')
        ?.sessions.push(session)
    } else if (latestConversationAt.isAfter(now.subtract(180, 'day'))) {
      groupedSessions
        .find((g) => g.period === 'Previous 180 Days')
        ?.sessions.push(session)
    } else {
      groupedSessions.find((g) => g.period === 'Older')?.sessions.push(session)
    }
  })

  return groupedSessions
}

const parseToFeedbackLabels = (val: string) =>
  val
    ?.split(',')
    .filter((item) => !isEmpty(item))
    .map((i) => {
      const [label, value] = i.split('|')
      return { label, value }
    })

const Chat: React.FC = () => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)
  const navigate = useExNavigate()
  const [content, setContent] = useState('')
  const [sendMessageLoading, setSendMessageLoading] = useState(false)
  const [sessionLoading, setSessionLoading] = useState(true)
  const [channelLoading, setChannelLoading] = useState(false)
  const [contextLoading, setContextLoading] = useState(false)
  const [conversations, setConversations] = useState<ChatConversation[]>([])
  const [dateRange, setDateRange] = useState(dateRangeItems[0])
  const [sessions, setSessions] = useState<ChatSession[]>([])
  const [sessionPageInput, setSessionPageInput] = useState('1')
  const [sessionTotalPages, setSessionTotalPages] = useState(1)
  const [sessionPage, setSessionPage] = useState(1)
  const [sessionSort, setSessionSort] = useState(false)
  const [agentLoading, setAgentLoading] = useState(false)
  const [agent, setAgent] = useState<ResponsesAgentResponse>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [agentUUID, setAgentUUID] = useState(searchParams.get('agent_uuid'))
  const [sessionUUID, setSessionUUID] = useState(
    searchParams.get('session_uuid')
  )
  const [mainChannel, setMainChannel] = useState<ChatMainChannel>()
  const contentRef = useRef<HTMLDivElement>(null)
  const currentSession = useMemo(
    () => sessions.find((s) => s.uuid === sessionUUID),
    [sessions, sessionUUID]
  )

  useEffect(() => {
    setAgentUUID(searchParams.get('agent_uuid'))
  }, [searchParams.get('agent_uuid')])

  useEffect(() => {
    setSessionUUID(searchParams.get('session_uuid'))
  }, [searchParams.get('session_uuid')])

  // Header visibility
  useEffect(() => {
    globalStore.header.visible = false
    return () => {
      globalStore.header.visible = true
    }
  }, [])

  // Load agent and channel
  useEffect(() => {
    if (!agentUUID) {
      navigate('/portal/agent')
      return
    }

    const loadChannel = (agentID: number) => {
      setChannelLoading(true)
      v1PublishchannelMainList({ agent_id: agentID })
        .then((res) => {
          const data = res.data
          setMainChannel({
            ...data,
            extraData: JSON.parse(data.extraData),
          })
        })
        .finally(() => {
          setChannelLoading(false)
        })
    }

    setAgentLoading(true)
    v1AgentList({ uuid: agentUUID })
      .then((res) => {
        loadChannel(res.data.id)
        setAgent(res.data)
      })
      .finally(() => {
        setAgentLoading(false)
      })
  }, [agentUUID])

  // Load sessions
  useEffect(() => {
    if (!agent) {
      return
    }

    const load = () => {
      setSessionLoading(true)
      v1ChatSessionsList({
        page: sessionPage,
        limit: 30,
        sort: JSON.stringify({
          latest_conversation_datetime: sessionSort ? 'asc' : 'desc',
        }),
        conditions: JSON.stringify([
          { field: 'agent_id', operator: '=', value: agent.id },
          {
            field: 'latest_conversation_datetime',
            operator: '>',
            value: dayjsUTC()
              .utc()
              .subtract(parseInt(dateRange?.key as any), 'day')
              .format(),
          },
        ]),
      })
        .then((res) => {
          setSessions(res.data.data)
          setSessionPage(res.data.pagination.currentPage)
          setSessionTotalPages(res.data.pagination.totalPages)
        })
        .finally(() => {
          setSessionLoading(false)
        })
    }

    // Debounce load sessions
    const isInput = ['input', 'button'].includes(
      document?.activeElement?.nodeName.toLowerCase() ?? ''
    )
    const timer = setTimeout(load, isInput ? 300 : 0)
    return () => clearTimeout(timer)
  }, [agent, sessionPage, dateRange, sessionSort])

  // Load conversations
  useEffect(() => {
    setConversations([])

    if (sessionUUID && !channelLoading) {
      setContextLoading(true)
      v1ChatConversationsList({ session_uuid: sessionUUID })
        .then((res) => {
          const conversations = res.data.map((conversation) => ({
            ...conversation,
            hasError: false,
            isLoading: false,
            currentNodeID: '',
            currentNodeLabel: '',
            startTime: '',
            endTime: '',
          }))
          setConversations(conversations)
        })
        .finally(() => {
          setContextLoading(false)
          autoScroll()
        })
    }
  }, [sessionUUID, channelLoading])

  const groupedSessions = useMemo<MenuProps['items']>(() => {
    const groups = groupSessions(sessions)
      .filter((group) => group.sessions.length > 0)
      .map((group) => ({
        ...group,
        sessions: group.sessions.sort((a, b) => {
          const at = dayjsUTC(a.latestConversationDatetime).unix()
          const bt = dayjsUTC(b.latestConversationDatetime).unix()
          return sessionSort ? at - bt : bt - at
        }),
      }))
      .map((group) => ({
        key: `g-${group.period}`,
        label: group.period.charAt(0).toUpperCase() + group.period.slice(1),
        type: 'group' as const,
        children: group.sessions.map((session) => ({
          key: session.uuid,
          label: (
            <SessionItem
              uuid={session.uuid}
              title={session.title}
              onRename={(uuid, title) =>
                setSessions(
                  sessions.map((s) => (s.uuid === uuid ? { ...s, title } : s))
                )
              }
              onDelete={(uuid) =>
                setSessions(sessions.filter((s) => s.uuid !== uuid))
              }
            />
          ),
        })),
      }))

    return sessionSort ? groups.reverse() : groups
  }, [sessions, sessionSort])

  const onSessionClick: MenuProps['onClick'] = (e) => {
    const sessionUUID = e.key
    navigate(
      `${window.location.pathname}?agent_uuid=${agentUUID}&session_uuid=${sessionUUID}`,
      { replace: true }
    )
    setSessionUUID(sessionUUID)
  }

  const handleNewSession = () => {
    setSessionUUID('')
    setConversations([])
    navigate(`${window.location.pathname}?agent_uuid=${agentUUID}`, {
      replace: true,
    })
  }

  const send = async (
    agentUUID: string,
    sessionUUID: string,
    content: string
  ) => {
    const userConversation: ChatConversation = {
      id: '',
      sessionUUID: sessionUUID,
      userID: 0,
      organizationID: 0,
      agentID: 0,
      content,
      role: ModelsConversationRoleType.RoleUser,
      dislike: false,
      like: false,
      feedback: '',
      feedbackLabel: '',
      createdAt: dayjsUTC().utc().toISOString(),
      intents: [],
      nodeInfos: [],
      session: currentSession,
      responseDurationInMilliseconds: 0,
      userTokens: 0,
      error: '',
      hasError: false,
      isLoading: false,
      startTime: '',
      endTime: '',
    }

    const assistantConversation: ChatConversation = {
      id: '',
      sessionUUID: sessionUUID,
      userID: 0,
      organizationID: 0,
      agentID: 0,
      content: '',
      role: ModelsConversationRoleType.RoleAssistant,
      dislike: false,
      like: false,
      feedback: '',
      feedbackLabel: '',
      createdAt: dayjsUTC().utc().toISOString(),
      userTokens: 0,
      intents: [],
      nodeInfos: [],
      session: currentSession,
      responseDurationInMilliseconds: 0,
      error: '',
      hasError: false,
      isLoading: true,
      startTime: '',
      endTime: '',
    }

    setConversations([
      ...conversations,
      userConversation,
      assistantConversation,
    ])

    const params: GenieCoreAppHandlersChatRequestsSendRequest = {
      agentUUID: agentUUID || '',
      sessionUUID: sessionUUID,
      language: getUserLanguage(),
      userPrompt: content,
      documentID: '',
      imageID: '',
      audioID: '',
      customFields: {},
    }

    const onMessage = (parsedData: SendResponse) => {
      userConversation.id = parsedData.requestMessageID

      if (parsedData.status !== 'completed') {
        assistantConversation.content += parsedData.answer
      }

      assistantConversation.id = parsedData.responseMessageID
      assistantConversation.startTime = parsedData.startTime
      assistantConversation.endTime = parsedData.endTime
      assistantConversation.nodeInfos = parsedData.nodeInfos
      assistantConversation.userTokens = parsedData.completionTokens

      setConversations([
        ...conversations,
        userConversation,
        assistantConversation,
      ])
      autoScroll()

      // Update the latest datetime only when the session list matches
      // any one of the following conditions:
      //
      // 1. total pages <= 1
      // 2. desc sorted and in the 1st page
      // 3. asc sorted and in the last page
      //
      if (
        sessionTotalPages > 1 &&
        ((!sessionSort && sessionPage !== 1) ||
          (sessionSort && sessionPage !== sessionTotalPages))
      ) {
        setSessions([...sessions.filter((s) => s.uuid !== sessionUUID)])
        return
      }

      const now = dayjsUTC().utc().toISOString()
      if (currentSession) {
        currentSession.latestConversationDatetime = now
        setSessions([
          ...sessions.filter((s) => s.uuid !== sessionUUID),
          currentSession,
        ])
      } else {
        setSessionUUID(parsedData.uuid)

        const session: ChatSession = {
          id: Date.now(),
          uuid: parsedData.uuid,
          title: parsedData.title,
          agentID: parsedData.agentID,
          userID: 0,
          organizationID: 0,
          latestConversationDatetime: now,
          createdAt: now,
          updatedAt: now,
        }
        setSessions([...sessions, session])
      }
    }

    const onError = (error: any) => {
      console.error('Error occurred:', error)
      assistantConversation.hasError = true
      assistantConversation.isLoading = false
    }

    const onComplete = () => {
      assistantConversation.hasError = false
      assistantConversation.isLoading = false
    }

    const timer = setInterval(() => {
      assistantConversation.responseDurationInMilliseconds += 500
      setConversations([
        ...conversations,
        userConversation,
        assistantConversation,
      ])
      autoScroll()
    }, 500)

    try {
      autoScroll()
      await createStreamingApiRequest(apiConfig.chatSend, {
        body: params,
        onMessage,
        onError,
        onComplete,
      })
    } catch (error) {
      console.error('Request failed:', error)
    } finally {
      clearInterval(timer)
    }
  }

  const handleSendMessage = async (content: string) => {
    if (content.trim() === '' || sendMessageLoading) {
      return
    }

    try {
      setSendMessageLoading(true)
      setContent('')
      await send(agentUUID || '', sessionUUID || '', content)
    } catch (error) {
      message.error(
        'Oops! There was a problem delivering your message. Please try sending it again.'
      )
    } finally {
      setSendMessageLoading(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage(content)
    }
  }

  const autoScroll = () => {
    setTimeout(() => {
      const element = contentRef.current
      if (!element) return
      const maxScroll = element.scrollHeight - element.clientHeight
      if (element.scrollTop === maxScroll) return
      const scrollStep = (maxScroll - element.scrollTop) / 10
      let lastScrollTop = element.scrollTop
      const animateScroll = () => {
        if (!element) return
        const newScrollTop = Math.min(element.scrollTop + scrollStep, maxScroll)
        element.scrollTop = newScrollTop
        if (newScrollTop < maxScroll && lastScrollTop !== newScrollTop) {
          lastScrollTop = newScrollTop
          requestAnimationFrame(animateScroll)
        }
      }
      requestAnimationFrame(animateScroll)
    }, 200)
  }

  const renderAvatar = (
    avatarUUID: string,
    size: number,
    style?: CSSProperties
  ) => {
    return (
      <>
        {isEmpty(avatarUUID) ? (
          <Avatar
            shape="square"
            size={size}
            icon={<QuestionCircleOutlined />}
            style={style}
          />
        ) : (
          <Avatar
            shape="square"
            size={size}
            src={getFileUrl(avatarUUID)}
            style={style}
          />
        )}
      </>
    )
  }

  const renderStartPage = (questions: string[]) => {
    return (
      <Flex className={styles.chatStartPage} vertical gap={64}>
        <div className={styles.chatStartPageBackground}></div>
        <Flex className={styles.chatStartPageHeader} vertical gap={48}>
          <Flex className={styles.chatStartPageHeaderAvatar} gap={12}>
            {renderAvatar(agent?.agentIconUUID ?? '', 64, {
              backgroundColor: isEmpty(agent?.agentIconUUID)
                ? '#bbb'
                : undefined,
            })}
            {agent?.agentName}
          </Flex>
          {agent?.description}
        </Flex>
        <Flex
          vertical
          gap={12}
          style={{ display: questions.length > 0 ? undefined : 'none' }}
        >
          You can ask me
          <Flex className={styles.chatStartPageQuestions} vertical gap={4}>
            {questions.map((question, index) => (
              <Button key={index} onClick={() => handleSendMessage(question)}>
                {question}
              </Button>
            ))}
          </Flex>
        </Flex>
      </Flex>
    )
  }

  const renderConversations = (conversations: ChatConversation[]) => {
    return conversations.map((conversation, index: number) => {
      const avatarUrl =
        conversation.role === 'assistant'
          ? getFileUrl(agent?.agentIconUUID ?? '')
          : getFileUrl(userStore.loginUser?.avatarUUID ?? '')
      const name = conversation.role === 'assistant' ? agent?.agentName : 'You'
      const nodeInfos = conversation.nodeInfos?.map((nodeInfo) => ({
        nodeId: nodeInfo.nodeId,
        nodeName: nodeInfo.nodeName,
        nodeType: nodeInfo.nodeType,
        startTime: nodeInfo.startTime,
        endTime: nodeInfo.endTime,
        status: nodeInfo.status,
        logs: nodeInfo.logs
          .map((log) => {
            const startTime = dayjsUTC(log.startTime).format('YYMMDDHHmmss.SSS')
            const endTime = log.endTime
              ? dayjsUTC(log.endTime).format('YYMMDDHHmmss.SSS')
              : 'NA'
            const error = log.error ? 'Error: ' + log.error : ''
            return `[${startTime}][${endTime}][${log.status}] ${log.message} ${error}`
          })
          .join('\n'),
        details: JSON.stringify(nodeInfo, null, 4),
        durationInMilliseconds: nodeInfo.durationInMicroSeconds / 1000,
      }))

      const feedbackLikeLabels: FeedbackLabel[] = []
      const feedbackDislikeLabels: FeedbackLabel[] = []

      if (conversation.like === true || conversation.dislike === true) {
        const labels = parseToFeedbackLabels(conversation.feedbackLabel)
        labels.forEach((label) => {
          feedbackLikeLabels.push(label)
          feedbackDislikeLabels.push(label)
        })
      } else {
        const likeLabels = parseToFeedbackLabels(
          mainChannel?.extraData?.feedbackOperationLikeOptions ?? ''
        )
        const dislikeLabels = parseToFeedbackLabels(
          mainChannel?.extraData?.feedbackOperationUnlikeOptions ?? ''
        )
        likeLabels.forEach((label) => {
          feedbackLikeLabels.push(label)
        })
        dislikeLabels.forEach((label) => {
          feedbackDislikeLabels.push(label)
        })
      }

      let feedbackLike: boolean | undefined
      if (conversation.like === true) {
        feedbackLike = true
      } else if (conversation.dislike === true) {
        feedbackLike = false
      }

      return (
        <Message
          key={index}
          id={conversation.id}
          avatarUrl={avatarUrl}
          name={name}
          content={conversation.content}
          role={conversation.role}
          isLoading={conversation.isLoading}
          feedbackLike={feedbackLike}
          userTokens={conversation.userTokens}
          durationInMs={conversation.responseDurationInMilliseconds}
          feedbackLikeLabels={feedbackLikeLabels}
          feedbackDislikeLabels={feedbackDislikeLabels}
          feedbackPlaceholder="Enter a description..."
          nodeInfos={nodeInfos}
          onAction={(action) => {
            if (action === MessageAction.Retry) {
              const userConversation = conversations[index - 1]
              send(agentUUID || '', sessionUUID || '', userConversation.content)
            }
          }}
        />
      )
    })
  }

  return (
    <>
      <Splitter className={styles.mainContainer}>
        <Splitter.Panel defaultSize={292} min={292} max={820}>
          <Flex className={styles.leftSection} vertical>
            <Flex className={styles.leftSectionHeader}>
              <Link to="/portal/agent">
                <Space style={{ color: 'initial', fontWeight: 'bold' }}>
                  <LeftOutlined />
                  {renderAvatar(agent?.agentIconUUID ?? '', 32)}
                  {agent?.agentName}
                </Space>
              </Link>
            </Flex>
            <Flex className={styles.leftSectionSession} gap={8}>
              <Button
                className={styles.leftSectionSearchButton}
                onClick={() => setIsSearchModalOpen(true)}
              >
                <SearchOutlined />
              </Button>
              <Button
                type="text"
                icon={<PlusOutlined />}
                className={styles.leftSectionSessionNewButton}
                onClick={handleNewSession}
              />
            </Flex>
            <Flex className={styles.leftSectionFilter} gap={8}>
              <Dropdown
                menu={{
                  items: dateRangeItems,
                  onClick: (item) => {
                    const data = dateRangeItems.find(
                      (i: any) => i.key === item.key
                    )
                    if (data) {
                      setDateRange(data)
                    }
                  },
                }}
              >
                <Button type="text" className={styles.leftSectionFilterRange}>
                  <CalendarOutlined />
                  {(dateRange as any).label}
                </Button>
              </Dropdown>
              <Button
                icon={
                  sessionSort ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )
                }
                onClick={() => {
                  // Set loading to true to prevent showing from debounce time
                  setSessionLoading(true)
                  setSessionSort(!sessionSort)
                }}
                type="text"
              />
            </Flex>
            <Flex className={styles.leftSectionHistory} vertical>
              {sessionLoading ? (
                <div style={{ margin: '16px' }}>
                  <Skeleton active />
                </div>
              ) : groupedSessions?.length == 0 ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ margin: 'auto' }}
                />
              ) : (
                <Menu
                  className={styles.leftSectionHistoryList}
                  mode="inline"
                  items={groupedSessions}
                  onClick={onSessionClick}
                  selectedKeys={currentSession ? [currentSession.uuid] : []}
                />
              )}
            </Flex>
            <Flex className={styles.leftSectionNavigation} gap={4}>
              <Button
                onClick={() => {
                  if (sessionPage > 1) {
                    const page = sessionPage - 1
                    setSessionPage(page)
                    setSessionPageInput(page.toString())
                  }
                }}
                disabled={sessionPage === 1}
              >
                ←
              </Button>
              <Space size={8}>
                <Input
                  value={sessionPageInput}
                  onChange={(e) => {
                    setSessionPageInput(e.target.value)
                    const value = parseInt(e.target.value)
                    if (value > 0 && value <= sessionTotalPages) {
                      setSessionPage(value)
                    }
                  }}
                  onBlur={(e) => {
                    const value = parseInt(e.target.value)
                    if (value <= 0) {
                      setSessionPage(1)
                      setSessionPageInput('1')
                    } else if (value > sessionTotalPages) {
                      setSessionPage(sessionTotalPages)
                      setSessionPageInput(sessionTotalPages.toString())
                    }
                  }}
                />
                /{sessionTotalPages}
              </Space>
              <Button
                onClick={() => {
                  const page = sessionPage + 1
                  setSessionPage(page)
                  setSessionPageInput(page.toString())
                }}
                disabled={sessionPage === sessionTotalPages}
              >
                →
              </Button>
            </Flex>
          </Flex>
        </Splitter.Panel>
        <Splitter.Panel>
          <Flex className={styles.chatSection} vertical gap={32}>
            <Flex
              className={styles.chatSectionMessageContainer}
              ref={contentRef}
            >
              <Flex className={styles.chatSectionMessageInner} vertical>
                <Flex
                  style={{
                    flex: 1,
                    marginBottom: conversations.length > 0 ? '72px' : undefined,
                  }}
                  vertical
                >
                  {renderStartPage(
                    mainChannel?.extraData?.openingQuestions ?? []
                  )}
                </Flex>
                {contextLoading ? (
                  <Skeleton active />
                ) : (
                  renderConversations(conversations)
                )}
              </Flex>
            </Flex>
            <Flex className={styles.chatSectionInputContainer}>
              <Flex className={styles.chatSectionInputInner} vertical gap={4}>
                <TextArea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Shift + Enter line feed; You can copy and paste/drag and drop uploaded files or images"
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  style={{ border: 'none' }}
                />
                <Flex className={styles.chatSectionInputInnerFunctions} gap={4}>
                  <Button
                    icon={<SendOutlined />}
                    type="text"
                    onClick={() => handleSendMessage(content)}
                    loading={sendMessageLoading}
                    style={{
                      backgroundColor: '#3278d9',
                      color: '#fff',
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Splitter.Panel>
      </Splitter>
      <SearchModal
        open={isSearchModalOpen}
        okText="Close"
        data={{
          agentId: agent?.id || 0,
          agentName: agent?.agentName || '',
          agentIconUUID: agent?.agentIconUUID || '',
        }}
        onFinish={() => {}}
        onCancel={() => setIsSearchModalOpen(false)}
      />
    </>
  )
}

export default observer(Chat)
