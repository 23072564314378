import {
  GroupOutlined,
  HomeOutlined,
  MailOutlined,
  PartitionOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  App,
  Button,
  Form,
  Input,
  Modal,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd'
import PasswordFormItem, {
  PasswordFormItemRefProps,
} from 'components/password-form-item'
import { observer } from 'mobx-react'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import fileStore, { FileBelongsType, FileDocument } from 'stores/file'
import userStore from 'stores/user'
import {
  getBase64,
  getFileUrl,
  getMessageFromError,
  isEmpty,
  UploadFileType,
} from 'utils/common'
import './index.css'

interface SettingsProps {
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: () => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

interface FormValues {
  organizationName: string
  organizationType: string
  roleName: string
  name: string
  email: string
  password: string
  oldPassword: string
}

const Settings: React.FC<SettingsProps> = (props) => {
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const [avatarUrl, setAvatarUrl] = useState('')
  const [fileList, setFileList] = useState<UploadFile<any>[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const passwordFormItemRef = useRef<PasswordFormItemRefProps>(null)

  const resetAvatar = () => {
    const url = getFileUrl(userStore.loginUser?.avatarUUID ?? '')
    setAvatarUrl(url)
  }

  const handleSave = async (values: FormValues) => {
    setConfirmLoading(true)
    try {
      const avatarId = userStore.loginUser?.avatarID ?? 0
      if (fileList.length > 0) {
        const file = fileList[0].response[0] as FileDocument
        const newAvatarId = file?.id ?? 0
        if (avatarId !== newAvatarId) {
          await userStore.updateAvatar(newAvatarId)
        }
      }
      if (values.name !== userStore.loginUser?.name) {
        await userStore.updateName(values.name)
      }
      if (passwordFormItemRef.current?.isPasswordChanged()) {
        await userStore.updatePassword(values.password, values.oldPassword)
      }
      return true
    } catch (error) {
      message.error(getMessageFromError(error))
      return false
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async (values: FormValues) => {
    const result = await handleSave(values)
    if (result && props.onFinish) {
      props.onFinish()
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    resetAvatar()
    form.resetFields()
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  const uploadProps: UploadProps = {
    showUploadList: false,
    multiple: false,
    action: fileStore.getUploadUrl(FileBelongsType.Avatar),
    beforeUpload: (file: UploadFileType) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
      }
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        message.error('Image must smaller than 10MB!')
      }
      return isJpgOrPng && isLt10M
    },
    onChange: (info) => {
      setFileList(info.fileList)

      switch (info.file.status) {
        case 'uploading':
          setIsUploading(true)
          break
        case 'done':
          setIsUploading(false)
          getBase64(info.file.originFileObj as UploadFileType, (url) => {
            setAvatarUrl(url)
          })
          break
        case 'error':
          setIsUploading(false)
          message.error(`${info.file.name} file upload failed.`)
          break
        case 'removed':
          setIsUploading(false)
          break
      }
    },
  }
  useEffect(() => {
    if (props.open) {
      resetAvatar()
      passwordFormItemRef.current?.reset()
    }
  }, [props.open])

  // Prevents rePassword and oldPassword from flashing when modal is closed
  if (!props.open) {
    return null
  }

  return (
    <Modal
      title="Setting"
      open={props.open}
      okText="Save"
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      footer={[]}
    >
      <Form
        form={form}
        name="settings"
        layout="vertical"
        className="settings-form"
        initialValues={{
          organizationName: userStore.loginUser?.organizationName,
          organizationType: userStore.loginUser?.organizationType,
          roleName: userStore.loginUser?.roleName,
          name: userStore.loginUser?.name,
          email: userStore.loginUser?.email,
        }}
        onFinish={handleFinish}
        style={{ maxWidth: 800, marginTop: '16px' }}
      >
        <Form.Item
          name="avatar"
          label="Profile Picture"
          valuePropName="fileList"
          getValueFromEvent={(e) => e.fileList}
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            {...uploadProps}
          >
            {isEmpty(avatarUrl) ? (
              <div>
                <UploadOutlined /> Upload
              </div>
            ) : (
              <div className="avatar-container">
                <img src={avatarUrl} alt="avatar" className="avatar-image" />
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="organizationName"
          label="Organization"
          rules={[
            { required: true, message: 'Please input your organizationName!' },
          ]}
        >
          <Input
            prefix={<HomeOutlined />}
            placeholder="organizationName"
            disabled
          />
        </Form.Item>
        <Form.Item
          name="organizationType"
          label="Organization Type"
          rules={[
            { required: true, message: 'Please input your organizationType!' },
          ]}
          style={{
            display: 'none',
          }}
        >
          <Input
            prefix={<PartitionOutlined />}
            placeholder="Organization Type"
            disabled
          />
        </Form.Item>
        <Form.Item
          name="roleName"
          label="Role"
          rules={[{ required: true, message: 'Please input your roleName!' }]}
          style={{
            display: 'none',
          }}
        >
          <Input prefix={<GroupOutlined />} placeholder="roleName" disabled />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input your email!' },
            {
              type: 'email',
              message: 'The input is not valid e-mail!',
            },
          ]}
          style={{
            display: 'none',
          }}
        >
          <Input prefix={<MailOutlined />} placeholder="Email" disabled />
        </Form.Item>
        <PasswordFormItem
          ref={passwordFormItemRef}
          form={form}
          password=""
          needOldPassword
        />
        <Form.Item className="actions">
          <Button onClick={handleCancel} style={{ marginRight: '8px' }}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={isUploading}>
            {props.okText || 'Save'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default observer(Settings)
