import {
  ConfigFormItemType,
  ConfigSectionType,
} from 'components/new-dynamic-form/types'
import { CommonConfig } from '../constants'

const ItemInputNumberConfig: ConfigSectionType[] = [
  ...CommonConfig,
  {
    sectionLabel: 'inputNumberConfig',
    items: [
      {
        itemLabel: 'DefaultValue',
        itemName: 'defaultValue',
        type: 'inputNumber',
      },
      { itemLabel: 'Placeholder', itemName: 'placeholder', type: 'input' },
      { itemLabel: 'Disabled', itemName: 'disabled', type: 'switch' },
      { itemLabel: 'Max', itemName: 'max', type: 'inputNumber' },
      { itemLabel: 'Min', itemName: 'min', type: 'inputNumber' },
      { itemLabel: 'Precision', itemName: 'precision', type: 'inputNumber' },
      { itemLabel: 'Step', itemName: 'step', type: 'inputNumber' },
      { itemLabel: 'StringMode', itemName: 'stringMode', type: 'switch' },
    ],
  },
]

export default ItemInputNumberConfig
